import React from 'react';
import { InputAdornment, StandardTextFieldProps } from '@mui/material';
import { theme } from 'styles/theme';
import { StyledTextField } from './styled';
import { Control, Controller } from 'react-hook-form';

interface MUIInputProps
  extends Pick<
    StandardTextFieldProps,
    | 'type'
    | 'label'
    | 'placeholder'
    | 'sx'
    | 'defaultValue'
    | 'disabled'
    | 'onChange'
    | 'value'
  > {
  mask?: string;
  icon?: React.ReactNode;
  readOnly?: boolean;
  name: string;
  control: Control<any>;
}

function Input({ name, control, icon, readOnly, ...props }: MUIInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledTextField
          {...props}
          onChange={onChange}
          value={value}
          helperText={error ? error.message : null}
          error={!!error}
          color="info"
          InputProps={{
            sx: {
              color: theme.palette.grey[900]
            },
            endAdornment: icon ? (
              <InputAdornment position="end">{icon}</InputAdornment>
            ) : (
              ''
            ),
            readOnly: readOnly
          }}
          InputLabelProps={{
            shrink: true
          }}
          variant="standard"
        />
      )}
    />
  );
}

export { Input };
