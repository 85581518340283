import { Statistic } from 'models/dashboard';

export const translateTnoName = (name: string): string => {
  switch (name) {
    case 'Suplemento nutricional hipercalórico':
      return 'Hipercalórico';
    case 'Suplemento nutricional hiperproteico':
      return 'Hiperproteico';
    case 'Suplemento nutricional imunomodulador':
      return 'Imunomodulador';
    case 'Suplemento nutricional hiper/hiper':
      return 'Hiper/hiper';
    case 'Módulo de fibra':
      return 'Mod Fibra';
    case 'Módulo proteico':
      return 'Mod Prot';
    default:
      return name;
  }
};

export const prepareTno = (tnos: Statistic[][]) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  let i = 1;
  for (const tno of tnos) {
    const data: any = {};
    data.id = i < 10 ? `C0${i}` : `C${i}`;

    if (tno) {
      for (const t of tno) {
        const name = translateTnoName(t.name);
        data[name] = t.quantity;
        ret.keys.add(name);
      }
    }

    ret.data.push(data);
    i++;
  }

  return ret;
};
