import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  AdvanceFormFooter,
  MainContentContainer,
  ToggleButtonComponent
} from 'components';
import HandGripForm from './forms/handGrip';
import TUGForm from './forms/tug';
import StandUpForm from './forms/standUp';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FIND_PATIENT_BY_ID } from 'services/graphql/queries/patient/getPatientById';
import { calculatePatientAge } from 'utils/functions';
import { Patient } from 'models/patient';
import { toast } from 'react-toastify';
import { StrengthFunctionality } from 'models/strenghtFunctionality';
import { FIND_STRENGHT_AND_FUNCTIONALITY } from 'services/graphql/queries/strenghtAndFunctionality/findStrenghtAndFunctionality';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FindByAppointmentIdVar } from 'shared';

interface PatientQueryType {
  findPatient: Patient;
}

interface StrenghtQueryType {
  findStFncByAppointmentId: StrengthFunctionality;
}

function StrenghtAndFuncionalityEvaluation() {
  const history = useHistory();
  const patientId = Number(localStorage.getItem('patientId'));
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [form, setForm] = useState('handGrip');
  const [toggleButtonSelectedValue, setToggleButtonSelectedValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const {
    data: dataPatient,
    loading: patientLoading,
    error: patientError
  } = useQuery<PatientQueryType>(FIND_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId
    }
  });
  const [loadStrenght, { data: dataStrenght, loading: loadingStrenght }] =
    useLazyQuery<StrenghtQueryType, FindByAppointmentIdVar>(
      FIND_STRENGHT_AND_FUNCTIONALITY,
      {
        fetchPolicy: 'no-cache',
        variables: {
          appointmentId
        }
      }
    );

  const { age, sex } = useMemo(() => {
    if (dataPatient?.findPatient) {
      const age = calculatePatientAge(dataPatient.findPatient?.birthdate);
      const sex = dataPatient.findPatient.gender;
      return { age, sex };
    }
    if (patientError) {
      toast.warn('Não foi possível carregar os dados do(a) paciente');
    }
    return { age: 0, sex: '' };
  }, [dataPatient]);

  const parsedStrenght = useMemo(() => {
    if (dataStrenght?.findStFncByAppointmentId) {
      return dataStrenght?.findStFncByAppointmentId;
    }
  }, [dataStrenght, loadingStrenght]);

  useEffect(() => {
    loadStrenght();
  }, []);

  function submitFormAndSetForm(form: string, selectedValue: number) {
    submitButtonRef.current?.click();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setForm(form);
      setToggleButtonSelectedValue(selectedValue);
      loadStrenght();
    }, 2000);
  }

  function switchForm() {
    if (form === 'handGrip' && age >= 60) submitFormAndSetForm('standUp', 2);
    if (form === 'handGrip' && age < 60) submitFormAndSetForm('tug', 3);
    if (form === 'standUp') submitFormAndSetForm('tug', 3);
    if (form === 'tug') submitForm();
  }
  function submitForm() {
    submitButtonRef.current?.click();
  }

  const [lastStrenght, setLastStrenght] = useState<
    StrengthFunctionality | undefined
  >();

  const { data, refetch: refetchLastAppointment } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    variables: {
      patientId
    }
  });

  function lastAppointmentStrenght() {
    if (data?.getLastPatientAppointment?.strengthFunctionality)
      setLastStrenght(data?.getLastPatientAppointment?.strengthFunctionality);
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    refetchLastAppointment();
    if (data?.getLastPatientAppointment?.strengthFunctionality)
      setHasLastAppointment(true);
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="11. Avaliação de força e funcionalidade"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentStrenght,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <ToggleButtonComponent
            selectedValue={toggleButtonSelectedValue}
            toggleButtonProps={[
              {
                title: 'Hand grip',
                value: 1,
                action: () => {
                  setForm('handGrip');
                  setToggleButtonSelectedValue(1);
                }
              },
              {
                title: 'Sentar e levantar da cadeira',
                value: 2,
                disabled: age < 60 ? true : false,
                action: () => {
                  setForm('standUp');
                  setToggleButtonSelectedValue(2);
                }
              },
              {
                title: 'TUG',
                value: 3,
                action: () => {
                  setForm('tug');
                  setToggleButtonSelectedValue(3);
                }
              }
            ]}
          />

          {loading || loadingStrenght || patientLoading ? (
            <CircularProgress />
          ) : (
            <>
              {form === 'handGrip' ? (
                <HandGripForm
                  lastStrenght={lastStrenght}
                  loadedStrenght={parsedStrenght}
                  age={age}
                  sex={sex}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'standUp' ? (
                <StandUpForm
                  lastStrenght={lastStrenght}
                  loadedStrenght={parsedStrenght}
                  age={age}
                  sex={sex}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'tug' ? (
                <TUGForm
                  lastStrenght={lastStrenght}
                  loadedStrenght={parsedStrenght}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/body-composition-evaluation')}
          action={() => {
            switchForm();
          }}
        />
      </Box>
    </Box>
  );
}

export { StrenghtAndFuncionalityEvaluation };
