import React, { useState, useEffect } from 'react';
import { Card, CardContent, Divider, Typography, Grid } from '@mui/material';
import { differenceInYears } from 'date-fns';

import { IColors } from 'shared/colors';
import { diagnoses } from 'utils/constants/diagnoses';
import { ChipComponent } from 'components/Chip';

import { Patient } from 'models/patient';

function DiagnoseChip({ diagnosis }: { diagnosis: string | undefined }) {
  const [name, setName] = useState('');
  const [color, setColor] = useState<IColors>({ colors: 'green' });

  useEffect(() => {
    const diag = diagnoses.find((d) => d.value === diagnosis);
    if (diag) setName(diag.label);

    switch (diagnosis) {
      case 'eutrophy':
        setColor({ colors: 'light-green' });
        break;
      case 'malnutrition':
        setColor({ colors: 'light-green' });
        break;
      case 'sarcopenia':
        setColor({ colors: 'light-green' });
        break;
      case 'obesity':
        setColor({ colors: 'light-green' });
        break;
      case 'sarcopenic':
        setColor({ colors: 'light-green' });
        break;
      case 'myopenia':
        setColor({ colors: 'light-green' });
        break;
      case 'overweight':
        setColor({ colors: 'light-green' });
        break;
      case 'cachexia':
        setColor({ colors: 'light-green' });
        break;
      default:
        break;
    }
  }, []);

  return (
    <ChipComponent
      sx={{ float: 'left', marginRight: '10px' }}
      label={name}
      color={color}
      size="small"
    />
  );
}

function Info({ title, value }: { title: string; value: string }) {
  return (
    <Grid item sm={6} sx={{ marginBottom: '10px' }}>
      <Typography color="#7B868C">{title}</Typography>
      <Typography color="#565656">{value}</Typography>
    </Grid>
  );
}

export function CardPatient({ patient }: { patient: Patient }) {
  const totalAppointments = patient.appointments.length;
  const fisrtAppointment = patient.appointments[0];
  const lastAppointment = totalAppointments
    ? patient.appointments[totalAppointments - 1]
    : undefined;
  const cid = fisrtAppointment?.diseaseData?.cids
    ? fisrtAppointment?.diseaseData?.cids[0]
    : undefined;
  const [modality, setModality] = useState('');

  const getAge = (date: Date) => {
    return differenceInYears(new Date(), new Date(date));
  };

  useEffect(() => {
    switch (patient.modality) {
      case 'SUS':
        setModality('SUS');
        break;
      case 'INSURANCE':
        setModality('Convênio');
        break;
      case 'PRIVATE':
        setModality('Particular');
        break;
      default:
        break;
    }
  }, [patient]);

  return (
    <Card sx={{ minWidth: 200, paddingBottom: '0px' }}>
      <CardContent>
        <DiagnoseChip diagnosis={lastAppointment?.diagnosis} />
        {patient.lastAppointment && (
          <Typography sx={{ color: '#7B868C' }}>
            {new Date(patient.lastAppointment).toLocaleDateString()}
            {new Date(patient.lastAppointment).toLocaleTimeString()}
          </Typography>
        )}
        <Typography
          color="#002169"
          variant="h5"
          sx={{ marginTop: '10px', fontWeight: 'bold' }}
        >
          {patient.name}
        </Typography>
        <Typography color="#565656" sx={{ fontWeight: 500 }}>
          {patient.appointments?.length} Consultas | {getAge(patient.birthdate)}
          Anos
        </Typography>
        <Divider sx={{ margin: '15px 0px' }} />
        <Grid container>
          <Info title="CID:" value={cid?.code || ''} />
          <Info
            title="Prescrição TNO:"
            value={
              lastAppointment?.nutritionalIntervention?.tno || 'Sem prescrição'
            }
          />
        </Grid>
        <Grid container>
          <Info
            title="Cód do Prontuário:"
            value={patient.medicalRecord.toString()}
          />
          <Info
            title="Data de Nascimento:"
            value={new Date(patient.birthdate).toLocaleDateString()}
          />
        </Grid>
        <Grid container>
          <Info
            title="Sexo:"
            value={patient.gender === 'MALE' ? 'Masculino' : 'Feminino'}
          />
          <Info title="Modalidade:" value={modality} />
        </Grid>
      </CardContent>
    </Card>
  );
}
