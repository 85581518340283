import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const apiURI = createHttpLink({
  uri: process.env.REACT_APP_API_URI
});

const authorization = setContext((_, { headers }) => {
  const token = localStorage.getItem('user_token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
const apolloClient = new ApolloClient({
  link: authorization.concat(apiURI),
  cache: new InMemoryCache()
});

export { apolloClient };
