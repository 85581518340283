import { format } from 'date-fns';
import * as yup from 'yup';
import { regexOnlyText } from 'utils';

export const validationSchema = yup.object({}).shape({
  appointmentDate: yup
    .date()
    .required('Insira uma data')
    .typeError('Esse campo deve possuir uma data válida'),
  appointmentHour: yup.string().required(),
  medicalRecord: yup
    .number()
    .typeError('Insira um número válido')
    .moreThan(0, 'O número do prontuário deve ser positivo'),
  name: yup
    .string()
    .required('Insira um nome')
    .min(6, 'Um nome deve conter pelo menos 6 caracteres')
    .matches(regexOnlyText, 'Apenas texto é permitido'),
  birthdate: yup
    .date()
    .required('Insira uma data de nascimento')
    .max(
      format(new Date(), 'yyyy-MM-dd'),
      'A data de nascimento deve ser menor que o dia atual'
    )
    .typeError('Esse campo deve possuir uma data válida'),
  age: yup
    .number()
    .max(130, 'A idade máxima permitida é 130 anos')
    .min(0, 'A idade deve ser positiva')
    .typeError('Idade inválida'),
  observations: yup
    .string()
    .max(500, 'A quantidade máxima de catacteres é 500')
    .nullable()
});
