import { gql } from '@apollo/client';

const CREATE_NUTRITIONAL_DATA = gql`
  mutation createNutritionalData($nutritionalData: NutritionalDataInput!) {
    createNutritionalData(nutritionalData: $nutritionalData) {
      id
      appointmentId
    }
  }
`;

export { CREATE_NUTRITIONAL_DATA };
