import { FullAppointment } from 'models/fullAppointment';
import { Patient } from 'models/patient';
import { calculatePatientAge } from 'utils/functions/calculateLoadedPatientAge';
import { generateDiseaseDataResults } from './renderDiseaseDataResult';
import { generateNutritionalDataShortResult } from './renderNutritionalDataResult';
import { generateNutritionalEvaluationResults } from './renderNutritionalEvaluationResults';

export function renderShortAppointmentResume(
  appointment: FullAppointment,
  patient: Patient
) {
  if (!appointment) return;
  const age = calculatePatientAge(patient.birthdate);
  const treatments = generateDiseaseDataResults(
    appointment.diseaseData
  )?.contents?.filter((item) => item.contentTitle?.includes('Tratamento'));
  const shortResume = [
    {
      title: 'Diagnóstico nutricional',
      contents: [generateNutritionalEvaluationResults(appointment)]
    },
    {
      title: 'Tratamento',
      contents: treatments
    },
    {
      title: '',
      contents: generateNutritionalDataShortResult(
        appointment?.NutritionalData,
        age
      )
    }
  ];
  return shortResume;
}
