import React, { useEffect, useState } from 'react';
import { Header } from 'components/Header';
import { InstDashboard } from './Institutional/index';
import jwt_decode from 'jwt-decode';
import { useMutation } from '@apollo/client';
import { SIGN_PRIVACY_TERM } from 'services/graphql/mutations/privacyTerm';
import { Box } from '@mui/material';
import { PrivacyTerm } from 'components/PrivacyTerm';

function InstitutionalDashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signUseTerm] = useMutation(SIGN_PRIVACY_TERM);
  const [userId, setUserId] = useState(0);

  const token = localStorage.getItem('user_token');

  useEffect(() => {
    if (!token) return;
    const decoded_token: {
      privacyTermSignDate: Date;
      sub: number;
    } = jwt_decode(token);
    if (decoded_token.privacyTermSignDate === null) setIsModalOpen(true);
    if (decoded_token.sub) setUserId(decoded_token.sub);
  }, []);

  async function signTerm() {
    await signUseTerm({
      variables: { userId },
      onCompleted: (data) => {
        localStorage.setItem('user_token', data.signPrivacyTerm);
        setIsModalOpen(false);
      }
    });
  }

  return (
    <>
      <Header />
      {!isModalOpen && (
        <Box padding="35px" bgcolor="paper">
          <InstDashboard />
        </Box>
      )}
      <PrivacyTerm action={signTerm} isOpen={isModalOpen} />
    </>
  );
}

export { InstitutionalDashboard };
