import { gql } from '@apollo/client';

export const FIND_MAN_BY_APPOINTMENT_ID = gql`
  query findManByAppointmentId($appointmentId: Float!) {
    findManByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      foodIntakeDecrease
      weightLoss
      mobility
      recentStress
      neurologicalProblems
      imc
    }
  }
`;
