import { gql } from '@apollo/client';

const UPDATE_DISEASE = gql`
  mutation updateDisease($diseaseData: DiseaseDataInput!) {
    updateDisease(diseaseData: $diseaseData) {
      appointmentId
      waterIntake
    }
  }
`;

export { UPDATE_DISEASE };
