import React from 'react';
import { Table, TableContainer, TableBody, TableRow } from '@mui/material';
import { theme } from 'styles/theme';
import { StyledTableCell, StyledTableCellHeader } from './styled';

interface RowType {
  firstCell: string;
  secondCell: string;
  thirdCell: string;
}

interface GlobalEvaluationTableProps {
  rows: RowType[];
  firstColumnselected: boolean;
  secondColumnselected: boolean;
  thirdColumnselected: boolean;
}

export function GlobalEvaluationTable({
  rows,
  firstColumnselected,
  secondColumnselected,
  thirdColumnselected
}: GlobalEvaluationTableProps) {
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <TableRow
            sx={{
              background: theme.palette.background.default,
              textAlign: 'center'
            }}
          >
            <StyledTableCellHeader
              selected={firstColumnselected}
              sx={{
                background: theme.palette.background.default,
                textAlign: 'center',
                border: '1px solid',
                borderColor: theme.palette.divider,
                fontWeight: '500',
                color: theme.palette.grey[300]
              }}
            >
              BEM NUTRIDO
            </StyledTableCellHeader>
            <StyledTableCellHeader selected={secondColumnselected}>
              DESNUTRIÇÃO SUSPEITA OU MODERADA
            </StyledTableCellHeader>
            <StyledTableCellHeader selected={thirdColumnselected}>
              GRAVEMENTE DESNUTRIDO
            </StyledTableCellHeader>
          </TableRow>
          {rows.map((row, index) => {
            return (
              <TableRow key={index}>
                <StyledTableCell selected={firstColumnselected}>
                  {row.firstCell}
                </StyledTableCell>
                <StyledTableCell selected={secondColumnselected}>
                  {row.secondCell}
                </StyledTableCell>
                <StyledTableCell selected={thirdColumnselected}>
                  {row.thirdCell}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
