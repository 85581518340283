import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  InputWithNumberMask,
  ChipComponent
} from 'components';
import { theme } from 'styles/theme';
import { toast } from 'react-toastify';
import { StrengthFunctionality } from 'models/strenghtFunctionality';
import {
  CREATE_STRENGHT_AND_FUNCTIONALITY,
  UPDATE_STRENGHT_AND_FUNCTIONALITY,
  StrenghtFunctionalityMutationsVars
} from 'services/graphql/mutations/strenghtAndFunctionality';
import { useMutation } from '@apollo/client';

interface IFormData {
  sitGetup: number;
}

export function calculateManStandUpReferenceNumber(age: number) {
  if (age >= 60 && age <= 64) return 17;
  if (age >= 65 && age <= 69) return 16;
  if (age >= 70 && age <= 75) return 15;
  if (age >= 75 && age <= 79) return 14;
  if (age >= 80 && age <= 84) return 13;
  if (age >= 85 && age <= 89) return 11;
  if (age >= 90 && age <= 94) return 9;
  return 0;
}

export function calculateWomanStandUpReferenceNumber(age: number) {
  if (age >= 60 && age <= 64) return 15;
  if (age >= 65 && age <= 69) return 15;
  if (age >= 70 && age <= 75) return 14;
  if (age >= 75 && age <= 79) return 13;
  if (age >= 80 && age <= 84) return 12;
  if (age >= 85 && age <= 89) return 11;
  if (age >= 90 && age <= 94) return 9;
  return 0;
}

export function setStandUpStatusFunction(
  minStandUpTimes: number,
  standUpTimes: number
) {
  if (standUpTimes !== minStandUpTimes) return 'Anormal';
  return 'Normal';
}

const StandUpForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    sex: string;
    age: number;
    loadedStrenght: StrengthFunctionality | undefined;
    lastStrenght: StrengthFunctionality | undefined;
  }
> = ({ sex, age, loadedStrenght, lastStrenght }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormData>();

  const watchStandUp = watch('sitGetup');
  const [standUpStatus, setStandUpStatus] = useState('Aguardando dados');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createStrenghtStandUpMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(CREATE_STRENGHT_AND_FUNCTIONALITY);
  const [updateStrenghtStandUpMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(UPDATE_STRENGHT_AND_FUNCTIONALITY);

  useEffect(() => {
    if (sex === 'MALE') {
      const minStandUpTimes = calculateManStandUpReferenceNumber(age);
      setStandUpStatus(setStandUpStatusFunction(minStandUpTimes, watchStandUp));
    }
    if (sex === 'FEMALE') {
      const minStandUpTimes = calculateWomanStandUpReferenceNumber(age);
      setStandUpStatus(setStandUpStatusFunction(minStandUpTimes, watchStandUp));
    }
  }, [watchStandUp]);

  function setLoadedStandUpValue(data: StrengthFunctionality | undefined) {
    if (!data) return;
    setValue('sitGetup', data.sitGetup);
  }

  useEffect(() => {
    setLoadedStandUpValue(loadedStrenght);
  }, [loadedStrenght]);

  useEffect(() => {
    setLoadedStandUpValue(lastStrenght);
  }, [lastStrenght]);

  async function createStrenght({ sitGetup }: IFormData) {
    if (loadedStrenght || !sitGetup) return;
    await createStrenghtStandUpMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          sitGetup
        }
      },
      onCompleted: () => {
        toast.success(
          'Avaliação de força e funcionalidade Sentar e levantar adicionada'
        );
      }
    });
  }

  async function updateStrenght({ sitGetup }: IFormData) {
    if (!loadedStrenght) return;
    await updateStrenghtStandUpMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          sitGetup
        }
      },
      onCompleted: () => {
        toast.info(
          'Avaliação de força e funcionalidade Sentar e levantar adicionada'
        );
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createStrenght(data);
      updateStrenght(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="25vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Sentar e levantar da cadeira
              </Typography>
              <TooltipComponent
                content="
                Pode ser usado como um substituto para avaliar a força muscular. 
                Consiste em avaliar em 30 segundos o número de vezes em que o paciente 
                se levanta da cadeira, sem usar os braços. Vale ressaltar que os pontos 
                de corte foram validados com a população idosa.
                "
              />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              sx={{ width: '40%' }}
              name="sitGetup"
              control={control}
              decimalScale={0}
              label={
                <Box>
                  <Typography>
                    Sentar e levantar da cadeira <i>(vezes):</i>
                  </Typography>
                </Box>
              }
              placeholder="Ex: 00 vezes"
              suffix=" vezes"
            />
          </Box>
          <ChipComponent
            sx={{ width: '55%' }}
            color={{
              colors: standUpStatus === 'Anormal' ? 'light-red' : 'light-blue'
            }}
            label={`Resistência de força de membros inferiores: ${standUpStatus}`}
            size="small"
          />
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(StandUpForm);
