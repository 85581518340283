import React from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { theme } from 'styles/theme';
import NumberFormat from 'react-number-format';

interface InputWithNumberMaskProps
  extends Pick<
    StandardTextFieldProps,
    'label' | 'placeholder' | 'sx' | 'disabled' | 'autoFocus'
  > {
  name: string;
  control: Control<any>;
  format?: string;
  decimalScale?: number;
  suffix?: string;
  limitNumberFunction?: () => boolean;
  fixedDecimalScale?: boolean;
}

function InputWithNumberMask({
  name,
  control,
  format,
  decimalScale,
  suffix,
  limitNumberFunction,
  fixedDecimalScale,
  ...props
}: InputWithNumberMaskProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <NumberFormat
          {...props}
          customInput={TextField}
          error={!!error}
          helperText={error ? error.message : null}
          variant="standard"
          color="info"
          suffix={suffix ? suffix : undefined}
          value={value}
          fixedDecimalScale={fixedDecimalScale}
          allowedDecimalSeparators={['.', ',']}
          isAllowed={limitNumberFunction ? limitNumberFunction : undefined}
          onValueChange={(e) => {
            onChange(e.floatValue);
          }}
          format={format ? format : undefined}
          decimalScale={decimalScale}
          InputProps={{
            sx: {
              color: theme.palette.grey[900],
              '&.MuiInput-underline:before': {
                borderColor: theme.palette.divider
              }
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    />
  );
}

export { InputWithNumberMask };
