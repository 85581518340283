import { gql } from '@apollo/client';

export const CREATE_ANTHROPOMETRIC = gql`
  mutation createAnthropometric($antropometricData: AntropometricInput!) {
    createAntropometric(antropometricData: $antropometricData) {
      id
      appointmentId
    }
  }
`;
