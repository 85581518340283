import { Appointment } from 'models/appointment';

export const prepareStrengthFunc = (appointments: Appointment[]) => {
  if (appointments) {
    return [
      {
        tag: 'Hand Grip',
        points: appointments.map((a) =>
          a.strengthFunctionality ? a.strengthFunctionality.handGrip : null
        )
      },
      {
        tag: 'Sentar e levantar',
        points: appointments.map((a) =>
          a.strengthFunctionality ? a.strengthFunctionality.sitGetup : null
        )
      },
      {
        tag: 'TUG',
        points: appointments.map((a) =>
          a.strengthFunctionality ? a.strengthFunctionality.tug : null
        )
      }
    ];
  } else {
    return [];
  }
};
