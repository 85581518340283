import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { Must as MustInterface } from 'models/must';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface MustProps {
  appointment: Appointment;
}

type MustKeys = 'imcPontuation' | 'weightLoss' | 'cannotFeed';

interface MustObjectKey {
  key: MustKeys;
}

const allMustFields: MustObjectKey[] = [
  { key: 'imcPontuation' },
  { key: 'weightLoss' },
  { key: 'cannotFeed' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newMustType = MappedType<MustInterface>;

export function Must({ appointment }: MustProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Must: newMustType = appointment.Must;
  if (!Must) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedMustQuestion(value: string) {
    switch (value) {
      case 'imcPontuation':
        return `Pontuação por IMC kg/m2:`;
      case 'weightLoss':
        return 'Perda de peso involuntária nos últimos 3 a 6 meses:';
      case 'cannotFeed':
        return `Se o indivíduo está gravemente
                doente e reduziu drasticamente
                a ingestão nutricional ou se
                prevê não conseguir alimentar-se durante menos de 5 dias:`;
      default:
        return 'Não há dados';
    }
  }

  function parsedMustAnswers(key: string, value: string) {
    const parsedValue = value.toString();
    if (key === 'imcPontuation') {
      switch (parsedValue) {
        case '0':
          return '0';
        case '1':
          return '1';
        case '2':
          return '2';
      }
    }
    if (key === 'weightLoss') {
      switch (parsedValue) {
        case '0':
          return '< 5%';
        case '1':
          return '5% a 10%';
        case '2':
          return '0 > 1%';
      }
    }
    if (key === 'cannotFeed') {
      switch (parsedValue) {
        case '0':
          return 'Não';
        case '2':
          return 'Sim';
      }
    }
  }

  const result = Must.cannotFeed + Must.imcPontuation + Must.weightLoss;
  function calculatedMustResult(): { status: string; color: IColors } {
    const parsedResult = Number(result);
    if (parsedResult >= 2 ? true : false)
      return { status: 'Alto risco', color: { colors: 'light-red' } };
    if (parsedResult === 1 ? true : false)
      return {
        status: 'Médio risco',
        color: { colors: 'light-yellow' }
      };
    if (parsedResult === 0 ? true : false)
      return { status: 'Baixo risco', color: { colors: 'light-green' } };
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-risk-screening')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              MUST: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allMustFields.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedMustQuestion(key)} />
                <ResultText title={`${parsedMustAnswers(key, Must[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: calculatedMustResult().color.colors }}
            label={calculatedMustResult().status}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
