import { Result } from '..';
import { FullAppointment } from 'models/fullAppointment';

function calculateNEASGPPPWeightLastWeeks(
  loadedAppointment: FullAppointment | undefined
) {
  const weightLoss = loadedAppointment?.neAsgppp.weightLastWeeks;
  switch (weightLoss) {
    case 'lessWeight':
      return 1;
    case 'sameWeight':
      return 0;
    case 'heavier':
      return 0;
    default:
      return 0;
  }
}

function calculateNEASGPPPLastMonthFood(
  loadedAppointment: FullAppointment | undefined
) {
  const lastMonthFood = loadedAppointment?.neAsgppp.lastMonthFood;
  switch (lastMonthFood) {
    case 'same':
      return 0;
    case 'more':
      return 0;
    case 'less':
      return 1;
    default:
      return 0;
  }
}

function calculateNEASGPPPCurrentFood(
  loadedAppointment: FullAppointment | undefined
) {
  const currentFood = loadedAppointment?.neAsgppp.currentFood;
  switch (currentFood) {
    case 'lessSolidFood':
      return 1;
    case 'muchLessSolidFood':
      return 2;
    case 'liquidFood':
      return 3;
    case 'suplements':
      return 3;
    case 'littleFood':
      return 4;
    case 'tube':
      return 0;
  }
  return 0;
}

function calculateNEASGPPPActivity(
  loadedAppointment: FullAppointment | undefined
) {
  const activity = loadedAppointment?.neAsgppp.activityLastMonth;
  switch (activity) {
    case 'normal':
      return 0;
    case 'almostNormal':
      return 1;
    case 'tired':
      return 2;
    case 'mostTimeInBed':
      return 3;
    case 'laidUp':
      return 3;
  }
  return 0;
}

function calculateNEASGPPPSymptomsPoints(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = loadedAppointment?.neAsgppp.normalFeed ? total + 0 : total + 1;
    total = loadedAppointment?.neAsgppp.nausea ? total + 1 : total + 0;
    total = loadedAppointment?.neAsgppp.constipation ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.mucositis ? total + 1 : total + 0;
    total = loadedAppointment?.neAsgppp.tasteless ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.swallowProblem ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.tiredness ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.appetiteLack ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.vomit ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.diarrhea ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.dryMouth ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.quicklySatified ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.smellsBother ? total + 2 : total + 0;
    total = loadedAppointment?.neAsgppp.others ? total + 1 : total + 0;
    total = loadedAppointment?.neAsgppp.pain ? total + 3 : total + 0;
    return total;
  }
  return total;
}

function calculateNEASGPPPConditions(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = loadedAppointment.neAsgppp.cancer ? total + 1 : total + 0;
    total = loadedAppointment.neAsgppp.cachexia ? total + 1 : total + 0;
    total = loadedAppointment.neAsgppp.trauma ? total + 1 : total + 0;
    total = loadedAppointment.neAsgppp.renalInsufficiency
      ? total + 1
      : total + 0;
    total = loadedAppointment.neAsgppp.aids ? total + 1 : total + 0;
    total = loadedAppointment.neAsgppp.decubitusUlcer ? total + 1 : total + 0;
    total = loadedAppointment.neAsgppp.moreThan65 ? total + 1 : total + 0;
    return total;
  }
  return total;
}

function calculateNEASGPPPFeverPoints(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = loadedAppointment.neAsgppp.fever;
    total = total + loadedAppointment.neAsgppp.feverDuration;
    total = total + loadedAppointment.neAsgppp.corticosteroids;
    return total;
  }
  return total;
}

function calculateNEASGPPPMusclePoints(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = total + loadedAppointment.neAsgppp.temple;
    total = total + loadedAppointment.neAsgppp.clavicle;
    total = total + loadedAppointment.neAsgppp.shoulder;
    total = total + loadedAppointment.neAsgppp.hands;
    total = total + loadedAppointment.neAsgppp.shoulderBlade;
    total = total + loadedAppointment.neAsgppp.thigh;
    total = total + loadedAppointment.neAsgppp.calf;
    total = total + loadedAppointment.neAsgppp.muscleGeneralState;
    return total;
  }
  return total;
}

function calculateNEASGPPPFatPoints(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = total + loadedAppointment.neAsgppp.periorbital;
    total = total + loadedAppointment.neAsgppp.triceps;
    total = total + loadedAppointment.neAsgppp.lowerRibs;
    total = total + loadedAppointment.neAsgppp.fatDeficitGeneralState;
    return total;
  }
  return total;
}

function calculateNEASGPPPHidratationPoints(
  loadedAppointment: FullAppointment | undefined
) {
  let total = 0;
  if (loadedAppointment?.neAsgppp) {
    total = total + loadedAppointment.neAsgppp.ankle;
    total = total + loadedAppointment.neAsgppp.sacralEdema;
    total = total + loadedAppointment.neAsgppp.hidratationGeneralState;
    return total;
  }
  return total;
}

export function calculateNEASGPPPResults(
  loadedAppointment: FullAppointment | undefined
) {
  if (loadedAppointment?.neAsgppp) {
    const weightLossPoints =
      calculateNEASGPPPWeightLastWeeks(loadedAppointment);
    const lastMonthFoodPoints =
      calculateNEASGPPPLastMonthFood(loadedAppointment);
    const currentFoodPoints = calculateNEASGPPPCurrentFood(loadedAppointment);
    const activityPoints = calculateNEASGPPPActivity(loadedAppointment);
    const symptomsPoints = calculateNEASGPPPSymptomsPoints(loadedAppointment);
    const conditionsPoints = calculateNEASGPPPConditions(loadedAppointment);
    const feverPoints = calculateNEASGPPPFeverPoints(loadedAppointment);
    const musclePoints = calculateNEASGPPPMusclePoints(loadedAppointment);
    const fatPoints = calculateNEASGPPPFatPoints(loadedAppointment);
    const hidratationPoints =
      calculateNEASGPPPHidratationPoints(loadedAppointment);
    const total =
      weightLossPoints +
      lastMonthFoodPoints +
      currentFoodPoints +
      activityPoints +
      symptomsPoints +
      conditionsPoints +
      feverPoints +
      musclePoints +
      fatPoints +
      hidratationPoints;
    if (total <= 1) return 'Nenhuma intervenção necessária no momento';
    if (total >= 2 && total <= 3)
      return 'Aconselhamento ao paciente e a seus familiares';
    if (total >= 4 && total <= 8) return 'Requer intervenção de nutricionista';
    if (total >= 9)
      return 'Indica uma necessidade urgente de conduta para a melhora dos sintomas e/ ou opções de intervenção nutricional';
  }
  return '';
}

function calculateNEAGSResults(loadedAppointment: FullAppointment | undefined) {
  if (loadedAppointment?.neAgs) {
    const result = loadedAppointment.neAgs.subjectiveEvaluation;
    if (result === 'severeMalnourish') return 'Gravemente desnutrido';
    if (result === 'malnourish')
      return 'Moderadamente (ou suspeita de ser) desnutrido';
    if (result === 'healthly') return 'Bem nutrido';
  }
}

function calculateNEMANResults(loadedAppointment: FullAppointment | undefined) {
  if (loadedAppointment?.neMan) {
    const {
      ingestDecrease,
      weightLoss,
      mobility,
      stress,
      neuroPsychological,
      bodyMass,
      ownHome,
      threeMedicines,
      eschar,
      mealsByDay,
      milkDerivatives,
      vegetablesEggs,
      meatFishBird,
      fruits,
      liquids,
      wayEating,
      nutritionalProblem,
      ownHealth,
      armCircumference,
      lagCircumference
    } = loadedAppointment.neMan;
    const parsedMilkDerivatives = milkDerivatives === 'yes' ? 1 : 0;
    const parsedVegetablesEggs = vegetablesEggs === 'yes' ? 1 : 0;
    const parsedMeatFishBird = meatFishBird === 'yes' ? 1 : 0;
    const result =
      Number(ingestDecrease) +
      Number(weightLoss) +
      Number(mobility) +
      Number(stress) +
      Number(neuroPsychological) +
      Number(bodyMass) +
      Number(ownHome) +
      Number(threeMedicines) +
      Number(eschar) +
      Number(mealsByDay) +
      Number(parsedMilkDerivatives) +
      Number(parsedVegetablesEggs) +
      Number(parsedMeatFishBird) +
      Number(fruits) +
      Number(liquids) +
      Number(wayEating) +
      Number(nutritionalProblem) +
      Number(ownHealth) +
      Number(armCircumference) +
      Number(lagCircumference);
    if (result >= 24 && result <= 30) return 'Normal';
    if (result >= 17 && result <= 23.5) return 'Risco de desnutrição';
    if (result < 17) return 'Desnutrido';
  }
}

export function generateNutritionalEvaluationResults(
  loadedAppointment: FullAppointment | undefined
): Result {
  if (loadedAppointment?.neAsgppp) {
    return {
      contentTitle: 'ASG-PPP',
      content: calculateNEASGPPPResults(loadedAppointment)
    };
  }
  if (loadedAppointment?.neMan) {
    return {
      contentTitle: 'MAN',
      content: calculateNEMANResults(loadedAppointment)
    };
  }
  if (loadedAppointment?.neAgs) {
    return {
      contentTitle: 'AGS',
      content: calculateNEAGSResults(loadedAppointment)
    };
  }
  return {
    contentTitle: 'Não há dados para exibir',
    content: ''
  };
}
