import {
  FormControlLabel,
  formControlLabelClasses,
  styled
} from '@mui/material';

interface StyledFormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled(
  FormControlLabel
)<StyledFormControlLabelProps>(({ theme, checked }) => ({
  [`&.${formControlLabelClasses.root}`]: {
    color: checked ? theme.palette.info.main : theme.palette.grey[900],
    minWidth: '10%'
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '14px'
  }
}));

export { StyledFormControlLabel };
