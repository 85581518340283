import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  IconButtonProps,
  Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Appointment } from 'models/appointment';
import { DiseaseData } from './historicAppointmentSteps/diseaseData';
import { Symptoms } from './historicAppointmentSteps/symptoms';
import { LifeQualityScale } from './historicAppointmentSteps/eortcLifeQuality';
import { Bioparams } from './historicAppointmentSteps/bioParams';
import { NutritionalData } from './historicAppointmentSteps/nutritionalData';
import { Man } from './historicAppointmentSteps/nutritionalRisk/man';
import { Mst } from './historicAppointmentSteps/nutritionalRisk/mst';
import { Must } from './historicAppointmentSteps/nutritionalRisk/must';
import { Nrs } from './historicAppointmentSteps/nutritionalRisk/nrs';
import { Asg } from './historicAppointmentSteps/nutritionalRisk/asgppp';
import { NeMan } from './historicAppointmentSteps/nutritionalEvaluation/neMan';
import { AGS } from './historicAppointmentSteps/nutritionalEvaluation/ags';
import { NeAsg } from './historicAppointmentSteps/nutritionalEvaluation/asgppp';
import { Anthropometric } from './historicAppointmentSteps/anthropometric';
import { Bia } from './historicAppointmentSteps/bodyComposition/bia';
import { Tc } from './historicAppointmentSteps/bodyComposition/tc';
import { Dxa } from './historicAppointmentSteps/bodyComposition/dxa';
import { StrenghtFunctionality } from './historicAppointmentSteps/strenghtFunctionality';
import { NutritionalClassification } from './historicAppointmentSteps/nutritionalClassification';
import { NutritionalIntervention } from './historicAppointmentSteps/nutritionalIntervention';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './historicAppointmentSteps/editAppointment';
import { useHistory } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  float: 'right',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

interface FullAppointmentProps {
  appointment: Appointment;
  gender: string;
  birthdate: Date;
}

export function FullAppointment({
  appointment,
  gender,
  birthdate
}: FullAppointmentProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function renderNutritionalScreening() {
    if (appointment.nrAsgppp) return <Asg appointment={appointment} />;
    if (appointment.MAN) return <Man appointment={appointment} />;
    if (appointment.Mst) return <Mst appointment={appointment} />;
    if (appointment.Must) return <Must appointment={appointment} />;
    if (appointment.Nrs) return <Nrs appointment={appointment} />;
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function renderNutritionalEvaluation() {
    if (appointment.neMan) return <NeMan appointment={appointment} />;
    if (appointment.neAgs) return <AGS appointment={appointment} />;
    if (appointment.neAsgppp) return <NeAsg appointment={appointment} />;
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |8. Avaliação nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }
  const [bcExpanded, setBcExpanded] = useState(false);
  const handleBcExpandClick = () => {
    setBcExpanded(!bcExpanded);
  };
  function renderBodyComposition() {
    if (
      appointment.bodyCompositionBia ||
      appointment.bodyCompositionTc ||
      appointment.bodyCompositionDxa
    ) {
      const history = useHistory();
      const appointmentId = appointment.id.toString();
      const patientId = appointment.patientId.toString();
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop="20px"
          >
            <Typography fontSize="18px" fontWeight="700" color="info.main">
              |10. Avaliação da composição corporal
            </Typography>
            <Box>
              <IconButton
                onClick={() =>
                  editAppointment(appointmentId, patientId, () =>
                    history.push('/home/body-composition-evaluation')
                  )
                }
              >
                <Edit />
              </IconButton>
              <ExpandMore
                expand={bcExpanded}
                onClick={handleBcExpandClick}
                aria-expanded={bcExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
          </Box>
          <Collapse in={bcExpanded} timeout="auto" unmountOnExit>
            <Bia appointment={appointment} />
            <Tc appointment={appointment} />
            <Dxa appointment={appointment} />
          </Collapse>
        </>
      );
    }
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |10. Avaliação da composição corporal
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  return (
    <Card sx={{ width: '100%' }}>
      <Typography
        color="#002169"
        sx={{
          float: 'left',
          padding: '10px 5px 10px 20px',
          fontWeight: 500
        }}
      >
        {`Consulta ${appointment.appointmentOrder}`}
      </Typography>
      <Typography color="#7B868C" sx={{ float: 'left', paddingTop: '10px' }}>
        {`| ${new Date(appointment.datetime).toLocaleDateString()}
         | ${new Date(appointment.datetime).toLocaleTimeString()}`}
      </Typography>
      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box padding="0 25px">
            <DiseaseData appointment={appointment} />
            <Symptoms appointment={appointment} />
            <LifeQualityScale appointment={appointment} />
            <Bioparams gender={gender} appointment={appointment} />
            <NutritionalData appointment={appointment} birthdate={birthdate} />
            {renderNutritionalScreening()}
            {renderNutritionalEvaluation()}
            <Anthropometric
              gender={gender}
              birthdate={birthdate}
              appointment={appointment}
            />
            {renderBodyComposition()}
            <StrenghtFunctionality
              gender={gender}
              birthdate={birthdate}
              appointment={appointment}
            />
            <NutritionalClassification appointment={appointment} />
            <NutritionalIntervention appointment={appointment} />
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
