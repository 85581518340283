import { HighlightOff } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

interface IRemovableChipComponentProps {
  chipTitle: string;
  deleteChip(chipToDelete: string): void;
}

function RemovableChipComponent({
  chipTitle,
  deleteChip
}: IRemovableChipComponentProps) {
  return chipTitle !== '' ? (
    <Chip
      size="small"
      variant="outlined"
      color="info"
      sx={{
        border: 'none',
        padding: '0 10px',
        marginRight: '10px',
        marginBottom: '10px',
        fontWeight: '500',
        background: theme.palette.info.light,
        color: theme.palette.info.main
      }}
      onDelete={() => deleteChip(chipTitle)}
      deleteIcon={<HighlightOff color="primary" />}
      label={chipTitle}
    />
  ) : (
    <span></span>
  );
}

export { RemovableChipComponent };
