import { TableCell, tableCellClasses, TableRowProps } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableCellHeader = styled(TableCell)<TableRowProps>(
  ({ theme, selected }) => ({
    [`&.${tableCellClasses.body}`]: {
      background: selected ? theme.palette.info.main : theme.palette.grey[100],
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      fontWeight: '500',
      color: selected ? theme.palette.background.paper : theme.palette.info.main
    }
  })
);

export const StyledTableCell = styled(TableCell)<TableRowProps>(
  ({ theme, selected }) => ({
    [`&.${tableCellClasses.body}`]: {
      padding: '10px',
      background: selected ? theme.palette.info.main : theme.palette.grey[100],
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      fontWeight: '500',
      color: selected ? theme.palette.background.paper : theme.palette.grey[900]
    }
  })
);
