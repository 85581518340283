export interface StagingDropdownType {
  code: string;
  staging: string;
}

export interface IFormInput {
  cids: StagingDropdownType[];
  others: othersInput[];
  smoking: 'yes' | 'no';
  alcoholism: 'yes' | 'no';
  bowelHabit: string;
  urinaryHabit: string;
  waterIntake: string;
  treatments: cidAndTratmentsInput[];
}

export const defaultValues: IFormInput = {
  cids: [{ code: '', staging: '' }],
  others: [],
  smoking: 'no',
  alcoholism: 'no',
  bowelHabit: 'oncePerDay',
  urinaryHabit: 'oncePerDay',
  waterIntake: 'betweenOneAndTwoCups',
  treatments: []
};

export interface cidAndTratmentsInput {
  code: string;
}
export type othersType =
  | 'ALLERGIES'
  | 'PRE_EXISTENT'
  | 'FAMILY_BACKGROUND'
  | 'INTOLERANCES';

export interface othersInput {
  type: othersType;
  description: string;
}

export interface CreateDiseaseInput {
  appointmentId: number;
  smoking: boolean;
  alcoholism: boolean;
  bowelHabit: string;
  urinaryHabit: string;
  waterIntake: string;
  cids: cidAndTratmentsInput[];
  treatments: cidAndTratmentsInput[];
  others: othersInput[];
}
