import { Statistic } from 'models/dashboard';

interface Props {
  data: {
    asgPpp: Statistic[][];
    man: Statistic[][];
    mst: Statistic[][];
    must: Statistic[][];
    nrs: Statistic[][];
  };
  selected: number;
}

function tranlateTriageResult(value: string) {
  switch (value) {
    case 'malnourished':
      return 'Desnutrido';
    case 'risk':
      return 'Risco desnutrição';
    case 'normal':
      return 'Normal';
    case 'high':
      return 'Alto risco';
    case 'medium':
      return 'Médio risco';
    case 'low':
      return 'Baixo risco';
    case 'nutritional risk':
      return 'Risco nutricional';
    case 'reevaluate':
      return 'Reavaliar';
    case 'none':
      return 'Interv desnecessária';
    case 'counseling':
      return 'Aconselhamento';
    case 'intervention':
      return 'Requer interv';
    case 'urgent':
      return 'Conduta Urgente';
    default:
      return 'Não há dados';
  }
}

export const prepareTriage = ({ data, selected }: Props) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  let dt: Statistic[][] = [];
  if (selected === 0) dt = data.asgPpp;
  else if (selected === 1) dt = data.man;
  else if (selected === 2) dt = data.mst;
  else if (selected === 3) dt = data.must;
  else if (selected === 4) dt = data.nrs;

  let i = 1;
  for (const d of dt) {
    const data: any = {};
    data.id = i < 10 ? `C0${i}` : `C${i}`;

    if (d) {
      for (const st of d) {
        const name = tranlateTriageResult(st.name);
        data[name] = st.quantity;
        ret.keys.add(name);
      }
    }
    ret.data.push(data);
    i++;
  }
  return ret;
};
