import { gql } from '@apollo/client';

export const CREATE_MST = gql`
  mutation createMST($mst: MSTInput!) {
    createMST(mst: $mst) {
      id
      appointmentId
    }
  }
`;
