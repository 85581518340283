import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { addConsultationRoutes } from '../allRoutes';
import jwt_decode from 'jwt-decode';

function AddConsultationRoutes() {
  function decodeTokenAndSetRole() {
    const token = localStorage.getItem('user_token');
    if (!token) return false;
    const decodedJWTRole: { role: string } = jwt_decode(token);
    return decodedJWTRole.role === 'NUTRITIONIST' ? true : false;
  }

  return (
    <Switch>
      {addConsultationRoutes.map((route) => {
        return decodeTokenAndSetRole() ? (
          <Route key={`${route.component}`} {...route} />
        ) : (
          <Redirect key={`${route.component}`} to="/dashboard/institutional" />
        );
      })}
    </Switch>
  );
}

export { AddConsultationRoutes };
