import { ChipProps } from '@mui/material';
import React from 'react';
import { IColors } from 'shared/colors';
import { StyledChip } from './style';

interface ChipComponentChips extends Pick<ChipProps, 'sx' | 'size'> {
  color: IColors;
  label: string | React.ReactNode;
}

function ChipComponent({ color, label, ...props }: ChipComponentChips) {
  return (
    <StyledChip {...props} colors={{ colors: color.colors }} label={label} />
  );
}

export { ChipComponent };
