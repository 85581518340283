import { Appointment } from 'models/appointment';

function getLines(period: (string | undefined)[]) {
  return [
    {
      name: '1x por dia',
      occurrences: period.reduce(
        (arr: number[], elm, i) =>
          elm === 'oncePerDay' ? [...arr, i + 1] : arr,
        []
      )
    },
    {
      name: 'Mais de 1x no dia',
      occurrences: period.reduce(
        (arr: number[], elm, i) =>
          elm === 'moreThanOncePerDay' ? [...arr, i + 1] : arr,
        []
      )
    },
    {
      name: '1x na semana',
      occurrences: period.reduce(
        (arr: number[], elm, i) =>
          elm === 'oncePerWeek' ? [...arr, i + 1] : arr,
        []
      )
    },
    {
      name: 'Mais de 1x na semana',
      occurrences: period.reduce(
        (arr: number[], elm, i) =>
          elm === 'moreThanOncePerWeek' ? [...arr, i + 1] : arr,
        []
      )
    },
    {
      name: '1 x no mês',
      occurrences: period.reduce(
        (arr: number[], elm, i) =>
          elm === 'oncePerMonth' ? [...arr, i + 1] : arr,
        []
      )
    },
    {
      name: 'Raramente',
      occurrences: period.reduce(
        (arr: number[], elm, i) => (elm === 'rarely' ? [...arr, i + 1] : arr),
        []
      )
    }
  ];
}

export const prepareSymptomsPeriod = (appointments: Appointment[]) => {
  const ret = [
    {
      name: 'Obstipação',
      lines: getLines(appointments.map((a) => a.symptoms?.constipation))
    },
    {
      name: 'Náusea',
      lines: getLines(appointments.map((a) => a.symptoms?.nausea))
    },
    {
      name: 'Anosmia',
      lines: getLines(appointments.map((a) => a.symptoms?.anosmia))
    },
    {
      name: 'Rapidamente satisfeito',
      lines: getLines(appointments.map((a) => a.symptoms?.quicklySatisfied))
    },
    {
      name: 'Boca seca - Xerostomia',
      lines: getLines(appointments.map((a) => a.symptoms?.dryMouth))
    },
    {
      name: 'Disfagia',
      lines: getLines(appointments.map((a) => a.symptoms?.dysphagia))
    },
    {
      name: 'Sem apetite',
      lines: getLines(appointments.map((a) => a.symptoms?.noAppetite))
    },
    {
      name: 'Dor',
      lines: getLines(appointments.map((a) => a.symptoms?.ache))
    },
    {
      name: 'Diarreia',
      lines: getLines(appointments.map((a) => a.symptoms?.diarrhea))
    },
    {
      name: 'Vômito',
      lines: getLines(appointments.map((a) => a.symptoms?.vomit))
    },
    {
      name: 'Os cheiros incomodam',
      lines: getLines(appointments.map((a) => a.symptoms?.smellBothers))
    },
    {
      name: 'Inapetência',
      lines: getLines(appointments.map((a) => a.symptoms?.inappetence))
    },
    {
      name: 'Feridas na boca - Mucosite',
      lines: getLines(appointments.map((a) => a.symptoms?.mucositis))
    },
    {
      name: 'Cansaço',
      lines: getLines(appointments.map((a) => a.symptoms?.tiredness))
    },
    {
      name: 'Disgeusia',
      lines: getLines(appointments.map((a) => a.symptoms?.dysgeusia))
    }
  ];
  return ret;
};
