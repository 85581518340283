import { gql } from '@apollo/client';

export const FIND_DXA_BY_APPOINTMENT_ID = gql`
  query findDxaByAppointmentId($appointmentId: Float!) {
    findDxaByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      appendicularMuscleMass
    }
  }
`;
