import { Statistic } from 'models/dashboard';
import { parseDiagnosisResult } from './translateDiagnosis';

export const prepareNutriStateTreatments = (
  data: {
    key: string;
    statistics: Statistic[];
  }[]
) => {
  const ret: { data: any[]; keys: string[] } = { data: [], keys: [] };

  for (const dt of data) {
    const nst: any = {};
    nst.id = parseDiagnosisResult(dt.key);

    for (const c of dt.statistics) {
      nst[c.name] = c.quantity;
      if (ret.keys.indexOf(c.name) === -1) ret.keys.push(c.name);
    }

    ret.data.push(nst);
  }

  return ret;
};
