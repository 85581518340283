import React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import './styles/global.css';
import { AppRoutes } from './routes/index';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './services/graphql/graphql.client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
