import { Typography } from '@mui/material';
import React from 'react';

interface LabelProps {
  title: string;
}

function Label({ title }: LabelProps) {
  return (
    <Typography fontSize="14px" fontWeight="400" color="grey.900">
      {title}
    </Typography>
  );
}

function ResultText({ title }: LabelProps) {
  return (
    <Typography fontSize="16px" fontWeight="700" color="grey.900">
      {title}
    </Typography>
  );
}

export { Label, ResultText };
