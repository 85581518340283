import { Appointment } from 'models/appointment';

export const prepareLifeQuality = (appointments: Appointment[]) => {
  if (appointments) {
    return [
      {
        tag: 'Escala funcional',
        points: appointments.map((a) =>
          a.ecog?.functionalScale || a.ecog?.functionalScale === 0
            ? a.ecog?.functionalScale
            : null
        )
      },
      {
        tag: 'Escala de sintomas',
        points: appointments.map((a) =>
          a.ecog?.symptomsScale || a.ecog?.symptomsScale === 0
            ? a.ecog?.symptomsScale
            : null
        )
      },
      {
        tag: 'Status da saúde global',
        points: appointments.map((a) =>
          a.ecog?.globalHealthStatus || a.ecog?.globalHealthStatus == 0
            ? a.ecog?.globalHealthStatus
            : null
        )
      }
    ];
  } else {
    return [];
  }
};
