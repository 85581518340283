import React, { useState } from 'react';
import { Box, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { AGS as AgsInterface } from 'models/ags';
import { ChipComponent } from 'components/Chip';
import { Subtitle } from 'components/Subtitle';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface AgsProps {
  appointment: Appointment;
}

type AgsKeys =
  | 'lost6Mont'
  | 'wheighthangeLastWeeks'
  | 'changeIngestion'
  | 'changeIngDuration'
  | 'changeIngType'
  | 'noGastroSymptoms'
  | 'nausea'
  | 'vomit'
  | 'diarrhea'
  | 'anorexia'
  | 'functionalCapacity'
  | 'functionalCapDuration'
  | 'functionalCapType'
  | 'primaryDiagnosis'
  | 'metabolicDemand'
  | 'fatLoss'
  | 'muscleLoss'
  | 'ankleEdema'
  | 'sacralEdema'
  | 'ascites'
  | 'diseaseChangeLastWeeks'
  | 'subjectiveEvaluation';

interface AgsObjectKey {
  key: AgsKeys;
}

const weightAgsQuestions: AgsObjectKey[] = [
  { key: 'lost6Mont' },
  { key: 'wheighthangeLastWeeks' }
];

const foodAgsQuestions: AgsObjectKey[] = [
  { key: 'changeIngestion' },
  { key: 'changeIngDuration' },
  { key: 'changeIngType' }
];

const symptomsAgsQuestions: AgsObjectKey[] = [
  { key: 'noGastroSymptoms' },
  { key: 'nausea' },
  { key: 'vomit' },
  { key: 'diarrhea' },
  { key: 'anorexia' }
];

const functionalCapacity: AgsObjectKey[] = [
  { key: 'functionalCapacity' },
  { key: 'functionalCapDuration' },
  { key: 'functionalCapType' }
];

const nutritionalRelation: AgsObjectKey[] = [
  { key: 'primaryDiagnosis' },
  { key: 'metabolicDemand' }
];

const physicExam: AgsObjectKey[] = [
  { key: 'fatLoss' },
  { key: 'muscleLoss' },
  { key: 'ankleEdema' },
  { key: 'sacralEdema' },
  { key: 'ascites' },
  { key: 'diseaseChangeLastWeeks' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newAgsType = MappedType<AgsInterface>;

export function AGS({ appointment }: AgsProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Ags: newAgsType = appointment.neAgs;
  if (!Ags) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Avaliação nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedAgsQuestion(value: string) {
    switch (value) {
      case 'lost6Mont':
        return 'Perda total nos últimos 6 meses (kg):';
      case 'wheighthangeLastWeeks':
        return 'Alteração nas últimas duas semanas:';
      case 'changeIngestion':
        return 'Alteração na ingestão alimentar:';
      case 'changeIngDuration':
        return 'Duração (semanas):';
      case 'changeIngType':
        return 'Tipo:';
      case 'noGastroSymptoms':
        return 'Nenhum';
      case 'nausea':
        return 'Naúsea';
      case 'vomit':
        return 'Vômitos';
      case 'diarrhea':
        return 'Diarreia';
      case 'anorexia':
        return 'Anorexia';
      case 'functionalCapacity':
        return 'Capacidade funcional:';
      case 'functionalCapDuration':
        return 'Duração (semanas):';
      case 'functionalCapType':
        return 'Tipo:';
      case 'primaryDiagnosis':
        return 'Diagnóstico primário:';
      case 'metabolicDemand':
        return 'Demanda metabólica (estresse):';
      case 'fatLoss':
        return 'Perda de gordura subcutânea (tríceps, tórax):';
      case 'muscleLoss':
        return 'Perda muscular (quadríceps, deltoide)';
      case 'ankleEdema':
        return 'Edema tornozelo';
      case 'sacralEdema':
        return 'Edema sacral';
      case 'ascites':
        return 'Ascite';
      case 'diseaseChangeLastWeeks':
        return 'Alteração nas últimas duas semanas:';
      case 'subjectiveEvaluation':
        return 'Avaliação subjetiva global:';
      default:
        return 'Não há dados';
    }
  }

  function parsedWeightAgsAnswers(
    key: string,
    value: string | number | boolean
  ) {
    if (key === 'lost6Mont') {
      return value + 'kg';
    }
    if (key === 'wheighthangeLastWeeks') {
      switch (value) {
        case 'increase':
          return 'Aumento';
        case 'noAlteration':
          return 'Sem alteração';
        case 'decrease':
          return 'Diminuição';
      }
    }
  }

  function parsedFoodAgsAnswers(key: string, value: string | number | boolean) {
    if (key === 'changeIngestion') {
      switch (value) {
        case 'noAlteration':
          return 'Sem alteração';
        case 'changed':
          return 'Alterada';
      }
    }
    if (key === 'changeIngDuration') {
      return value ? value + ' Semana(s)' : 'Sem alteração';
    }
    if (key === 'changeIngType') {
      switch (value) {
        case 'solid':
          return 'Dieta sólida subótima';
        case 'liquid':
          return 'Dieta líquida completa';
        case 'hypocaloricLiquids':
          return 'Líquidos hipocalóricos';
        case 'starvation':
          return 'Inanição';
      }
    }
  }

  function parsedFunctionalCapAgsAnswers(
    key: string,
    value: string | boolean | number
  ) {
    if (key === 'functionalCapacity') {
      switch (value) {
        case 'noDisfunction':
          return 'Sem disfunção (capacidade completa)';
        case 'disfunction':
          return 'Disfunção';
      }
    }
    if (key === 'functionalCapDuration') {
      return value ? value + ' Semana(s)' : 'Sem alteração';
    }
    if (key === 'functionalCapType') {
      switch (value) {
        case 'suboptimalWork':
          return 'Trabalho subótimo';
        case 'ambulatory':
          return 'Ambulatório';
        case 'bedridden':
          return 'Acamado';
      }
    }
  }

  function parsedNutritionalRelationAgsAnswers(
    key: string,
    value: string | boolean | number
  ) {
    if (key === 'primaryDiagnosis') {
      return value || 'Sem diagnóstico';
    }

    if (key === 'metabolicDemand') {
      switch (value) {
        case 'noStress':
          return 'Sem estresse';
        case 'low':
          return 'Baixo estresse';
        case 'moderate':
          return 'Estresse moderado';
        case 'high':
          return 'Estresse elevado';
      }
    }
  }

  function switchPhysicExamValue(value: string | boolean | number) {
    switch (value) {
      case '0':
        return '0 = normal';
      case '1':
        return '+ 1 = leve';
      case '2':
        return '+ 2 = moderado';
      case '3':
        return '+ 3 = grave';
    }
  }

  function parsedPhysicExamAgsAnswers(
    key: string,
    value: string | boolean | number
  ) {
    if (key === 'fatLoss') {
      return switchPhysicExamValue(value);
    }
    if (key === 'muscleLoss') {
      return switchPhysicExamValue(value);
    }
    if (key === 'ankleEdema') {
      return switchPhysicExamValue(value);
    }
    if (key === 'sacralEdema') {
      return switchPhysicExamValue(value);
    }
    if (key === 'ascites') {
      return switchPhysicExamValue(value);
    }
    if (key === 'diseaseChangeLastWeeks') {
      switch (value) {
        case 'increase':
          return 'Aumento';
        case 'noAlteration':
          return 'Sem alteração';
        case 'decrease':
          return 'Diminuição';
      }
    }
  }

  function parsedSubjectiveEcaluationAgsAnswers(
    value: string | boolean | number
  ) {
    switch (value) {
      case 'healthly':
        return 'Bem nutrido';
      case 'malnourish':
        return 'Moderadamente (ou suspeita de ser) desnutrido';
      case 'severeMalnourish':
        return 'Gravemente desnutrido';
    }
  }

  function generateAGSResult(): { status: string; color: IColors } {
    switch (Ags.subjectiveEvaluation) {
      case 'healthly':
        return { status: 'Bem nutrido', color: { colors: 'light-green' } };
      case 'malnourish':
        return {
          status: 'Moderadamente (ou suspeita de ser) desnutrido',
          color: { colors: 'light-orange' }
        };
      case 'severeMalnourish':
        return {
          status: 'Gravemente desnutrido',
          color: { colors: 'light-red' }
        };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |8. Avaliação nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-evaluation')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              AGS: <strong>Bem nutrido</strong>
            </Typography>
          }
        />
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ALTERAÇÃO NO PESO:" />
          <Box
            width="82%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {weightAgsQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAgsQuestion(key)} />
                <ResultText
                  title={`${parsedWeightAgsAnswers(key, Ags[key])}`}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ALTERAÇÃO NA INGESTÃO ALIMENTAR" />
          <Box
            width="65%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {foodAgsQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAgsQuestion(key)} />
                <ResultText title={`${parsedFoodAgsAnswers(key, Ags[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="SINTOMAS GASTRINTESTINAIS" />
          <Box
            width="72%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {symptomsAgsQuestions.map(({ key }, index) => {
            return Ags[key] ? (
              <Box key={index} margin="15px 0" marginRight="15px">
                <Chip
                  sx={{
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={parsedAgsQuestion(key)}
                />
              </Box>
            ) : (
              ''
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="CAPACIDADE FUNCIONAL" />
          <Box
            width="78%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          {functionalCapacity.map(({ key }) => {
            return (
              <Box key={key} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAgsQuestion(key)} />
                <ResultText
                  title={
                    parsedFunctionalCapAgsAnswers(key, Ags[key]) ||
                    'Não há dados'
                  }
                />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="DOENÇA E SUA RELAÇÃO COM NECESSIDADES NUTRICIONAIS" />
          <Box
            width="51%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexDirection="column"
        >
          {nutritionalRelation.map(({ key }) => {
            return (
              <Box key={key} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAgsQuestion(key)} />
                <ResultText
                  title={`${parsedNutritionalRelationAgsAnswers(
                    key,
                    Ags[key]
                  )}`}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="EXAME FÍSICO" />
          <Box
            width="88%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          {physicExam.map(({ key }) => {
            return (
              <Box key={key} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAgsQuestion(key)} />
                <ResultText
                  title={`${parsedPhysicExamAgsAnswers(key, Ags[key])}`}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="AVALIAÇÃO SUBJETIVA GLOBAL" />
          <Box
            width="75%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title={parsedAgsQuestion('subjectiveEvaluation')} />
            <ResultText
              title={`${parsedSubjectiveEcaluationAgsAnswers(
                Ags.subjectiveEvaluation
              )}`}
            />
          </Box>
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: generateAGSResult().color.colors }}
            label={`${generateAGSResult().status}`}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
