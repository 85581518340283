import React from 'react';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';
import { theme } from 'styles/theme';
import { useHistory } from 'react-router-dom';

function ConsultationStepsList() {
  const history = useHistory();
  const routes = [
    '/home/appointment-and-pacient-data',
    '/home/disease-data',
    '/home/symptons',
    '/home/life-quality-scale',
    '/home/biochemical-parameters',
    '/home/nutritional-data-and-information',
    '/home/nutritional-risk-screening',
    '/home/nutritional-evaluation',
    '/home/anthropometric-evaluation',
    '/home/body-composition-evaluation',
    '/home/strength-functionality-evaluation',
    '/home/nutritional-diagnosis-and-classification',
    '/home/nutritional-intervention'
  ];
  const path = history.location.pathname;
  const activeRouteIndex = routes.findIndex((pathIndex) => pathIndex === path);
  function isActive(routeIndex: number) {
    return activeRouteIndex === routeIndex;
  }
  function isDone(routeIndex: number) {
    return activeRouteIndex > routeIndex;
  }
  const consultationSteps = [
    {
      label: '1. Dados da consulta e do paciente',
      isActive: isActive(0),
      isDone: isDone(0)
    },
    {
      label: '2. Dados da doença',
      isActive: isActive(1),
      isDone: isDone(1)
    },
    {
      label: '3. Sintomas',
      isActive: isActive(2),
      isDone: isDone(2)
    },
    {
      label: '4. Escala de qualidade de vida',
      isActive: isActive(3),
      isDone: isDone(3)
    },
    {
      label: '5. Parâmetros bioquímicos',
      isActive: isActive(4),
      isDone: isDone(4)
    },
    {
      label: '6. Dados e informações nutricionais',
      isActive: isActive(5),
      isDone: isDone(5)
    },
    {
      label: '7. Triagem do risco nutricional',
      isActive: isActive(6),
      isDone: isDone(6)
    },
    {
      label: '8. Avaliação nutricional',
      isActive: isActive(7),
      isDone: isDone(7)
    },
    {
      label: '9. Avaliação antropométrica',
      isActive: isActive(8),
      isDone: isDone(8)
    },
    {
      label: '10. Avaliação da composição corporal',
      isActive: isActive(9),
      isDone: isDone(9)
    },
    {
      label: '11. Avaliação da força e funcionalidade',
      isActive: isActive(10),
      isDone: isDone(10)
    },
    {
      label: '12. Classificação e diagnóstico nutricional',
      isActive: isActive(11),
      isDone: isDone(11)
    },
    {
      label: '13. Intervenção nutricional',
      isActive: isActive(12),
      isDone: isDone(12)
    }
  ];

  return (
    <Box display="flex" flexDirection="column" width="30%">
      <ListSubheader
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          color: theme.palette.grey[900],
          background: theme.palette.background.paper,
          width: '75%',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
          borderRadius: '4px 13px 0px 0px'
        }}
        disableSticky
      >
        Etapas do cadastro
      </ListSubheader>
      <List
        sx={{
          background: theme.palette.background.paper,

          width: '100%',
          maxHeight: '75vh',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: '8px'
          },
          '::-webkit-scrollbar-track': {
            background: theme.palette.background.default
          },
          '::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[400],
            borderRadius: '20px'
          },
          borderRadius: '4px'
        }}
      >
        <Box
          sx={{
            borderRadius: '0px 25px 4px 4px'
          }}
        >
          {consultationSteps.map((step) => {
            return (
              <ListItem
                sx={{
                  color:
                    step.isActive || step.isDone
                      ? theme.palette.info.main
                      : theme.palette.grey[900],

                  borderBottom: '1px solid',
                  background: step.isActive
                    ? theme.palette.info.light
                    : theme.palette.background.paper,
                  borderColor: theme.palette.divider
                }}
                key={step.label}
                secondaryAction={
                  <Checkbox
                    checked={step.isDone}
                    edge="end"
                    icon={<CircleOutlined />}
                    checkedIcon={<CheckCircle color="success" />}
                  />
                }
              >
                <ListItemText>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: step.isActive ? 'bold' : '400'
                    }}
                  >
                    {step.label}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </Box>
      </List>
    </Box>
  );
}

export { ConsultationStepsList };
