import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Chip, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { Subtitle, ToggleButtonComponent } from 'components';
import { theme } from 'styles/theme';
import {
  IQuestionsType,
  thirdStepQuestions,
  lastStepQuestions,
  secondStepQuestions,
  firstStepQuestions
} from './questions';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  CREATE_ECOG,
  UPDATE_ECOG,
  CreateEcogVars,
  UpdateEcogVars
} from 'services/graphql/mutations/ecog';
import { toast } from 'react-toastify';
import { Ecog } from 'models/ecog';
import { limitDecimal } from 'utils/functions/limitDecimal';

const defaultValues: Ecog = {
  effortDiffculty: 1,
  longWalkDifficulty: 1,
  shortWalkDifficulty: 1,
  stayInBed: 1,
  helpFeed: 1,
  activityDiffeculty: 1,
  leisureDifficulty: 1,
  breathShort: 1,
  ache: 1,
  rest: 1,
  troubleSleep: 1,
  weak: 1,
  appetiteLack: 1,
  feelSick: 1,
  feelVomit: 1,
  constipation: 1,
  diarrhea: 1,
  tired: 1,
  acheActivities: 1,
  attetionDiffculty: 1,
  nervous: 1,
  worried: 1,
  riledup: 1,
  depressed: 1,
  rememberDifficulty: 1,
  familiarLife: 1,
  socialActivities: 1,
  financialDifficulty: 1,
  mentalHealth: 1,
  qualityOfLife: 1
};

const EcoGForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastEcog: Ecog | undefined; loadedEcog: Ecog | undefined }
> = ({ lastEcog, loadedEcog }, ref) => {
  const history = useHistory();
  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues
  });
  const [ecogToUpdate, setEcogToUpdate] = useState<Ecog>();
  const [physicalFunctioning, setPhysicalFunctioning] = useState(0);
  const [functionFunctioning, setFunctionFunctioning] = useState(0);
  const [emotionalFunctioning, setEmotionalFunctioning] = useState(0);
  const [cognitiveFunctioning, setCognitiveFunctioning] = useState(0);
  const [socialFunctioning, setSocialFunctioning] = useState(0);
  const [tiredness, setTiredness] = useState(0);
  const [nauseaAndVomit, setNauseaAndVomit] = useState(0);
  const [pain, setPain] = useState(0);
  const [dispenia, setDispenia] = useState(0);
  const [insomnia, setInsomnia] = useState(0);
  const [appetiteLack, setAppetiteLack] = useState(0);
  const [constipation, setConstipation] = useState(0);
  const [diarrhea, setDiarrhea] = useState(0);
  const [finantialDifficult, setFinantialDifficult] = useState(0);
  const [globalHealth, setGlobalHealth] = useState(0);

  const [functionalScaleResult, setFunctionalScaleResult] = useState(0);
  const [symptomsScaleResult, setSymptomsScaleResult] = useState(0);
  const [globalHealthScaleResult, setGlobalHealthScaleResult] = useState(0);

  const [createEcogMutation] = useMutation<null, CreateEcogVars>(CREATE_ECOG);
  const [updateEcogMutation] = useMutation<null, UpdateEcogVars>(UPDATE_ECOG);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loadedEcog) {
      setEcogToUpdateFunction(loadedEcog);
      setEcogToUpdate(loadedEcog);
    }
    setFirstStepResultValue();
    setSecondStepResultValue();
    setThirdStepResultValue();
    setLastStepResultValue();
  }, [loadedEcog]);

  useEffect(() => {
    if (lastEcog) setEcogToUpdateFunction(lastEcog);
    setFirstStepResultValue();
    setSecondStepResultValue();
    setThirdStepResultValue();
    setLastStepResultValue();
  }, [lastEcog]);

  async function setEcogToUpdateFunction(ecog: Ecog) {
    if (localStorage.getItem('appointmentId')) {
      setEcogValues(ecog);
    }
  }

  function setEcogValues(ecog: Ecog) {
    if (ecog) {
      setValue('effortDiffculty', ecog.effortDiffculty);
      setValue('longWalkDifficulty', ecog.longWalkDifficulty);
      setValue('shortWalkDifficulty', ecog.shortWalkDifficulty);
      setValue('stayInBed', ecog.stayInBed);
      setValue('helpFeed', ecog.helpFeed);
      setValue('activityDiffeculty', ecog.activityDiffeculty);
      setValue('leisureDifficulty', ecog.leisureDifficulty);
      setValue('breathShort', ecog.breathShort);
      setValue('ache', ecog.ache);
      setValue('rest', ecog.rest);
      setValue('troubleSleep', ecog.troubleSleep);
      setValue('weak', ecog.weak);
      setValue('appetiteLack', ecog.appetiteLack);
      setValue('feelSick', ecog.feelSick);
      setValue('feelVomit', ecog.feelVomit);
      setValue('constipation', ecog.constipation);
      setValue('diarrhea', ecog.diarrhea);
      setValue('tired', ecog.tired);
      setValue('acheActivities', ecog.acheActivities);
      setValue('attetionDiffculty', ecog.attetionDiffculty);
      setValue('nervous', ecog.nervous);
      setValue('worried', ecog.worried);
      setValue('riledup', ecog.riledup);
      setValue('depressed', ecog.depressed);
      setValue('rememberDifficulty', ecog.rememberDifficulty);
      setValue('familiarLife', ecog.familiarLife);
      setValue('socialActivities', ecog.socialActivities);
      setValue('financialDifficulty', ecog.financialDifficulty);
      setValue('mentalHealth', ecog.mentalHealth);
      setValue('qualityOfLife', ecog.qualityOfLife);
    }
  }
  function renderQuestions(
    options: IQuestionsType[],
    setPartialResultFunction: () => void
  ) {
    const questions = options.map(({ name, question }) => {
      return (
        <Box
          key={name}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="40%">
            <Typography
              textAlign="justify"
              fontWeight="500"
              fontSize="14px"
              color="grey.900"
              paddingRight="20px"
            >
              {question}
            </Typography>
          </Box>
          <Box width="52%">
            <ToggleButtonComponent
              selectedValue={getValues(name)}
              toggleButtonProps={[
                {
                  title: '1',
                  value: 1,
                  action: () => {
                    setValue(name, 1), setPartialResultFunction();
                  }
                },
                {
                  title: '2',
                  value: 2,
                  action: () => {
                    setValue(name, 2), setPartialResultFunction();
                  }
                },
                {
                  title: '3',
                  value: 3,
                  action: () => {
                    setValue(name, 3), setPartialResultFunction();
                  }
                },
                {
                  title: '4',
                  value: 4,
                  action: () => {
                    setValue(name, 4), setPartialResultFunction();
                  }
                }
              ]}
            />
          </Box>
        </Box>
      );
    });
    return questions;
  }

  function renderLastQuestions(
    options: IQuestionsType[],
    setPartialResultFunction: () => void
  ) {
    const questions = options.map(({ name, question }) => {
      return (
        <Box
          key={name}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="40%">
            <Typography
              fontWeight="500"
              fontSize="14px"
              color="grey.900"
              paddingRight="20px"
            >
              {question}
            </Typography>
          </Box>
          <Box width="52%">
            <ToggleButtonComponent
              selectedValue={getValues(name)}
              toggleButtonProps={[
                {
                  title: '1',
                  value: 1,
                  action: () => {
                    setValue(name, 1), setPartialResultFunction();
                  }
                },
                {
                  title: '2',
                  value: 2,
                  action: () => {
                    setValue(name, 2), setPartialResultFunction();
                  }
                },
                {
                  title: '3',
                  value: 3,
                  action: () => {
                    setValue(name, 3), setPartialResultFunction();
                  }
                },
                {
                  title: '4',
                  value: 4,
                  action: () => {
                    setValue(name, 4), setPartialResultFunction();
                  }
                },
                {
                  title: '5',
                  value: 5,
                  action: () => {
                    setValue(name, 5), setPartialResultFunction();
                  }
                },
                {
                  title: '6',
                  value: 6,
                  action: () => {
                    setValue(name, 6), setPartialResultFunction();
                  }
                },
                {
                  title: '7',
                  value: 7,
                  action: () => {
                    setValue(name, 7), setPartialResultFunction();
                  }
                }
              ]}
            />
          </Box>
        </Box>
      );
    });
    return questions;
  }

  function calculateBruteScore(
    resultOfQuestionsSum: number,
    numberOfQuestions: number
  ) {
    return resultOfQuestionsSum / numberOfQuestions;
  }

  function calculateFunctionalScaleTotalScore(
    bruteScore: number,
    range: number
  ) {
    const totalScore = (1 - (bruteScore - 1) / range) * 100;
    return limitDecimal(totalScore);
  }

  function calculateSymptomsScaleTotalScore(bruteScore: number, range: number) {
    const totalScore = ((bruteScore - 1) / range) * 100;
    return totalScore;
  }

  function calculatePhysicalFunctioningTotalScore() {
    const firstQuestion = getValues().effortDiffculty;
    const secondQuestion = getValues().longWalkDifficulty;
    const thirdQuestion = getValues().shortWalkDifficulty;
    const fourthQuestion = getValues().stayInBed;
    const fifthQuestion = getValues().helpFeed;
    const sumOfQuestions =
      firstQuestion +
      secondQuestion +
      thirdQuestion +
      fourthQuestion +
      fifthQuestion;
    const bruteScore = calculateBruteScore(sumOfQuestions, 5);
    setPhysicalFunctioning(bruteScore);
  }

  function calculateFunctionFunctioningTotalScore() {
    const questionSix = getValues().activityDiffeculty;
    const questionSeven = getValues().leisureDifficulty;
    const sumOfQuestion = questionSix + questionSeven;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setFunctionFunctioning(bruteScore);
  }

  function calculateEmotionalFunctioningTotalScore() {
    const questionTwentyOne = getValues().nervous;
    const questionTwentyTwo = getValues().worried;
    const questionTwentyThree = getValues().riledup;
    const questionTwentyFour = getValues().depressed;
    const sumOfQuestion =
      questionTwentyOne +
      questionTwentyTwo +
      questionTwentyThree +
      questionTwentyFour;
    const bruteScore = calculateBruteScore(sumOfQuestion, 4);
    setEmotionalFunctioning(bruteScore);
  }

  function calculateCognitiveFunctioningTotalScore() {
    const questionTwenty = getValues().attetionDiffculty;
    const questionTwentyFive = getValues().rememberDifficulty;
    const sumOfQuestion = questionTwenty + questionTwentyFive;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setCognitiveFunctioning(bruteScore);
  }

  function calculateSocialFunctioningTotalScore() {
    const questionTwentySix = getValues().familiarLife;
    const questionTwentySeven = getValues().socialActivities;
    const sumOfQuestion = questionTwentySix + questionTwentySeven;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setSocialFunctioning(bruteScore);
  }

  function calculateTirednessTotalScore() {
    const questionTen = getValues().rest;
    const questionTwelve = getValues().weak;
    const questionEighteen = getValues().tired;
    const sumOfQuestion = questionTen + questionTwelve + questionEighteen;
    const bruteScore = calculateBruteScore(sumOfQuestion, 3);
    setTiredness(bruteScore);
  }

  function calculateNauseaAndVomit() {
    const questionFourteen = getValues().feelSick;
    const questionFifteen = getValues().feelVomit;
    const sumOfQuestion = questionFourteen + questionFifteen;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setNauseaAndVomit(bruteScore);
  }

  function calculatePain() {
    const questionNine = getValues().ache;
    const questionNineteen = getValues().acheActivities;
    const sumOfQuestion = questionNine + questionNineteen;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setPain(bruteScore);
  }

  function calculateDispenia() {
    const bruteScore = getValues().breathShort;
    setDispenia(bruteScore);
  }
  function calculateInsomnia() {
    const bruteScore = getValues().troubleSleep;
    setInsomnia(bruteScore);
  }
  function calculateAppetiteLack() {
    const bruteScore = getValues().appetiteLack;
    setAppetiteLack(bruteScore);
  }
  function calculateConstipation() {
    const bruteScore = getValues().constipation;
    setConstipation(bruteScore);
  }
  function calculateDiarrhea() {
    const bruteScore = getValues().diarrhea;
    setDiarrhea(bruteScore);
  }
  function calculateFinantialDifficult() {
    const bruteScore = getValues().financialDifficulty;
    setFinantialDifficult(bruteScore);
  }
  function calculateGlobalHealth() {
    const questionTwentyNine = getValues().mentalHealth;
    const questionThirty = getValues().qualityOfLife;
    const sumOfQuestion = questionTwentyNine + questionThirty;
    const bruteScore = calculateBruteScore(sumOfQuestion, 2);
    setGlobalHealth(bruteScore);
  }

  function setFirstStepResultValue() {
    calculatePhysicalFunctioningTotalScore();
  }

  function setSecondStepResultValue() {
    calculateFunctionFunctioningTotalScore();
    calculateNauseaAndVomit();
    calculateTirednessTotalScore();
    calculatePain();
    calculateDispenia();
    calculateInsomnia();
    calculateAppetiteLack();
  }

  function setThirdStepResultValue() {
    calculateTirednessTotalScore();
    calculateEmotionalFunctioningTotalScore();
    calculateCognitiveFunctioningTotalScore();
    calculateSocialFunctioningTotalScore();

    calculateEmotionalFunctioningTotalScore();
    calculatePain();

    calculateConstipation();
    calculateDiarrhea();
    calculateFinantialDifficult();
  }

  function setLastStepResultValue() {
    calculateGlobalHealth();
  }

  useEffect(() => {
    const rs =
      (physicalFunctioning +
        functionFunctioning +
        emotionalFunctioning +
        cognitiveFunctioning +
        socialFunctioning) /
      5;
    setFunctionalScaleResult(calculateFunctionalScaleTotalScore(rs, 3));
  }, [
    physicalFunctioning,
    functionFunctioning,
    emotionalFunctioning,
    cognitiveFunctioning,
    socialFunctioning
  ]);
  useEffect(() => {
    const rs =
      (tiredness +
        nauseaAndVomit +
        pain +
        dispenia +
        insomnia +
        appetiteLack +
        constipation +
        diarrhea +
        finantialDifficult) /
      9;
    setSymptomsScaleResult(calculateSymptomsScaleTotalScore(rs, 3));
  }, [
    tiredness,
    nauseaAndVomit,
    pain,
    dispenia,
    insomnia,
    appetiteLack,
    constipation,
    diarrhea,
    finantialDifficult
  ]);
  useEffect(() => {
    const total = calculateSymptomsScaleTotalScore(globalHealth, 6);
    setGlobalHealthScaleResult(total);
  }, [globalHealth]);

  async function createEcog(data: Ecog) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (!ecogToUpdate) {
      await createEcogMutation({
        variables: {
          ecog: {
            ...data,
            appointmentId,
            functionalScale: limitDecimal(functionalScaleResult),
            symptomsScale: limitDecimal(symptomsScaleResult),
            globalHealthStatus: limitDecimal(globalHealthScaleResult)
          }
        },
        onCompleted: () => {
          toast.success('EORTC Criado com sucesso');
          history.push('/home/biochemical-parameters');
        }
      });
    }
  }

  async function updateEcog(data: Ecog) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (ecogToUpdate) {
      await updateEcogMutation({
        variables: {
          id: ecogToUpdate.id,
          ecog: {
            ...data,
            appointmentId,
            functionalScale: limitDecimal(functionalScaleResult),
            symptomsScale: limitDecimal(symptomsScaleResult),
            globalHealthStatus: limitDecimal(globalHealthScaleResult)
          }
        },
        onCompleted: () => {
          toast.info('EORTC Atualizado.');
          history.push('/home/biochemical-parameters');
        }
      });
    }
  }

  async function onSubmit(data: Ecog) {
    try {
      createEcog(data);
      updateEcog(data);
    } catch {
      toast.error('Houve um erro');
    }
  }

  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="395vh"
        >
          <Box
            marginBottom="-25px"
            width="50%"
            alignSelf="flex-end"
            fontWeight="500"
            fontSize="12px"
            color="grey.900"
            display="flex"
            justifyContent="space-around"
          >
            <Typography fontWeight="500" fontSize="12px">
              Não
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingLeft="25px">
              Pouco
            </Typography>
            <Typography fontWeight="500" fontSize="12px">
              Moderadamente
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingRight="15px">
              Muito
            </Typography>
          </Box>

          {renderQuestions(firstStepQuestions, setFirstStepResultValue)}
          <Divider />
          <Box marginBottom="-20px">
            <Subtitle title="Durante a última semana:" />
          </Box>
          <Box
            marginBottom="-10px"
            width="50%"
            alignSelf="flex-end"
            fontWeight="500"
            fontSize="12px"
            color="grey.900"
            display="flex"
            justifyContent="space-around"
          >
            <Typography fontWeight="500" fontSize="12px">
              Não
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingLeft="25px">
              Pouco
            </Typography>
            <Typography fontWeight="500" fontSize="12px">
              Moderadamente
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingRight="15px">
              Muito
            </Typography>
          </Box>
          {renderQuestions(secondStepQuestions, setSecondStepResultValue)}

          <Divider />
          <Box marginBottom="-20px">
            <Subtitle title="Durante a última semana:" />
          </Box>
          <Box
            marginBottom="-10px"
            width="50%"
            alignSelf="flex-end"
            fontWeight="500"
            fontSize="12px"
            color="grey.900"
            display="flex"
            justifyContent="space-around"
          >
            <Typography fontWeight="500" fontSize="12px">
              Não
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingLeft="25px">
              Pouco
            </Typography>
            <Typography fontWeight="500" fontSize="12px">
              Moderadamente
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingRight="15px">
              Muito
            </Typography>
          </Box>

          {renderQuestions(thirdStepQuestions, setThirdStepResultValue)}
          <Divider />

          <Box marginBottom="-20px">
            <Subtitle title="Para as seguintes perguntas, por favor, selecione um número de 1 a 7 que melhor se aplica ao paciente." />
          </Box>
          <Box
            marginBottom="-10px"
            width="50%"
            marginRight="10px"
            alignSelf="flex-end"
            fontWeight="500"
            fontSize="12px"
            color="grey.900"
            display="flex"
            justifyContent="space-between"
          >
            <Typography fontWeight="500" fontSize="12px">
              Péssima
            </Typography>
            <Typography fontWeight="500" fontSize="12px" paddingRight="10px">
              Ótima
            </Typography>
          </Box>

          {renderLastQuestions(lastStepQuestions, setLastStepResultValue)}
          <Divider />

          <Chip
            sx={{ background: theme.palette.info.light, fontWeight: '700' }}
            color="info"
            variant="outlined"
            label={`ESCALA FUNCIONAL: ${limitDecimal(functionalScaleResult)}`}
          />
          <Chip
            sx={{ background: theme.palette.info.light, fontWeight: '700' }}
            color="info"
            variant="outlined"
            label={`ESCALA DE SINTOMAS: ${limitDecimal(symptomsScaleResult)}`}
          />
          <Chip
            sx={{ background: theme.palette.info.light, fontWeight: '700' }}
            color="info"
            variant="outlined"
            label={`STATUS DE SAÚDE GLOBAL: ${limitDecimal(
              globalHealthScaleResult
            )}`}
          />
        </Box>
        <Button sx={{ display: 'none' }} ref={ref} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(EcoGForm);
