import { gql } from '@apollo/client';

export const UPDATE_NRS = gql`
  mutation updateNRS($id: Float!, $nrs: NRSInput!) {
    updateNRS(id: $id, nrs: $nrs) {
      id
      appointmentId
    }
  }
`;
