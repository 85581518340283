import React, { useState } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { theme } from 'styles/theme';
import { StyledToggleButton } from './style';
export interface ToggleButtonPropsValue {
  title: string;
  value: number | string;
  action(): void;
  disabled?: boolean;
}

interface IToggleButtonComponentProps {
  toggleButtonProps: ToggleButtonPropsValue[];
  selectedValue?: number;
}

function ToggleButtonComponent({
  toggleButtonProps,
  selectedValue
}: IToggleButtonComponentProps) {
  const [value, setValue] = useState(1);
  return (
    <ToggleButtonGroup
      size="small"
      value={selectedValue || selectedValue === 0 ? selectedValue : value}
      exclusive
      sx={{
        background: theme.palette.background.default,
        width: '100%'
      }}
    >
      {toggleButtonProps.map(({ title, value, action, disabled }) => {
        return (
          <StyledToggleButton
            disabled={disabled}
            amount={toggleButtonProps.length}
            key={value}
            value={value}
            onClick={() => {
              setValue(Number(value)), action();
            }}
          >
            {title}
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export { ToggleButtonComponent };
