import React, { useState } from 'react';
import { Box, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { Ecog } from 'models/ecog';
import { theme } from 'styles/theme';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './editAppointment';

type Keys =
  | 'effortDiffculty'
  | 'longWalkDifficulty'
  | 'shortWalkDifficulty'
  | 'stayInBed'
  | 'helpFeed'
  | 'activityDiffeculty'
  | 'leisureDifficulty'
  | 'breathShort'
  | 'ache'
  | 'rest'
  | 'troubleSleep'
  | 'weak'
  | 'appetiteLack'
  | 'feelSick'
  | 'feelVomit'
  | 'constipation'
  | 'diarrhea'
  | 'tired'
  | 'acheActivities'
  | 'attetionDiffculty'
  | 'nervous'
  | 'worried'
  | 'riledup'
  | 'depressed'
  | 'rememberDifficulty'
  | 'familiarLife'
  | 'socialActivities'
  | 'financialDifficulty'
  | 'mentalHealth'
  | 'qualityOfLife';

function parseLifeQualityName(value: string) {
  switch (value) {
    case 'effortDiffculty':
      return 'Você tem qualquer dificuldade quando faz grandes esforços, por exemplo carregar uma bolsa de compras pesada ou uma mala?';
    case 'longWalkDifficulty':
      return 'Você tem qualquer dificuldade quando faz uma longa caminhada?';
    case 'shortWalkDifficulty':
      return 'Você tem qualquer dificuldade quando faz uma curta caminhada fora de casa?';
    case 'stayInBed':
      return 'Você tem que ficar numa cama ou na cadeira durante o dia?';
    case 'helpFeed':
      return 'Você precisa de ajuda para se alimentar, se vestir, se lavar ou usar o banheiro?';
    case 'activityDiffeculty':
      return 'Tem sido difícil fazer suas atividades diárias?';
    case 'leisureDifficulty':
      return 'Tem sido díficil ter atividades de divertimento ou lazer?';
    case 'breathShort':
      return 'Você teve falta de ar?';
    case 'ache':
      return 'Você tem tido dor?';
    case 'rest':
      return 'Você precisou repousar?';
    case 'troubleSleep':
      return 'Você tem tido problemas para dormir?';
    case 'weak':
      return 'Você tem se sentido fraco(a)?';
    case 'appetiteLack':
      return 'Você tem tido falta de apetite?';
    case 'feelSick':
      return 'Você tem se sentido enjoado(a)?';
    case 'feelVomit':
      return 'Você vomitou?';
    case 'constipation':
      return 'Você tem tido prisão de ventre?';
    case 'diarrhea':
      return 'Você tem tido diarréia?';
    case 'tired':
      return 'Você esteve cansado(a)?';
    case 'acheActivities':
      return 'A dor interferiu nas suas atividades diárias?';
    case 'attetionDiffculty':
      return 'Você tem tido dificuldade para se concentrar em coisa, como ler jornal ou ver televisão?';
    case 'nervous':
      return 'Você se sentiu nervoso(a)?';
    case 'worried':
      return 'Você esteve preocupado(a)?';
    case 'riledup':
      return 'Você se sentiu irritado(a) facilmente?';
    case 'depressed':
      return 'Você se sentiu deprimido(a)?';
    case 'rememberDifficulty':
      return 'Você tem tido dificuldade de se lembrar das coisas?';
    case 'familiarLife':
      return 'A sua condição física ou o tratamento médico tem interferido em sua vida familiar?';
    case 'socialActivities':
      return 'A sua condição física ou o tratamento médico tem interferido em suas atividade sociais?';
    case 'financialDifficulty':
      return 'A sua condição física ou o tratamento médico tem lhe trazido dificuldade financeiras?';
    case 'mentalHealth':
      return 'Como classificaria a sua saúde em geral, durantea última semana?';
    case 'qualityOfLife':
      return 'Como você classificaria a sua qualidade de vida geral, durante a última semana?';
    default:
      return 'Não há dados';
  }
}

interface LifeQuality {
  key: Keys;
}

const lifeQualityKeys: LifeQuality[] = [
  { key: 'effortDiffculty' },
  { key: 'longWalkDifficulty' },
  { key: 'shortWalkDifficulty' },
  { key: 'stayInBed' },
  { key: 'helpFeed' },
  { key: 'activityDiffeculty' },
  { key: 'leisureDifficulty' },
  { key: 'breathShort' },
  { key: 'ache' },
  { key: 'rest' },
  { key: 'troubleSleep' },
  { key: 'weak' },
  { key: 'appetiteLack' },
  { key: 'feelSick' },
  { key: 'feelVomit' },
  { key: 'constipation' },
  { key: 'diarrhea' },
  { key: 'tired' },
  { key: 'acheActivities' },
  { key: 'attetionDiffculty' },
  { key: 'nervous' },
  { key: 'worried' },
  { key: 'riledup' },
  { key: 'depressed' },
  { key: 'rememberDifficulty' },
  { key: 'familiarLife' },
  { key: 'socialActivities' },
  { key: 'financialDifficulty' },
  { key: 'mentalHealth' },
  { key: 'qualityOfLife' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type MyNewType = MappedType<Ecog>;

export function LifeQualityScale({
  appointment
}: {
  appointment: Appointment;
}) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const ecog: MyNewType = appointment.ecog;
  if (!ecog)
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |4. Escala de qualidade de vida
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |4. Escala de qualidade de vida
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/life-quality-scale')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          padding="10px 20px"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          {lifeQualityKeys.map((value, index) => {
            return (
              <Box margin="15px 0px" width="45%" key={index}>
                <Label title={parseLifeQualityName(value.key) + ':'} />
                <ResultText title={JSON.stringify(ecog[value.key]) || ''} />
              </Box>
            );
          })}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="20vh"
          justifyContent="space-between"
        >
          <Chip
            sx={{
              background: theme.palette.info.light,
              fontWeight: '700',
              width: '50%'
            }}
            color="info"
            variant="outlined"
            label={`ESCALA FUNCIONAL: ${appointment.ecog.functionalScale}`}
          />
          <Chip
            sx={{
              background: theme.palette.info.light,
              fontWeight: '700',
              width: '50%'
            }}
            color="info"
            variant="outlined"
            label={`ESCALA DE SINTOMAS: ${appointment.ecog.symptomsScale}`}
          />
          <Chip
            sx={{
              background: theme.palette.info.light,
              fontWeight: '700',
              width: '50%'
            }}
            color="info"
            variant="outlined"
            label={`STATUS DE SAÚDE GLOBAL: ${appointment.ecog.globalHealthStatus}`}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
