import React, { useState } from 'react';
import { ButtonComponent } from 'components/Button';
import CloseIcon from '@mui/icons-material/Close';
import logo from 'assets/newLogo.png';
import attention from 'assets/attention.png';
import { Box, IconButton, Typography, Modal } from '@mui/material';
import { theme } from 'styles/theme';

interface PrivacyTermProps {
  action: () => void;
  isOpen: boolean;
}

export function PrivacyTerm({ action, isOpen }: PrivacyTermProps) {
  const [isAttentionModalOpen, setIsAttentionModalOpen] = useState(false);
  function handleCloseAttentionModal() {
    setIsAttentionModalOpen(false);
  }
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        return;
      }}
    >
      <Box
        position="absolute"
        top="12.5%"
        left="25%"
        width="50vw"
        height="75vh"
        borderRadius="4px"
        padding="5px 0"
        display="flex"
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: theme.palette.background.paper
        }}
      >
        <Box
          padding="15px 10px"
          width="100%"
          borderBottom="1px solid"
          borderColor="divider"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <img height="40px" src={logo} alt="Concluido" />
            <Typography
              marginLeft="145px"
              fontSize="18px"
              fontWeight="600"
              color="grey.900"
            >
              Termos de serviço
            </Typography>
          </Box>
        </Box>
        <Box margin="0 10px" padding="10% 20%">
          <ButtonComponent
            width="115px"
            sx={{
              margin: '0 15px',
              bgcolor: 'info.main',
              textTransform: 'none'
            }}
            title="Termo de uso"
            isTransparent={false}
            onClick={() => window.open('/useTerm.pdf', '_blank')}
          />
          <ButtonComponent
            width="115px"
            title="Termo de privacidade"
            isTransparent={false}
            sx={{
              margin: '0 15px',
              bgcolor: 'info.main',
              textTransform: 'none'
            }}
            onClick={() => window.open('/privacyTerm.pdf', '_blank')}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
          padding="15px 10px"
          borderTop="1px solid"
          borderColor="divider"
        >
          <ButtonComponent
            width="115px"
            title="Não aceito"
            sx={{
              borderColor: theme.palette.info.main,
              color: theme.palette.info.main,
              textTransform: 'none'
            }}
            isTransparent={true}
            onClick={() => setIsAttentionModalOpen(true)}
          />
          <ButtonComponent
            title="Aceito"
            sx={{
              minWidth: '115px',
              textTransform: 'none',
              marginLeft: '15px',
              bgcolor: theme.palette.info.main
            }}
            isTransparent={false}
            onClick={action}
          />
        </Box>
        <Modal
          open={isAttentionModalOpen}
          onClose={() => setIsAttentionModalOpen(false)}
        >
          <Box
            position="absolute"
            top="27%"
            left="37%"
            width="30vw"
            height="43vh"
            borderRadius="8px"
            padding="5px 4%"
            display="flex"
            flexDirection="column"
            textAlign="center"
            alignItems="center"
            justifyContent="space-evenly"
            sx={{
              background: theme.palette.background.paper
            }}
          >
            <Box position="absolute" left="90%" bottom="85%">
              <IconButton onClick={() => setIsAttentionModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <img src={attention} alt="Atenção" />
            <Typography color="grey.900" fontWeight="500" fontSize="18px">
              Para acessar a plafatorma, é necessário aceitar os termos. Deseja
              tentar novamente?
            </Typography>
            <Box width="90%" display="flex" justifyContent="space-between">
              <ButtonComponent
                title="Não"
                sx={{
                  width: '115px',
                  borderColor: theme.palette.info.main,
                  color: theme.palette.info.main,
                  textTransform: 'none'
                }}
                isTransparent={true}
                onClick={handleCloseAttentionModal}
              />
              <ButtonComponent
                width="115px"
                title="Sim"
                isTransparent={false}
                onClick={() => setIsAttentionModalOpen(false)}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
}
