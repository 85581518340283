import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { Bioparams as BioparamsInterface } from 'models/bioparams';
import { Subtitle } from 'components/Subtitle';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './editAppointment';

type BioparamsKeys =
  | 'albumin'
  | 'transferrin'
  | 'preAlbumin'
  | 'retinol'
  | 'pcr'
  | 'hemoglobin'
  | 'hematocrit'
  | 'lymphocite'
  | 'neutrophyl'
  | 'creatinine'
  | 'urea'
  | 'platelets'
  | 'leukocytes'
  | 'tgo'
  | 'tgp';

function parseBioparamsName(value: string) {
  switch (value) {
    case 'albumin':
      return { title: 'Albumina', suffix: 'g/dL' };
    case 'transferrin':
      return { title: 'Transferrina', suffix: 'mg/dL' };
    case 'preAlbumin':
      return { title: 'Pré Albumina', suffix: 'mg/dL' };
    case 'retinol':
      return { title: 'Proteína transportadora de retinol', suffix: 'mg/dL' };
    case 'pcr':
      return { title: 'PCR', suffix: 'mg/dL' };
    case 'hemoglobin':
      return { title: 'Hemoglobina', suffix: 'mg/dL' };
    case 'hematocrit':
      return { title: 'Hematócrito', suffix: '%' };
    case 'lymphocite':
      return { title: 'Contagem total de linfócitos', suffix: 'mm³' };
    case 'neutrophyl':
      return { title: 'Neutrófilos', suffix: 'mm³' };
    case 'creatinine':
      return { title: 'Creatinina', suffix: 'mg/dL' };
    case 'urea':
      return { title: 'Ureia', suffix: 'mg/dL' };
    case 'platelets':
      return { title: 'Plaquetas', suffix: 'uL' };
    case 'leukocytes':
      return { title: 'Leucócitos', suffix: 'uL' };
    case 'tgo':
      return { title: 'TGO', suffix: 'uL' };
    case 'tgp':
      return { title: 'TGP', suffix: 'uL' };
    default:
      return { title: 'Não há dados', suffix: '' };
  }
}

interface keysType {
  key: BioparamsKeys;
  functionToSetChipResult(): { status: string; color: IColors };
}

interface fieldReturn {
  status: string;
  color: IColors;
}

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type MyNewType = MappedType<BioparamsInterface>;

interface BioparamsProps {
  appointment: Appointment;
  gender: string;
}

export function Bioparams({ appointment, gender }: BioparamsProps) {
  const bioparams: MyNewType = appointment.bioParams;
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  if (!bioparams)
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |5. Parâmetros bioquímicos
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );

  function verifyAlbuminValue(): fieldReturn {
    const albumin = bioparams.albumin;
    if (albumin >= 3.5) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (albumin < 3.5 && albumin >= 3.0) {
      return { status: 'Depleção leve', color: { colors: 'light-yellow' } };
    } else if (albumin <= 2.9 && albumin >= 2.4) {
      return { status: 'Depleção moderada', color: { colors: 'light-orange' } };
    } else if (albumin < 2.4) {
      return { status: 'Depleção grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyTransferrinValue(): fieldReturn {
    const transferrin = bioparams.transferrin;
    if (transferrin < 400 && transferrin >= 200) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (transferrin < 199 && transferrin >= 150) {
      return { status: 'Depleção leve', color: { colors: 'light-yellow' } };
    } else if (transferrin <= 149 && transferrin >= 100) {
      return { status: 'Depleção moderada', color: { colors: 'light-orange' } };
    } else if (transferrin < 100) {
      return { status: 'Depleção grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyPreAlbuminValue(): fieldReturn {
    const preAlbumin = bioparams.preAlbumin;
    if (preAlbumin > 20) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (preAlbumin < 15 && preAlbumin >= 10) {
      return { status: 'Depleção leve', color: { colors: 'light-yellow' } };
    } else if (preAlbumin <= 10 && preAlbumin >= 5) {
      return { status: 'Depleção moderada', color: { colors: 'light-orange' } };
    } else if (preAlbumin < 5) {
      return { status: 'Depleção grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyRetinolValue(): fieldReturn {
    const retinol = bioparams.retinol;
    if (retinol >= 3) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (retinol < 3) {
      return { status: 'Depleção', color: { colors: 'light-yellow' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyPcrValue(): fieldReturn {
    const pcr = bioparams.pcr;
    if (pcr < 0.3) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (pcr > 0.3 && pcr <= 1) {
      return {
        status: 'Normal ou elevação pequena',
        color: { colors: 'light-yellow' }
      };
    } else if (pcr > 1 && pcr <= 10) {
      return { status: 'Elevação moderada', color: { colors: 'light-orange' } };
    } else if (pcr > 10 && pcr < 50) {
      return {
        status: 'Elevação importante',
        color: { colors: 'light-orange' }
      };
    } else if (pcr > 50) {
      return { status: 'Elevação severa', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const BioparamsProteinObjectArray: keysType[] = [
    { key: 'albumin', functionToSetChipResult: verifyAlbuminValue },
    { key: 'transferrin', functionToSetChipResult: verifyTransferrinValue },
    { key: 'preAlbumin', functionToSetChipResult: verifyPreAlbuminValue },
    { key: 'retinol', functionToSetChipResult: verifyRetinolValue },
    { key: 'pcr', functionToSetChipResult: verifyPcrValue }
  ];

  function renderFields(fieldArray: keysType[]) {
    return fieldArray.map((value, index) => {
      return bioparams[value.key] ? (
        <Box width="33%" key={index}>
          <Box display="flex" margin="20px 0">
            <Typography fontWeight="700" fontSize="16px" color="grey.300">
              {parseBioparamsName(value.key).title}:
            </Typography>{' '}
            <Typography fontWeight="700" fontSize="16px" color="grey.900">
              {bioparams[value.key]}
              {parseBioparamsName(value.key).suffix}
            </Typography>
          </Box>
          <ChipComponent
            color={{ colors: value.functionToSetChipResult().color.colors }}
            label={value.functionToSetChipResult().status}
            size="small"
          />
        </Box>
      ) : (
        ''
      );
    });
  }

  function verifyHemoglobinValue(): fieldReturn {
    const hemoglobin = bioparams.hemoglobin;
    if (gender === 'MALE' && hemoglobin > 12) {
      return { status: 'Desnutrição leve', color: { colors: 'light-green' } };
    } else if (gender === 'MALE' && hemoglobin > 10 && hemoglobin <= 12) {
      return {
        status: 'Desnutrição moderada',
        color: { colors: 'light-orange' }
      };
    } else if (gender === 'MALE' && hemoglobin < 10) {
      return { status: 'Desnutrição grave', color: { colors: 'light-red' } };
    }

    if (gender === 'FEMALE' && hemoglobin > 10) {
      return { status: 'Desnutrição leve', color: { colors: 'light-green' } };
    } else if (gender === 'FEMALE' && hemoglobin > 8 && hemoglobin <= 10) {
      return {
        status: 'Desnutrição moderada',
        color: { colors: 'light-orange' }
      };
    } else if (gender === 'FEMALE' && hemoglobin < 8) {
      return { status: 'Desnutrição grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyHematocritValue(): fieldReturn {
    const hematocrit = bioparams.hematocrit;
    if (gender === 'MALE' && hematocrit > 36) {
      return { status: 'Desnutrição leve', color: { colors: 'light-yellow' } };
    } else if (gender === 'MALE' && hematocrit <= 36 && hematocrit >= 31) {
      return {
        status: 'Desnutrição moderada',
        color: { colors: 'light-orange' }
      };
    } else if (gender === 'MALE' && hematocrit < 31) {
      return { status: 'Desnutrição grave', color: { colors: 'light-red' } };
    }

    if (gender === 'FEMALE' && hematocrit > 31) {
      return { status: 'Desnutrição leve', color: { colors: 'light-yellow' } };
    } else if (gender === 'FEMALE' && hematocrit <= 31 && hematocrit >= 24) {
      return {
        status: 'Desnutrição moderada',
        color: { colors: 'light-orange' }
      };
    } else if (gender === 'FEMALE' && hematocrit < 24) {
      return { status: 'Desnutrição grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyLymphocyteValue(): fieldReturn {
    const lymphocyte = bioparams.lymphocite;
    if (lymphocyte >= 2000) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (lymphocyte >= 1200 && lymphocyte < 2000) {
      return { status: 'Depleção leve', color: { colors: 'light-yellow' } };
    } else if (lymphocyte <= 1199 && lymphocyte >= 800) {
      return { status: 'Depleção moderada', color: { colors: 'light-orange' } };
    } else if (lymphocyte < 800) {
      return { status: 'Depleção grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyNeutrophylValue(): fieldReturn {
    const neutrophyl = bioparams.neutrophyl;
    if (neutrophyl > 1500) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (neutrophyl >= 1000 && neutrophyl <= 1500) {
      return { status: 'Neutropenia leve', color: { colors: 'light-yellow' } };
    } else if (neutrophyl < 1000 && neutrophyl >= 500) {
      return {
        status: 'Neutropenia moderada',
        color: { colors: 'light-orange' }
      };
    } else if (neutrophyl < 500) {
      return { status: 'Neutropenia grave', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyCreatinineValue(): fieldReturn {
    const creatinine = bioparams.creatinine;
    if (gender === 'MALE' && creatinine < 0.6) {
      return { status: 'Abaixo', color: { colors: 'light-orange' } };
    } else if (gender === 'MALE' && creatinine >= 0.6 && creatinine <= 1.2) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (gender === 'MALE' && creatinine > 1.2) {
      return { status: 'Acima', color: { colors: 'light-red' } };
    }

    if (gender === 'FEMALE' && creatinine < 0.5) {
      return { status: 'Abaixo', color: { colors: 'light-orange' } };
    } else if (gender === 'FEMALE' && creatinine >= 0.5 && creatinine <= 1.1) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (gender === 'FEMALE' && creatinine > 1.1) {
      return { status: 'Acima', color: { colors: 'light-red' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }
  function verifyUreaValue(): fieldReturn {
    const urea = bioparams.urea;
    if (urea > 50) {
      return { status: 'Acima', color: { colors: 'light-red' } };
    } else if (urea <= 50 && urea >= 10) {
      return { status: 'Normal', color: { colors: 'light-green' } };
    } else if (urea < 10) {
      return { status: 'Abaixo', color: { colors: 'light-orange' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyPlateletsValue(): fieldReturn {
    const platelets = bioparams.platelets;
    if (platelets < 140.0) {
      return { status: 'Plaquetopenia', color: { colors: 'light-orange' } };
    } else if (platelets >= 140.0 && platelets <= 450.0) {
      return { status: 'Sem classificação', color: { colors: 'light-green' } };
    } else if (platelets > 450.0) {
      return { status: 'Trombocitose', color: { colors: 'light-orange' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyLeukocytesValue(): fieldReturn {
    const leukocytes = bioparams.leukocytes;
    if (leukocytes <= 4.0) {
      return { status: 'Leucopenia', color: { colors: 'light-orange' } };
    } else if (leukocytes >= 11.0) {
      return { status: 'Leucocitose', color: { colors: 'light-orange' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyTgoValue(): fieldReturn {
    const tgo = bioparams.tgo;
    if (tgo > 34) {
      return {
        status: 'Sobrecarga hepática',
        color: { colors: 'light-orange' }
      };
    } else if (tgo < 34) {
      return {
        status: 'Disfunção hepática',
        color: { colors: 'light-orange' }
      };
    } else if (tgo === 34) {
      return { status: 'Adequado', color: { colors: 'light-green' } };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  function verifyTgpValue(): fieldReturn {
    const tgp = bioparams.tgp;
    if (tgp <= 49 && tgp >= 10) {
      return { status: 'Adequado', color: { colors: 'light-green' } };
    } else if (tgp < 10) {
      return {
        status: 'Disfunção hepática',
        color: { colors: 'light-orange' }
      };
    } else if (tgp > 49) {
      return {
        status: 'Sobrecarga hepática',
        color: { colors: 'light-orange' }
      };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const BioparamsHemoglobinObjectArray: keysType[] = [
    { key: 'hemoglobin', functionToSetChipResult: verifyHemoglobinValue },
    { key: 'hematocrit', functionToSetChipResult: verifyHematocritValue },
    { key: 'lymphocite', functionToSetChipResult: verifyLymphocyteValue },
    { key: 'neutrophyl', functionToSetChipResult: verifyNeutrophylValue },
    { key: 'creatinine', functionToSetChipResult: verifyCreatinineValue },
    { key: 'urea', functionToSetChipResult: verifyUreaValue },
    { key: 'platelets', functionToSetChipResult: verifyPlateletsValue },
    { key: 'leukocytes', functionToSetChipResult: verifyLeukocytesValue },
    { key: 'tgo', functionToSetChipResult: verifyTgoValue },
    { key: 'tgp', functionToSetChipResult: verifyTgpValue }
  ];

  function generateBNResult(BNValue: number): fieldReturn {
    if (BNValue <= 0 && BNValue >= -5) {
      return { status: 'Metabolismo normal', color: { colors: 'light-green' } };
    }
    if (BNValue < -5 && BNValue >= -10) {
      return {
        status: 'Hipermetabolismo leve ou nível de estresse 1',
        color: { colors: 'light-yellow' }
      };
    }
    if (BNValue < -10 && BNValue >= -15) {
      return {
        status: 'Hipermetabolismo moderado ou nível de estresse 2',
        color: { colors: 'light-orange' }
      };
    }
    if (BNValue < -15) {
      return {
        status: 'Hipermetabolismo grave ou nível de estresse 3',
        color: { colors: 'light-red' }
      };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |5. Parâmetros bioquímicos
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/biochemical-parameters')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          padding="10px 20px"
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box width="100%" margin="20px 0">
            <Box width="100%" display="flex" justifyContent="space-between">
              <Subtitle title="PROTEÍNAS CIRCULANTES" />
              <Box
                width="78%"
                borderBottom="1px solid"
                borderColor="divider"
                marginBottom="13px"
              ></Box>
            </Box>
            <Box display="flex" flexWrap="wrap">
              {renderFields(BioparamsProteinObjectArray)}
            </Box>
          </Box>
          <Box width="100%" margin="20px 0">
            <Box width="100%" display="flex" justifyContent="space-between">
              <Subtitle title="HEMOGRAMA" />
              <Box
                width="88%"
                borderBottom="1px solid"
                borderColor="divider"
                marginBottom="13px"
              ></Box>
            </Box>
            <Box display="flex" flexWrap="wrap">
              {renderFields(BioparamsHemoglobinObjectArray)}
            </Box>
          </Box>
          <Box width="100%" margin="20px 0">
            <Box width="100%" display="flex" justifyContent="space-between">
              <Subtitle title="CALCULAR BALANÇO NITROGENADO (BN)" />
              <Box
                width="68%"
                borderBottom="1px solid"
                borderColor="divider"
                marginBottom="13px"
              ></Box>
            </Box>
            <Box display="flex">
              <Box width="33%">
                <Label title="Valor do nitrogênio ingerido (g/dia):" />
                <ResultText title={bioparams.ingestedNitrogen + 'g'} />
              </Box>
              <Box width="33%">
                <Label title="Valor do nitrogênio excretado (g/dia):" />
                <ResultText title={bioparams.excretedNitrogen + 'g'} />
              </Box>
              <Box display="flex" alignItems="flex-end">
                <ChipComponent
                  color={{ colors: 'grey' }}
                  label={`VALOR DO BN: ${
                    bioparams.ingestedNitrogen - bioparams.excretedNitrogen
                  } (g/dia)`}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <ChipComponent
          sx={{ textTransform: 'uppercase', fontWeight: '700' }}
          color={{
            colors: generateBNResult(
              bioparams.ingestedNitrogen - bioparams.excretedNitrogen
            ).color.colors
          }}
          label={
            generateBNResult(
              bioparams.ingestedNitrogen - bioparams.excretedNitrogen
            ).status
          }
        />
      </Collapse>
    </Box>
  );
}
