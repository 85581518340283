import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5082C9',
      dark: '#002169'
    },
    secondary: {
      main: '#BD9327',
      light: '#FFF4E2'
    },
    success: {
      main: '#20D071',
      contrastText: '#65AC28',
      light: '#E3FFE2'
    },
    info: {
      main: '#008FBE',
      light: '#EDF6FF',
      '700': '#192386'
    },
    error: {
      main: '#BD2727',
      light: '#FFE2E2'
    },
    warning: {
      main: '#E35518',
      light: '#FFEDE2'
    },
    grey: {
      '100': '#F6F9FC',
      '200': '#CECECE',
      '300': '#7B868C',
      '400': '#7A7A7A',
      '900': '#565656'
    },
    background: {
      default: '#EDF6FF',
      paper: '#FFFFFF'
    },
    divider: '#CECECE'
  },
  shape: {
    borderRadius: 4
  }
});

export { theme };
