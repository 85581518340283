import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { NeMan as ManInterface } from 'models/neMan';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface MANProps {
  appointment: Appointment;
}

type ManKeys =
  | 'ingestDecrease'
  | 'weightLoss'
  | 'mobility'
  | 'stress'
  | 'neuroPsychological'
  | 'bodyMass'
  | 'ownHome'
  | 'threeMedicines'
  | 'eschar'
  | 'mealsByDay'
  | 'milkDerivatives'
  | 'vegetablesEggs'
  | 'meatFishBird'
  | 'fruits'
  | 'liquids'
  | 'wayEating'
  | 'nutritionalProblem'
  | 'ownHealth'
  | 'armCircumference'
  | 'lagCircumference';

interface ManObjectKey {
  key: ManKeys;
}

const allMANFields: ManObjectKey[] = [
  { key: 'ingestDecrease' },
  { key: 'weightLoss' },
  { key: 'mobility' },
  { key: 'stress' },
  { key: 'neuroPsychological' },
  { key: 'bodyMass' },
  { key: 'ownHome' },
  { key: 'threeMedicines' },
  { key: 'eschar' },
  { key: 'mealsByDay' },
  { key: 'milkDerivatives' },
  { key: 'vegetablesEggs' },
  { key: 'meatFishBird' },
  { key: 'fruits' },
  { key: 'liquids' },
  { key: 'wayEating' },
  { key: 'nutritionalProblem' },
  { key: 'ownHealth' },
  { key: 'armCircumference' },
  { key: 'lagCircumference' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newManType = MappedType<ManInterface>;

export function NeMan({ appointment }: MANProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const MAN: newManType = appointment.neMan;
  if (!MAN) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Avaliação nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  const milkDerivates = MAN.milkDerivatives === 'yes' ? 1 : 0;
  const vegetablesEggs = MAN.vegetablesEggs === 'yes' ? 1 : 0;
  const meatFishBird = MAN.meatFishBird === 'yes' ? 1 : 0;

  const result =
    Number(MAN.ingestDecrease) +
    Number(MAN.weightLoss) +
    Number(MAN.mobility) +
    Number(MAN.stress) +
    Number(MAN.neuroPsychological) +
    Number(MAN.bodyMass) +
    Number(MAN.ownHome) +
    Number(MAN.threeMedicines) +
    Number(MAN.eschar) +
    Number(MAN.mealsByDay) +
    milkDerivates +
    vegetablesEggs +
    meatFishBird +
    Number(MAN.fruits) +
    Number(MAN.liquids) +
    Number(MAN.wayEating) +
    Number(MAN.nutritionalProblem) +
    Number(MAN.ownHealth) +
    Number(MAN.armCircumference) +
    Number(MAN.lagCircumference);

  function parseMANQuestion(value: string) {
    switch (value) {
      case 'ingestDecrease':
        return `A- Nos últimos 3 meses, houve diminuição
              da ingesta alimentar devido a perda de apetite,
              problemas digestivos ou dificuldade para mastigar
              ou deglutir?:`;
      case 'weightLoss':
        return 'B- Perda de peso nos últimos 3 meses:';
      case 'mobility':
        return 'C- Mobilidade:';
      case 'stress':
        return 'D- Passou por algum stress psicológico ou doença aguda nos últimos 3 meses?:';
      case 'neuroPsychological':
        return 'E- Problemas neuropsicológicos?:';
      case 'bodyMass':
        return 'F- Índice de Massa Corporal (IMC = peso [kg]/ estatura [m²]):';
      case 'ownHome':
        return 'G- O paciente vive na sua própria casa (não em instituição geriátrica ou hospital)?:';
      case 'threeMedicines':
        return 'H- Utiliza mais de três medicamentos diferentes por dia?:';
      case 'eschar':
        return 'I- Lesões de pele ou escaras?:';
      case 'mealsByDay':
        return 'J- Quantas refeições faz por dia?:';
      case 'milkDerivatives':
        return 'Pelo menos uma porção diária de leite ou derivados (leite, queijo, iogurte)?:';
      case 'vegetablesEggs':
        return 'Duas ou mais porções semanais de leguminosas ou ovos?:';
      case 'meatFishBird':
        return 'Carne, peixe ou aves todos os dias?:';
      case 'fruits':
        return 'L- O paciente consome duas ou mais porções diárias de fruta ou produtos hortícolas?:';
      case 'liquids':
        return 'M- Quantos copos de líquidos (água, suco, café, chá, leite) o paciente consome por dia?:';
      case 'wayEating':
        return 'N- Modo de se alimentar:';
      case 'nutritionalProblem':
        return 'O- O paciente acredita ter algum problema nutricional?:';
      case 'ownHealth':
        return 'P- Em comparação com outras pessoas da mesma idade, como considera o paciente a sua própria saúde?:';
      case 'armCircumference':
        return 'Q- Perímetro braquial (PB) em centímetros:';
      case 'lagCircumference':
        return 'R- Perímetro da perna (PP) em centímetros:';
      default:
        return 'Não há dados';
    }
  }

  function parseManAnswers(key: string, value: string) {
    const parsedValue = value.toString();
    if (key === 'ingestDecrease') {
      switch (parsedValue) {
        case '0':
          return 'Diminuição grave da ingesta';
        case '1':
          return 'Diminuição moderada da ingesta';
        case '2':
          return 'Sem diminuição da ingesta';
      }
    }
    if (key === 'weightLoss') {
      switch (parsedValue) {
        case '0':
          return 'Superior a três quilos';
        case '1':
          return 'Não sabe informar';
        case '2':
          return 'Entre um e três quilos';
        case '3':
          return 'Sem perda de peso';
      }
    }
    if (key === 'mobility') {
      switch (parsedValue) {
        case '0':
          return 'Restrito ao leito ou à cadeira de rodas';
        case '1':
          return 'Deambula, mas não é capaz de sair de casa';
        case '2':
          return 'Normal';
      }
    }
    if (key === 'stress') {
      switch (parsedValue) {
        case '0':
          return 'Sim';
        case '1':
          return 'Não';
      }
    }
    if (key === 'neuroPsychological') {
      switch (parsedValue) {
        case '0':
          return 'Demência ou depressão grave';
        case '1':
          return 'Demência ligeira';
        case '2':
          return 'Sem problemas psicológicos';
      }
    }
    if (key === 'bodyMass') {
      switch (parsedValue) {
        case '0':
          return 'IMC < 19';
        case '1':
          return '19 ≤ IMC < 21';
        case '2':
          return '21 ≤ IMC < 23';
        case '3':
          return 'IMC ≥ 23';
      }
    }
    if (key === 'ownHome') {
      switch (parsedValue) {
        case '0':
          return 'Sim';
        case '1':
          return 'Não';
      }
    }
    if (key === 'threeMedicines') {
      switch (parsedValue) {
        case '0':
          return 'Sim';
        case '1':
          return 'Não';
      }
    }
    if (key === 'eschar') {
      switch (parsedValue) {
        case '0':
          return 'Sim';
        case '1':
          return 'Não';
      }
    }
    if (key === 'mealsByDay') {
      switch (parsedValue) {
        case '0':
          return 'Uma refeição';
        case '1':
          return 'Duas refeições';
        case '2':
          return 'Três refeições';
      }
    }
    if (key === 'milkDerivatives') {
      switch (parsedValue) {
        case 'yes':
          return 'Sim';
        case 'no':
          return 'Não';
      }
    }
    if (key === 'vegetablesEggs') {
      switch (parsedValue) {
        case 'yes':
          return 'Sim';
        case 'no':
          return 'Não';
      }
    }
    if (key === 'meatFishBird') {
      switch (parsedValue) {
        case 'yes':
          return 'Sim';
        case 'no':
          return 'Não';
      }
    }
    if (key === 'fruits') {
      switch (parsedValue) {
        case '1':
          return 'Sim';
        case '0':
          return 'Não';
      }
    }
    if (key === 'liquids') {
      switch (parsedValue) {
        case '0':
          return 'Menos de três copos';
        case '0.5':
          return 'Três a cinco copos';
        case '1':
          return 'Mais de cinco copos';
      }
    }
    if (key === 'wayEating') {
      switch (parsedValue) {
        case '0':
          return 'Não é capaz de se alimentar sozinho';
        case '1':
          return 'Alimenta-se sozinho, porém com dificuldade';
        case '2':
          return 'Alimenta-se sozinho, sem dificuldade';
      }
    }
    if (key === 'nutritionalProblem') {
      switch (parsedValue) {
        case '0':
          return 'Acredita estar desnutrido';
        case '1':
          return 'Não sabe dizer';
        case '2':
          return 'Acredita não ter problema nutricional';
      }
    }
    if (key === 'ownHealth') {
      switch (parsedValue) {
        case '0':
          return 'Pior';
        case '0.5':
          return 'Não sabe';
        case '1':
          return 'Igual';
        case '2':
          return 'Melhor';
      }
    }
    if (key === 'armCircumference') {
      switch (parsedValue) {
        case '0':
          return 'PB < 21';
        case '0.5':
          return '≥ 21 PB ≤ 22';
        case '1':
          return 'PB > 22';
      }
    }
    if (key === 'lagCircumference') {
      switch (parsedValue) {
        case '0':
          return 'PP < 31';
        case '1':
          return 'PP > 31';
      }
    }
  }

  function calculateMANResult(): { status: string; color: IColors } {
    const parsedResult = Number(result);
    if (parsedResult >= 24 && parsedResult <= 31 ? true : false)
      return { status: 'Normal', color: { colors: 'light-green' } };
    if (parsedResult >= 17 && parsedResult <= 23.5 ? true : false)
      return {
        status: 'Risco de desnutrição',
        color: { colors: 'light-orange' }
      };
    if (parsedResult < 17 ? true : false)
      return { status: 'Desnutrido', color: { colors: 'light-red' } };
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |8. Avaliação nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-evaluation')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              MAN: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allMANFields.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parseMANQuestion(key)} />
                <ResultText title={`${parseManAnswers(key, MAN[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: calculateMANResult().color.colors }}
            label={calculateMANResult().status}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
