import { gql } from '@apollo/client';

const UPDATE_ECOG = gql`
  mutation updateEcog($id: Float!, $ecog: EcogInput!) {
    updateEcog(id: $id, ecog: $ecog) {
      id
      appointmentId
    }
  }
`;

export { UPDATE_ECOG };
