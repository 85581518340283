import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { NutritionalIntervention as NutritionalInterventionInterface } from 'models/nutritionalIntervention';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './editAppointment';
import { useHistory } from 'react-router-dom';

interface NutritionalInterventionProps {
  appointment: Appointment;
}

type NutritionalInterventionKeys =
  | 'usedWeight'
  | 'energyNeed'
  | 'calorificValue'
  | 'proteinNeed'
  | 'proteinValue'
  | 'foodIntake'
  | 'nutritionalTherapy'
  | 'tno'
  | 'tne'
  | 'therapyDosage'
  | 'TherapyProtein'
  | 'therapyCalorific';

interface NutritionalInterventionObjectKey {
  key: NutritionalInterventionKeys;
}

const allNutritionalInterventionFields: NutritionalInterventionObjectKey[] = [
  { key: 'usedWeight' },
  { key: 'energyNeed' },
  { key: 'calorificValue' },
  { key: 'proteinNeed' },
  { key: 'proteinValue' },
  { key: 'foodIntake' },
  { key: 'nutritionalTherapy' },
  { key: 'tno' },
  { key: 'tne' },
  { key: 'therapyDosage' },
  { key: 'TherapyProtein' },
  { key: 'therapyCalorific' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newNutritionalInterventionType =
  MappedType<NutritionalInterventionInterface>;

export function NutritionalIntervention({
  appointment
}: NutritionalInterventionProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const NutritionalIntervention: newNutritionalInterventionType =
    appointment.nutritionalIntervention;
  if (!NutritionalIntervention) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |13. Intervenção nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parseNutritionalInterventionQuestion(value: string) {
    switch (value) {
      case 'usedWeight':
        return 'Qual peso você gostaria de utilizar?';
      case 'energyNeed':
        return 'Necessidade energética';
      case 'calorificValue':
        return 'Especifique  o valor calórico a ser utilizado';
      case 'proteinNeed':
        return 'Necessidade proteica:';
      case 'proteinValue':
        return 'Especifique  o valor proteico a ser utilizado:';
      case 'foodIntake':
        return 'Monitoramento da ingestão alimentar:';
      case 'nutritionalTherapy':
        return 'Deseja prescrever terapia nutricional?';
      case 'tno':
        return 'Prescrição da TNO:';
      case 'tne':
        return 'Prescrição da TNE:';
      case 'therapyDosage':
        return 'Posologia da terapia nutricional prescrita:';
      case 'TherapyProtein':
        return 'Valor proteico da TNO ou TNE:';
      case 'therapyCalorific':
        return 'Valor calórico da TNO ou TNE:';
      default:
        return 'Não há dados';
    }
  }

  function parseNutritionalInterventionAnswers(
    key: string,
    value: string | number | boolean
  ) {
    const parsedValue = value.toString();
    if (key === 'usedWeight') {
      switch (parsedValue) {
        case 'actual':
          return 'Atual';
        case 'ideal':
          return 'Ideal';
      }
    }
    if (key === 'energyNeed') {
      switch (parsedValue) {
        case '25-30':
          return '25-30 kcal/kg/dia';
        case '32-38':
          return '32-38 kcal/kg/dia';
        case '20-25':
          return '20-25 kcal/kg/dia';
      }
    }
    if (key === 'calorificValue') {
      return value + ' kcal/kg';
    }
    if (key === 'proteinNeed') {
      switch (parsedValue) {
        case '1':
          return '1g/kg/dia';
        case '1.1-1.5':
          return '1,2-1,5g/kg/d';
        case '1.5-2.0':
          return '1,5-2,0g/kg/d';
        case '0.8-1':
          return '0,8-1g/kg/d';
      }
    }
    if (key === 'proteinValue') {
      return value + 'g ptn/kg/dia';
    }
    if (key === 'foodIntake') {
      switch (parsedValue) {
        case 'lessThan75Percent':
          return 'Menor que 75%';
        case 'moreThan75Percent':
          return 'Maior que 75%';
      }
    }
    if (key === 'nutritionalTherapy') {
      switch (value ? 'yes' : 'no') {
        case 'yes':
          return 'Sim';
        case 'no':
          return 'Não';
      }
    }
    if (key === 'tno') {
      return parsedValue.split(';');
    }
    if (key === 'tne') {
      return parsedValue.split(';');
    }
    if (key === 'therapyDosage') {
      return value + 'ml';
    }
    if (key === 'TherapyProtein') {
      return value + 'g';
    }
    if (key === 'therapyCalorific') {
      return value + 'kcal';
    }
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |13. Intervenção nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-intervention')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allNutritionalInterventionFields.map(({ key }, index) => {
            return NutritionalIntervention[key] ? (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parseNutritionalInterventionQuestion(key)} />
                <ResultText
                  title={`${parseNutritionalInterventionAnswers(
                    key,
                    NutritionalIntervention[key]
                  )}`}
                />
              </Box>
            ) : (
              ''
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
}
