import { Result } from '..';
import { NutritionalData } from 'models/nutritionalData';
import { calculateIMC } from 'utils/functions/calculateIMC';
import { limitDecimal } from 'utils/functions/limitDecimal';
import { verifyIMCClassification } from 'utils/functions/verifyIMCClassification';
import { verifyWeightLossPercentage } from 'utils/functions/weightLossPercentage';

export function verifyWeightLossClassification(
  weightLossPercentage: number,
  weightLossTime: string
) {
  if (
    weightLossPercentage >= 1 &&
    weightLossPercentage <= 2 &&
    weightLossTime === 'oneWeek'
  ) {
    return 'Perda Significativa';
  }
  if (weightLossPercentage > 2 && weightLossTime === 'oneWeek') {
    return 'Perda Grave';
  }
  if (weightLossPercentage === 5 && weightLossTime === 'oneMonth') {
    return 'Perda Significativa';
  }
  if (weightLossPercentage > 5 && weightLossTime === 'oneMonth') {
    return 'Perda Grave';
  }
  if (weightLossPercentage === 7.5 && weightLossTime === 'threeMonths') {
    return 'Perda Significativa';
  }
  if (weightLossPercentage > 7.5 && weightLossTime === 'threeMonths') {
    return 'Perda Grave';
  }

  if (weightLossPercentage === 10 && weightLossTime === 'sixMonths') {
    return 'Perda Significativa';
  }
  if (weightLossPercentage > 10 && weightLossTime === 'sixMonths') {
    return 'Perda Grave';
  }
  if (weightLossTime === 'noWeightLoss') {
    return 'Não houve perda';
  }
  if (weightLossPercentage <= 0 && weightLossTime !== 'noWeightLoss') {
    return 'Não houve perda';
  }
  return 'Sem perda significativa';
}

function calculateNutritionalDataInfo(
  weight: number,
  habitualWeight: number,
  height: number,
  age: number,
  weightLossTime?: string
) {
  const weightLossOrGain = limitDecimal(habitualWeight - weight);
  const idealWeight = Math.round(Math.pow(height, 2) * 21);
  const IMC = calculateIMC(height, weight);
  const weightLossPercentage = verifyWeightLossPercentage(
    weight,
    habitualWeight
  );
  const imcClassification = verifyIMCClassification(IMC, age);
  const weightLossClassification = verifyWeightLossClassification(
    weightLossPercentage,
    weightLossTime ? weightLossTime : ''
  );
  return {
    weightLossOrGain,
    IMC,
    weightLossPercentage,
    imcClassification,
    idealWeight,
    weightLossClassification
  };
}

export function generateNutritionalDataResults(
  NutritionalData: NutritionalData | undefined,
  age: number
): Result[] {
  if (!NutritionalData) {
    return [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ];
  }
  const weightLossTime = NutritionalData.weightLossTime;
  const weight = NutritionalData.weight;
  const habitualWeight = NutritionalData.habitualWeight;
  const height = NutritionalData.height;
  if (!weight || !habitualWeight || !height) {
    return [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ];
  }
  const weightLossOrGain = calculateNutritionalDataInfo(
    weight,
    habitualWeight,
    height,
    age
  ).weightLossOrGain;
  if (weight && habitualWeight && height) {
    return [
      {
        contentTitle: 'Peso atual',
        content: weight.toString() + 'kg'
      },
      {
        contentTitle: 'Altura',
        content: height.toString() + 'm'
      },
      {
        contentTitle:
          weightLossOrGain > 0
            ? 'Valor total da perda de peso'
            : 'Valor total do ganho de peso',
        content: Math.abs(weightLossOrGain).toString() + 'kg'
      },
      {
        contentTitle: 'Peso ideal',
        content:
          calculateNutritionalDataInfo(
            weight,
            habitualWeight,
            height,
            age
          ).idealWeight.toString() + 'kg'
      },
      {
        contentTitle: 'IMC',
        content: calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age
        ).IMC.toString()
      },
      {
        contentTitle: 'Classificação IMC',
        content: calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age
        ).imcClassification
      },
      {
        contentTitle: 'Percentual da perda de peso',
        content: `${calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age
        ).weightLossPercentage.toString()}%`
      },
      {
        contentTitle: 'Classificação da perda de peso',
        content: calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age,
          weightLossTime
        ).weightLossClassification
      }
    ];
  }
  return [
    {
      contentTitle: 'Não há dados para exibir',
      content: ''
    }
  ];
}

export function generateNutritionalDataShortResult(
  NutritionalData: NutritionalData | undefined,
  age: number
): Result[] {
  if (!NutritionalData) {
    return [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ];
  }
  const weight = NutritionalData.weight;
  const habitualWeight = NutritionalData.habitualWeight;
  const height = NutritionalData.height;
  if (!weight || !habitualWeight || !height) {
    return [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ];
  }
  if (weight && height) {
    return [
      {
        contentTitle: 'Peso',
        content: weight.toString() + 'kg'
      },
      {
        contentTitle: 'Altura',
        content: height.toString() + 'm'
      },
      {
        contentTitle: 'IMC',
        content: calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age
        ).IMC.toString()
      },
      {
        contentTitle: 'Classificação IMC',
        content: calculateNutritionalDataInfo(
          weight,
          habitualWeight,
          height,
          age
        ).imcClassification
      }
    ];
  }
  return [
    {
      contentTitle: 'Não há dados para exibir',
      content: ''
    }
  ];
}
