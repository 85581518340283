export function verifyIMCClassification(IMC: number, age: number) {
  if (IMC < 16 && age < 60) return 'Magreza Grau |||';
  if (IMC >= 16 && IMC <= 16.99 && age < 60) return 'Magreza Grau ||';
  if (IMC >= 17 && IMC <= 18.49 && age < 60) return 'Magreza Grau |';
  if (IMC >= 18.5 && IMC <= 24.99 && age < 60) return 'Eutrofia';
  if (IMC >= 25 && IMC <= 29.99 && age < 60) return 'Sobrepeso';
  if (IMC >= 30 && IMC <= 34.99 && age < 60) return 'Obesidade Grau |';
  if (IMC >= 35 && IMC <= 39.99 && age < 60) return 'Obesidade Grau ||';
  if (IMC >= 40 && age < 60) return 'Obesidade Grau |||';
  if (age >= 60 && IMC < 23) return 'Baixo peso';
  if (age >= 60 && IMC >= 23 && IMC < 28) return 'Eutrofia';
  if (age >= 60 && IMC >= 28 && IMC < 30) return 'Sobrepeso';
  if (age >= 60 && IMC >= 30) return 'Obesidade';
  if (!IMC) return 'Sem classificação';
}
