import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FindByAppointmentIdVar } from 'shared';
import { limitDecimal } from 'utils';
import {
  AdvanceFormFooter,
  MainContentContainer,
  ToggleButtonComponent
} from 'components';
import { AGS } from 'models/ags';
import { ASG } from 'models/asg';
import { FullAppointment } from 'models/fullAppointment';
import { Man } from 'models/man';
import { NeASG } from 'models/neAsg';
import { NeMan } from 'models/neMan';
import { NutritionalData } from 'models/nutritionalData';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FIND_NE_AGS_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-evaluation/findAGSByAppointmentId';
import { FIND_NE_ASGPPP_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-evaluation/findASGPPPByAppointmentId';
import { FIND_NE_MAN_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-evaluation/findNEManByAppointmentId';
import { FIND_ASG_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/asg/findAsgByAppointmentId';
import { FIND_MAN_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/man/findManByAppointmentId';
import { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalData/findNutritionalDataByAppointmentId';
import { calculateIMC } from 'utils/functions/calculateIMC';
import AGSForm from './forms/ags';
import ASGPPP from './forms/asgppp';
import MAN from './forms/man';

interface NrManQueryType {
  findManByAppointmentId: Man;
}

interface NrAsgQueryType {
  findNrAsgpppByAppointmentId: ASG;
}

interface NutritionalDataQueryType {
  findNutritionalDataByAppointmentId: NutritionalData;
}

interface NeAsgQueryType {
  findNeAsgpppByAppointmentId: NeASG;
}

interface NeManQueryType {
  findNeManByAppointmentId: NeMan;
}

interface NeAgsQueryType {
  findNeAgsByAppointmentId: AGS;
}

function NutritionalEvaluation() {
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const [form, setForm] = useState('none');

  const {
    data: nutritionalData,
    error: nutritionalDataError,
    loading: nutritionalDataLoading
  } = useQuery<NutritionalDataQueryType, FindByAppointmentIdVar>(
    FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: neAsgData,
    loading: neAsgLoading,
    error: neAsgError
  } = useQuery<NeAsgQueryType, FindByAppointmentIdVar>(
    FIND_NE_ASGPPP_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: neManData,
    loading: neManLoading,
    error: neManError
  } = useQuery<NeManQueryType, FindByAppointmentIdVar>(
    FIND_NE_MAN_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );
  const {
    data: AGSData,
    loading: neAgsLoading,
    error: neAgsError
  } = useQuery<NeAgsQueryType, FindByAppointmentIdVar>(
    FIND_NE_AGS_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: nrAsgData,
    error: nrAsgError,
    loading: nrAsgLoading
  } = useQuery<NrAsgQueryType, FindByAppointmentIdVar>(
    FIND_ASG_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: nrManData,
    error: nrManError,
    loading: nrManLoading
  } = useQuery<NrManQueryType, FindByAppointmentIdVar>(
    FIND_MAN_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  function verifyFormPosition() {
    switch (form) {
      case 'ags':
        return 1;
      case 'man':
        return 2;
      case 'asgppp':
        return 3;
    }
  }

  const [lastAsg, setLastAsg] = useState<NeASG | undefined>();
  const [lastMan, setLastMan] = useState<NeMan | undefined>();
  const [lastAgs, setLastAgs] = useState<AGS | undefined>();

  const patientId = Number(localStorage.getItem('patientId'));
  const { data: lastAppointment } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentNutritionalData() {
    if (lastAppointment?.getLastPatientAppointment?.neAsgppp) {
      setForm('asgppp');
      setLastAsg(lastAppointment?.getLastPatientAppointment?.neAsgppp);
    }
    if (lastAppointment?.getLastPatientAppointment?.neMan) {
      setLastMan(lastAppointment?.getLastPatientAppointment?.neMan);
      setForm('man');
    }
    if (lastAppointment?.getLastPatientAppointment?.neAgs) {
      setLastAgs(lastAppointment?.getLastPatientAppointment?.neAgs);
      setForm('ags');
    }
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (lastAppointment?.getLastPatientAppointment?.neAsgppp) {
      setHasLastAppointment(true);
    }
    if (lastAppointment?.getLastPatientAppointment?.neMan) {
      setHasLastAppointment(true);
    }
    if (lastAppointment?.getLastPatientAppointment?.neAgs) {
      setHasLastAppointment(true);
    }
  }, [lastAppointment]);

  const parsedNutritionalData = useMemo(() => {
    if (nutritionalData?.findNutritionalDataByAppointmentId) {
      const data = nutritionalData.findNutritionalDataByAppointmentId;
      const IMC = calculateIMC(data.height, data.weight);
      const { height, weight, habitualWeight } = data;
      const weightLoss = limitDecimal(habitualWeight - weight);
      return { IMC, height, weight, weightLoss, habitualWeight };
    }
    if (nutritionalDataError) {
      toast.warn('Não foi possível carregar dados nutricionais');
      return;
    }
  }, [nutritionalData, nutritionalDataError, nutritionalDataLoading]);

  const loadedNeAsg = useMemo(() => {
    if (neAsgData?.findNeAsgpppByAppointmentId && !neAsgError) {
      setForm('asgppp');
      return neAsgData.findNeAsgpppByAppointmentId;
    }
  }, [neAsgData, neAsgLoading, neAsgError]);

  const loadedNeMan = useMemo(() => {
    if (neManData?.findNeManByAppointmentId && !neManError) {
      setForm('man');
      return neManData?.findNeManByAppointmentId;
    }
  }, [neManData, neManLoading, neManError]);

  const loadedAGS = useMemo(() => {
    if (AGSData?.findNeAgsByAppointmentId && !neAgsError) {
      setForm('ags');
      return AGSData?.findNeAgsByAppointmentId;
    }
  }, [AGSData, neAgsLoading, neAgsError]);

  const loadedNrAsg = useMemo(() => {
    if (
      nrAsgData?.findNrAsgpppByAppointmentId &&
      !nrAsgError &&
      !neAsgData?.findNeAsgpppByAppointmentId &&
      !AGSData?.findNeAgsByAppointmentId &&
      !neManData?.findNeManByAppointmentId
    ) {
      setForm('asgppp');
      return nrAsgData?.findNrAsgpppByAppointmentId;
    }
  }, [nrAsgData, nrAsgLoading, nrAsgError]);

  const loadedNrMan = useMemo(() => {
    if (
      nrManData?.findManByAppointmentId &&
      !nrManError &&
      !neAsgData?.findNeAsgpppByAppointmentId &&
      !AGSData?.findNeAgsByAppointmentId &&
      !neManData?.findNeManByAppointmentId
    ) {
      setForm('man');
      return nrManData?.findManByAppointmentId;
    }
  }, [nrManData, nrManLoading, nrManError]);

  useEffect(() => {
    if (
      !loadedNeAsg &&
      !loadedNeMan &&
      !loadedAGS &&
      !loadedNrAsg &&
      !loadedNrMan
    ) {
      setForm('ags');
    }
  }, [loadedNeAsg, loadedNeMan, loadedAGS, loadedNrAsg, loadedNrMan]);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="8. Avaliação nutricional"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentNutritionalData,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {form === 'none' ||
          neAsgLoading ||
          neManLoading ||
          neAgsLoading ||
          nrAsgLoading ||
          nrManLoading ? (
            <CircularProgress />
          ) : (
            <>
              <ToggleButtonComponent
                selectedValue={verifyFormPosition()}
                toggleButtonProps={[
                  { value: 1, title: 'AGS', action: () => setForm('ags') },
                  { value: 2, title: 'MAN', action: () => setForm('man') },
                  {
                    value: 3,
                    title: 'ASG-PPP',
                    action: () => setForm('asgppp')
                  }
                ]}
              />
              {form === 'asgppp' ? (
                <ASGPPP
                  lastAsg={lastAsg}
                  loadedNrASG={loadedNrAsg}
                  loadedData={loadedNeAsg}
                  height={parsedNutritionalData?.height || 0}
                  weight={parsedNutritionalData?.weight || 0}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'ags' ? (
                <AGSForm
                  lastAGS={lastAgs}
                  loadedData={loadedAGS}
                  weight={parsedNutritionalData?.weight || 0}
                  habitualWeight={parsedNutritionalData?.habitualWeight || 0}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'man' ? (
                <MAN
                  lastMan={lastMan}
                  loadedNrMan={loadedNrMan}
                  loadedData={loadedNeMan}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/nutritional-risk-screening')}
          action={() => formSubmitButtonRef.current?.click()}
        />
      </Box>
    </Box>
  );
}

export { NutritionalEvaluation };
