import { gql } from '@apollo/client';

const FIND_SYMPTOMS_BY_APPOINTMENT_ID = gql`
  query findSymptomsByAppointmentId($appointmentId: Float!) {
    findSymptomsByAppointmentId(appointmentId: $appointmentId) {
      id
      constipation
      nausea
      anosmia
      quicklySatisfied
      dryMouth
      dysphagia
      noAppetite
      ache
      diarrhea
      vomit
      smellBothers
      inappetence
      mucositis
      tiredness
      dysgeusia
      appointmentId
      others {
        id
        name
        frequency
      }
    }
  }
`;

export { FIND_SYMPTOMS_BY_APPOINTMENT_ID };
