import { Appointment } from 'models/appointment';

export const prepareWeight = (appointments: Appointment[] | undefined) => {
  if (appointments) {
    return [
      {
        points: appointments.map((app) => app.NutritionalData?.weight || null)
      }
    ];
  } else {
    return [];
  }
};
