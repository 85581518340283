import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  TablePagination
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useHistory } from 'react-router-dom';

import { PieChart, PieData } from 'components/Dashboard/';
import { CidsDialog, CidData } from 'components/Dashboard';
import { cid } from 'utils/constants';
import { diagnoses } from 'utils/constants/diagnoses';

import { PATIENT_LIST } from 'services/graphql/queries/dashboard/nutritionist/patientList';

type Props = {
  patientName: string;
  neoplasms: string[];
};

type Cid = {
  code: string;
};

type Patient = {
  patientId: number;
  patientName: string;
  appointments: Appointment[];
};
type Appointment = {
  datetime: Date;
  diagnosis: string;
  diseaseData: {
    cids: Cid[];
  };
  nutritionalIntervention?: {
    tno: string;
  };
};

const BlueCell = ({ children }: any) => {
  return (
    <TableCell sx={{ color: '#002169', backgroundColor: '#EDF6FF' }}>
      {children}
    </TableCell>
  );
};

export function Content({ patientName, neoplasms }: Props) {
  const history = useHistory();

  const [queryPatientList, { data: patientQueryData }] =
    useLazyQuery(PATIENT_LIST);
  const [openCidModal, setOpenCidModal] = useState(false);
  const [patientData, setPatientData] = useState<Patient[]>([]);
  const [chartData, setChartData] = useState<PieData[]>([]);
  const [modalData, setModalData] = useState<CidData[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!patientName.length || isNaN(+patientName)) {
      queryPatientList({
        variables: { patientName: patientName, patientChart: null }
      });
    } else {
      queryPatientList({
        variables: { patientName: null, patientChart: +patientName }
      });
    }
  }, [patientName]);

  useEffect(() => {
    const result = neoplasms.reduce(
      (partial, curr: string) => (
        (partial[curr] = (partial[curr] || 0) + 1), partial
      ),
      Object.create(null)
    );
    const chart = [];
    const modal = [];
    for (const key in result) {
      const cidLabel = cid.find((c) => c.value === key);
      chart.push({
        id: key,
        value: result[key],
        label: cidLabel ? cidLabel.resume : '',
        color: 'hsl(0, 0, 0)'
      });
      modal.push({
        name: cidLabel ? cidLabel.label : '',
        cid: key,
        quant: parseInt(result[key])
      });
    }
    setChartData(chart);
    setModalData(modal);
  }, [neoplasms]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (patientQueryData && patientQueryData.nutriDashPatientList)
      setPatientData(patientQueryData.nutriDashPatientList);
  }, [patientQueryData]);

  return (
    <Grid
      justifyContent="center"
      container
      spacing={2}
      // sx={{ flexGrow: 1 }}
      style={{ marginBottom: '20px' }}
    >
      <Grid item xl={7} md={7} sm={12} xs={12}>
        <Card>
          <CardHeader title="Últimos pacientes" />
          <CardContent>
            <Table sx={{ padding: '0px' }}>
              <TableHead>
                <TableRow>
                  <BlueCell>Nome</BlueCell>
                  <BlueCell>Última consulta</BlueCell>
                  <BlueCell>Nº Consultas</BlueCell>
                  <BlueCell>CID</BlueCell>
                  <BlueCell>Estado nutricional</BlueCell>
                  <BlueCell>Prescrição TNO</BlueCell>
                  <BlueCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {patientData?.slice(page * 10, page * 10 + 10).map((row) => {
                  const diag = diagnoses.find(
                    (d) => d.value === row.appointments[0]?.diagnosis
                  );
                  return (
                    <TableRow key={row.patientName}>
                      <TableCell>{row.patientName}</TableCell>
                      <TableCell>
                        {row.appointments[0]
                          ? new Date(
                              row.appointments[0].datetime
                            ).toLocaleDateString()
                          : ''}
                      </TableCell>
                      <TableCell>{row.appointments.length}</TableCell>
                      <TableCell>
                        {row.appointments[0]?.diseaseData?.cids[0]?.code ||
                          'Não há CID'}
                      </TableCell>
                      <TableCell>{diag ? diag.label : ''}</TableCell>
                      <TableCell>
                        {row.appointments[0]?.nutritionalIntervention?.tno ||
                          ''}
                      </TableCell>
                      <TableCell>
                        <DashboardIcon
                          htmlColor="#002169"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push({
                              pathname: '/dashboard/patient',
                              state: { id: row.patientId }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={patientData.length}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xl={3.5}
        md={4}
        sm={12}
        xs={12}
        justifyContent="center"
        minWidth={450}
      >
        <Card>
          <CardHeader title="Principais neoplasias atendidas" />
          <Divider />
          <CardContent sx={{ height: '300px', width: '400px' }}>
            <PieChart data={chartData} />
            <Link
              sx={{
                position: 'relative',
                left: '130px'
              }}
              component="button"
              variant="body2"
              onClick={() => {
                setOpenCidModal(true);
              }}
            >
              Ver tudo
            </Link>
          </CardContent>
        </Card>
      </Grid>
      <CidsDialog
        open={openCidModal}
        onClose={() => setOpenCidModal(false)}
        cidData={modalData}
      />
    </Grid>
  );
}
