export const waterIntakeFrequency = [
  {
    label: '1 ou 2 copos por dia',
    value: 'betweenOneAndTwoCups'
  },
  { label: '3 a 5 copos por dia', value: 'betweenThreeAndFiveCups' },
  { label: 'Mais de 5 copos por dia', value: 'moreThanFiveCups' }
];

export function parseWaterIntakeValue(value: string) {
  switch (value) {
    case 'betweenOneAndTwoCups':
      return '1 ou 2 copos por dia';
    case 'betweenThreeAndFiveCups':
      return '3 a 5 copos por dia';
    case 'moreThanFiveCups':
      return 'Mais de 5 copos por dia';
    default:
      return 'Não há dados';
  }
}

export default waterIntakeFrequency;
