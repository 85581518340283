import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  RadioButtonInput,
  ChipComponent,
  ClassificationTable
} from 'components';
import { useMutation } from '@apollo/client';
import {
  CREATE_MAN,
  UPDATE_MAN,
  CreateManVars,
  UpdateManVars
} from 'services/graphql/mutations/nutritional-risk/man';
import { toast } from 'react-toastify';
import { Man } from 'models/man';
import { useHistory } from 'react-router-dom';

interface IFormData {
  foodIntakeDecrease: string;
  weightLoss: string;
  mobility: string;
  recentStress: string;
  neurologicalProblems: string;
  imc: string;
}

const defaultValues: IFormData = {
  foodIntakeDecrease: '0',
  weightLoss: '0',
  mobility: '0',
  recentStress: '0',
  neurologicalProblems: '0',
  imc: '0'
};

const MANForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { imc: number; loadedValues: Man | undefined; lastMan: Man | undefined }
> = ({ imc, loadedValues, lastMan }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormData>({
    defaultValues
  });

  const history = useHistory();

  const [createManMutation] = useMutation<null, CreateManVars>(CREATE_MAN);
  const [updateManMutation] = useMutation<null, UpdateManVars>(UPDATE_MAN);

  const [result, setResult] = useState(0);
  const watchFoodIngestion = watch('foodIntakeDecrease');
  const watchWeightLoss = watch('weightLoss');
  const watchMobility = watch('mobility');
  const watchRecentStress = watch('recentStress');
  const watchNeurologicalProblems = watch('neurologicalProblems');
  const watchIMC = watch('imc');

  function setIMCValue() {
    if (imc < 19) setValue('imc', '0');
    if (imc >= 19 && imc < 21) setValue('imc', '1');
    if (imc >= 21 && imc < 23) setValue('imc', '2');
    if (imc >= 23) setValue('imc', '3');
  }

  useEffect(() => {
    setIMCValue();
  }, []);

  useEffect(() => {
    setResult(
      Number(watchFoodIngestion) +
        Number(watchWeightLoss) +
        Number(watchMobility) +
        Number(watchRecentStress) +
        Number(watchNeurologicalProblems) +
        Number(watchIMC)
    );
  }, [
    watchFoodIngestion,
    watchWeightLoss,
    watchMobility,
    watchRecentStress,
    watchNeurologicalProblems,
    watchIMC
  ]);

  const MANquestions = [
    {
      name: 'foodIntakeDecrease',
      title: `A- Nos últimos 3 meses, houve diminuição
              da ingesta alimentar devido a perda de apetite,
              problemas digestivos ou dificuldade para mastigar
              ou deglutir?:`,
      radioButtons: [
        {
          checked: watchFoodIngestion === '0',
          label: 'Diminuição grave da ingesta',
          value: '0'
        },
        {
          checked: watchFoodIngestion === '1',
          label: 'Diminuição moderada da ingesta',
          value: '1'
        },
        {
          checked: watchFoodIngestion === '2',
          label: 'Sem diminuição da ingesta',
          value: '2'
        }
      ]
    },
    {
      name: 'weightLoss',
      title: `B- Perda de peso nos últimos 3 meses:`,
      radioButtons: [
        {
          checked: watchWeightLoss === '0',
          label: 'Superior a três quilos',
          value: '0'
        },
        {
          checked: watchWeightLoss === '1',
          label: 'Não sabe informar',
          value: '1'
        },
        {
          checked: watchWeightLoss === '2',
          label: 'Entre um e três quilos',
          value: '2'
        },
        {
          checked: watchWeightLoss === '3',
          label: 'Sem perda de peso',
          value: '3'
        }
      ]
    },
    {
      name: 'mobility',
      title: `C- Mobilidade:`,
      radioButtons: [
        {
          checked: watchMobility === '0',
          label: 'Restrito ao leito ou à cadeira de rodas',
          value: '0'
        },
        {
          checked: watchMobility === '1',
          label: 'Deambula, mas não é capaz de sair de casa',
          value: '1'
        },
        {
          checked: watchMobility === '2',
          label: 'Normal',
          value: '2'
        }
      ]
    },
    {
      name: 'recentStress',
      title: `D- Passou por algum stress psicológico ou doença
              aguda nos últimos 3 meses?:`,
      radioButtons: [
        {
          checked: watchRecentStress === '0',
          label: 'Sim',
          value: '0'
        },
        {
          checked: watchRecentStress === '2',
          label: 'Não',
          value: '2'
        }
      ]
    },
    {
      name: 'neurologicalProblems',
      title: `E- Problemas neuropsicológicos?:`,
      radioButtons: [
        {
          checked: watchNeurologicalProblems === '0',
          label: 'Demência ou depressão grave',
          value: '0'
        },
        {
          checked: watchNeurologicalProblems === '1',
          label: 'Demência ligeira',
          value: '1'
        },
        {
          checked: watchNeurologicalProblems === '2',
          label: 'Sem problemas psicológicos',
          value: '2'
        }
      ]
    },
    {
      name: 'imc',
      title: `F- Índice de Massa Corporal (IMC = peso [kg]/ estatura [m²]):`,
      radioButtons: [
        {
          checked: watchIMC === '0',
          label: 'IMC < 19',
          value: '0'
        },
        {
          checked: watchIMC === '1',
          label: '19 ≤ IMC < 21',
          value: '1'
        },
        {
          checked: watchIMC === '2',
          label: '21 ≤ IMC < 23',
          value: '2'
        },
        {
          checked: watchIMC === '3',
          label: 'IMC ≥ 23',
          value: '3'
        }
      ]
    }
  ];

  function setFormValues(data: Man | undefined) {
    if (data) {
      setValue('foodIntakeDecrease', data.foodIntakeDecrease.toString());
      setValue('weightLoss', data.weightLoss.toString());
      setValue('mobility', data.mobility.toString());
      setValue('recentStress', data.recentStress.toString());
      setValue('neurologicalProblems', data.neurologicalProblems.toString());
      setValue('imc', data.imc.toString());
    }
  }

  useEffect(() => {
    setFormValues(loadedValues);
  }, [loadedValues]);

  useEffect(() => {
    setFormValues(lastMan);
  }, [lastMan]);

  async function createMan(data: Man) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (!appointmentId) return;
    if (!loadedValues)
      await createManMutation({
        variables: {
          man: {
            appointmentId,
            imc: Number(data.imc),
            mobility: Number(data.mobility),
            neurologicalProblems: Number(data.neurologicalProblems),
            recentStress: Number(data.recentStress),
            weightLoss: Number(data.weightLoss),
            foodIntakeDecrease: Number(data.foodIntakeDecrease)
          }
        },
        onCompleted: () => {
          toast.success('Triagem do risco nutricional MAN criada com sucesso.');
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  async function updateMan(data: Man) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (!appointmentId) return;
    if (loadedValues) {
      await updateManMutation({
        variables: {
          id: loadedValues.id,
          man: {
            appointmentId,
            imc: Number(data.imc),
            mobility: Number(data.mobility),
            neurologicalProblems: Number(data.neurologicalProblems),
            recentStress: Number(data.recentStress),
            weightLoss: Number(data.weightLoss),
            foodIntakeDecrease: Number(data.foodIntakeDecrease)
          }
        },
        onCompleted: () => {
          toast.info(
            'Triagem do risco nutricional MAN atualizada com sucesso.'
          );
          history.push('/home/nutritional-evaluation');
        }
      });
    }
  }

  function onSubmit(form: IFormData) {
    try {
      createMan(form);
      updateMan(form);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="135vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Miniavaliação Nutricional MNA®
            </Typography>
            <TooltipComponent content="Utilizada para população idosa" />
          </Box>
          <Divider sx={{ marginTop: '-15px' }} />
          {MANquestions.map(({ name, title, radioButtons }) => {
            return (
              <Box key={name} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          <ChipComponent
            sx={{
              width: '100%'
            }}
            color={{ colors: 'light-blue' }}
            label={`SOMA TOTAL ${result} PONTOS`}
          />
          <Box
            minHeight="31vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              width="100%"
              fontWeight="500"
              borderBottom="1px solid"
              borderColor="grey.200"
              fontSize="18px"
              color="grey.900"
            >
              Classificação
            </Typography>
            <ClassificationTable
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: '0-7',
                  secondCellContent: 'Desnutrido',
                  selected: result <= 7 ? true : false
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: '8-11',
                  secondCellContent: 'Risco de desnutrição',
                  selected: result >= 8 && result <= 11 ? true : false
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: '12-14',
                  secondCellContent: 'Normal',
                  selected: result >= 12 && result <= 14 ? true : false
                }
              ]}
            />
          </Box>
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(MANForm);
