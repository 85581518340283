import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography
} from '@mui/material';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
  ChipComponent,
  InputWithNumberMask,
  RadioButtonInput,
  TooltipComponent,
  ButtonComponent
} from 'components';
import { AutoCompleteComponent } from '../components/autocomplete';
import { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalData/findNutritionalDataByAppointmentId';
import { limitDecimal } from 'utils/functions';
import { toast } from 'react-toastify';
import {
  UPDATE_NUTRITIONAL_INTERVENTION,
  CREATE_NUTRITIONAL_INTERVENTION,
  NutritionalInterventionMutationsVars
} from 'services/graphql/mutations/nutritionalIntervention';
import { NutritionalIntervention } from 'models/nutritionalIntervention';
import { useHistory } from 'react-router-dom';
import { theme } from 'styles/theme';
import doneImage from 'assets/done.png';
import CloseIcon from '@mui/icons-material/Close';
import { FindByAppointmentIdVar } from 'shared';
import { FIND_NUTRITIONAL_INTERVENTION_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalntervention/findNutritionalInterventionByAppointmentId';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IFormData,
  InterventionQueryType,
  NutritionalDataQueryType,
  TNEOptions,
  TNOOptions,
  validationSchema
} from './constsInterfacesSchema';

const NutritionalInterventionForm: ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastIntervention: NutritionalIntervention | undefined }
> = ({ lastIntervention }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      usedWeight: 'actual',
      energyNeed: '25-30',
      proteinNeed: '1',
      foodIntake: 'lessThan75Percent',
      nutritionalTherapy: 'yes'
    }
  });
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const { data: nutritionalDataQuery, loading: loadingNutritionalData } =
    useQuery<NutritionalDataQueryType, FindByAppointmentIdVar>(
      FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID,
      {
        fetchPolicy: 'no-cache',
        variables: {
          appointmentId
        }
      }
    );
  const [createNutritionalInterventionMutation] = useMutation<
    null,
    NutritionalInterventionMutationsVars
  >(CREATE_NUTRITIONAL_INTERVENTION);
  const [updateNutritionalInterventionMutation] = useMutation<
    null,
    NutritionalInterventionMutationsVars
  >(UPDATE_NUTRITIONAL_INTERVENTION);
  const { data: interventionQueryData, loading: loadingIntervention } =
    useQuery<InterventionQueryType, FindByAppointmentIdVar>(
      FIND_NUTRITIONAL_INTERVENTION_BY_APPOINTMENT_ID,
      {
        fetchPolicy: 'no-cache',
        variables: {
          appointmentId
        }
      }
    );

  const [proteicPercentual, setProteicPercentual] = useState(0);
  const [caloricPercentual, setCaloricPercentual] = useState(0);
  const [weight, setWeight] = useState(0);
  const [TNOChips, setTNOChips] = useState(['']);
  const [TNEChips, setTNEChips] = useState(['']);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadedIntervention = useMemo(() => {
    if (
      interventionQueryData?.findNutritionalInterventionByAppointmentId &&
      nutritionalDataQuery?.findNutritionalDataByAppointmentId
    ) {
      const data =
        interventionQueryData?.findNutritionalInterventionByAppointmentId;
      setFormValues(data);
      return data;
    }
  }, [interventionQueryData, loadingIntervention, nutritionalDataQuery]);

  const nutritionalData = useMemo(() => {
    if (nutritionalDataQuery?.findNutritionalDataByAppointmentId) {
      const data = nutritionalDataQuery?.findNutritionalDataByAppointmentId;
      setWeight(data.weight);
      return data;
    }
  }, [nutritionalDataQuery, loadingNutritionalData]);

  const watchTno = watch('tno');
  const watchTne = watch('tne');
  const watchUsedWeight = watch('usedWeight');
  const watchEnergyNeed = watch('energyNeed');
  const watchCalorificValue = watch('calorificValue');
  const watchProteinNeed = watch('proteinNeed');
  const watchProteinValue = watch('proteinValue');
  const watchFoodIntake = watch('foodIntake');
  const watchNutritionalTherapy = watch('nutritionalTherapy');
  const watchTherapyProtein = watch('TherapyProtein');
  const watchTherapyCalorific = watch('therapyCalorific');
  const watchTherapyDosage = watch('therapyDosage');

  function calculateIdealWeight() {
    const idealWeight = Math.round(
      Math.pow(nutritionalData?.height || 0, 2) * 21
    );
    return idealWeight;
  }

  function useIdealWeightOrActualWeight() {
    if (watchUsedWeight === 'ideal') setWeight(calculateIdealWeight());
    if (watchUsedWeight === 'actual' && nutritionalData?.weight) {
      setWeight(nutritionalData?.weight);
    }
  }

  useEffect(() => {
    useIdealWeightOrActualWeight();
  }, [watchUsedWeight]);

  function setEnergyNeedTooltipText(energyNeed: string) {
    switch (energyNeed) {
      case '25-30':
        return `Para adultos e idosos em tratamento antineoplásico, 
          pacientes em tratamento paliativo e sobreviventes do câncer`;
      case '32-38':
        return `Para idosos com IMC < 18,5 kg/m²`;
      case '20-25':
        return ` Para pacientes obesos`;
    }
    return '';
  }

  function setProteicNeedTooltipText(proteicNeed: string) {
    switch (proteicNeed) {
      case '1':
        return `Para adultos e idoso em tratamento e pacientes em cuidados paliativos`;
      case '1.1-1.5':
        return `Para adultos e idoso em tratamento com algum grau de desnutrição`;
      case '1.2-2.0':
        return `Para adultos e idoso em tratamento com inflamação sistêmica persistente`;
      case '0.8-1':
        return `Para sobreviventes do câncer`;
    }
    return '';
  }

  function calculateTotalCaloricValue(weight: number, energyNeed: number) {
    return limitDecimal(weight * energyNeed);
  }

  function calculateTotalProteinValue(weight: number, proteinNeed: number) {
    return limitDecimal(weight * proteinNeed);
  }

  function calculateProteicOrCaloricPercentual(
    therapyProteinOrCaloric: number,
    totalProteicOrCaloricValue: number
  ) {
    return limitDecimal(
      (therapyProteinOrCaloric / totalProteicOrCaloricValue) * 100
    );
  }

  useEffect(() => {
    const totalProteicValue = calculateTotalProteinValue(
      weight,
      watchProteinValue
    );
    const value = calculateProteicOrCaloricPercentual(
      watchTherapyProtein,
      totalProteicValue
    );
    setProteicPercentual(value);
  }, [watchTherapyProtein, watchProteinValue, weight]);

  useEffect(() => {
    const totalCaloricValue = calculateTotalCaloricValue(
      weight,
      watchCalorificValue
    );
    const value = calculateProteicOrCaloricPercentual(
      watchTherapyCalorific,
      totalCaloricValue
    );
    setCaloricPercentual(value);
  }, [watchTherapyCalorific, watchCalorificValue, weight]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function setFormValues(data?: NutritionalIntervention) {
    if (!data) return;
    setValue('usedWeight', data.usedWeight);
    setValue('energyNeed', data.energyNeed);
    setValue('calorificValue', data.calorificValue);
    setValue('proteinNeed', data.proteinNeed);
    setValue('proteinValue', data.proteinValue);
    setValue('foodIntake', data.foodIntake);
    setValue('nutritionalTherapy', data.nutritionalTherapy ? 'yes' : 'no');
    setValue('tno', data.tno);
    setValue('tne', data.tne);
    setValue('therapyDosage', data.therapyDosage);
    setValue('TherapyProtein', data.TherapyProtein);
    setValue('therapyCalorific', data.therapyCalorific);
    if (data.tno) setTNOChips(data.tno.split(';'));
    if (data.tne) setTNEChips(data.tne.split(';'));
  }

  useEffect(() => {
    setFormValues(lastIntervention);
  }, [lastIntervention]);

  async function createNutritionalIntervention(data: IFormData) {
    if (loadedIntervention) return;
    await createNutritionalInterventionMutation({
      variables: {
        nutritionalIntervention: {
          appointmentId,
          ...data,
          nutritionalTherapy: data.nutritionalTherapy === 'yes' ? true : false
        }
      },
      onCompleted: () => {
        toast.success('Intervenção nutricional adicionada.');
        setIsModalOpen(true);
      }
    });
  }

  async function updateNutritionalIntervention(data: IFormData) {
    if (!loadedIntervention) return;
    await updateNutritionalInterventionMutation({
      variables: {
        nutritionalIntervention: {
          appointmentId,
          ...data,
          nutritionalTherapy: data.nutritionalTherapy === 'yes' ? true : false
        }
      },
      onCompleted: () => {
        toast.info('Intervenção nutricional atualizada.');
        setIsModalOpen(true);
      }
    });
  }

  function handleCloseModal() {
    localStorage.removeItem('appointmentId');
    localStorage.removeItem('patientId');
    history.push('/dashboard/nutritionist');
  }

  function copyFoodData() {
    const tnoArray = watchTno?.split(';') || ['Sem Dados'];
    const tneArray = watchTne?.split(';') || ['Sem Dados'];
    const filteredTNO = tnoArray.filter((item) => item !== '');
    const filteredTNE = tneArray.filter((item) => item !== '');
    const tnoData: string[] = [
      '- Prescrição da TNO: ',
      ...(filteredTNO.map((item) => `${item}. `) || 'Sem dados')
    ];
    const tneData: string[] = [
      '\n- Prescrição da TNE: ',
      ...(filteredTNE.map((item) => `${item}. `) || 'Sem dados')
    ];
    const therapyDosage = watchTherapyDosage
      ? watchTherapyDosage + 'ml'
      : 'Sem dados';
    const protein = watchTherapyProtein
      ? watchTherapyProtein + 'g'
      : 'Sem dados';
    const kcal = watchTherapyCalorific
      ? watchTherapyCalorific + 'kcal'
      : 'Sem dados';
    const allDataArray: string[] = [
      ...tnoData,
      ...tneData,
      `\n - Posologia da terapia nutricional: ${therapyDosage}`,
      `\n - Valor Proteico da TNO e/ou TNE: ${protein}`,
      `\n- Valor calórico da TNO e/ou TNE: ${kcal}`
    ];
    const ParsedDataToCopy = String(allDataArray).replace(/,/g, ' ');
    navigator.clipboard.writeText(ParsedDataToCopy);
    toast.success('Dados copiados');
  }

  function onSubmit(data: IFormData) {
    try {
      createNutritionalIntervention(data);
      updateNutritionalIntervention(data);
    } catch {
      toast.error('Houve um erro');
    }
  }
  if (loadingIntervention || loadingNutritionalData)
    return <CircularProgress />;
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="170vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <RadioButtonInput
              title="Qual peso você gostaria de utilizar?"
              control={control}
              disabled={false}
              radioButtons={[
                {
                  checked: watchUsedWeight === 'actual',
                  label: 'Atual',
                  value: 'actual'
                },
                {
                  checked: watchUsedWeight === 'ideal',
                  label: 'Ideal',
                  value: 'ideal'
                }
              ]}
              name="usedWeight"
            />
            <ChipComponent
              color={{ colors: 'green' }}
              sx={{
                justifyItems: 'center',
                marginRight: '35%'
              }}
              label={`PESO SELECIONADO: ${weight}`}
            />
          </Box>
          <Box
            margin="15px 0"
            marginBottom="25px"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            minHeight="30vh"
          >
            <RadioButtonInput
              title={
                <Box height="25px">
                  Necessidade energética{' '}
                  <TooltipComponent
                    content={setEnergyNeedTooltipText(watchEnergyNeed)}
                    isSmall
                  />
                </Box>
              }
              control={control}
              disabled={false}
              radioButtons={[
                {
                  checked: watchEnergyNeed === '25-30',
                  label: '25-30 kcal/kg/dia',
                  value: '25-30'
                },
                {
                  checked: watchEnergyNeed === '32-38',
                  label: '32-38 kcal/kg/dia',
                  value: '32-38'
                },
                {
                  checked: watchEnergyNeed === '20-25',
                  label: '20-25 kcal/kg/dia',
                  value: '20-25'
                }
              ]}
              name="energyNeed"
            />
            <InputWithNumberMask
              decimalScale={1}
              control={control}
              name="calorificValue"
              placeholder="Ex: 30 kcal / kg"
              sx={{ width: '50%' }}
              label="Especifique o valor calórico a ser utilizado"
              suffix="kcal / kg"
            />
            <ChipComponent
              sx={{ width: '40%' }}
              size="small"
              color={{ colors: 'light-blue' }}
              label={`VALOR CALÓRICO TOTAL: ${
                calculateTotalCaloricValue(weight, watchCalorificValue)
                  ? calculateTotalCaloricValue(weight, watchCalorificValue) +
                    ' KCAL/DIA'
                  : 'Aguardando dados'
              }`}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            minHeight="26vh"
            margin="15px 0"
            flexDirection="column"
          >
            <RadioButtonInput
              title={
                <Box height="25px">
                  Necessidade protéica{' '}
                  <TooltipComponent
                    content={setProteicNeedTooltipText(watchProteinNeed)}
                    isSmall
                  />
                </Box>
              }
              control={control}
              disabled={false}
              radioButtons={[
                {
                  checked: watchProteinNeed === '1',
                  label: '1g/kg/dia',
                  value: '1'
                },
                {
                  checked: watchProteinNeed === '1.1-1.5',
                  label: '1,2-1,5g/kg/d',
                  value: '1.1-1.5'
                },
                {
                  checked: watchProteinNeed === '1.5-2.0',
                  label: '1,5-2,0g/kg/d',
                  value: '1.5-2.0'
                },
                {
                  checked: watchProteinNeed === '0.8-1',
                  label: '0,8-1g/kg/d',
                  value: '0.8-1'
                }
              ]}
              name="proteinNeed"
            />
            <InputWithNumberMask
              decimalScale={1}
              control={control}
              name="proteinValue"
              placeholder="Ex: 2.0 g ptn/kg/dia"
              suffix="g ptn/kg/dia"
              sx={{ width: '50%' }}
              label="Especifique o valor proteico a ser utilizado:"
            />
            <ChipComponent
              sx={{ width: '40%' }}
              size="small"
              color={{ colors: 'light-blue' }}
              label={`VALOR PROTEICO TOTAL: ${
                calculateTotalProteinValue(weight, watchProteinValue)
                  ? calculateTotalProteinValue(weight, watchProteinValue) +
                    ' PTN/DIA'
                  : 'Aguardando dados'
              }`}
            />
          </Box>

          <Box
            minHeight="100vh"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <RadioButtonInput
              title="Monitoramento da ingestão alimentar:"
              control={control}
              disabled={false}
              radioButtons={[
                {
                  checked: watchFoodIntake === 'lessThan75Percent',
                  label: 'Menor que 75%',
                  value: 'lessThan75Percent'
                },
                {
                  checked: watchFoodIntake === 'moreThan75Percent',
                  label: 'Maior que 75%',
                  value: 'moreThan75Percent'
                }
              ]}
              name="foodIntake"
            />

            <RadioButtonInput
              title="Deseja prescrever terapia nutricional?"
              control={control}
              disabled={false}
              radioButtons={[
                {
                  checked: watchNutritionalTherapy === 'yes',
                  label: 'Sim',
                  value: 'yes'
                },
                {
                  checked: watchNutritionalTherapy === 'no',
                  label: 'Não',
                  value: 'no'
                }
              ]}
              name="nutritionalTherapy"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontWeight="500" color="grey.900">
                É possível adicionar mais de uma opção a cada item:
              </Typography>
              <ButtonComponent
                width="fit-content"
                isTransparent={false}
                onClick={copyFoodData}
                title="Copiar dados da prescrição nutricional"
              />
            </Box>
            <AutoCompleteComponent
              label="Prescrição da TNO:"
              disabled={watchNutritionalTherapy === 'no'}
              actionOnChange={(data) => setValue('tno', data.join(';'))}
              placeholder="Escolha Prescrição TNO"
              chipsHeader="TNOs adicionados"
              errorFromForm={false}
              options={TNOOptions}
              loadedOptions={TNOChips}
            />
            <AutoCompleteComponent
              label="Prescrição da TNE:"
              disabled={watchNutritionalTherapy === 'no'}
              actionOnChange={(data) => setValue('tne', data.join(';'))}
              placeholder="Escolha Prescrição TNE"
              chipsHeader="TNEs adicionados"
              errorFromForm={false}
              options={TNEOptions}
              loadedOptions={TNEChips}
            />
            <InputWithNumberMask
              decimalScale={0}
              control={control}
              disabled={watchNutritionalTherapy === 'no'}
              name="therapyDosage"
              placeholder="Ex: 3ml"
              suffix="ml"
              sx={{ width: '35%' }}
              label="Posologia da terapia nutricional prescrita:"
            />
            <InputWithNumberMask
              decimalScale={0}
              control={control}
              disabled={watchNutritionalTherapy === 'no'}
              name="TherapyProtein"
              placeholder="Ex: 3g"
              suffix="g"
              sx={{ width: '35%' }}
              label="Valor proteico da TNO e/ou TNE:"
            />
            <ChipComponent
              sx={{ width: '40%' }}
              color={{ colors: 'light-blue' }}
              label={`VALOR PERCENTUAL PROTEICO: ${
                limitDecimal(proteicPercentual) || 0
              }%`}
            />
            <InputWithNumberMask
              decimalScale={0}
              control={control}
              disabled={watchNutritionalTherapy === 'no'}
              name="therapyCalorific"
              placeholder="Ex: 3kcal"
              suffix="kcal"
              sx={{ width: '35%' }}
              label="Valor calórico da TNO e/ou TNE:"
            />
            <ChipComponent
              sx={{ width: '40%' }}
              color={{ colors: 'light-blue' }}
              label={`VALOR PERCENTUAL CALÓRICO: ${
                limitDecimal(caloricPercentual) || 0
              }%`}
            />
          </Box>
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              position="absolute"
              top="27%"
              left="37%"
              width="30vw"
              height="43vh"
              borderRadius="8px"
              padding="5px 5%"
              display="flex"
              flexDirection="column"
              textAlign="center"
              alignItems="center"
              justifyContent="space-evenly"
              sx={{
                background: theme.palette.background.paper
              }}
            >
              <Box position="absolute" left="90%" bottom="85%">
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <img src={doneImage} alt="Concluido" />
              <Typography color="grey.900" fontWeight="500" fontSize="24px">
                Cadastro do paciente efetuado com sucesso
              </Typography>
              <ButtonComponent
                width="95px"
                title="Finalizar"
                isTransparent={false}
                onClick={handleCloseModal}
              />
            </Box>
          </Modal>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(NutritionalInterventionForm);
