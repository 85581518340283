import React, { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  CircularProgress
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  ChipComponent,
  InputWithNumberMask,
  RadioButtonInput,
  Subtitle,
  ArrayInputComponent
} from 'components';
import { theme } from 'styles/theme';
import { useMutation } from '@apollo/client';
import {
  CREATE_ASG,
  ASGMutationVars,
  UPDATE_ASG
} from 'services/graphql/mutations/nutritional-risk/asg';
import { toast } from 'react-toastify';
import { ASG } from 'models/asg';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  asgDefaultValues,
  firstColumnSymptoms,
  IFormData,
  secondColumnSymptoms,
  setActivityLastMonthPointsFunction,
  setFoodIngestionQuantityPointsFunction,
  setFoodIngestionTypePointsFunction,
  setWeightPointsFunction,
  symptomsKey,
  SymptomsType
} from './asgInterfacesAndFunctions';
import { ASGvalidationSchema } from './schemas';

const ASGForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    height: number;
    weight: number;
    loadedData: ASG | undefined;
    lastAsg: ASG | undefined;
  }
> = ({ height, weight, loadedData, lastAsg }, ref) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitted }
  } = useForm<IFormData>({
    defaultValues: asgDefaultValues,
    resolver: yupResolver(ASGvalidationSchema)
  });

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createASGMutation] = useMutation<null, ASGMutationVars>(CREATE_ASG);
  const [updateASGMutation] = useMutation<null, ASGMutationVars>(UPDATE_ASG);
  const history = useHistory();

  const watchWeight = watch('currentWeight');
  const watchWeightLastMonth = watch('weight1Month');
  const watchWeightLoss = watch('weightLastWeeks');
  const watchFoodIngestionLastMonth = watch('lastMonthFood');
  const watchFoodIngestionNow = watch('currentFood');
  const watchActivityLastMonth = watch('activityLastMonth');

  const [symptomsPoints, setSymptomsPoints] = useState(0);
  const [hasOtherSymptoms, setHasOtherSymptoms] = useState(false);
  const [otherSymptomsChips, setOtherSymptomsChips] = useState<string[]>([]);
  const [hasPain, setHasPain] = useState(false);
  const [painChips, setPainChips] = useState<string[]>([]);
  const [normalFeed, setNormalFeed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    verifyWeightLoss();
  }, [watchWeight, watchWeightLastMonth]);

  function calculateHeight() {
    if (!height) return;
    const [meters, centimeters] = height.toString().split('.', 2);
    if (Number(meters) > 1.99 && !centimeters) setValue('heightOver1m', 100);
    else if (Number(meters) > 0.99 && !centimeters) setValue('heightOver1m', 0);
    else if (centimeters.length === 1)
      setValue('heightOver1m', Number(centimeters) * 10);
    else if (Number(meters) >= 2)
      setValue('heightOver1m', Number(centimeters) + 100);
    else setValue('heightOver1m', Number(centimeters));

    if (centimeters && centimeters.length === 1 && Number(meters) >= 2)
      setValue('heightOver1m', Number(centimeters) * 10 + 100);
  }

  function verifyWeightLoss() {
    const weight = getValues('currentWeight');
    const weightLastMonth = getValues('weight1Month');
    if (weight < weightLastMonth) setValue('weightLastWeeks', 'lessWeight');
    if (weight === weightLastMonth) setValue('weightLastWeeks', 'sameWeight');
    if (weight > weightLastMonth) setValue('weightLastWeeks', 'heavier');
  }

  function generateSymptoms(symptoms: SymptomsType[]) {
    return symptoms.map(({ name, title, value }) => {
      return (
        <Box
          key={name}
          display="flex"
          flexDirection="column"
          margin="5px 0"
          width="95%"
        >
          <FormControlLabel
            sx={{
              color: theme.palette.grey[900],
              '& .MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            control={
              <Checkbox
                color="info"
                checked={getValues(name) === true ? true : false}
                name={name}
                onChange={(event) =>
                  setSymptonsValue(
                    name,
                    value,
                    event.target.checked ? true : false
                  )
                }
              />
            }
            label={title}
          />
        </Box>
      );
    });
  }

  function setSymptonsValue(
    name: symptomsKey,
    value: number,
    operationType: boolean
  ) {
    setValue(name, operationType);
    calculateSymptomsPoinst(value, name);
  }

  function calculateSymptomsPoinst(value: number, name: symptomsKey) {
    if (!getValues(name)) setSymptomsPoints(symptomsPoints - value);

    if (getValues(name)) setSymptomsPoints(symptomsPoints + value);
  }

  useEffect(() => {
    if (weight) setValue('currentWeight', weight);
    calculateHeight();
  }, []);

  useEffect(() => {
    setValue('others', otherSymptomsChips.join(';'));
  }, [otherSymptomsChips]);

  function setHasOtherSymptomsFunction(value: boolean) {
    setHasOtherSymptoms(value);
    if (!value) {
      setOtherSymptomsChips([]);
      setSymptomsPoints(symptomsPoints - 1);
    } else {
      setSymptomsPoints(symptomsPoints + 1);
    }
  }

  useEffect(() => {
    setValue('pain', painChips.join(';'));
  }, [painChips]);

  function setPainFunction(value: boolean) {
    setHasPain(value);
    if (!value) {
      setPainChips([]);
      setSymptomsPoints(symptomsPoints - 3);
    } else {
      setSymptomsPoints(symptomsPoints + 3);
    }
  }

  function setNormalFeedValue(value: boolean) {
    setNormalFeed(value);
    setValue('normalFeed', value);
  }

  function setFormValues(loadedData: ASG) {
    let total = 0;
    setValue('currentWeight', loadedData.currentWeight);
    setValue('heightOver1m', loadedData.heightOver1m);
    setValue('weight1Month', loadedData.weight1Month);
    setValue('weight6Month', loadedData.weight6Month);
    setValue('weightLastWeeks', loadedData.weightLastWeeks);
    setValue('lastMonthFood', loadedData.lastMonthFood);
    setValue('currentFood', loadedData.currentFood);
    if (loadedData.normalFeed) {
      setValue('normalFeed', true);
      setNormalFeed(true);
    }
    if (loadedData.nausea) {
      setValue('nausea', true);
      total = total + 1;
    }
    if (loadedData.constipation) {
      setValue('constipation', true);
      total = total + 1;
    }
    if (loadedData.mucositis) {
      setValue('mucositis', true);
      total = total + 2;
    }
    if (loadedData.tasteless) {
      setValue('tasteless', true);
      total = total + 1;
    }
    if (loadedData.swallowProblem) {
      setValue('swallowProblem', true);
      total = total + 2;
    }
    if (loadedData.tiredness) {
      setValue('tiredness', true);
      total = total + 1;
    }
    if (loadedData.appetiteLack) {
      setValue('appetiteLack', true);
      total = total + 3;
    }
    if (loadedData.vomit) {
      setValue('vomit', true);
      total = total + 3;
    }
    if (loadedData.diarrhea) {
      setValue('diarrhea', true);
      total = total + 3;
    }
    if (loadedData.dryMouth) {
      setValue('dryMouth', true);
      total = total + 1;
    }
    if (loadedData.quicklySatified) {
      setValue('quicklySatified', true);
      total = total + 1;
    }
    if (loadedData.smellsBother) {
      setValue('smellsBother', true);
      total = total + 1;
    }
    setValue('pain', loadedData.pain);
    setValue('others', loadedData.others);
    setValue('activityLastMonth', loadedData.activityLastMonth);
    if (getValues('others') !== '') {
      setOtherSymptomsChips(loadedData.others.split(';'));
      setHasOtherSymptoms(true);
      total = total + 3;
    }
    if (getValues('pain') !== '') {
      setPainChips(loadedData.pain.split(';'));
      setHasPain(true);
      total = total + 1;
    }
    setSymptomsPoints(total);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    if (!loadedData) {
      setLoading(false);
      return;
    }
    setFormValues(loadedData);
  }, [loadedData]);

  useEffect(() => {
    if (!lastAsg) return;
    setFormValues(lastAsg);
  }, [lastAsg]);

  useEffect(() => {
    const hasError =
      errors.currentWeight?.type === 'required' ||
      errors.heightOver1m?.type === 'required' ||
      errors.weight1Month?.type === 'required' ||
      errors.weight6Month?.type === 'required';
    if (hasError) {
      toast.error(
        'Você precisa preencher todos os campos obrigatórios para avançar'
      );
      window.scrollTo(0, 0);
    }
  }, [errors, isSubmitted]);

  async function createASG(data: IFormData) {
    if (!appointmentId) return;
    if (loadedData) return;
    await createASGMutation({
      variables: {
        asg: { appointmentId, ...data }
      },
      onCompleted: () => {
        toast.success(
          'Triagem do risco nutricional ASG-PPP criada com sucesso.'
        );
        history.push('/home/nutritional-evaluation');
      }
    });
  }

  async function updateASG(data: IFormData) {
    if (!appointmentId || !loadedData) return;
    await updateASGMutation({
      variables: {
        asg: { appointmentId, ...data }
      },
      onCompleted: () => {
        toast.info(
          'Triagem do risco nutricional ASG-PPP atualizada com sucesso.'
        );
        history.push('/home/nutritional-evaluation');
      }
    });
  }

  function onSubmit(form: IFormData) {
    try {
      createASG(form);
      updateASG(form);
    } catch {
      toast.error('Houve um erro');
    }
  }

  if (loading) return <CircularProgress />;

  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Avaliação Subjetiva Global - Preenchida pelo Paciente
            </Typography>
            <TooltipComponent content="Utilizada em pacientes adultos e idosos oncológicos" />
          </Box>
          <Divider />
          <ChipComponent
            sx={{ marginTop: '20px' }}
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${
              setFoodIngestionQuantityPointsFunction(
                watchFoodIngestionLastMonth
              ) +
              setFoodIngestionTypePointsFunction(watchFoodIngestionNow) +
              setWeightPointsFunction(watchWeightLoss) +
              setActivityLastMonthPointsFunction(watchActivityLastMonth) +
              symptomsPoints
            } PONTOS`}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="50vh"
            margin="15px 0"
          >
            <Box>
              <Subtitle title="Peso:" />
              <Typography
                fontSize="14px"
                fontWeight="500"
                color={theme.palette.grey[900]}
              >
                Resumindo meu peso atual e recente:
              </Typography>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                suffix="kg"
                placeholder="Ex: 00,0 kg"
                control={control}
                name="currentWeight"
                label={
                  <Box display="flex">
                    Eu atualmente peso aproximadamente (kg):
                    <Typography color="error.main">*</Typography>
                  </Box>
                }
              />
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={0}
                placeholder="Ex: 00 cm"
                suffix="cm"
                control={control}
                name="heightOver1m"
                label={
                  <Box display="flex">
                    Eu tenho aproximadamente 1 metro e (cm):
                    <Typography color="error.main">*</Typography>
                  </Box>
                }
              />
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                suffix="kg"
                placeholder="Ex: 00,0 kg"
                control={control}
                name="weight1Month"
                label={
                  <Box display="flex">
                    Há 1 mês eu costumava pesar (kg):
                    <Typography color="error.main">*</Typography>
                  </Box>
                }
              />
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                placeholder="Ex: 00,0 kg"
                suffix="kg"
                control={control}
                name="weight6Month"
                label={
                  <Box display="flex">
                    Há 6 meses eu costumava pesar (kg):
                    <Typography color="error.main">*</Typography>
                  </Box>
                }
              />
            </Box>

            <Box>
              <RadioButtonInput
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 5px'
                }}
                control={control}
                name="weightLastWeeks"
                disabled={false}
                title="Durante as duas últimas semanas o meu peso:"
                radioButtons={[
                  {
                    checked: watchWeightLoss === 'lessWeight',
                    label: 'Diminuiu (1)',
                    value: 'lessWeight'
                  },
                  {
                    checked: watchWeightLoss === 'sameWeight',
                    label: 'Ficou igual (0)',
                    value: 'sameWeight'
                  },
                  {
                    checked: watchWeightLoss === 'heavier',
                    label: 'Aumentou (0)',
                    value: 'heavier'
                  }
                ]}
              />
            </Box>

            <ChipComponent
              sx={{ width: '18%' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${setWeightPointsFunction(watchWeightLoss)} PONTOS`}
            />
          </Box>
          <Divider />
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box margin="10px 0">
              <Subtitle title="Ingestão alimentar:" />
            </Box>
            <Box height="max-content" marginBottom="15px">
              <RadioButtonInput
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                control={control}
                name="lastMonthFood"
                disabled={false}
                title="Comparada com minha alimentação habitual, no último mês, eu tenho comido:"
                radioButtons={[
                  {
                    checked: watchFoodIngestionLastMonth === 'same',
                    label: 'A mesma coisa (0)',
                    value: 'same'
                  },
                  {
                    checked: watchFoodIngestionLastMonth === 'more',
                    label: 'Mais do que o habitual (0)',
                    value: 'more'
                  },
                  {
                    checked: watchFoodIngestionLastMonth === 'less',
                    label: 'Menos do que o habitual (1)',
                    value: 'less'
                  }
                ]}
              />
            </Box>
            <Box height="max-content" marginBottom="15px">
              <RadioButtonInput
                sx={{
                  marginTop: '10px',
                  height: 'max-content',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr'
                }}
                control={control}
                name="currentFood"
                disabled={false}
                title="Atualmente, eu estou comendo:"
                radioButtons={[
                  {
                    checked: watchFoodIngestionNow === 'lessSolidFood',
                    label: `a mesma comida (sólida) em menor quantidade do que o habitual (1)`,
                    value: 'lessSolidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'muchLessSolidFood',
                    label: 'a mesma comida (sólida) em pouca quantidade (2)',
                    value: 'muchLessSolidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'liquidFood',
                    label: 'apenas alimentos líquidos (3)',
                    value: 'liquidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'suplements',
                    label: 'apenas suplementos nutricionais (3)',
                    value: 'suplements'
                  },
                  {
                    checked: watchFoodIngestionNow === 'littleFood',
                    label: 'muito pouca quantidade de qualquer alimento (4)',
                    value: 'littleFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'tube',
                    label: 'apenas alimentação por sonda ou pela veia (0)',
                    value: 'tube'
                  }
                ]}
              />
            </Box>

            <ChipComponent
              sx={{ width: '18%' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${
                setFoodIngestionQuantityPointsFunction(
                  watchFoodIngestionLastMonth
                ) + setFoodIngestionTypePointsFunction(watchFoodIngestionNow)
              } PONTOS`}
            />
          </Box>
          <Box margin="20px 0">
            <Divider />
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            <Box>
              <Subtitle title="Sintomas" />
              <Typography
                fontSize="14px"
                fontWeight="500"
                color={theme.palette.grey[900]}
              >
                Durante as duas últimas semanas, eu tenho tido os seguintes
                problemas que me impedem de comer o suficiente (marque todos os
                que estiver sentindo):
              </Typography>
            </Box>
            <Box width="100%" display="flex">
              <Box width="50%">
                <FormControlLabel
                  sx={{
                    color: theme.palette.grey[900],
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px'
                    }
                  }}
                  control={
                    <Checkbox
                      checked={normalFeed ? true : false}
                      color="info"
                      value={normalFeed}
                      onChange={(event) =>
                        setNormalFeedValue(event.target.checked)
                      }
                    />
                  }
                  label="sem problemas para me alimentar (0)"
                />
                {generateSymptoms(firstColumnSymptoms)}
                <Box display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    sx={{ color: theme.palette.grey[900] }}
                    control={
                      <Checkbox
                        checked={hasPain ? true : false}
                        color="info"
                        value={hasPain}
                        onChange={(event) =>
                          setPainFunction(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography
                          fontSize="14px"
                          color={theme.palette.grey[900]}
                        >
                          dor, onde?
                        </Typography>
                        <TooltipComponent
                          isSmall
                          content="Ao incluir dores, será considerado automaticamente, três pontos adicionais"
                        />
                      </Box>
                    }
                  />
                  {hasPain ? (
                    <ArrayInputComponent
                      label="Onde?"
                      placeholder="Digite aqui..."
                      subtitle="Dores adicionadas"
                      fieldData={painChips}
                      setFieldData={setPainChips}
                      inputWidth="100%"
                      buttonWidth="30%"
                    />
                  ) : (
                    ''
                  )}
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    sx={{ color: theme.palette.grey[900] }}
                    control={
                      <Checkbox
                        checked={hasOtherSymptoms ? true : false}
                        color="info"
                        value={hasOtherSymptoms}
                        onChange={(event) =>
                          setHasOtherSymptomsFunction(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography
                          fontSize="14px"
                          color={theme.palette.grey[900]}
                        >
                          outros
                        </Typography>
                        <TooltipComponent
                          isSmall
                          content="Ao incluir outros sintomas, será considerado automaticamente, um ponto adicional"
                        />
                      </Box>
                    }
                  />
                  {hasOtherSymptoms ? (
                    <ArrayInputComponent
                      label="Outros"
                      placeholder="Digite aqui..."
                      subtitle="Sintomas adicionados"
                      fieldData={otherSymptomsChips}
                      setFieldData={setOtherSymptomsChips}
                      inputWidth="100%"
                      buttonWidth="30%"
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Box width="50%">{generateSymptoms(secondColumnSymptoms)}</Box>
            </Box>
            <ChipComponent
              sx={{ width: '18%', margin: '20px 0' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${symptomsPoints} PONTOS`}
            />
          </Box>
          <Divider />
          <Box width="100%" display="flex" flexDirection="column">
            <Subtitle title="Atividades e função" />
            <Box>
              <RadioButtonInput
                control={control}
                name="activityLastMonth"
                disabled={false}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr'
                }}
                title="No último mês, de um modo geral, eu consideraria
                a minha atividade (função) como:"
                radioButtons={[
                  {
                    checked: watchActivityLastMonth === 'normal',
                    label: 'Normal, sem nenhuma limitação (0)',
                    value: 'normal'
                  },
                  {
                    checked: watchActivityLastMonth === 'almostNormal',
                    label: `Não totalmente normal, mas capaz de manter quase todas as atividades normais (1)`,
                    value: 'almostNormal'
                  },
                  {
                    checked: watchActivityLastMonth === 'tired',
                    label:
                      'Sem disposição para a maioria das coisas, mas ficando na cama ou na cadeira menos da metade do dia (2)',
                    value: 'tired'
                  },
                  {
                    checked: watchActivityLastMonth === 'mostTimeInBed',
                    label:
                      'Capaz de fazer pouca atividade e passando a maior parte do dia na cadeira ou na cama (3)',
                    value: 'mostTimeInBed'
                  },
                  {
                    checked: watchActivityLastMonth === 'laidUp',
                    label: 'Praticamente acamado, raramente fora da cama (3)',
                    value: 'laidUp'
                  }
                ]}
              />
            </Box>
            <ChipComponent
              sx={{ width: '18%', margin: '20px 0' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${setActivityLastMonthPointsFunction(
                watchActivityLastMonth
              )} PONTOS`}
            />
          </Box>
          <ChipComponent
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${
              setFoodIngestionQuantityPointsFunction(
                watchFoodIngestionLastMonth
              ) +
              setFoodIngestionTypePointsFunction(watchFoodIngestionNow) +
              setWeightPointsFunction(watchWeightLoss) +
              setActivityLastMonthPointsFunction(watchActivityLastMonth) +
              symptomsPoints
            } PONTOS`}
          />
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(ASGForm);
