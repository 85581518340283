import React, { useState } from 'react';
import { Button, Dialog, Box, Typography, IconButton } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';

import logo from 'assets/newLogo.png';
import { Input } from 'components/Input';
import { theme } from 'styles/theme';
import { CHANGE_PASSWORD } from 'services/graphql/mutations/user/changePassword';

interface Props {
  show: boolean;
  onClose: () => void;
}

interface IFormType {
  oldPassword: string;
  newPassword: string;
  confPassword: string;
}

interface GqlResponse {
  changePassword: boolean;
}

const validationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Insira sua senha atual')
    .min(6, 'Uma senha deve ter pelo menos 6 caracteres'),
  newPassword: yup
    .string()
    .notOneOf(
      [yup.ref('oldPassword'), null],
      'A Senha nova deve ser diferente da senha antiga'
    )
    .required('Insira sua nova senha')
    .min(6, 'Uma senha deve ter pelo menos 6 caracteres'),
  confPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      'A confirmação de senha deve ser igual a nova senha'
    )
    .required('Insira a confirmação de senha')
    .min(6, 'Uma senha deve ter pelo menos 6 caracteres')
});

const defaultValues = {
  oldPassword: '',
  newpassword: '',
  confPassword: ''
};

export function ChangePassModal({ show, onClose }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues
  });

  const [changePAss] = useMutation<GqlResponse>(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      if (data.changePassword) {
        toast.success('Senha alterada com sucesso.');
        onClose();
        reset();
      } else {
        toast.error('Ocorreu um erro ao tentar alterar sua senha');
      }
    },
    onError: () => {
      toast.error('Ocorreu um erro ao tentar alterar sua senha');
    },
    fetchPolicy: 'network-only'
  });

  function onSubmit({ oldPassword, newPassword, confPassword }: IFormType) {
    if (newPassword === confPassword) {
      changePAss({
        variables: { oldPassword, newPassword }
      });
    } else {
      toast.error('A confirmação de senha deve ser igual à nova senha');
    }
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <Box
        width="500px"
        // height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          minHeight="70vh"
          width="400px"
          borderRadius="4px"
          bgcolor="background.paper"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ paddingTop: '10px' }}
        >
          <img height="200px" src={logo} alt="Nestlé Health Science" />
          <Box width="95%" padding="20px 20px" paddingBottom="20px">
            <Typography
              fontSize="18px"
              color={theme.palette.grey[900]}
              align="center"
            >
              <strong>Redefinir a sua senha</strong>
            </Typography>
            <Typography
              align="center"
              fontSize="18px"
              color={theme.palette.grey[900]}
            >
              Preencha os campos abaixo:
            </Typography>
          </Box>
          <Box
            padding="0 15px"
            marginBottom="25px"
            width="92%"
            minHeight="50vh"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="oldPassword"
              label="Senha Atual"
              placeholder="Digite aqui"
              type={showPassword ? 'text' : 'password'}
              icon={
                <IconButton
                  sx={{ color: theme.palette.grey[200] }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="newPassword"
              label="Nova senha"
              placeholder="Digite aqui"
              type={showPassword ? 'text' : 'password'}
              icon={
                <IconButton
                  sx={{ color: theme.palette.grey[200] }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />

            <Input
              sx={{ width: '100%' }}
              control={control}
              name="confPassword"
              label="Confirmação Senha"
              placeholder="Digite aqui"
              type={showPassword ? 'text' : 'password'}
              icon={
                <IconButton
                  sx={{ color: theme.palette.grey[200] }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            <Button
              sx={{
                width: '100%',
                textTransform: 'none',
                alignSelf: 'flex-end'
              }}
              color="info"
              variant="contained"
              type="submit"
            >
              Redefinir
            </Button>
            <Button
              sx={{
                width: '100%',
                textTransform: 'none',
                alignSelf: 'flex-end'
              }}
              color="info"
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
