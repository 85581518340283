import { gql } from '@apollo/client';

const FIND_FULL_PATIENT_BY_ID = gql`
  query findPatient($id: Float!) {
    findPatient(id: $id) {
      id
      name
      birthdate
      gender
      modality
      observations
      medicalRecord
      lastAppointment
      appointments {
        id
        appointmentOrder
        patientId
        datetime
        diagnosis
        diseaseData {
          smoking
          alcoholism
          bowelHabit
          urinaryHabit
          waterIntake
          cids {
            code
            staging
          }
          others {
            type
            description
          }
          treatments {
            code
          }
        }
        symptoms {
          constipation
          nausea
          anosmia
          quicklySatisfied
          dryMouth
          dysphagia
          noAppetite
          ache
          diarrhea
          vomit
          smellBothers
          inappetence
          mucositis
          tiredness
          dysgeusia
          others {
            name
            frequency
          }
        }
        ecog {
          effortDiffculty
          longWalkDifficulty
          shortWalkDifficulty
          stayInBed
          helpFeed
          activityDiffeculty
          leisureDifficulty
          breathShort
          ache
          rest
          troubleSleep
          weak
          appetiteLack
          feelSick
          feelVomit
          constipation
          diarrhea
          tired
          acheActivities
          attetionDiffculty
          nervous
          worried
          riledup
          depressed
          rememberDifficulty
          familiarLife
          socialActivities
          financialDifficulty
          mentalHealth
          qualityOfLife
          appointmentId
          functionalScale
          symptomsScale
          globalHealthStatus
        }
        factg {
          noEnergy
          nausea
          familyNeedsDifficulty
          ache
          secondaryEffects
          sick
          decumbent
          friendsRelationship
          friendsRelationship
          emotionalSupport
          friendSupport
          familyAcceptance
          satisfyingConversation
          closenessPartner
          sexLife
          sad
          confrontation
          losingHope
          nervous
          deathIdea
          worseningState
          workCapacity
          workFulfillment
          livePleasure
          illnessAcceptance
          sleepWell
          likeFun
          pleased
          concentration
          convulsions
          remember
          frustation
          fearConvulsion
          visionDifficulty
          independence
          hardHearing
          findWords
          thoughtsDifficulty
          personalityChange
          decisionHability
          familyContribution
          thoughtOrganizarion
          personalCares
          thoughtsPractice
          readCapacity
          writeCapacity
          driveCapacity
          sensationsDifficulty
          weakness
          coordinationDifficulty
          headache
        }
        bioParams {
          albumin
          transferrin
          preAlbumin
          retinol
          pcr
          additionalProteins
          hemoglobin
          hematocrit
          lymphocite
          neutrophyl
          creatinine
          urea
          platelets
          leukocytes
          tgo
          tgp
          additionalBllod
          ingestedNitrogen
          excretedNitrogen
          additionalNitrogen
        }
        NutritionalData {
          height
          weight
          habitualWeight
          weightLossTime
        }
        nrAsgppp {
          currentWeight
          heightOver1m
          weight1Month
          weight6Month
          weightLastWeeks
          lastMonthFood
          currentFood
          normalFeed
          nausea
          constipation
          mucositis
          tasteless
          swallowProblem
          tiredness
          appetiteLack
          vomit
          diarrhea
          dryMouth
          quicklySatified
          smellsBother
          others
          pain
          activityLastMonth
        }
        MAN {
          foodIntakeDecrease
          weightLoss
          mobility
          recentStress
          neurologicalProblems
          imc
        }
        Mst {
          weightLoss
          weightLossQuantity
          eatingLess
        }
        Must {
          imcPontuation
          weightLoss
          cannotFeed
        }
        Nrs {
          imcLessThan20
          weightLoss
          eatingLess
          seriouslyIll
          nutritionalStatusDeterioration
          diseaseLevel
        }
        neAsgppp {
          currentWeight
          heightOver1m
          heightOver1m
          weight1Month
          weight6Month
          weightLastWeeks
          lastMonthFood
          currentFood
          normalFeed
          nausea
          constipation
          mucositis
          tasteless
          swallowProblem
          tiredness
          appetiteLack
          vomit
          diarrhea
          dryMouth
          quicklySatified
          smellsBother
          others
          pain
          activityLastMonth
          weight1MonthSec
          weight6MonthSec
          relevantDiagnosis
          primaryStaging
          primaryStagingOther
          cancer
          cachexia
          trauma
          renalInsufficiency
          aids
          decubitusUlcer
          moreThan65
          corporalTemperature
          fever
          feverDuration
          corticosteroids
          temple
          clavicle
          shoulder
          hands
          shoulderBlade
          thigh
          calf
          muscleGeneralState
          periorbital
          triceps
          lowerRibs
          fatDeficitGeneralState
          ankle
          sacralEdema
          hidratationGeneralState
          globalCategory
        }
        neAgs {
          lost6Mont
          wheighthangeLastWeeks
          changeIngestion
          changeIngDuration
          changeIngType
          noGastroSymptoms
          nausea
          vomit
          diarrhea
          anorexia
          functionalCapacity
          functionalCapDuration
          functionalCapType
          primaryDiagnosis
          metabolicDemand
          fatLoss
          muscleLoss
          ankleEdema
          sacralEdema
          ascites
          diseaseChangeLastWeeks
          subjectiveEvaluation
        }
        neMan {
          ingestDecrease
          weightLoss
          mobility
          stress
          neuroPsychological
          bodyMass
          ownHome
          threeMedicines
          eschar
          mealsByDay
          milkDerivatives
          vegetablesEggs
          meatFishBird
          fruits
          liquids
          wayEating
          nutritionalProblem
          ownHealth
          armCircumference
          lagCircumference
        }
        antropometric {
          cp
          cb
          dct
        }
        bodyCompositionBia {
          fatFreeMassPct
          fatFreeMassKg
          leanMassKg
          fatMassPct
          fatMassKg
          resistance
          reactance
          phaseAngle
        }
        bodyCompositionTc {
          skeletalMuscleMass
          adiposeTissue
        }
        bodyCompositionDxa {
          appendicularMuscleMass
        }
        strengthFunctionality {
          handGrip
          sitGetup
          tug
        }
        nutritionalIntervention {
          usedWeight
          energyNeed
          calorificValue
          proteinNeed
          proteinValue
          foodIntake
          nutritionalTherapy
          tno
          tne
          therapyDosage
          TherapyProtein
          therapyCalorific
        }
      }
    }
  }
`;

export { FIND_FULL_PATIENT_BY_ID };
