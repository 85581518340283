import { FullAppointment } from 'models/fullAppointment';
import { Patient } from 'models/patient';
import { calculatePatientAge } from 'utils/functions/calculateLoadedPatientAge';
import {
  verifyAlbuminValue,
  verifyTransferrinValue,
  verifyPreAlbuminValue,
  verifyRetinolValue,
  verifyPcrValue,
  verifyHemoglobinValue,
  verifyHematocritValue,
  verifyLymphocyteValue,
  verifyNeutrophylValue,
  verifyCreatinineValue,
  verifyUreaValue,
  verifyPlateletsValue,
  verifyLeukocytesValue,
  verifyTgoValue,
  verifyTgpValue,
  generateBioParamsBNResults
} from './renderBioparamsResult';

import { generateDiseaseDataResults } from './renderDiseaseDataResult';
import { generateSymptomsResults } from './renderSymptomsResult';
import { generateLifeQualityResults } from './renderLifeQualityResults';

import { generateNutritionalDataResults } from './renderNutritionalDataResult';
import { generateNutritionalRiskResults } from './renderNutritionalRiskResults';
import { generateNutritionalEvaluationResults } from './renderNutritionalEvaluationResults';
import {
  generateAnthropometricEvaluationAMBResults,
  generateAnthropometricEvaluationCBResults,
  generateAnthropometricEvaluationCMBResults,
  generateAnthropometricEvaluationCPResults,
  generateAnthropometricEvaluationDCTResults
} from './renderAnthropometricResults';
import {
  generateBodyCompositionDXAResults,
  generateBodyCompositionTCResults
} from './renderBodyCompositionResults';
import { generateStrengthEvaluationResults } from './renderStrenghtResult';
import { StepResult } from '..';

export function renderAllAppointmentDataParsed(
  appointment: FullAppointment,
  patient: Patient
) {
  const loadedAppointment = appointment;
  const { gender, birthdate } = patient;
  const age = calculatePatientAge(birthdate);
  const bioparams = [
    verifyAlbuminValue?.(loadedAppointment?.bioParams?.albumin),
    verifyTransferrinValue(loadedAppointment?.bioParams?.transferrin),
    verifyPreAlbuminValue(loadedAppointment?.bioParams?.preAlbumin),
    verifyRetinolValue(loadedAppointment?.bioParams?.retinol),
    verifyPcrValue(loadedAppointment?.bioParams?.pcr),
    verifyHemoglobinValue(loadedAppointment?.bioParams?.hemoglobin, gender),
    verifyHematocritValue(loadedAppointment?.bioParams?.hematocrit, gender),
    verifyLymphocyteValue(loadedAppointment?.bioParams?.lymphocite),
    verifyNeutrophylValue(loadedAppointment?.bioParams?.neutrophyl),
    verifyCreatinineValue(loadedAppointment?.bioParams?.creatinine, gender),
    verifyUreaValue(loadedAppointment?.bioParams?.urea),
    verifyPlateletsValue(loadedAppointment?.bioParams?.platelets),
    verifyLeukocytesValue(loadedAppointment?.bioParams?.leukocytes),
    verifyTgoValue(loadedAppointment?.bioParams?.tgo),
    verifyTgpValue(loadedAppointment?.bioParams?.tgp),
    generateBioParamsBNResults(
      loadedAppointment?.bioParams ? loadedAppointment?.bioParams : undefined
    )
  ];

  const appointmentData: StepResult[] | undefined = [
    generateDiseaseDataResults(loadedAppointment?.diseaseData),
    {
      title: 'Sintomas',
      contents: generateSymptomsResults(loadedAppointment?.symptoms)[0]
        ? generateSymptomsResults(loadedAppointment?.symptoms)
        : [{ contentTitle: 'Não há dados para exibir', content: '' }]
    },
    generateLifeQualityResults(
      loadedAppointment?.ecog,
      loadedAppointment?.factg
    ),
    {
      title: 'Parâmetros bioquímicos',
      contents: bioparams.filter(
        (items) => items.content && items.contentTitle !== undefined
      )
    },
    {
      title: 'Dados e informações nutricionais',
      contents: generateNutritionalDataResults(
        loadedAppointment?.NutritionalData,
        age
      )
    },
    {
      title: 'Triagem do risco nutricional',
      contents: [generateNutritionalRiskResults(loadedAppointment)]
    },
    {
      title: 'Avaliação nutricional',
      contents: [generateNutritionalEvaluationResults(loadedAppointment)]
    },
    {
      title: 'Avaliação antropométrica',
      contents: [
        generateAnthropometricEvaluationCPResults(loadedAppointment, gender),
        generateAnthropometricEvaluationCBResults(
          loadedAppointment,
          gender,
          age
        ),
        generateAnthropometricEvaluationDCTResults(
          loadedAppointment,
          gender,
          age
        ),
        generateAnthropometricEvaluationCMBResults(
          loadedAppointment,
          gender,
          age
        ),
        generateAnthropometricEvaluationAMBResults(
          loadedAppointment,
          gender,
          age
        )
      ]
    },
    {
      title: 'Avaliação da composição corporal',
      contents: [
        generateBodyCompositionTCResults(loadedAppointment),
        generateBodyCompositionDXAResults(loadedAppointment)
      ]
    },
    {
      title: 'Avaliação da força força e funcionalidade',
      contents: generateStrengthEvaluationResults(
        loadedAppointment,
        gender,
        age
      )
    }
  ];
  return appointmentData;
}
