import { Appointment } from 'models/appointment';

export const prepareCorporalComposition = (appointments: Appointment[]) => {
  if (appointments) {
    return [
      {
        tag: 'TC',
        points: appointments.map((a) =>
          a.bodyCompositionTc ? a.bodyCompositionTc.skeletalMuscleMass : null
        )
      },
      {
        tag: 'DXA',
        points: appointments.map((a) =>
          a.bodyCompositionDxa
            ? a.bodyCompositionDxa.appendicularMuscleMass
            : null
        )
      }
    ];
  } else {
    return [];
  }
};
