import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import {
  InputWithNumberMask,
  ChipComponent,
  TooltipComponent
} from 'components';
import { toast } from 'react-toastify';
import { StrengthFunctionality } from 'models/strenghtFunctionality';
import { useMutation } from '@apollo/client';
import {
  UPDATE_STRENGHT_AND_FUNCTIONALITY,
  CREATE_STRENGHT_AND_FUNCTIONALITY,
  StrenghtFunctionalityMutationsVars
} from 'services/graphql/mutations/strenghtAndFunctionality';

interface IFormData {
  handGrip: number;
}

export function calculateElderlyManHandGrip(handGrip: number) {
  if (handGrip < 27) return 'Baixa força';
  if (handGrip === 27) return 'Força normal';
  if (handGrip > 27) return 'Força acima da média';

  return 'Aguardando dados';
}

export function calculateElderlyWomanHandGrip(sex: string, handGrip: number) {
  if (sex === 'FEMALE') {
    if (handGrip < 16) return 'Baixa força';
    if (handGrip === 16) return 'Força normal';
    if (handGrip > 16) return 'Força acima da média';
  }
  return 'Aguardando dados';
}

export function calculateAdultManHandGripPercentil(age: number) {
  if (age <= 5) return 8;
  if (age > 5 && age <= 10) return 17;
  if (age > 10 && age <= 15) return 29;
  if (age > 15 && age <= 20) return 40;
  if (age > 20 && age <= 25) return 48;
  if (age > 25 && age <= 30) return 51;
  if (age > 30 && age <= 35) return 51;
  if (age > 35 && age <= 40) return 50;
  if (age > 40 && age <= 45) return 49;
  if (age > 45 && age <= 50) return 48;
  if (age > 50 && age <= 55) return 47;
  if (age > 55 && age <= 60) return 45;
  if (age > 60 && age <= 65) return 43;
  return 0;
}

export function calculateAdultWomanHandGripPercentil(age: number) {
  if (age <= 5) return 8;
  if (age > 5 && age <= 10) return 16;
  if (age > 10 && age <= 15) return 24;
  if (age > 15 && age <= 20) return 28;
  if (age > 20 && age <= 25) return 30;
  if (age > 25 && age <= 30) return 31;
  if (age > 30 && age <= 35) return 31;
  if (age > 35 && age <= 40) return 31;
  if (age > 40 && age <= 45) return 30;
  if (age > 45 && age <= 50) return 29;
  if (age > 50 && age <= 55) return 28;
  if (age > 55 && age <= 60) return 27;
  if (age > 60 && age <= 65) return 25;
  return 0;
}

export function calculateHandGripResult(percentil: number, handGrip: number) {
  if (handGrip < percentil) return 'Baixa força';
  if (handGrip === percentil) return 'Força normal';
  if (handGrip > percentil) return 'Força acima da média';
  return 'Aguardando dados';
}

const HandGripForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    age: number;
    sex: string;
    loadedStrenght: StrengthFunctionality | undefined;
    lastStrenght: StrengthFunctionality | undefined;
  }
> = ({ age, sex, loadedStrenght, lastStrenght }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormData>();
  const [handGripStrenght, setHandGripStrenght] = useState('Aguardando dados');
  const watchHandGrip = watch('handGrip');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createStrenghtHandGripMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(CREATE_STRENGHT_AND_FUNCTIONALITY);
  const [updateStrenghtHandGripMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(UPDATE_STRENGHT_AND_FUNCTIONALITY);

  useEffect(() => {
    if (age > 65 && sex === 'MALE')
      setHandGripStrenght(calculateElderlyManHandGrip(watchHandGrip));
    if (age > 65 && sex === 'FEMALE')
      setHandGripStrenght(calculateElderlyWomanHandGrip(sex, watchHandGrip));
    if (sex === 'MALE' && age <= 65) {
      const handGripValue = calculateHandGripResult(
        calculateAdultManHandGripPercentil(age),
        watchHandGrip
      );
      setHandGripStrenght(handGripValue);
    }
    if (sex === 'FEMALE' && age <= 65) {
      const handGripValue = calculateHandGripResult(
        calculateAdultWomanHandGripPercentil(age),
        watchHandGrip
      );
      setHandGripStrenght(handGripValue);
    }
  }, [watchHandGrip]);

  function setLoadedHandGrip(data: StrengthFunctionality | undefined) {
    if (!data) return;
    setValue('handGrip', data.handGrip);
  }

  useEffect(() => {
    setLoadedHandGrip(loadedStrenght);
  }, [loadedStrenght]);

  useEffect(() => {
    setLoadedHandGrip(lastStrenght);
  }, [lastStrenght]);

  async function createStrenght({ handGrip }: IFormData) {
    if (loadedStrenght || !handGrip) return;
    await createStrenghtHandGripMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          handGrip
        }
      },
      onCompleted: () => {
        toast.success(
          'Avaliação de força e funcionalidade Hand Grip adicionada'
        );
      }
    });
  }

  async function updateStrenght({ handGrip }: IFormData) {
    if (!loadedStrenght) return;
    await updateStrenghtHandGripMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          handGrip
        }
      },
      onCompleted: () => {
        toast.info('Avaliação de força e funcionalidade Hand Grip adicionada');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createStrenght(data);
      updateStrenght(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="25vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Hand Grip
              </Typography>
              <TooltipComponent
                content="
                Reflete a força máxima derivada da contração combinada dos músculos
                extrínsecos e intrínsecos da mão que levam à flexão das articulações.
                É um preditor de resultados desfavoráveis aos pacientes e está associada
                à perda de funcionalidade física e ao impacto negativo na recuperação
                da saúde após uma doença ou cirurgia.
                "
              />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              sx={{ width: '40%' }}
              name="handGrip"
              control={control}
              decimalScale={1}
              label={
                <Box>
                  <Typography>
                    Hand grip <i>(kg):</i>
                  </Typography>
                </Box>
              }
              placeholder="Ex: 00,0kg"
              suffix="kg"
            />
          </Box>
          <ChipComponent
            sx={{ width: '40%' }}
            color={{
              colors:
                handGripStrenght === 'Baixa força' ? 'light-red' : 'light-blue'
            }}
            label={`Força de pressão palmar: ${handGripStrenght}`}
            size="small"
          />
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(HandGripForm);
