import { Statistic } from 'models/dashboard';

export const translateTneName = (name: string): string => {
  switch (name) {
    case 'NE hiper/hiper':
      return 'Hiper/hiper';
    case 'NE hiperproteica':
      return 'Hiperproteica';
    case 'NE hipercalórica':
      return 'Hipercalórica';
    case 'NE imunomoduladora':
      return 'Imunomoduladora';
    default:
      return '';
  }
};

export const prepareTne = (tnes: Statistic[][]) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  let i = 1;
  for (const tne of tnes) {
    const data: any = {};
    data.id = i < 10 ? `C0${i}` : `C${i}`;

    if (tne) {
      for (const t of tne) {
        const name = translateTneName(t.name);
        data[name] = t.quantity;
        // if (ret.keys.indexOf(name) === -1)
        ret.keys.add(name);
      }
    }

    ret.data.push(data);
    i++;
  }

  return ret;
};
