import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  SelectChangeEvent
} from '@mui/material';
import { useQuery } from '@apollo/client';

import { PieChart, PieData, MultiGroupedBar } from 'components/Dashboard';
import { INSITUTIONAL_DASHBOARD } from 'services/graphql/queries/dashboard/institutional/dashboard';
import {
  prepareCidNutriDiag,
  prepareCids,
  prepareDiagnosis,
  prepareNutriStateTreatments,
  prepareNutriSup,
  prepareSymptomsTne,
  prepareSymptomsTno,
  prepareTne,
  prepareTno,
  prepareTreatments,
  prepareTriage
} from './dataPrepare';
import { prepareDiagnosisTno } from './dataPrepare/prepareDiagnosisTNO';
import { prepareDiagnosisTNE } from './dataPrepare/prepareDiagnosisTNE';
import jwt_decode from 'jwt-decode';

function Container({
  children
}: {
  children: JSX.Element | JSX.Element[] | undefined;
}) {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ flexGrow: 1 }}
      style={{ padding: '20px 20px' }}
    >
      {children}
    </Grid>
  );
}

export function InstDashboard() {
  const token = localStorage.getItem('user_token');
  const decoded_token: { role: string; clinicId: number } = jwt_decode(
    String(token)
  );
  const { data } = useQuery(INSITUTIONAL_DASHBOARD, {
    variables: {
      clinicId:
        decoded_token.role === 'NUTRITIONIST'
          ? decoded_token.clinicId
          : undefined
    }
  });
  const [gender, setGender] = useState<PieData[]>([]);
  const [cids, setCids] = useState<PieData[]>([]);
  const [treatments, setTreatments] = useState<PieData[]>([]);
  const [diagnosis, setDiagnosis] = useState<any[]>([]);
  const [diagnosisKeys, setDiagnosisKeys] = useState<string[]>([]);

  const [triage, setTriage] = useState<any[]>([]);
  const [triageKeys, setTriageKeys] = useState<string[]>([]);
  const [triageSelected, setTriageSelected] = useState<number>(0);

  const [tno, setTno] = useState<any[]>([]);
  const [tnoKeys, setTnoKeys] = useState<string[]>([]);

  const [tne, setTne] = useState<any[]>([]);
  const [tneKeys, setTneKeys] = useState<string[]>([]);

  const [cidNutri, setCidNutri] = useState<any[]>([]);
  const [cidNutriKeys, setCidNutiKeys] = useState<string[]>([]);

  const [nutriState, setNutriState] = useState<any[]>([]);
  const [nutriStateKeys, setNutriStateKeys] = useState<string[]>([]);

  const [nutriSup, setNutriSup] = useState<any[]>([]);
  const [nutriSupKeys, setNutriSupKeys] = useState<string[]>([]);

  const [diagnosisTno, setDiagnosisTno] = useState<any[]>([]);
  const [diagnosisTnoKeys, setDiagnosisTnoKeys] = useState<string[]>([]);

  const [diagnosisTne, setDiagnosisTne] = useState<any[]>([]);
  const [diagnosisTneKeys, setDiagnosisTneKeys] = useState<string[]>([]);

  const [symptomsTno, setSymptomsTno] = useState<any[]>([]);
  const [symptomsTnoKeys, setSymptomsTnoKeys] = useState<string[]>([]);

  const [symptomsTne, setSymptomsTne] = useState<any[]>([]);
  const [symptomsTneKeys, setSymptomsTneKeys] = useState<string[]>([]);

  useEffect(() => {
    if (data && data.institutionalDashBoard) {
      const dash = data.institutionalDashBoard;
      const genders = [
        {
          id: 'Masculino',
          label: 'Masculino',
          value: dash.gender.male || 0
        },
        {
          id: 'Feminino',
          label: 'Feminino',
          value: dash.gender.female || 0
        }
      ];
      setGender(genders);
      setCids(prepareCids(dash.cids));
      setTreatments(prepareTreatments(dash.treatments));

      const diags = prepareDiagnosis(dash.diagnosis);
      setDiagnosis(diags.data);
      setDiagnosisKeys(Array.from(diags.keys));

      const trg = prepareTriage({
        data: dash.triage,
        selected: triageSelected
      });
      setTriage(trg.data);
      setTriageKeys(Array.from(trg.keys));

      const tno = prepareTno(dash.tno);
      setTno(tno.data);
      setTnoKeys(Array.from(tno.keys));

      const tne = prepareTne(dash.tne);
      setTne(tne.data);
      setTneKeys(Array.from(tne.keys));

      const cidNutriDiag = prepareCidNutriDiag(dash.cidNutriDiag);
      setCidNutri(cidNutriDiag.data);
      setCidNutiKeys(Array.from(cidNutriDiag.keys));

      const nutriStateTreat = prepareNutriStateTreatments(
        dash.nutriStateTreatment
      );
      setNutriState(nutriStateTreat.data);
      setNutriStateKeys(nutriStateTreat.keys);

      const nutriSup = prepareNutriSup(dash.nutriSupplementation);
      setNutriSup(nutriSup.data);
      setNutriSupKeys(nutriSup.keys);

      const dataDiagnosisTno = prepareDiagnosisTno(dash.diagnosisTno);
      setDiagnosisTno(dataDiagnosisTno.data);
      setDiagnosisTnoKeys(Array.from(dataDiagnosisTno.keys));

      const dataDiagnosisTne = prepareDiagnosisTNE(dash.diagnosisTne);
      setDiagnosisTne(dataDiagnosisTne.data);
      setDiagnosisTneKeys(Array.from(dataDiagnosisTne.keys));

      const dataSymptomsTno = prepareSymptomsTno(dash.symptomsTno);
      setSymptomsTno(dataSymptomsTno.data);
      setSymptomsTnoKeys(Array.from(dataSymptomsTno.keys));

      const dataSymptomsTne = prepareSymptomsTne(dash.symptomsTne);
      setSymptomsTne(dataSymptomsTne.data);
      setSymptomsTneKeys(Array.from(dataSymptomsTne.keys));
    }
  }, [data]);

  const selectTriage = (event: SelectChangeEvent) => {
    const sel = +event.target.value;
    setTriageSelected(sel);
  };

  useEffect(() => {
    if (data && data.institutionalDashBoard) {
      const dash = data.institutionalDashBoard;
      const trg = prepareTriage({
        data: dash.triage,
        selected: triageSelected
      });
      setTriage(trg.data);
      setTriageKeys(Array.from(trg.keys));
    }
  }, [triageSelected]);

  return (
    <>
      <Typography sx={{ padding: '20px 40px', color: '#565656' }} variant="h4">
        Dashboard geral
      </Typography>

      <Container>
        <Grid md={4} sm={12} item padding={'0px 8px'}>
          <Card>
            <CardHeader title="Sexo" sx={{ color: '#565656' }} />
            <Divider />
            <CardContent sx={{ height: '271px', width: '380px' }}>
              <PieChart data={gender} />
            </CardContent>
          </Card>
        </Grid>
        <Grid md={4} sm={12} item padding={'0px 8px'}>
          <Card>
            <CardHeader
              title="Principais Neoplasias"
              sx={{ color: '#565656' }}
            />
            <Divider />
            <CardContent sx={{ height: '271px', width: '380px' }}>
              <PieChart data={cids} />
            </CardContent>
          </Card>
        </Grid>
        <Grid md={4} sm={12} item padding={'0px 8px'}>
          <Card>
            <CardHeader
              title="Principais Tratamentos"
              sx={{ color: '#565656' }}
            />
            <Divider />
            <CardContent sx={{ height: '271px', width: '380px' }}>
              <PieChart data={treatments} />
            </CardContent>
          </Card>
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Diagnóstico Nutricional"
            data={diagnosis}
            keys={diagnosisKeys}
          />
        </Grid>
      </Container>
      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Triagem"
            data={triage}
            keys={triageKeys}
            select={['AsgPPP', 'MAN', 'MST', 'MUST', 'NRS']}
            selected={triageSelected}
            setSelected={selectTriage}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar title="TNO" data={tno} keys={tnoKeys} />
        </Grid>
      </Container>
      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar title="TNE" data={tne} keys={tneKeys} />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre a neoplasia e o Diagnóstico Nutricional"
            data={cidNutri}
            keys={cidNutriKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre o estado Nutricional e o Tratamento"
            data={nutriState}
            keys={nutriStateKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Suplementação Nutricional"
            data={nutriSup}
            keys={nutriSupKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre a Prescrição Nutricional TNO e o Diagnóstico Nutricional"
            data={diagnosisTno}
            keys={diagnosisTnoKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre a Prescrição Nutricional TNE e o Diagnóstico Nutricional"
            data={diagnosisTne}
            keys={diagnosisTneKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre o sintoma e a Prescrição TNO"
            data={symptomsTno}
            keys={symptomsTnoKeys}
          />
        </Grid>
      </Container>

      <Container>
        <Grid md={12} sm={12} item padding={'0px 8px'}>
          <MultiGroupedBar
            title="Correlação entre o sintoma e a Prescrição TNE"
            data={symptomsTne}
            keys={symptomsTneKeys}
          />
        </Grid>
      </Container>
    </>
  );
}
