import React from 'react';
import { Table, TableBody, TableContainer, TableRow, Box } from '@mui/material';
import { theme } from 'styles/theme';
import { StyledTableCell } from './style';

interface RowType {
  firstCell: string;
  secondCell: string;
}

interface CutOffTableProps {
  title: string;
  rows: RowType[];
}

function CutoffTable({ rows, title }: CutOffTableProps) {
  return (
    <TableContainer
      sx={{ border: '1px solid', borderColor: theme.palette.grey[200] }}
    >
      <Box
        sx={{
          textAlign: 'center',
          color: theme.palette.grey[300],
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '35px'
        }}
      >
        PONTO DE CORTE
      </Box>
      <Box
        sx={{
          background: theme.palette.info.main,
          textAlign: 'center',
          color: theme.palette.background.paper,
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '35px'
        }}
      >
        {title}
      </Box>
      <Table size="small">
        <TableBody>
          <TableRow
            sx={{
              background: theme.palette.background.default,
              textAlign: 'center'
            }}
          >
            <StyledTableCell
              sx={{
                background: theme.palette.background.default,
                textAlign: 'center',
                border: '1px solid',
                borderColor: theme.palette.divider,
                fontWeight: '500',
                color: theme.palette.grey[300]
              }}
            >
              Homens
            </StyledTableCell>
            <StyledTableCell>Mulheres</StyledTableCell>
          </TableRow>
          {rows.map((row, index) => {
            return (
              <TableRow key={index}>
                <StyledTableCell>{row.firstCell}</StyledTableCell>
                <StyledTableCell>{row.secondCell}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { CutoffTable };
