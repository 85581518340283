import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { Man as ManInterface } from 'models/man';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface MANProps {
  appointment: Appointment;
}

type ManKeys =
  | 'foodIntakeDecrease'
  | 'weightLoss'
  | 'mobility'
  | 'recentStress'
  | 'neurologicalProblems'
  | 'imc';

interface ManObjectKey {
  key: ManKeys;
}

const allMANFields: ManObjectKey[] = [
  { key: 'foodIntakeDecrease' },
  { key: 'weightLoss' },
  { key: 'mobility' },
  { key: 'recentStress' },
  { key: 'neurologicalProblems' },
  { key: 'imc' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newManType = MappedType<ManInterface>;

export function Man({ appointment }: MANProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const MAN: newManType = appointment.MAN;
  if (!MAN) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  const result =
    MAN.foodIntakeDecrease +
    MAN.weightLoss +
    MAN.mobility +
    MAN.recentStress +
    MAN.neurologicalProblems +
    MAN.imc;

  function parseMANQuestion(value: string) {
    switch (value) {
      case 'foodIntakeDecrease':
        return `A- Nos últimos 3 meses, houve diminuição
              da ingesta alimentar devido a perda de apetite,
              problemas digestivos ou dificuldade para mastigar
              ou deglutir?:`;
      case 'weightLoss':
        return 'B- Perda de peso nos últimos 3 meses:';
      case 'mobility':
        return 'C- Mobilidade:';
      case 'recentStress':
        return 'D- Passou por algum stress psicológico ou doença aguda nos últimos 3 meses?:';
      case 'neurologicalProblems':
        return 'E- Problemas neuropsicológicos?:';
      case 'imc':
        return 'F- Índice de Massa Corporal (IMC = peso [kg]/ estatura [m²]):';
      default:
        return 'Não há dados';
    }
  }

  function parseManAnswers(key: string, value: string) {
    const parsedValue = value.toString();
    if (key === 'foodIntakeDecrease') {
      switch (parsedValue) {
        case '0':
          return 'Diminuição grave da ingesta';
        case '1':
          return 'Diminuição moderada da ingesta';
        case '2':
          return 'Sem diminuição da ingesta';
      }
    }
    if (key === 'weightLoss') {
      switch (parsedValue) {
        case '0':
          return 'Superior a três quilos';
        case '1':
          return 'Não sabe informar';
        case '2':
          return 'Entre um e três quilos';
        case '3':
          return 'Sem perda de peso';
      }
    }
    if (key === 'mobility') {
      switch (parsedValue) {
        case '0':
          return 'Restrito ao leito ou à cadeira de rodas';
        case '1':
          return 'Deambula, mas não é capaz de sair de casa';
        case '2':
          return 'Normal';
      }
    }
    if (key === 'recentStress') {
      switch (parsedValue) {
        case '0':
          return 'Sim';
        case '2':
          return 'Não';
      }
    }
    if (key === 'neurologicalProblems') {
      switch (parsedValue) {
        case '0':
          return 'Demência ou depressão grave';
        case '1':
          return 'Demência ligeira';
        case '2':
          return 'Sem problemas psicológicos';
      }
    }
    if (key === 'imc') {
      switch (parsedValue) {
        case '0':
          return 'IMC < 19';
        case '1':
          return '19 ≤ IMC < 21';
        case '2':
          return '21 ≤ IMC < 23';
        case '3':
          return 'IMC ≥ 23';
      }
    }
  }

  function calculateMANResult(): { status: string; color: IColors } {
    const parsedResult = Number(result);
    if (parsedResult <= 7 ? true : false)
      return { status: 'Desnutrido', color: { colors: 'light-red' } };
    if (parsedResult >= 8 && parsedResult <= 11 ? true : false)
      return {
        status: 'Risco de desnutrição',
        color: { colors: 'light-yellow' }
      };
    if (parsedResult >= 12 && parsedResult <= 14 ? true : false)
      return { status: 'Normal', color: { colors: 'light-green' } };
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-risk-screening')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              MAN: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allMANFields.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parseMANQuestion(key)} />
                <ResultText title={`${parseManAnswers(key, MAN[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: calculateMANResult().color.colors }}
            label={calculateMANResult().status}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
