import { gql } from '@apollo/client';

export const UPDATE_TC = gql`
  mutation updateTc($tc: TcInput!) {
    updateTc(tc: $tc) {
      appointmentId
      id
    }
  }
`;
