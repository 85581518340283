import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';

type CardProps = {
  color: string;
  title: string;
  quant: number;
};

export function DashCard({ color, title, quant }: CardProps) {
  return (
    <Grid item md={1.6} sm={3} xs={12}>
      <Paper
        elevation={3}
        sx={{
          borderLeft: '5px solid',
          borderColor: { color },
          height: '100%',
          minHeight: '102px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Typography color={'#565656'}>{title}</Typography>
        <Box display={'flex'}>
          <Box display={'inline'} flexGrow={1}>
            <Typography fontSize={22} color={'#565656'} noWrap={false}>
              <b>{quant}</b>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}
