import { gql } from '@apollo/client';

export const CREATE_NUTRITIONAL_INTERVENTION = gql`
  mutation createIntervention(
    $nutritionalIntervention: NutritionalInterventionInput!
  ) {
    createNutritionalIntervention(
      nutritionalIntervention: $nutritionalIntervention
    ) {
      id
    }
  }
`;
