import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';

type Point = number | string | null;

interface Data {
  tag?: string;
  points: Point[];
}

interface DataSelectorProps {
  data: Data[];
  selected: number;
  // setSelected: (val: number) => void;
}

interface PlotData {
  id: string;
  data: { x: string; y: Point }[];
}

interface Props {
  title: string | React.ReactNode;
  data: Data[];
  color: string;
}

export function LineGraph({ title, data, color }: Props) {
  // Sort first arrays with more valid points
  data.sort((a, b) =>
    a.points.filter((f) => f !== null).length <=
    b.points.filter((f) => f !== null).length
      ? 1
      : -1
  );

  const dataToPlot = (index: number) => {
    return [
      {
        id: data[index].tag || '.', // Nivo dont show graph if id is empty
        data: data[index].points.map((sel, i) => ({
          x: i < 10 ? `C0${i + 1}` : `C${i + 1}`,
          y: sel
        }))
      }
    ];
  };

  const [selected, setSelected] = useState<number>(0);
  const [plottedData, setPlottedData] = useState<PlotData[]>(dataToPlot(0));

  const DataSelector = ({ data, selected }: DataSelectorProps) => {
    return (
      <Select
        value={selected.toString()}
        onChange={handleChange}
        variant="standard"
        label=""
      >
        {data.map((d, i) => (
          <MenuItem value={i} key={i}>
            {d.tag}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    const sel = +event.target.value;
    setSelected(sel);
    setPlottedData(dataToPlot(sel));
  };

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          data.length > 1 && <DataSelector data={data} selected={selected} />
        }
      ></CardHeader>
      <Divider />
      <CardContent
        sx={{
          width: '100%',
          height: '270px',
          paddingTop: '0px'
        }}
      >
        <ResponsiveLine
          data={plottedData}
          colors={color}
          lineWidth={3}
          curve="cardinal"
          margin={{ top: 10, right: 10, bottom: 20, left: 40 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto'
          }}
          yFormat=" >-.2f"
          pointSize={10}
          pointBorderWidth={2}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </CardContent>
    </Card>
  );
}
