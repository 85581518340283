import { Chip, chipClasses, styled } from '@mui/material';
import { IColors } from 'shared/colors';
import { renderDifferentColors } from 'utils/renderDifferentColors';

interface IChipProps {
  colors: IColors;
}

const StyledChip = styled(Chip)<IChipProps>(({ colors }) => ({
  [`&.${chipClasses.root}`]: {
    background: renderDifferentColors(colors.colors).backgroundColor,
    color: renderDifferentColors(colors.colors).textColor,
    border: '1px solid',
    borderColor: renderDifferentColors(colors.colors).textColor,
    fontSize: '12px'
  }
}));

export { StyledChip };
