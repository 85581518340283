export type symptomsKey =
  | 'currentWeight'
  | 'heightOver1m'
  | 'weight1Month'
  | 'weight6Month'
  | 'weightLastWeeks'
  | 'lastMonthFood'
  | 'currentFood'
  | 'normalFeed'
  | 'nausea'
  | 'constipation'
  | 'mucositis'
  | 'tasteless'
  | 'swallowProblem'
  | 'tiredness'
  | 'appetiteLack'
  | 'vomit'
  | 'diarrhea'
  | 'dryMouth'
  | 'quicklySatified'
  | 'smellsBother'
  | 'others'
  | 'pain'
  | 'activityLastMonth';

export interface IFormData {
  currentWeight: number;
  heightOver1m: number;
  weight1Month: number;
  weight6Month: number;
  weightLastWeeks: string;
  lastMonthFood: string;
  currentFood: string;
  normalFeed: boolean;
  nausea: boolean;
  constipation: boolean;
  mucositis: boolean;
  tasteless: boolean;
  swallowProblem: boolean;
  tiredness: boolean;
  appetiteLack: boolean;
  vomit: boolean;
  diarrhea: boolean;
  dryMouth: boolean;
  quicklySatified: boolean;
  smellsBother: boolean;
  others: string;
  pain: string;
  activityLastMonth: string;
}

export interface SymptomsType {
  name: symptomsKey;
  title: string;
  value: number;
}

export function setWeightPointsFunction(weightLoss: string) {
  switch (weightLoss) {
    case 'lessWeight':
      return 1;
    case 'sameWeight':
      return 0;
    case 'heavier':
      return 0;
  }
  return 0;
}

export function setFoodIngestionQuantityPointsFunction(
  foodIngestionLastMonth: string
) {
  switch (foodIngestionLastMonth) {
    case 'same':
      return 0;
    case 'more':
      return 0;
    case 'less':
      return 1;
  }
  return 0;
}

export function setFoodIngestionTypePointsFunction(foodIngestionNow: string) {
  switch (foodIngestionNow) {
    case 'lessSolidFood':
      return 1;
    case 'muchLessSolidFood':
      return 2;
    case 'liquidFood':
      return 3;
    case 'suplements':
      return 3;
    case 'littleFood':
      return 4;
    case 'tube':
      return 0;
  }
  return 0;
}

export function setActivityLastMonthPointsFunction(activityLastMonth: string) {
  switch (activityLastMonth) {
    case 'normal':
      return 0;
    case 'almostNormal':
      return 1;
    case 'tired':
      return 2;
    case 'mostTimeInBed':
      return 3;
    case 'laidUp':
      return 3;
  }
  return 0;
}

export const firstColumnSymptoms: SymptomsType[] = [
  {
    name: 'nausea',
    title: 'náuseas (enjoos) (1)',
    value: 1
  },
  {
    name: 'constipation',
    title: 'obstipação (intestino preso) (1)',
    value: 1
  },
  {
    name: 'mucositis',
    title: 'feridas na boca (2)',
    value: 2
  },
  {
    name: 'tasteless',
    title: 'os alimentos têm gosto estranho ou não têm gosto (1)',
    value: 1
  },
  {
    name: 'swallowProblem',
    title: 'problemas para engolir (2)',
    value: 2
  },
  {
    name: 'tiredness',
    title: 'cansaço (fadiga) (1)',
    value: 1
  }
];

export const secondColumnSymptoms: SymptomsType[] = [
  {
    name: 'appetiteLack',
    title: 'sem apetite, apenas sem vontade de comer (3)',
    value: 3
  },
  {
    name: 'vomit',
    title: 'vômitos (3)',
    value: 3
  },
  {
    name: 'diarrhea',
    title: 'diarréia (3)',
    value: 3
  },
  {
    name: 'dryMouth',
    title: 'boca seca (1)',
    value: 1
  },
  {
    name: 'quicklySatified',
    title: 'me sinto rapidamente satisfeito (1)',
    value: 1
  },
  {
    name: 'smellsBother',
    title: 'os cheiros me incomodam (1)',
    value: 1
  }
];

export const asgDefaultValues = {
  weightLastWeeks: 'lessWeight',
  lastMonthFood: 'same',
  currentFood: 'lessSolidFood',
  activityLastMonth: 'normal',
  normalFeed: false,
  nausea: false,
  constipation: false,
  mucositis: false,
  tasteless: false,
  swallowProblem: false,
  tiredness: false,
  appetiteLack: false,
  vomit: false,
  diarrhea: false,
  dryMouth: false,
  quicklySatified: false,
  smellsBother: false,
  others: ''
};
