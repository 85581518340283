import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import {
  InputWithNumberMask,
  ChipComponent,
  TooltipComponent
} from 'components';
import { CutoffTable } from 'components/CutoffTable/cutoffTable';
import { limitDecimal } from 'utils/functions';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import {
  CREATE_TC,
  UPDATE_TC,
  TcMutationsVars
} from 'services/graphql/mutations/bodyCompositionEvaluation';
import { Tc } from 'models/tc';

const validationSchema = yup.object().shape({
  skeletalMuscleMass: yup
    .number()
    .required('Insira um valor de massa muscular esquelética')
    .max(150, 'O valor máximo é 150'),
  adiposeTissue: yup
    .number()
    .required('Insira um valor de tecido adiposo')
    .max(150, 'O valor máximo é 150')
});

interface IFormType {
  skeletalMuscleMass: number;
  adiposeTissue: number;
}

export function calculateTCMuscleMass(
  skeletalMuscleMass: number,
  height: number
) {
  return limitDecimal(skeletalMuscleMass / height);
}

const TCForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { height: number; loadedData: Tc | undefined; lastTc: Tc | undefined }
> = ({ height, loadedData, lastTc }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormType>({
    resolver: yupResolver(validationSchema)
  });

  const [skeletalMuscleMassIndex, setSkeletalMuscleMassIndex] = useState(0);
  const watchSkeletalMuscleMass = watch('skeletalMuscleMass');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createTCMutation] = useMutation<null, TcMutationsVars>(CREATE_TC);
  const [updateTCMutation] = useMutation<null, TcMutationsVars>(UPDATE_TC);

  function calculateSkeletalMuscleMassIndex(
    skeletalMuscleMass: number,
    height: number
  ) {
    if (!height) {
      toast.warning(
        'É necessário preencher a altura do paciente na etapa 6 para fazer o calculo'
      );
      return;
    }
    return calculateTCMuscleMass(skeletalMuscleMass, height);
  }

  useEffect(() => {
    const value = calculateSkeletalMuscleMassIndex(
      watchSkeletalMuscleMass,
      height
    );
    if (!value) return;
    setSkeletalMuscleMassIndex(value);
  }, [watchSkeletalMuscleMass]);

  function setLoadedValuesToForm(data: Tc | undefined) {
    if (!data) return;
    setValue('adiposeTissue', data?.adiposeTissue);
    setValue('skeletalMuscleMass', data?.skeletalMuscleMass);
  }

  useEffect(() => {
    setLoadedValuesToForm(loadedData);
  }, [loadedData]);

  useEffect(() => {
    setLoadedValuesToForm(lastTc);
  }, [lastTc]);

  async function createTC(data: IFormType) {
    if (loadedData) return;
    await createTCMutation({
      variables: {
        tc: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.success('Dados de tomografia computadorizada adicionados.');
      }
    });
  }

  async function updateTC(data: IFormType) {
    if (!loadedData) return;
    await updateTCMutation({
      variables: {
        tc: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.info('Dados de tomografia computadorizada atualizados.');
      }
    });
  }

  function onSubmit(data: IFormType) {
    try {
      createTC(data);
      updateTC(data);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="75vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Tomografia Computadorizada (TC)
              </Typography>
              <TooltipComponent content="Tomografia Computadorizada (TC)" />
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box
              margin="30px 0"
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Massa muscular esquelética em <i>kg</i> – L3:
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="skeletalMuscleMass"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Tecido adiposo em <i>kg</i> – L3:
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="adiposeTissue"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
            </Box>
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`Índice de massa muscular esquelética (IME): ${
                skeletalMuscleMassIndex ? skeletalMuscleMassIndex : '0'
              }cm²/m²`}
            />
          </Box>
          <Box margin="25px 0">
            <CutoffTable
              title="Tomografia computadorizada (TC)"
              rows={[
                {
                  firstCell: 'IME = 52,4 cm³/m²',
                  secondCell: 'IME = 38,5 cm³/m²'
                },
                {
                  firstCell: 'IME = 43,0 cm³/m² (IMC < 25kg/m²)',
                  secondCell: 'IME = 41,0 cm³/m²'
                },
                {
                  firstCell: 'IME = 53,0 cm³/m² (IMC > 25kg/m²)',
                  secondCell: ''
                },
                {
                  firstCell: 'IME = 52,3 cm³/m² (IMC < 30kg/m²)',
                  secondCell: 'IME = 38,6 cm³/m² (IMC < 30kg/m²)'
                },
                {
                  firstCell: 'IME = 54,3 cm³/m² (IMC > 30g/m²)',
                  secondCell: 'IME = 46,6 cm³/m² (IMC > 30kg/m²)'
                },
                {
                  firstCell: 'IME = 45,4 cm³/m²',
                  secondCell: 'IME = 34,4 cm³/m²'
                },
                {
                  firstCell: 'IME = 41,6 cm³/m²',
                  secondCell: 'IME = 32,0 cm³/m²'
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(TCForm);
