import { gql } from '@apollo/client';

const UPDATE_BIOPARAMS = gql`
  mutation updateBioparams($bioparamsId: Float!, $bioparams: BioparamsInput!) {
    updateBioparams(bioparamsId: $bioparamsId, bioparams: $bioparams) {
      id
    }
  }
`;

export { UPDATE_BIOPARAMS };
