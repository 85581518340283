import { gql } from '@apollo/client';

export const SET_APPOINTMENT_DIAGNOSIS = gql`
  mutation setDiagnosis(
    $diagnosis: String!
    $appointmenId: Float!
    $therapeuticPlan: String!
  ) {
    setAppointmentDiagnosis(
      diagnosis: $diagnosis
      appointmenId: $appointmenId
      therapeuticPlan: $therapeuticPlan
    ) {
      id
    }
  }
`;
