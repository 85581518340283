import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import {
  StyledFirstTableCell,
  StyledSecondTableCell,
  StyledTable
} from './style';
import { IColors } from 'shared/colors';
import { TableRow } from '@mui/material';

interface TableCellContent {
  firstCellContent: string;
  secondCellContent: string;
  thirdCellContent?: string;
  color: IColors;
  selected: boolean;
}

interface ClassificationTableProps {
  rows: TableCellContent[];
  size?: 'small' | 'medium';
}

function ClassificationTable({ rows, size }: ClassificationTableProps) {
  return (
    <TableContainer>
      <StyledTable size={size ? size : 'medium'}>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.firstCellContent}>
              <StyledFirstTableCell
                hasthirdcell={row.thirdCellContent ? true : false}
                selected={row.selected}
                colors={row.color}
              >
                {row.firstCellContent}
              </StyledFirstTableCell>
              <StyledSecondTableCell
                hasthirdcell={row.thirdCellContent ? true : false}
                selected={row.selected}
                colors={row.color}
              >
                {row.secondCellContent}
              </StyledSecondTableCell>
              {row.thirdCellContent ? (
                <StyledSecondTableCell
                  hasthirdcell={row.thirdCellContent ? true : false}
                  selected={row.selected}
                  colors={row.color}
                >
                  {row.thirdCellContent}
                </StyledSecondTableCell>
              ) : (
                ''
              )}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

export { ClassificationTable };
