import { gql } from '@apollo/client';

const INSITUTIONAL_DASHBOARD = gql`
  query institutionalDashBoard($clinicId: Float) {
    institutionalDashBoard(clinicId: $clinicId) {
      gender {
        male
        female
      }
      cids {
        name
        quantity
      }
      treatments {
        name
        quantity
      }
      diagnosis {
        name
        quantity
      }
      triage {
        asgPpp {
          name
          quantity
        }
        man {
          name
          quantity
        }
        mst {
          name
          quantity
        }
        must {
          name
          quantity
        }
        nrs {
          name
          quantity
        }
      }
      tno {
        name
        quantity
      }
      tne {
        name
        quantity
      }
      cidNutriDiag {
        key
        statistics {
          name
          quantity
        }
      }
      nutriStateTreatment {
        key
        statistics {
          name
          quantity
        }
      }
      nutriSupplementation {
        name
        quantity
      }
      diagnosisTno {
        key
        statistics {
          name
          quantity
        }
      }
      diagnosisTne {
        key
        statistics {
          name
          quantity
        }
      }
      symptomsTno {
        key
        statistics {
          name
          quantity
        }
      }
      symptomsTne {
        key
        statistics {
          name
          quantity
        }
      }
    }
  }
`;

export { INSITUTIONAL_DASHBOARD };
