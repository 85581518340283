import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Input } from 'components/Input';
import { theme } from 'styles/theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import logo from 'assets/newLogo.png';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from 'services/graphql/mutations/user/forgotPassword';
import { ButtonComponent } from 'components/Button';
import CloseIcon from '@mui/icons-material/Close';
import doneImage from 'assets/done.png';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Insira um e-mail')
    .email('Insira um e-mail válido')
});

const defaultValues = {
  email: ''
};

interface IFormType {
  email: string;
}

function ForgotPassword() {
  const history = useHistory();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  function handleCloseModal() {
    history.push('/');
  }

  function onSubmit({ email }: IFormType) {
    try {
      forgotPassword({
        variables: {
          email
        },
        onCompleted: () => {
          setIsModalOpen(true);
        }
      });
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <>
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.16)"
          width="32%"
          minHeight="75vh"
          borderRadius="4px"
          bgcolor="background.paper"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="100%" padding="10px 0px" paddingBottom="20px">
            <Box minHeight="fit-content" display="flex" justifyContent="center">
              <img height="100px" src={logo} alt="Nestlé Health Science" />
            </Box>
            <Box
              margin="15px"
              marginTop="5%"
              minHeight="13vh"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                fontSize="18px"
                fontWeight="700"
                textAlign="center"
                color={theme.palette.grey[900]}
              >
                Esqueci minha senha
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight="500"
                color={theme.palette.grey[300]}
              >
                Insira seu e-mail de cadastro abaixo para redefinir a sua senha.
              </Typography>
            </Box>
          </Box>
          <Box
            marginBottom="25px"
            width="92%"
            minHeight="30vh"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="email"
              label="Email"
              placeholder="Digite aqui"
            />
            <Box width="100%">
              <Button
                sx={{
                  width: '100%',
                  textTransform: 'none',
                  margin: '10px 0',
                  alignSelf: 'flex-end'
                }}
                color="info"
                variant="contained"
                type="submit"
              >
                Redefinir senha
              </Button>
              <Button
                sx={{
                  width: '100%',
                  textTransform: 'none',
                  margin: '10px 0',
                  alignSelf: 'flex-end'
                }}
                color="info"
                variant="outlined"
                onClick={() => history.push('/')}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          position="absolute"
          top="27%"
          left="37%"
          width="30vw"
          height="50vh"
          borderRadius="8px"
          padding="5px 2%"
          display="flex"
          flexDirection="column"
          textAlign="center"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{
            background: theme.palette.background.paper
          }}
        >
          <Box position="absolute" left="90%" bottom="85%">
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <img src={doneImage} alt="Concluido" />
          <Typography color="grey.900" fontWeight="500" fontSize="24px">
            E-mail de redefinição de senha enviado!
          </Typography>
          <Typography color="grey.300" fontSize="16px">
            Acesse seu e-mail para criar uma nova senha
          </Typography>
          <ButtonComponent
            width="130px"
            title="Ok, entendi"
            isTransparent={false}
            onClick={handleCloseModal}
          />
        </Box>
      </Modal>
    </>
  );
}

export { ForgotPassword };
