import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { BarDatum, ResponsiveBar } from '@nivo/bar';

import { shuffleColors } from 'styles/graphColors';

interface Props {
  title: string;
  data: BarDatum[];
  keys: string[];
  select?: string[];
  selected?: number;
  setSelected?: (event: SelectChangeEvent) => void;
}

interface DataSelectorProps {
  data: string[];
  selected: number;
  setSelected: (event: SelectChangeEvent) => void;
}

const DataSelector = ({ data, selected, setSelected }: DataSelectorProps) => {
  return (
    <Select
      value={selected.toString()}
      onChange={setSelected}
      variant="standard"
      label=""
    >
      {data.map((d, i) => (
        <MenuItem value={i} key={i}>
          {d}
        </MenuItem>
      ))}
    </Select>
  );
};

export const MultiGroupedBar = ({
  title,
  data,
  keys,
  select,
  selected,
  setSelected
}: Props) => {
  const colors = shuffleColors();
  // const keys = data[0]?.bars?.map((bar) => bar.key);

  return (
    <Card>
      <CardHeader
        color="#565656"
        title={title}
        sx={{ color: '#565656' }}
        action={
          select &&
          setSelected &&
          select.length > 1 && (
            <DataSelector
              data={select}
              setSelected={setSelected}
              selected={selected || 0}
            />
          )
        }
      />
      <Divider />
      <CardContent sx={{ width: '100%', height: '270px', paddingTop: '0px' }}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy="id"
          colors={colors}
          margin={{ top: 50, right: 10, bottom: 30, left: 50 }}
          padding={0.3}
          groupMode="grouped"
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]]
          }}
          axisBottom={{
            tickRotation: -8
          }}
          axisLeft={{
            tickValues: Array.from(Array(100).keys())
          }}
          gridYValues={Array.from(Array(100).keys())}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateY: -30,
              itemsSpacing: 2,
              itemWidth: 125,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              symbolSize: 20
            }
          ]}
          role="application"
        />
      </CardContent>
    </Card>
  );
};
