import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import {
  DetailedRadioButton,
  Input,
  Subtitle,
  RadioButtonInput
} from 'components';
import { format, parseISO, setHours, setMinutes } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import differenceInYears from 'date-fns/differenceInYears';
import { yupResolver } from '@hookform/resolvers/yup';
import { regexOnlyText, regexEmptyString } from 'utils';
import { theme } from 'styles/theme';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_PATIENT,
  CreatePatientReturn,
  CreatePatientVars,
  UPDATE_PATIENT,
  UpdatePatientReturn,
  UpdatePatientVars
} from 'services/graphql/mutations/patients';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  CREATE_APPOINTMENT,
  CreateAppointmentVars,
  CreateAppointmentReturn
} from 'services/graphql/mutations/appointments';
import {
  FIND_PATIENT_BY_ID,
  FIND_PATIENTS_BY_NAME,
  FindPatientByIdVars,
  FindPatientByNameReturn,
  FindPatientByNameVars,
  FindPatientReturn
} from 'services/graphql/queries/patient';
import { Patient } from 'models/patient';
import { toast } from 'react-toastify';
import { IFormInput, defaultValues } from './constsAndInterfaces';
import { validationSchema } from './schema';

const AppointmentAndPatientDataForm: React.ForwardRefRenderFunction<HTMLButtonElement> =
  (props, ref) => {
    const history = useHistory();
    const { handleSubmit, control, setValue, watch, getValues } =
      useForm<IFormInput>({
        defaultValues,
        resolver: yupResolver(validationSchema)
      });
    const patientID = localStorage.getItem('patientId');

    const [appointmentType, setAppointmentType] = useState('firstAppointment');
    const [searchPatientValue, setSearchPatientValue] = useState('');
    const [searchPacientError, setSearchPacientError] = useState('');
    const [patientExists, setPatientExists] = useState<Patient>();
    const [patientToUpdate, setPatientToUpdate] = useState<Patient>();

    const { data: patientsFoundByName, loading } = useQuery<
      FindPatientByNameReturn,
      FindPatientByNameVars
    >(FIND_PATIENTS_BY_NAME, {
      fetchPolicy: 'no-cache',
      variables: {
        name: searchPatientValue
      }
    });

    const { data: findPatientById, loading: loadingFindPatient } = useQuery<
      FindPatientReturn,
      FindPatientByIdVars
    >(FIND_PATIENT_BY_ID, {
      variables: { id: Number(patientID) }
    });

    const [patientMutation] = useMutation<
      CreatePatientReturn,
      CreatePatientVars
    >(CREATE_PATIENT);

    const [appointmentMutation] = useMutation<
      CreateAppointmentReturn,
      CreateAppointmentVars
    >(CREATE_APPOINTMENT);

    const [updatePatientMutation] = useMutation<
      UpdatePatientReturn,
      UpdatePatientVars
    >(UPDATE_PATIENT);

    const watchBirthDate = watch('birthdate', defaultValues.birthdate);
    const watchGender = watch('gender', defaultValues.gender);
    const watchModality = watch('modality', defaultValues.modality);

    function convertStringToDate(dateToConvert: string) {
      const newConvertedDate = parseISO(dateToConvert);
      return newConvertedDate;
    }

    function getAgeByBirthdate(date: Date) {
      const age = differenceInYears(new Date(), date);
      return age;
    }

    useEffect(() => {
      const birthdateParsed = convertStringToDate(watchBirthDate);
      const age = getAgeByBirthdate(birthdateParsed);
      setValue('age', age);
    }, [watchBirthDate]);

    useMemo(() => {
      if (findPatientById) {
        const currentPatient = findPatientById.findPatient;
        setPatientToUpdate(currentPatient);
        setPatientValuesAfterSearchPatient(currentPatient);
        setAppointmentValuesForUpdatePatient(currentPatient);
        if (getValues('appointmentOrder') > 1) {
          setAppointmentType('returnAppointment');
        }
      }
    }, [findPatientById, loadingFindPatient]);

    function verifyIfSearchInputIsValid(value: string) {
      if (value.match(regexOnlyText) || value.match(regexEmptyString)) {
        setSearchPacientError('');
      } else {
        setSearchPacientError('Caractere não permitido');
      }
    }

    const onChangeSearchPatientInput = async (value: string) => {
      verifyIfSearchInputIsValid(value);
      setSearchPatientValue(value);
    };

    const debouncedSearchPatientFunction = AwesomeDebouncePromise(
      onChangeSearchPatientInput,
      1000
    );

    function setExistentPacient(existentPatient: Patient) {
      setPatientExists(existentPatient);
    }

    function getLastItemOfInput(array: any[]) {
      const lastItem = array[array.length - 1];
      return lastItem || 0;
    }

    function setPatientValuesAfterSearchPatient(searchedPatient: any) {
      if (searchedPatient) {
        setExistentPacient(searchedPatient);
        setValue('name', searchedPatient.name);
        setValue(
          'birthdate',
          format(new Date(searchedPatient.birthdate), 'yyyy-MM-dd')
        );
        setValue('gender', searchedPatient.gender);
        setValue('modality', searchedPatient.modality);
        setValue('medicalRecord', searchedPatient.medicalRecord);
        setValue('observations', searchedPatient.observations);
        setAppointmentValuesAfterSearchPatient(searchedPatient);
      }
    }

    async function setAppointmentValuesAfterSearchPatient(
      searchedPatient: any
    ) {
      if (searchedPatient) {
        const lastAppointment = getLastItemOfInput(
          searchedPatient.appointments
        );
        if (lastAppointment) {
          setValue('appointmentOrder', lastAppointment.appointmentOrder + 1);
          return;
        }
        setValue('appointmentOrder', 1);
      }
    }

    async function setAppointmentValuesForUpdatePatient(
      updatePatient: Patient
    ) {
      if (updatePatient) {
        const lastAppointment = getLastItemOfInput(updatePatient.appointments);
        if (lastAppointment) {
          setValue('appointmentOrder', lastAppointment.appointmentOrder + 1);
          return;
        }
        setValue('appointmentOrder', 1);
        setValue('appointmentOrder', lastAppointment.appointmentOrder);
      }
    }

    function setMinutesAndHoursToAppointmentDate(date: string, hours: string) {
      const parsedAppointmentDate = new Date(date);
      const appointmentHoursAndMinutes = hours;
      const [appointmentHours, appointmentMinutes] =
        appointmentHoursAndMinutes.split(':', 2);

      const parsedAppointmentDateHours = setHours(
        parsedAppointmentDate,
        Number(appointmentHours)
      );
      const parsedAppointmentDateMinutes = setMinutes(
        parsedAppointmentDateHours,
        Number(appointmentMinutes)
      );
      return parsedAppointmentDateMinutes;
    }

    function createAppointmentAndPatient(formData: IFormInput) {
      if (!patientExists && !patientToUpdate) {
        const appointmentDate = setMinutesAndHoursToAppointmentDate(
          formData.appointmentDate,
          formData.appointmentHour
        );
        patientMutation({
          variables: {
            patient: {
              name: formData.name,
              birthdate: formData.birthdate,
              modality: formData.modality,
              gender: formData.gender,
              medicalRecord: formData.medicalRecord,
              observations: formData.observations
            }
          },
          onCompleted: (data) => {
            appointmentMutation({
              variables: {
                appointment: {
                  datetime: appointmentDate,
                  patientId: data.createPatient.id,
                  appointmentOrder: formData.appointmentOrder,
                  clinicId: 0,
                  userId: 0
                }
              },
              onCompleted: (data) => {
                localStorage.setItem(
                  'appointmentId',
                  JSON.stringify(data.createAppointment.id)
                );
                toast.success('Consulta criada com sucesso.');
                history.push('/home/disease-data');
              }
            });
            localStorage.setItem('patientId', String(data.createPatient.id));
          }
        });
      }
    }

    function createReturnAppointment(data: IFormInput) {
      if (patientExists && !patientToUpdate) {
        const appointmentDate = setMinutesAndHoursToAppointmentDate(
          data.appointmentDate,
          data.appointmentHour
        );
        appointmentMutation({
          variables: {
            appointment: {
              datetime: appointmentDate,
              patientId: patientExists.id,
              appointmentOrder: data.appointmentOrder,
              clinicId: 0,
              userId: 0
            }
          },
          onCompleted: (data) => {
            localStorage.setItem('patientId', JSON.stringify(patientExists.id));
            localStorage.setItem(
              'appointmentId',
              JSON.stringify(data.createAppointment.id)
            );
            toast.success('Consulta de retorno criada com sucesso.');
            history.push('/home/disease-data');
          }
        });
      }
    }

    function updateExistentPatientData(formData: IFormInput) {
      if (patientToUpdate) {
        updatePatientMutation({
          variables: {
            id: patientToUpdate.id,
            patient: {
              name: formData.name,
              birthdate: formData.birthdate,
              modality: formData.modality,
              medicalRecord: formData.medicalRecord,
              gender: formData.gender,
              observations: formData.observations
            }
          },
          onCompleted: () => {
            toast.info('Dados do paciente atualizados com sucesso.');
            history.push('/home/disease-data');
          }
        });
      }
    }

    const onSubmit: SubmitHandler<IFormInput> = (formData): void => {
      try {
        createReturnAppointment(formData);
        createAppointmentAndPatient(formData);
        updateExistentPatientData(formData);
      } catch {
        toast.error('Houve um erro.');
      }
    };

    return (
      <Box width="100%">
        <form
          onKeyDown={(e) => {
            e.key === 'Enter' ? e.preventDefault() : null;
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            height="75vh"
          >
            <Box display="flex" width="100%" justifyContent="space-between">
              <Input
                type="number"
                name="appointmentOrder"
                sx={{ width: '30' }}
                control={control}
                label="Número da consulta:"
                readOnly
              />
              <Input
                name="appointmentDate"
                control={control}
                sx={{ width: '30%' }}
                label="Data da consulta:"
                type="date"
                value={defaultValues.appointmentDate}
              />
              <Input
                name="appointmentHour"
                control={control}
                sx={{ width: '30%' }}
                label="Hora da consulta:"
                type="time"
              />
            </Box>
            <Box>
              <Typography fontSize="14px" color="grey.900">
                Tipo de consulta:
              </Typography>
              <DetailedRadioButton
                buttons={[
                  {
                    checked: appointmentType === 'firstAppointment',
                    title: 'Primeira consulta',
                    action: () => setAppointmentType('firstAppointment'),
                    value: 'firstAppointment',
                    description:
                      'Será necessário informar todos os  dados do paciente'
                  },
                  {
                    checked: appointmentType === 'returnAppointment',
                    title: 'Retorno/Acompanhamento',
                    action: () => setAppointmentType('returnAppointment'),
                    value: 'returnAppointment',
                    description:
                      'Escolha um paciente já pré-cadastrado no sistema'
                  }
                ]}
              />
            </Box>
            <Divider />
            <Subtitle title="Informações do paciente" />
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Autocomplete
                freeSolo={true}
                fullWidth
                disabled={appointmentType === 'firstAppointment'}
                loading={loading}
                options={patientsFoundByName?.findPatientsByName || []}
                getOptionLabel={(option: { name: string }) => option.name}
                onChange={(event, data) => {
                  setPatientValuesAfterSearchPatient(data);
                }}
                renderInput={(params) => (
                  <TextField
                    value={searchPatientValue}
                    label="Pesquisar"
                    error={!!searchPacientError}
                    helperText={searchPacientError}
                    placeholder="Insira o texto aqui..."
                    onChange={(e) =>
                      debouncedSearchPatientFunction(e.target.value)
                    }
                    {...params}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
              <SearchOutlined
                sx={{
                  position: 'absolute',
                  color: theme.palette.grey[200]
                }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Input
                name="medicalRecord"
                control={control}
                type="number"
                label="Código do prontuário"
                disabled={appointmentType === 'returnAppointment'}
                placeholder="Insira o código aqui..."
              />
              <Input
                name="name"
                control={control}
                sx={{
                  width: '38%'
                }}
                disabled={appointmentType === 'returnAppointment'}
                label="Nome completo"
                placeholder="Insira o texto aqui..."
              />
              <Box
                sx={{
                  width: '27%'
                }}
              >
                <RadioButtonInput
                  title="Sexo:"
                  disabled={appointmentType === 'returnAppointment'}
                  sx={{
                    height: '25px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '1fr 1fr 1fr'
                  }}
                  radioButtons={[
                    {
                      label: 'Masculino',
                      value: 'MALE',
                      checked: watchGender === 'MALE'
                    },
                    {
                      label: 'Feminino',
                      value: 'FEMALE',
                      checked: watchGender === 'FEMALE'
                    }
                  ]}
                  name="gender"
                  control={control}
                />
              </Box>
            </Box>
            <Box display="grid" gridTemplateColumns="1.5fr 1.5fr 2fr">
              <Input
                sx={{ width: '85%' }}
                name="birthdate"
                control={control}
                disabled={appointmentType === 'returnAppointment'}
                type="date"
                label="Data de nascimento:"
                placeholder="Insira o texto aqui..."
              />
              <Input
                name="age"
                readOnly
                control={control}
                disabled={appointmentType === 'returnAppointment'}
                type="number"
                label="Idade:"
                placeholder="Insira o texto aqui..."
                sx={{ width: '95%' }}
              />
              <Box>
                <RadioButtonInput
                  title="Modalidade:"
                  disabled={appointmentType === 'returnAppointment'}
                  radioButtons={[
                    {
                      label: 'Particular',
                      value: 'PRIVATE',
                      checked: watchModality === 'PRIVATE'
                    },
                    {
                      label: 'Convênio',
                      value: 'INSURANCE',
                      checked: watchModality === 'INSURANCE'
                    },
                    {
                      label: 'SUS',
                      value: 'SUS',
                      checked: watchModality === 'SUS'
                    }
                  ]}
                  name="modality"
                  control={control}
                />
              </Box>
            </Box>
            <Input
              name="observations"
              control={control}
              disabled={appointmentType === 'returnAppointment'}
              label="Observação"
              placeholder="Insira suas observações..."
            />
          </Box>
          <Button sx={{ display: 'none' }} ref={ref} type="submit">
            Submit
          </Button>
        </form>
      </Box>
    );
  };

export default forwardRef(AppointmentAndPatientDataForm);
