import React from 'react';
import { Typography } from '@mui/material';

interface SubheaderProps {
  title: string;
}

function Subtitle({ title }: SubheaderProps) {
  return (
    <Typography fontSize="18px" fontWeight="500" color="grey.300">
      {title}
    </Typography>
  );
}

export { Subtitle };
