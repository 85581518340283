import { gql } from '@apollo/client';

const CREATE_BIOPARAMS = gql`
  mutation createBioparams($bioparams: BioparamsInput!) {
    createBioparams(bioparams: $bioparams) {
      id
    }
  }
`;

export { CREATE_BIOPARAMS };
