import React from 'react';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

import { shuffleColors } from 'styles/graphColors';

// interface Bar {
//   key: string;
//   value: number;
// }

// interface Group {
//   name: string;
//   bars: Bar[];
// }

// interface Props {
//   title: string;
//   data: Group[];
// }

export const GroupedBar = ({ title, data }: any) => {
  const colors = shuffleColors();
  // const keys = data[0]?.bars?.map((bar) => bar.key);

  return (
    <Card>
      <CardHeader color="#565656" title={title} sx={{ color: '#565656' }} />
      <Divider />
      <CardContent sx={{ width: '100%', height: '270px', paddingTop: '0px' }}>
        <ResponsiveBar
          data={data}
          keys={['CB', 'DTC', 'CP']}
          indexBy="name"
          colors={colors}
          margin={{ top: 50, right: 10, bottom: 30, left: 50 }}
          padding={0.3}
          groupMode="grouped"
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]]
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateY: -30,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              symbolSize: 20
            }
          ]}
          role="application"
        />
      </CardContent>
    </Card>
  );
};
