import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ChipComponent,
  ClassificationTable,
  RadioButtonInput
} from 'components';
import { Man } from 'models/man';
import { NeMan } from 'models/neMan';
import {
  CREATE_NE_MAN,
  UPDATE_NE_MAN,
  NeManMutationsVars
} from 'services/graphql/mutations/nutritional-evaluation/man';
import { theme } from 'styles/theme';

interface MANForm {
  ingestDecrease: string;
  weightLoss: string;
  mobility: string;
  stress: string;
  neuroPsychological: string;
  bodyMass: string;
  ownHome: string;
  threeMedicines: string;
  eschar: string;
  mealsByDay: string;
  milkDerivatives: string;
  vegetablesEggs: string;
  meatFishBird: string;
  fruits: string;
  liquids: string;
  wayEating: string;
  nutritionalProblem: string;
  ownHealth: string;
  armCircumference: string;
  lagCircumference: string;
}

const defaultValues: MANForm = {
  ingestDecrease: '0',
  weightLoss: '0',
  mobility: '0',
  stress: '0',
  neuroPsychological: '0',
  bodyMass: '0',
  ownHome: '0',
  threeMedicines: '0',
  eschar: '0',
  mealsByDay: '0',
  milkDerivatives: 'yes',
  vegetablesEggs: 'yes',
  meatFishBird: 'yes',
  fruits: '0',
  liquids: '0',
  wayEating: '0',
  nutritionalProblem: '0',
  ownHealth: '0',
  armCircumference: '0',
  lagCircumference: '0'
};

const MAN: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    loadedData: NeMan | undefined;
    loadedNrMan: Man | undefined;
    lastMan: NeMan | undefined;
  }
> = ({ loadedData, loadedNrMan, lastMan }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues
  });
  const history = useHistory();

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createManMutation] = useMutation<null, NeManMutationsVars>(
    CREATE_NE_MAN
  );
  const [updateManMutation] = useMutation<null, NeManMutationsVars>(
    UPDATE_NE_MAN
  );

  const [result, setResult] = useState(0);
  const watchIngestDecrease = watch('ingestDecrease');
  const watchWeightLoss = watch('weightLoss');
  const watchMobility = watch('mobility');
  const watchStress = watch('stress');
  const watchNeuroPsychological = watch('neuroPsychological');
  const watchBodyMass = watch('bodyMass');
  const watchOwnHome = watch('ownHome');
  const watchThreeMedicines = watch('threeMedicines');
  const watchEschar = watch('eschar');
  const watchMealsByDay = watch('mealsByDay');
  const watchMilkDerivatives = watch('milkDerivatives');
  const watchVegetablesEggs = watch('vegetablesEggs');
  const watchMeatFishBird = watch('meatFishBird');
  const watchFruits = watch('fruits');
  const watchLiquids = watch('liquids');
  const watchWayEating = watch('wayEating');
  const watchNutritionalProblem = watch('nutritionalProblem');
  const watchOwnHealth = watch('ownHealth');
  const watchArmCircumference = watch('armCircumference');
  const watchLagCircumference = watch('lagCircumference');

  useEffect(() => {
    setResult(
      Number(watchIngestDecrease) +
        Number(watchWeightLoss) +
        Number(watchMobility) +
        Number(watchStress) +
        Number(watchNeuroPsychological) +
        Number(watchBodyMass) +
        Number(watchOwnHome) +
        Number(watchThreeMedicines) +
        Number(watchEschar) +
        Number(watchMealsByDay) +
        verifyFoodPoints(watchMilkDerivatives) +
        verifyFoodPoints(watchVegetablesEggs) +
        verifyFoodPoints(watchMeatFishBird) +
        Number(watchFruits) +
        Number(watchLiquids) +
        Number(watchWayEating) +
        Number(watchNutritionalProblem) +
        Number(watchOwnHealth) +
        Number(watchArmCircumference) +
        Number(watchLagCircumference)
    );
  }, [
    watchIngestDecrease,
    watchWeightLoss,
    watchMobility,
    watchStress,
    watchNeuroPsychological,
    watchBodyMass,
    watchOwnHome,
    watchThreeMedicines,
    watchEschar,
    watchMealsByDay,
    watchMilkDerivatives,
    watchVegetablesEggs,
    watchMeatFishBird,
    watchFruits,
    watchLiquids,
    watchWayEating,
    watchNutritionalProblem,
    watchOwnHealth,
    watchArmCircumference,
    watchLagCircumference
  ]);

  const MANFirstQuestions = [
    {
      name: 'ingestDecrease',
      title: `A- Nos últimos 3 meses, houve diminuição
              da ingesta alimentar devido a perda de apetite,
              problemas digestivos ou dificuldade para mastigar
              ou deglutir?:`,
      radioButtons: [
        {
          checked: watchIngestDecrease === '0',
          label: 'Diminuição grave da ingesta',
          value: '0'
        },
        {
          checked: watchIngestDecrease === '1',
          label: 'Diminuição moderada da ingesta',
          value: '1'
        },
        {
          checked: watchIngestDecrease === '2',
          label: 'Sem diminuição da ingesta',
          value: '2'
        }
      ]
    },
    {
      name: 'weightLoss',
      title: `B- Perda de peso nos últimos 3 meses:`,
      radioButtons: [
        {
          checked: watchWeightLoss === '0',
          label: 'Superior a três quilos',
          value: '0'
        },
        {
          checked: watchWeightLoss === '1',
          label: 'Não sabe informar',
          value: '1'
        },
        {
          checked: watchWeightLoss === '2',
          label: 'Entre um e três quilos',
          value: '2'
        },
        {
          checked: watchWeightLoss === '3',
          label: 'Sem perda de peso',
          value: '3'
        }
      ]
    },
    {
      name: 'mobility',
      title: `C- Mobilidade:`,
      radioButtons: [
        {
          checked: watchMobility === '0',
          label: 'Restrito ao leito ou à cadeira de rodas',
          value: '0'
        },
        {
          checked: watchMobility === '1',
          label: 'Deambula, mas não é capaz de sair de casa',
          value: '1'
        },
        {
          checked: watchMobility === '2',
          label: 'Normal',
          value: '2'
        }
      ]
    },
    {
      name: 'stress',
      title: `D- Passou por algum stress psicológico ou doença
              aguda nos últimos 3 meses?:`,
      radioButtons: [
        {
          checked: watchStress === '0',
          label: 'Sim',
          value: '0'
        },
        {
          checked: watchStress === '1',
          label: 'Não',
          value: '1'
        }
      ]
    },
    {
      name: 'neuroPsychological',
      title: `E- Problemas neuropsicológicos?:`,
      radioButtons: [
        {
          checked: watchNeuroPsychological === '0',
          label: 'Demência ou depressão grave',
          value: '0'
        },
        {
          checked: watchNeuroPsychological === '1',
          label: 'Demência ligeira',
          value: '1'
        },
        {
          checked: watchNeuroPsychological === '2',
          label: 'Sem problemas psicológicos',
          value: '2'
        }
      ]
    },
    {
      name: 'bodyMass',
      title: `F- Índice de Massa Corporal (IMC = peso [kg]/ estatura [m²]):`,
      radioButtons: [
        {
          checked: watchBodyMass === '0',
          label: 'IMC < 19',
          value: '0'
        },
        {
          checked: watchBodyMass === '1',
          label: '19 ≤ IMC < 21',
          value: '1'
        },
        {
          checked: watchBodyMass === '2',
          label: '21 ≤ IMC < 23',
          value: '2'
        },
        {
          checked: watchBodyMass === '3',
          label: 'IMC ≥ 23',
          value: '3'
        }
      ]
    },
    {
      name: 'ownHome',
      title: `G- O paciente vive na sua própria casa (não em instituição geriátrica ou hospital)?:`,
      radioButtons: [
        {
          checked: watchOwnHome === '0',
          label: 'Sim',
          value: '0'
        },
        {
          checked: watchOwnHome === '1',
          label: 'Não',
          value: '1'
        }
      ]
    },
    {
      name: 'threeMedicines',
      title: `H- Utiliza mais de três medicamentos diferentes por dia?:`,
      radioButtons: [
        {
          checked: watchThreeMedicines === '0',
          label: 'Sim',
          value: '0'
        },
        {
          checked: watchThreeMedicines === '1',
          label: 'Não',
          value: '1'
        }
      ]
    },
    {
      name: 'eschar',
      title: `I- Lesões de pele ou escaras?:`,
      radioButtons: [
        {
          checked: watchEschar === '0',
          label: 'Sim',
          value: '0'
        },
        {
          checked: watchEschar === '1',
          label: 'Não',
          value: '1'
        }
      ]
    },
    {
      name: 'mealsByDay',
      title: `J- Quantas refeições faz por dia?:`,
      radioButtons: [
        {
          checked: watchMealsByDay === '0',
          label: 'Uma refeição',
          value: '0'
        },
        {
          checked: watchMealsByDay === '1',
          label: 'Duas refeições',
          value: '1'
        },
        {
          checked: watchMealsByDay === '2',
          label: 'Três refeições',
          value: '2'
        }
      ]
    }
  ];

  const MANSecondQuestions = [
    {
      name: 'milkDerivatives',
      title: `Pelo menos uma porção diária de leite ou derivados (leite, queijo, iogurte)?:`,
      radioButtons: [
        {
          checked: watchMilkDerivatives === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchMilkDerivatives === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    },
    {
      name: 'vegetablesEggs',
      title: `Duas ou mais porções semanais de leguminosas ou ovos?:`,
      radioButtons: [
        {
          checked: watchVegetablesEggs === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchVegetablesEggs === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    },
    {
      name: 'meatFishBird',
      title: `Carne, peixe ou aves todos os dias?:`,
      radioButtons: [
        {
          checked: watchMeatFishBird === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchMeatFishBird === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    }
  ];

  const MANThirdQuestions = [
    {
      name: 'fruits',
      title: `L- O paciente consome duas ou mais porções diárias de fruta ou produtos hortícolas?:`,
      radioButtons: [
        {
          checked: watchFruits === '1',
          label: 'Sim',
          value: '1'
        },
        {
          checked: watchFruits === '0',
          label: 'Não',
          value: '0'
        }
      ]
    },
    {
      name: 'liquids',
      title: `M- Quantos copos de líquidos (água, suco, café, chá, leite) o paciente consome por dia?:`,
      radioButtons: [
        {
          checked: watchLiquids === '0',
          label: 'Menos de três copos',
          value: '0'
        },
        {
          checked: watchLiquids === '0.5',
          label: 'Três a cinco copos',
          value: '0.5'
        },
        {
          checked: watchLiquids === '1',
          label: 'Mais de cinco copos',
          value: '1'
        }
      ]
    },
    {
      name: 'wayEating',
      title: `N- Modo de se alimentar:`,
      radioButtons: [
        {
          checked: watchWayEating === '0',
          label: 'Não é capaz de se alimentar sozinho',
          value: '0'
        },
        {
          checked: watchWayEating === '1',
          label: 'Alimenta-se sozinho, porém com dificuldade',
          value: '1'
        },
        {
          checked: watchWayEating === '2',
          label: 'Alimenta-se sozinho, sem dificuldade',
          value: '2'
        }
      ]
    },
    {
      name: 'nutritionalProblem',
      title: `O- O paciente acredita ter algum problema nutricional?:`,
      radioButtons: [
        {
          checked: watchNutritionalProblem === '0',
          label: 'Acredita estar desnutrido',
          value: '0'
        },
        {
          checked: watchNutritionalProblem === '1',
          label: 'Não sabe dizer',
          value: '1'
        },
        {
          checked: watchNutritionalProblem === '2',
          label: 'Acredita não ter problema nutricional',
          value: '2'
        }
      ]
    },
    {
      name: 'ownHealth',
      title: `P- Em comparação com outras pessoas da mesma
              idade, como considera o paciente a sua própria saúde?:`,
      radioButtons: [
        {
          checked: watchOwnHealth === '0',
          label: 'Pior',
          value: '0'
        },
        {
          checked: watchOwnHealth === '0.5',
          label: 'Não sabe',
          value: '0.5'
        },
        {
          checked: watchOwnHealth === '1',
          label: 'Igual',
          value: '1'
        },
        {
          checked: watchOwnHealth === '2',
          label: 'Melhor',
          value: '2'
        }
      ]
    },
    {
      name: 'armCircumference',
      title: `Q- Perímetro braquial (PB) em centímetros:`,
      radioButtons: [
        {
          checked: watchArmCircumference === '0',
          label: 'PB < 21',
          value: '0'
        },
        {
          checked: watchArmCircumference === '0.5',
          label: '≥ 21 PB ≤ 22',
          value: '0.5'
        },
        {
          checked: watchArmCircumference === '1',
          label: 'PB > 22',
          value: '1'
        }
      ]
    },
    {
      name: 'lagCircumference',
      title: `R- Perímetro da perna (PP) em centímetros:`,
      radioButtons: [
        {
          checked: watchLagCircumference === '0',
          label: 'PP < 31',
          value: '0'
        },
        {
          checked: watchLagCircumference === '1',
          label: 'PP > 31',
          value: '1'
        }
      ]
    }
  ];

  function verifyFoodPoints(field: string) {
    switch (field) {
      case 'yes':
        return 1;
      case 'no':
        return 0;
    }
    return 0;
  }

  function loadNrManData() {
    if (loadedData) return;
    if (loadedNrMan && !loadedData) {
      setValue(
        'ingestDecrease',
        JSON.stringify(loadedNrMan.foodIntakeDecrease)
      );
      setValue('weightLoss', JSON.stringify(loadedNrMan.weightLoss));
      setValue('mobility', JSON.stringify(loadedNrMan.mobility));
      if (Number(loadedNrMan.recentStress) > 0) setValue('stress', '1');
      else setValue('stress', JSON.stringify(loadedNrMan.recentStress));
      setValue(
        'neuroPsychological',
        JSON.stringify(loadedNrMan.neurologicalProblems)
      );
      setValue('bodyMass', JSON.stringify(loadedNrMan.imc));
    }
  }

  function loadNeMan(data: NeMan | undefined) {
    if (!data) return;
    setValue('ingestDecrease', data.ingestDecrease);
    setValue('weightLoss', data.weightLoss);
    setValue('mobility', data.mobility);
    setValue('stress', data.stress);
    setValue('neuroPsychological', data.neuroPsychological);
    setValue('bodyMass', data.bodyMass);
    setValue('ownHome', data.ownHome);
    setValue('threeMedicines', data.threeMedicines);
    setValue('eschar', data.eschar);
    setValue('mealsByDay', data.mealsByDay);
    setValue('milkDerivatives', data.milkDerivatives);
    setValue('vegetablesEggs', data.vegetablesEggs);
    setValue('meatFishBird', data.meatFishBird);
    setValue('fruits', data.fruits);
    setValue('liquids', data.liquids);
    setValue('wayEating', data.wayEating);
    setValue('nutritionalProblem', data.nutritionalProblem);
    setValue('ownHealth', data.ownHealth);
    setValue('armCircumference', JSON.stringify(data.armCircumference));
    setValue('lagCircumference', JSON.stringify(data.lagCircumference));
  }

  useEffect(() => {
    loadNrManData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadNeMan(loadedData);
  }, [loadedData]);

  useEffect(() => {
    loadNeMan(lastMan);
  }, [lastMan]);

  async function createMan(data: MANForm) {
    if (!appointmentId) return;
    if (loadedData) return;
    await createManMutation({
      variables: {
        neMan: {
          appointmentId,
          ...data,
          lagCircumference: Number(data.lagCircumference),
          armCircumference: Number(data.armCircumference)
        }
      },
      onCompleted: () => {
        toast.success('Avaliação nutricional MAN criada com sucesso.');
        history.push('/home/anthropometric-evaluation');
      }
    });
  }

  async function updateMan(data: MANForm) {
    if (!loadedData) return;
    await updateManMutation({
      variables: {
        neMan: {
          appointmentId: loadedData.appointmentId,
          ...data,
          lagCircumference: Number(data.lagCircumference),
          armCircumference: Number(data.armCircumference)
        }
      },
      onCompleted: () => {
        toast.info('Avaliação nutricional MAN atualizada com sucesso.');
        history.push('/home/anthropometric-evaluation');
      }
    });
  }

  function onSubmit(data: MANForm) {
    try {
      createMan(data);
      updateMan(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="340vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Mini Nutritional Assessment MNA®
            </Typography>
          </Box>
          <Divider />

          <ChipComponent
            sx={{
              width: '100%',
              margin: '15px 0'
            }}
            color={{ colors: 'light-blue' }}
            label={`SOMA TOTAL ${result} PONTOS`}
          />
          {MANFirstQuestions.map(({ name, title, radioButtons }) => {
            return (
              <Box key={name} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          <Typography
            fontWeight="700"
            fontSize="14px"
            color={theme.palette.grey[900]}
          >
            K- O paciente consome:
          </Typography>
          {MANSecondQuestions.map(({ name, title, radioButtons }) => {
            return (
              <Box key={name} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          {MANThirdQuestions.map(({ name, title, radioButtons }) => {
            return (
              <Box key={name} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          <ChipComponent
            sx={{
              width: '100%',
              margin: '15px 0'
            }}
            color={{ colors: 'light-blue' }}
            label={`SOMA TOTAL ${result} PONTOS`}
          />
          <Box
            minHeight="31vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              width="100%"
              fontWeight="500"
              borderBottom="1px solid"
              borderColor="grey.200"
              fontSize="18px"
              color="grey.900"
            >
              Classificação
            </Typography>
            <ClassificationTable
              rows={[
                {
                  color: { colors: 'light-green' },
                  firstCellContent: '24-30',
                  secondCellContent: 'Normal',
                  selected: result >= 24 && result <= 31 ? true : false
                },
                {
                  color: { colors: 'light-orange' },
                  firstCellContent: '17-23,5',
                  secondCellContent: 'Risco de desnutrição',
                  selected: result >= 17 && result <= 23.5 ? true : false
                },
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Menor que 17',
                  secondCellContent: 'Desnutrido',
                  selected: result < 17 ? true : false
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(MAN);
