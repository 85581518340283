import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { Nrs as NrsInterface } from 'models/nrs';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { Subtitle } from 'components/Subtitle';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface NrsProps {
  appointment: Appointment;
}

type NrsKeys =
  | 'imcLessThan20'
  | 'weightLoss'
  | 'eatingLess'
  | 'seriouslyIll'
  | 'nutritionalStatusDeterioration'
  | 'diseaseLevel';

interface NrsObjectKey {
  key: NrsKeys;
}

const allNrsFields: NrsObjectKey[] = [
  { key: 'imcLessThan20' },
  { key: 'weightLoss' },
  { key: 'eatingLess' },
  { key: 'seriouslyIll' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newNrsType = MappedType<NrsInterface>;

export function Nrs({ appointment }: NrsProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Nrs: newNrsType = appointment.Nrs;
  if (!Nrs) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedNrsQuestion(value: string) {
    switch (value) {
      case 'imcLessThan20':
        return `O IMC é menor que 20,5kg/m²?:`;
      case 'weightLoss':
        return 'O paciente perdeu peso nos 3 últimos meses?:';
      case 'eatingLess':
        return `O paciente teve sua ingestão dietética reduzida na última semana?:`;
      case 'seriouslyIll':
        return `O paciente é gravemente doente?:`;
      default:
        return 'Não há dados';
    }
  }

  function parsedNrsAnswers(key: string, value: boolean | string) {
    if (key === 'imcLessThan20') {
      return value ? 'Sim' : 'Não';
    }
    if (key === 'weightLoss') {
      return value ? 'Sim' : 'Não';
    }
    if (key === 'eatingLess') {
      return value ? 'Sim' : 'Não';
    }
    if (key === 'seriouslyIll') {
      return value ? 'Sim' : 'Não';
    }
  }

  const result = Nrs.diseaseLevel + Nrs.nutritionalStatusDeterioration;
  function calculatedNrsResult(): { status: string; color: IColors } {
    const parsedResult = Number(result);
    if (parsedResult <= 3 ? true : false)
      return { status: 'Risco nutricional', color: { colors: 'light-yellow' } };
    if (parsedResult > 3 ? true : false)
      return {
        status: 'Reavaliar semanalmente',
        color: { colors: 'light-yellow' }
      };
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-risk-screening')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              NRS-2002: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ETAPA 1. TRIAGEM INICIAL" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allNrsFields.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedNrsQuestion(key)} />
                <ResultText title={`${parsedNrsAnswers(key, Nrs[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ETAPA 2. TRIAGEM FINAL" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title="Deterioração do estado nutricional:" />
            <ResultText title={Nrs.nutritionalStatusDeterioration} />
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title="Gravidade da doença (grau de estresse):" />
            <ResultText title={Nrs.diseaseLevel} />
          </Box>
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: calculatedNrsResult().color.colors }}
            label={calculatedNrsResult().status}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
