import { gql } from '@apollo/client';

const CREATE_ECOG = gql`
  mutation createEcog($ecog: EcogInput!) {
    createEcog(ecog: $ecog) {
      id
      appointmentId
    }
  }
`;

export { CREATE_ECOG };
