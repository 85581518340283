export type symptomsKey =
  | 'constipation'
  | 'nausea'
  | 'anosmia'
  | 'quicklySatisfied'
  | 'dryMouth'
  | 'dysphagia'
  | 'noAppetite'
  | 'ache'
  | 'diarrhea'
  | 'vomit'
  | 'smellBothers'
  | 'inappetence'
  | 'mucositis'
  | 'tiredness'
  | 'dysgeusia';

export const firstColumn: symptomsKey[] = [
  'constipation',
  'nausea',
  'anosmia',
  'quicklySatisfied',
  'dryMouth',
  'dysphagia',
  'noAppetite',
  'ache'
];
export const secondColumn: symptomsKey[] = [
  'vomit',
  'smellBothers',
  'inappetence',
  'mucositis',
  'tiredness',
  'dysgeusia',
  'diarrhea'
];

export function generateLabel(item: symptomsKey): string {
  switch (item) {
    case 'constipation':
      return 'Obstipação';

    case 'nausea':
      return 'Náusea';

    case 'anosmia':
      return 'Anosmia';

    case 'quicklySatisfied':
      return 'Se sente rapidamente satisfeito';

    case 'dryMouth':
      return 'Boca seca - Xerostomia';

    case 'dysphagia':
      return 'Problemas para engolir - Disfagia';

    case 'noAppetite':
      return 'Sem apetite, apenas sem vontade para comer';

    case 'ache':
      return 'Dor';

    case 'diarrhea':
      return 'Diarreia';

    case 'vomit':
      return 'Vômito';

    case 'smellBothers':
      return 'Os cheiros incomodam';

    case 'inappetence':
      return 'Inapetência';

    case 'mucositis':
      return 'Feridas na boca - Mucosite';

    case 'tiredness':
      return 'Cansaço';

    case 'dysgeusia':
      return 'Os alimentos não têm gosto ou têm gosto estranho - Disgeusia';

    default:
      return 'Outro';
  }
}
