import { gql } from '@apollo/client';

export const FIND_NE_ASGPPP_BY_APPOINTMENT_ID = gql`
  query findNeAsgPPPByAppointmentId($appointmentId: Float!) {
    findNeAsgpppByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      currentWeight
      heightOver1m
      weight1Month
      weight6Month
      weightLastWeeks
      lastMonthFood
      currentFood
      normalFeed
      nausea
      constipation
      mucositis
      tasteless
      swallowProblem
      tiredness
      appetiteLack
      vomit
      diarrhea
      dryMouth
      quicklySatified
      smellsBother
      others
      pain
      activityLastMonth
      weight1MonthSec
      weight6MonthSec
      relevantDiagnosis
      primaryStaging
      primaryStagingOther
      cancer
      cachexia
      trauma
      renalInsufficiency
      aids
      decubitusUlcer
      moreThan65
      corporalTemperature
      fever
      feverDuration
      corticosteroids
      temple
      clavicle
      shoulder
      hands
      shoulderBlade
      thigh
      calf
      muscleGeneralState
      periorbital
      triceps
      lowerRibs
      fatDeficitGeneralState
      ankle
      sacralEdema
      hidratationGeneralState
      globalCategory
    }
  }
`;
