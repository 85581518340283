import { gql } from '@apollo/client';

export const UPDATE_MUST = gql`
  mutation updateMust($id: Float!, $must: MUSTInput!) {
    updateMust(id: $id, must: $must) {
      id
      appointmentId
    }
  }
`;
