import { gql } from '@apollo/client';

const PATIENT_LIST = gql`
  query patientList($patientName: String, $patientChart: Int) {
    nutriDashPatientList(
      patientName: $patientName
      patientChart: $patientChart
    ) {
      patientId
      patientName
      appointments {
        datetime
        diagnosis
        diseaseData {
          cids {
            code
          }
        }
        nutritionalIntervention {
          tno
        }
      }
    }
  }
`;

export { PATIENT_LIST };
