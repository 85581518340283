import { Appointment } from 'models/appointment';
import { verifyWeightLossPercentage } from 'utils/functions/weightLossPercentage';
import {
  calculateWwightLossPoints,
  setActivityLastMonthPointsFunction,
  setFoodIngestionQuantityPointsFunction,
  setFoodIngestionTypePointsFunction,
  setWeightPointsFunction
} from 'pages/NutritionalEvaluation/forms/asgppp';
import { NeASG } from 'models/neAsg';
import { NeMan } from 'models/neMan';
import { AGS } from 'models/ags';

const calcAsgpppSymptomsPoints = (asg: NeASG): number => {
  let total = 0;
  if (asg.nausea) total = total + 1;
  if (asg.constipation) total = total + 1;
  if (asg.mucositis) total = total + 2;
  if (asg.tasteless) total = total + 1;
  if (asg.swallowProblem) total = total + 2;
  if (asg.tiredness) total = total + 1;
  if (asg.appetiteLack) total = total + 3;
  if (asg.vomit) total = total + 3;
  if (asg.diarrhea) total = total + 3;
  if (asg.dryMouth) total = total + 1;
  if (asg.quicklySatified) total = total + 1;
  if (asg.smellsBother) total = total + 1;

  if (asg.others !== '') total = total + 1;
  if (asg.pain !== '') total = total + 3;
  return total;
};

const calcAsgConditionsPoints = (asg: NeASG): number => {
  let conditions = 0;
  if (asg.cancer) conditions = conditions + 1;
  if (asg.cachexia) conditions = conditions + 1;
  if (asg.trauma) conditions = conditions + 1;
  if (asg.renalInsufficiency) conditions = conditions + 1;
  if (asg.aids) conditions = conditions + 1;
  if (asg.decubitusUlcer) conditions = conditions + 1;
  if (asg.moreThan65) conditions = conditions + 1;
  return conditions;
};

const calcAsgppp = (asg: NeASG): number => {
  const weightlossPct = verifyWeightLossPercentage(
    asg.currentWeight,
    asg.weight1Month
  );
  return (
    calculateWwightLossPoints(weightlossPct) +
    setFoodIngestionQuantityPointsFunction(asg.lastMonthFood) +
    setFoodIngestionTypePointsFunction(asg.currentFood) +
    setWeightPointsFunction(asg.weightLastWeeks) +
    setActivityLastMonthPointsFunction(asg.activityLastMonth) +
    asg.temple +
    asg.clavicle +
    asg.shoulder +
    asg.hands +
    asg.shoulderBlade +
    asg.thigh +
    asg.calf +
    asg.muscleGeneralState +
    asg.periorbital +
    asg.triceps +
    asg.lowerRibs +
    asg.fatDeficitGeneralState +
    asg.ankle +
    asg.sacralEdema +
    asg.hidratationGeneralState +
    calcAsgpppSymptomsPoints(asg) +
    calcAsgConditionsPoints(asg) +
    // temperatureQuestionsPoints
    asg.fever +
    asg.feverDuration +
    asg.corticosteroids
  );
};

const calcMan = (neMan: NeMan) => {
  const milkDerivates = neMan.milkDerivatives === 'yes' ? 1 : 0;
  const vegetablesEggs = neMan.vegetablesEggs === 'yes' ? 1 : 0;
  const meatFishBird = neMan.meatFishBird === 'yes' ? 1 : 0;

  const result =
    Number(neMan.ingestDecrease) +
    Number(neMan.weightLoss) +
    Number(neMan.mobility) +
    Number(neMan.stress) +
    Number(neMan.neuroPsychological) +
    Number(neMan.bodyMass) +
    Number(neMan.ownHome) +
    Number(neMan.threeMedicines) +
    Number(neMan.eschar) +
    Number(neMan.mealsByDay) +
    milkDerivates +
    vegetablesEggs +
    meatFishBird +
    Number(neMan.fruits) +
    Number(neMan.liquids) +
    Number(neMan.wayEating) +
    Number(neMan.nutritionalProblem) +
    Number(neMan.ownHealth) +
    Number(neMan.armCircumference) +
    Number(neMan.lagCircumference);

  return result;
};

const calcAGS = (ags: AGS) => {
  if (ags.subjectiveEvaluation == 'severeMalnourish') return 0;
  if (ags.subjectiveEvaluation == 'malnourish') return 50;
  if (ags.subjectiveEvaluation == 'healthly') return 100;
  return 0;
};

export const prepareNutriEval = (appointments: Appointment[]) => {
  if (appointments) {
    return [
      {
        tag: 'ASG-PPP',
        points: appointments.map((a) => {
          return a.neAsgppp ? calcAsgppp(a.neAsgppp) : null;
        })
      },
      {
        tag: 'AGS',
        points: appointments.map((a) => {
          return a.neAgs ? calcAGS(a.neAgs) : null;
        })
      },
      {
        tag: 'MAN',
        points: appointments.map((a) => {
          return a.neMan ? calcMan(a.neMan) : null;
        })
      }
    ];
  } else {
    return [];
  }
};
