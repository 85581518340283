export const treatments = [
  {
    label: 'Quimioterapia (QT)',
    value: 'QT'
  },
  {
    label: 'Quimioterapia neoadjuvante (QTN)',
    value: 'QTN'
  },
  {
    label: 'Quimioterapia adjuvante (QTA)',
    value: 'QTA'
  },
  {
    label: 'Quimioterapia oral (QTO)',
    value: 'QTO'
  },
  {
    label: 'Radioterapia (RT)',
    value: 'RT'
  },
  {
    label: 'Quimioradioterapia (QT/RT)',
    value: 'QT/RT'
  },
  {
    label: 'Quimioterapia subcutânea (QTSC)',
    value: 'QTSC'
  },
  {
    label: 'Hormonioterapia (HT)',
    value: 'HT'
  },
  {
    label: 'Imunoterapia (IMT)',
    value: 'IMT'
  },
  {
    label: 'Terapia alvo (TA)',
    value: 'TA'
  },
  {
    label: 'Cirurgia',
    value: 'Cirurgia'
  }
];

export function parseTreatmentsNames(name: string) {
  switch (name) {
    case 'QT':
      return 'Quimioterapia (QT)';

    case 'QTN':
      return 'Quimioterapia neoadjuvante (QTN)';

    case 'QTA':
      return 'Quimioterapia adjuvante (QTA)';

    case 'QTO':
      return 'Quimioterapia oral (QTO)';

    case 'RT':
      return 'Radioterapia (RT)';

    case 'QT/RT':
      return 'Quimioradioterapia (QT/RT)';

    case 'QTSC':
      return 'Quimioterapia subcutânea (QTSC)';

    case 'HT':
      return 'Hormonioterapia (HT)';

    case 'IMT':
      return 'Imunoterapia (IMT)';

    case 'TA':
      return 'Terapia alvo (TA)';

    case 'Cirurgia':
      return 'Cirurgia';
  }
  return 'Tratamento não encontrado';
}
