import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ChipComponent,
  ClassificationTable,
  RadioButtonInput,
  TooltipComponent
} from 'components';
import {
  CREATE_MST,
  UPDATE_MST,
  CreateMSTVars,
  UpdateMSTVars
} from 'services/graphql/mutations/nutritional-risk/mst';
import { toast } from 'react-toastify';
import { Mst } from 'models/mst';
import { useHistory } from 'react-router-dom';

interface IFormData {
  weightLoss: string;
  weightLossQuantity: string;
  eatingLess: string;
}

const defaultValues: IFormData = {
  weightLoss: '0',
  weightLossQuantity: '1',
  eatingLess: '0'
};

const MSTForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    weightLoss: number;
    loadedValues: Mst | undefined;
    lastMst: Mst | undefined;
  }
> = ({ weightLoss, loadedValues, lastMst }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormData>({
    defaultValues
  });

  const history = useHistory();

  const [createMstMutation] = useMutation<null, CreateMSTVars>(CREATE_MST);
  const [updateMstMutation] = useMutation<null, UpdateMSTVars>(UPDATE_MST);

  const watchWeightLoss = watch('weightLoss');
  const watchWeightLossQuantity = watch('weightLossQuantity');
  const watchEatingLess = watch('eatingLess');
  const [result, setResult] = useState(0);

  useEffect(() => {
    if (watchWeightLoss === '0') setValue('weightLossQuantity', '0');
    if (watchWeightLossQuantity !== 'dontKnow')
      setResult(
        Number(watchWeightLoss) +
          Number(watchWeightLossQuantity) +
          Number(watchEatingLess)
      );
    else setResult(Number(watchWeightLoss) + 2 + Number(watchEatingLess));
  }, [watchWeightLoss, watchWeightLossQuantity, watchEatingLess]);

  const MSTquestions = [
    {
      name: 'weightLoss',
      title: `Você teve perda recente de peso?:`,
      radioButtons: [
        {
          checked: watchWeightLoss === '0',
          label: 'Não',
          value: '0'
        },
        {
          checked: watchWeightLoss === '1',
          label: 'Não sabe',
          value: '1'
        },
        {
          checked: watchWeightLoss === '2',
          label: 'Sim',
          value: '2'
        }
      ]
    },
    {
      name: 'weightLossQuantity',
      title: `Quanto (em kg) foi a sua perda de peso?:`,
      disabled: watchWeightLoss === '0',
      radioButtons: [
        {
          checked: watchWeightLossQuantity === '1',
          label: '1 - 5',
          value: '1'
        },
        {
          checked: watchWeightLossQuantity === '2',
          label: '6 - 10',
          value: '2'
        },
        {
          checked: watchWeightLossQuantity === '3',
          label: '11 - 15',
          value: '3'
        },
        {
          checked: watchWeightLossQuantity === '4',
          label: '> 15',
          value: '4'
        },
        {
          checked: watchWeightLossQuantity === 'dontKnow',
          label: 'Não sabe',
          value: 'dontKnow'
        }
      ]
    },
    {
      name: 'eatingLess',
      title: `Você está comendo menos por redução do apetite?:`,
      radioButtons: [
        {
          checked: watchEatingLess === '0',
          label: 'Não',
          value: '0'
        },
        {
          checked: watchEatingLess === '1',
          label: 'Sim',
          value: '1'
        }
      ]
    }
  ];

  function setFormValues(data: Mst | undefined) {
    if (data) {
      setValue('weightLoss', data.weightLoss.toString());
      setValue('weightLossQuantity', data.weightLossQuantity.toString());
      setValue('eatingLess', data.eatingLess.toString());
    }
  }

  useEffect(() => {
    if (weightLoss > 0) {
      setValue('weightLoss', '2');
      if (weightLoss > 0 && weightLoss <= 5)
        setValue('weightLossQuantity', '1');
      if (weightLoss > 5 && weightLoss <= 10)
        setValue('weightLossQuantity', '2');
      if (weightLoss > 10 && weightLoss <= 15)
        setValue('weightLossQuantity', '3');
      if (weightLoss > 15) setValue('weightLossQuantity', '4');
    }

    setFormValues(loadedValues);
  }, []);

  useEffect(() => {
    if (weightLoss > 0) {
      setValue('weightLoss', '2');
      if (weightLoss > 0 && weightLoss <= 5)
        setValue('weightLossQuantity', '1');
      if (weightLoss > 5 && weightLoss <= 10)
        setValue('weightLossQuantity', '2');
      if (weightLoss > 10 && weightLoss <= 15)
        setValue('weightLossQuantity', '3');
      if (weightLoss > 15) setValue('weightLossQuantity', '4');
    }

    setFormValues(lastMst);
  }, [lastMst]);

  const appointmentId = Number(localStorage.getItem('appointmentId'));

  async function createMST(data: IFormData) {
    if (!loadedValues)
      await createMstMutation({
        variables: {
          mst: {
            appointmentId,
            weightLoss: Number(data.weightLoss),
            weightLossQuantity:
              data.weightLossQuantity === 'dontKnow'
                ? 2
                : Number(data.weightLossQuantity),
            eatingLess: Number(data.eatingLess)
          }
        },
        onCompleted: () => {
          toast.success('Triagem do risco nutricional MST criada com sucesso.');
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  async function updateMST(data: IFormData) {
    if (loadedValues)
      await updateMstMutation({
        variables: {
          id: loadedValues.id,
          mst: {
            appointmentId,
            weightLoss: Number(data.weightLoss),
            weightLossQuantity:
              data.weightLossQuantity === 'dontKnow'
                ? 2
                : Number(data.weightLossQuantity),
            eatingLess: Number(data.eatingLess)
          }
        },
        onCompleted: () => {
          toast.info(
            'Triagem do risco nutricional MST atualizada com sucesso.'
          );
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  function onSubmit(form: IFormData) {
    try {
      createMST(form);
      updateMST(form);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="90vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Ferramenta de Triagem para Desnutrição (MST)
            </Typography>
            <TooltipComponent content="Utilizada em pacientes adultos e idosos, internados" />
          </Box>
          <Divider />
          {MSTquestions.map(({ name, title, radioButtons, disabled }) => {
            return (
              <Box key={title} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={disabled ? disabled : false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          <ChipComponent
            sx={{
              width: '100%'
            }}
            color={{ colors: 'light-blue' }}
            label={`SOMA TOTAL ${result} PONTOS`}
          />
          <Box
            minHeight="31vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              width="100%"
              fontWeight="500"
              borderBottom="1px solid"
              borderColor="grey.200"
              fontSize="18px"
              color="grey.900"
            >
              Classificação
            </Typography>
            <ClassificationTable
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Maior ou igual a 2',
                  secondCellContent: 'Alto risco',
                  selected: result >= 2 ? true : false
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: '1',
                  secondCellContent: 'Médio risco',
                  selected: result === 1 ? true : false
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: '0',
                  secondCellContent: 'Baixo risco',
                  selected: result === 0 ? true : false
                }
              ]}
            />
          </Box>
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(MSTForm);
