import React from 'react';
import { Box, Stack } from '@mui/material';
import { ButtonComponent } from '../Button';

interface IAdvanceFormFooterProps {
  action(): void;
  back(): void;
  buttonTitle?: string;
}

function AdvanceFormFooter({
  action,
  back,
  buttonTitle
}: IAdvanceFormFooterProps) {
  return (
    <Box
      alignSelf="flex-end"
      width="60vw"
      display="flex"
      justifyContent="flex-end"
      position="relative"
    >
      <Stack direction="row" spacing={2}>
        <ButtonComponent
          isTransparent={true}
          onClick={() => {
            back();
          }}
          title="Voltar"
        />

        <ButtonComponent
          isTransparent={false}
          onClick={() => action()}
          title={buttonTitle ? buttonTitle : 'Avançar'}
        />
      </Stack>
    </Box>
  );
}

export { AdvanceFormFooter };
