import { gql } from '@apollo/client';

export const CREATE_NRS = gql`
  mutation createNRS($nrs: NRSInput!) {
    createNRS(nrs: $nrs) {
      id
      appointmentId
    }
  }
`;
