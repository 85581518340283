import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import {
  ChipComponent,
  InputWithNumberMask,
  ClassificationTable
} from 'components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { limitDecimal } from 'utils/functions';
import { AnthropometricData } from 'models/anthropometric';
import {
  CREATE_ANTHROPOMETRIC,
  UPDATE_ANTHROPOMETRIC,
  AnthropometricMutationVars
} from 'services/graphql/mutations/anthropometricEvaluation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

interface IFormData {
  dct: number;
}

export function calculateManDCTPercentil(sex: string, age: number) {
  if (sex === 'MALE') {
    if (age >= 2 && age <= 2.9) return 8.6;
    if (age >= 3 && age <= 3.9) return 8.5;
    if (age >= 4 && age <= 4.9) return 8.1;
    if (age >= 5 && age <= 5.9) return 8;
    if (age >= 6 && age <= 6.9) return 8.1;
    if (age >= 7 && age <= 7.9) return 8.1;
    if (age >= 8 && age <= 8.9) return 8.5;
    if (age >= 9 && age <= 9.9) return 9.3;
    if (age >= 10 && age <= 10.9) return 10.1;
    if (age >= 11 && age <= 11.9) return 10.7;
    if (age >= 12 && age <= 12.9) return 10.5;
    if (age >= 13 && age <= 13.9) return 9.8;
    if (age >= 14 && age <= 14.9) return 8.8;
    if (age >= 15 && age <= 15.9) return 8.2;
    if (age >= 16 && age <= 16.9) return 8.4;
    if (age >= 17 && age <= 17.9) return 8.7;
    if (age >= 18 && age <= 18.9) return 8.8;
    if (age >= 19 && age <= 19.9) return 9;
    if (age >= 20 && age <= 29.9) return 10.2;
    if (age >= 30 && age <= 39.9) return 10.5;
    if (age >= 40 && age <= 49.9) return 11.5;
    if (age >= 50 && age <= 59.9) return 11.7;
    if (age >= 60 && age <= 69.9) return 11.6;
    if (age >= 70 && age <= 79.9) return 11.4;
    if (age >= 80 && age <= 90.9) return 10.9;
  }
  return 0;
}

export function calculateWomanDCTPercentil(sex: string, age: number) {
  if (sex === 'FEMALE') {
    if (age >= 2 && age <= 2.9) return 8.9;
    if (age >= 3 && age <= 3.9) return 9.1;
    if (age >= 4 && age <= 4.9) return 8.9;
    if (age >= 5 && age <= 5.9) return 9.2;
    if (age >= 6 && age <= 6.9) return 9.6;
    if (age >= 7 && age <= 7.9) return 10;
    if (age >= 8 && age <= 8.9) return 10.6;
    if (age >= 9 && age <= 9.9) return 11.3;
    if (age >= 10 && age <= 10.9) return 12.2;
    if (age >= 11 && age <= 11.9) return 13.3;
    if (age >= 12 && age <= 12.9) return 14.4;
    if (age >= 13 && age <= 13.9) return 15.5;
    if (age >= 14 && age <= 14.9) return 16.5;
    if (age >= 15 && age <= 15.9) return 17.4;
    if (age >= 16 && age <= 16.9) return 17.8;
    if (age >= 17 && age <= 17.9) return 17.8;
    if (age >= 18 && age <= 18.9) return 17.9;
    if (age >= 19 && age <= 19.9) return 18;
    if (age >= 20 && age <= 29.9) return 20.5;
    if (age >= 30 && age <= 39.9) return 23.9;
    if (age >= 40 && age <= 49.9) return 25.7;
    if (age >= 50 && age <= 59.9) return 26.1;
    if (age >= 60 && age <= 69.9) return 24.7;
    if (age >= 70 && age <= 79.9) return 21.9;
    if (age >= 80 && age <= 90.9) return 17.4;
  }
  return 0;
}

const validationSchema = yup.object().shape({
  dct: yup
    .number()
    .required('Insira uma circunferência')
    .max(100, 'A circunferência máxima é 100cm')
});

const DCTForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    age: number;
    sex: string;
    loadedDct: AnthropometricData | undefined;
    lastDct: AnthropometricData | undefined;
  }
> = ({ age, sex, loadedDct, lastDct }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const watchDctCircumference = watch('dct');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [result, setResult] = useState(0);
  const [DCTPercentil, setDCTPercentil] = useState(0);
  const [createAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(CREATE_ANTHROPOMETRIC);
  const [updateAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(UPDATE_ANTHROPOMETRIC);

  useEffect(() => {
    setDCTPercentil(
      sex === 'MALE'
        ? calculateManDCTPercentil(sex, age)
        : calculateWomanDCTPercentil(sex, age)
    );
  }, []);

  function calculateDCTAdequacy(DCT: number) {
    const result = (DCT / DCTPercentil) * 100;
    setResult(limitDecimal(result));
  }

  useEffect(() => {
    calculateDCTAdequacy(watchDctCircumference);
  }, [watchDctCircumference]);

  function calculateResultText() {
    if (result < 70) return 'Desnutrição grave';
    if (result >= 70 && result < 80) return 'Desnutrição moderada';
    if (result >= 80 && result < 90) return 'Desnutrição leve';
    if (result >= 90 && result < 110) return 'Eutrofia';
    if (result >= 110 && result < 120) return 'Sobrepeso';
    if (result >= 120) return 'Obesidade';
    if (!result) return 'Aguardando circunferência';
  }

  function setLoadedValue(data: AnthropometricData | undefined) {
    if (!data) return;
    setValue('dct', data.dct);
  }

  useEffect(() => {
    if (!lastDct) setLoadedValue(loadedDct);
  }, [loadedDct]);

  useEffect(() => {
    setLoadedValue(lastDct);
  }, [lastDct]);

  async function createAnthropometric({ dct }: IFormData) {
    if (loadedDct) return;
    await createAnthropometricMutation({
      variables: {
        antropometricData: {
          appointmentId,
          dct
        }
      },
      onCompleted: () => {
        toast.success('Dados antropométricos adicionados.');
      }
    });
  }

  async function updateAnthropometric({ dct }: IFormData) {
    if (loadedDct || lastDct) {
      await updateAnthropometricMutation({
        variables: {
          antropometricData: {
            appointmentId,
            dct
          }
        },
        onCompleted: () => {
          toast.info('Dados antropométricos adicionados.');
        }
      });
    }
  }

  function onSubmit(data: IFormData) {
    try {
      createAnthropometric(data);
      updateAnthropometric(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="55vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                lineHeight="40px"
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Dobra cutânea tricipital
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              name="dct"
              control={control}
              decimalScale={1}
              label={
                <Box>
                  <Typography>
                    Circunferência <i>(cm)</i>:
                  </Typography>
                </Box>
              }
              placeholder="Ex: 31cm"
              suffix="cm"
            />
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`RESULTADO: ${calculateResultText()}`}
              size="small"
            />
          </Box>
          <Box>
            <ClassificationTable
              size="small"
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Menor que 70%',
                  secondCellContent: 'Desnutrição grave',
                  selected: result < 70
                },
                {
                  color: { colors: 'light-orange' },
                  firstCellContent: 'Entre 70% e 80%',
                  secondCellContent: 'Desnutrição moderada',
                  selected: result >= 70 && result < 80
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 80% e 90%',
                  secondCellContent: 'Desnutrição leve',
                  selected: result >= 80 && result < 90
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: 'Entre 90 e 110%',
                  secondCellContent: 'Eutrofia',
                  selected: result >= 90 && result < 110
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 110% e 120%',
                  secondCellContent: 'Sobrepeso',
                  selected: result >= 110 && result < 120
                },
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Maior que 120%',
                  secondCellContent: 'Obesidade',
                  selected: result >= 120
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(DCTForm);
