import { StepResult } from '..';
import { Ecog } from 'models/ecog';
import { Factg } from 'models/factg';

export function generateLifeQualityResults(
  ecog: Ecog | undefined,
  factg: Factg | undefined
): StepResult {
  if (ecog) {
    return {
      title: 'EORTC-30',
      contents: [
        {
          contentTitle: 'Escala funcional',
          content: ecog.functionalScale?.toString() || '0'
        },
        {
          contentTitle: 'Escala de sintomas',
          content: ecog.symptomsScale?.toString() || '0'
        },
        {
          contentTitle: 'Estado da saúde global',
          content: ecog.globalHealthStatus?.toString() || '0'
        }
      ]
    };
  }
  if (factg) {
    return {
      title: 'FACT-G',
      contents: [
        {
          contentTitle: 'Com qualidade de vida | 90 pontos',
          content: ''
        }
      ]
    };
  }
  return {
    title: 'Escala de qualidade de vida',
    contents: [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ]
  };
}
