import { Result } from '..';
import { FullAppointment } from 'models/fullAppointment';
import {
  calculateAdultManHandGripPercentil,
  calculateAdultWomanHandGripPercentil,
  calculateElderlyManHandGrip,
  calculateElderlyWomanHandGrip,
  calculateHandGripResult
} from '../../../StrenghtAndFuncionalityEvaluation/forms/handGrip';
import {
  calculateManStandUpReferenceNumber,
  calculateWomanStandUpReferenceNumber,
  setStandUpStatusFunction
} from '../../../StrenghtAndFuncionalityEvaluation/forms/standUp';
import { setTugStatusFunction } from '../../../StrenghtAndFuncionalityEvaluation/forms/tug';

function generateStrengthFunctionalityHandGripResults(
  sex: string,
  handGrip: number | undefined,
  age: number
): Result {
  if (age > 65 && sex === 'MALE' && handGrip) {
    return {
      contentTitle: 'Hand Grip',
      content: calculateElderlyManHandGrip(handGrip)
    };
  }
  if (age > 65 && sex === 'FEMALE' && handGrip) {
    return {
      contentTitle: 'Hand Grip',
      content: calculateElderlyWomanHandGrip(sex, handGrip)
    };
  }
  if (age <= 65 && sex === 'MALE' && handGrip) {
    const percentil = calculateAdultManHandGripPercentil(age);
    return {
      contentTitle: 'Hand Grip',
      content: calculateHandGripResult(percentil, handGrip)
    };
  }
  if (age <= 65 && sex === 'FEMALE' && handGrip) {
    const percentil = calculateAdultWomanHandGripPercentil(age);
    return {
      contentTitle: 'Hand Grip',
      content: calculateHandGripResult(percentil, handGrip)
    };
  }
  return {
    contentTitle: 'Hand Grip',
    content: 'Não há dados para exibir'
  };
}

function generateStrengthFunctionalitySitGetUpResults(
  sex: string,
  standUpTimes: number | undefined,
  age: number
): Result {
  if (sex === 'MALE' && standUpTimes) {
    const minStandUpTimes = calculateManStandUpReferenceNumber(age);
    return {
      contentTitle: 'Sentar e levantar da cadeira',
      content: setStandUpStatusFunction(minStandUpTimes, standUpTimes)
    };
  }
  if (sex === 'FEMALE' && standUpTimes) {
    const minStandUpTimes = calculateWomanStandUpReferenceNumber(age);
    return {
      contentTitle: 'Sentar e levantar da cadeira',
      content: setStandUpStatusFunction(minStandUpTimes, standUpTimes)
    };
  }
  return {
    contentTitle: 'Sentar e levantar da cadeira',
    content: 'Não há dados para exibir'
  };
}

function generateStrengthFunctionalityTUG(tug: number): Result {
  if (tug) {
    return {
      contentTitle: 'TUG',
      content: setTugStatusFunction(tug)
    };
  }
  return {
    contentTitle: 'TUG',
    content: 'Não há dados para exibir'
  };
}

export function generateStrengthEvaluationResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string,
  age: number
): Result[] {
  if (!loadedAppointment?.strengthFunctionality) {
    return [
      {
        contentTitle: 'Não há dados para exibir',
        content: ''
      }
    ];
  }
  if (loadedAppointment?.strengthFunctionality) {
    const { handGrip, tug, sitGetup } = loadedAppointment.strengthFunctionality;
    return [
      generateStrengthFunctionalityHandGripResults(sex, handGrip, age),
      generateStrengthFunctionalitySitGetUpResults(sex, sitGetup, age),
      generateStrengthFunctionalityTUG(tug)
    ];
  }
  return [
    {
      contentTitle: '',
      content: ''
    }
  ];
}
