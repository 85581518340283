import React from 'react';
import { Box, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { Bia as BiaInterface } from 'models/bia';

interface BiaProps {
  appointment: Appointment;
}

type BiaKeys =
  | 'fatFreeMassPct'
  | 'fatFreeMassKg'
  | 'leanMassKg'
  | 'fatMassPct'
  | 'fatMassKg'
  | 'resistance'
  | 'reactance'
  | 'phaseAngle';

interface BiaObjectKey {
  key: BiaKeys;
}

const allBiaFields: BiaObjectKey[] = [
  { key: 'fatFreeMassPct' },
  { key: 'fatFreeMassKg' },
  { key: 'leanMassKg' },
  { key: 'fatMassPct' },
  { key: 'fatMassKg' },
  { key: 'resistance' },
  { key: 'reactance' },
  { key: 'phaseAngle' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newBiaType = MappedType<BiaInterface>;

export function Bia({ appointment }: BiaProps) {
  const Bia: newBiaType = appointment.bodyCompositionBia;
  if (!Bia) {
    return <></>;
  }

  function parseBiaQuestion(value: string) {
    switch (value) {
      case 'fatFreeMassPct':
        return 'Percentual de Massa Livre de Gordura (FFM) (%):';
      case 'fatFreeMassKg':
        return 'Quantidade em kg de Massa Livre de Gordura (FFM):';
      case 'leanMassKg':
        return 'Quantidade em kg de Massa Magra (LBM):';
      case 'fatMassPct':
        return 'Percentual de Massa Gordura (FM) (%):';
      case 'fatMassKg':
        return 'Quantidade em kg de Massa Gordura (FM):';
      case 'resistance':
        return 'Resistência:';
      case 'reactance':
        return 'Reatância:';
      case 'phaseAngle':
        return 'Ângulo de fase:';
      default:
        return 'Não há dados';
    }
  }

  function parseBiaAnswers(key: string, value: number) {
    if (key === 'fatFreeMassPct') {
      return value + '%';
    }
    if (key === 'fatFreeMassKg') {
      return value + 'kg';
    }
    if (key === 'leanMassKg') {
      return value + 'kg';
    }
    if (key === 'fatMassPct') {
      return value + '%';
    }
    if (key === 'fatMassKg') {
      return value + 'kg';
    }
    if (key === 'resistance') {
      return value;
    }
    if (key === 'reactance') {
      return value;
    }
    if (key === 'phaseAngle') {
      return value;
    }
  }

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Typography
        sx={{ padding: '0 20px' }}
        fontSize="16px"
        fontWeight="700"
        color="grey.900"
      >
        Bioimpedância – BIA
      </Typography>
      <Box
        display="flex"
        padding="0 20px"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {allBiaFields.map(({ key }, index) => {
          return (
            <Box key={index} margin="15px 0" marginRight="15px" width="48%">
              <Label title={parseBiaQuestion(key)} />
              <ResultText title={`${parseBiaAnswers(key, Bia[key])}`} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
