import * as yup from 'yup';

export const ASGvalidationSchema = yup.object().shape({
  currentWeight: yup
    .number()
    .required('Insira um peso atual')
    .max(600.99, 'O peso máximo é 600.99'),
  heightOver1m: yup
    .number()
    .required('Insira uma altura em cm acima de 1 metro')
    .max(170, 'A altura acima de 1 metro em cm máxima é de 170'),
  weight1Month: yup
    .number()
    .required('Insira um peso de um mês atrás')
    .max(600.99, 'O peso máximo é 600.99'),
  weight6Month: yup
    .number()
    .required('Insira um peso de seis meses atrás')
    .max(600.99, 'O peso máximo é 600.99')
});
