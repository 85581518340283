import { gql } from '@apollo/client';

export const UPDATE_DXA = gql`
  mutation updateDxa($dxa: DxaInput!) {
    updateDxa(dxa: $dxa) {
      appointmentId
      id
    }
  }
`;
