import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AdvanceFormFooter } from 'components/AdvanceFormFooter';
import { MainContentContainer } from 'components/MainContentContainer';
import { Subtitle } from 'components/Subtitle';
import AppointmentAndPatientDataForm from './form';

function AppointmentAndPatientData() {
  const history = useHistory();

  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);

  function onSubmitAppointmentAndPatientDataForm() {
    formSubmitButtonRef.current?.click();
  }

  return (
    <Box display="flex" flexDirection="column" width="62%">
      <MainContentContainer
        headerTitle="1. Dados da consulta e paciente"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: false
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box width="100%">
            <Subtitle title="Registro da consulta" />
          </Box>
          <AppointmentAndPatientDataForm ref={formSubmitButtonRef} />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/dashboard/nutritionist')}
          action={() => onSubmitAppointmentAndPatientDataForm()}
        />
      </Box>
    </Box>
  );
}

export { AppointmentAndPatientData };
