import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { Mst as MstInterface } from 'models/mst';
import { ChipComponent } from 'components/Chip';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface MstProps {
  appointment: Appointment;
}

type MstKeys = 'eatingLess' | 'weightLoss' | 'weightLossQuantity';

interface MstObjectKey {
  key: MstKeys;
}

const allMstFields: MstObjectKey[] = [
  { key: 'weightLoss' },
  { key: 'weightLossQuantity' },
  { key: 'eatingLess' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newMstType = MappedType<MstInterface>;

export function Mst({ appointment }: MstProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Mst: newMstType = appointment.Mst;
  if (!Mst) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedMstQuestion(value: string) {
    switch (value) {
      case 'weightLoss':
        return `Você teve perda recente de peso?:`;
      case 'weightLossQuantity':
        return 'Quanto (em kg) foi a sua perda de peso?:';
      case 'eatingLess':
        return 'Você está comendo menos por redução do apetite?:';
      default:
        return 'Não há dados';
    }
  }

  function parsedMstAnswers(key: string, value: string) {
    const parsedValue = value.toString();
    if (key === 'weightLoss') {
      switch (parsedValue) {
        case '0':
          return 'Não';
        case '1':
          return 'Não sabe';
        case '2':
          return 'Sim';
      }
    }
    if (key === 'weightLossQuantity') {
      switch (parsedValue) {
        case '0':
          return 'Sem perda de peso';
        case '1':
          return '1 - 5';
        case '2':
          return '6 - 10';
        case '3':
          return '11 - 15';
        case '4':
          return '> 15';
      }
    }
    if (key === 'eatingLess') {
      switch (parsedValue) {
        case '0':
          return 'Não';
        case '1':
          return 'Sim';
      }
    }
  }

  const result = Mst.eatingLess + Mst.weightLoss + Mst.weightLossQuantity;
  function calculatedMstResult(): { status: string; color: IColors } {
    const parsedResult = Number(result);
    if (parsedResult >= 2 ? true : false)
      return { status: 'Alto risco', color: { colors: 'light-red' } };
    if (parsedResult === 1 ? true : false)
      return {
        status: 'Médio risco',
        color: { colors: 'light-yellow' }
      };
    if (parsedResult === 0 ? true : false)
      return { status: 'Baixo risco', color: { colors: 'light-green' } };
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-risk-screening')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              MST: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allMstFields.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedMstQuestion(key)} />
                <ResultText title={`${parsedMstAnswers(key, Mst[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: calculatedMstResult().color.colors }}
            label={calculatedMstResult().status}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
