import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { StepResult } from '..';
import jwt_decode from 'jwt-decode';

interface PatientData {
  height: string;
  weight: string;
  name: string;
  imc: string;
  imcClassification: string;
}

export function exportAppointmentData(
  parsed: StepResult[],
  diagnosis: string,
  therapeuticPlan: string,
  patient?: PatientData
) {
  if (!parsed) toast.error('Não há dados para exportar');
  const token = localStorage.getItem('user_token');
  if (!token) return;
  const decoded_token: {
    name: string;
  } = jwt_decode(token);
  const body = {
    contents: parsed,
    patientName: patient?.name,
    patientHeight: patient?.height,
    patientWeight: patient?.weight,
    imc: patient?.imc,
    imcClassification: patient?.imcClassification,
    diagnosis,
    therapeuticPlan,
    nutritionistName: decoded_token.name
  };
  fetch(
    `${process.env.REACT_APP_API_CONTROLLER_URL}/appointment-data-resume-pdf`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
  )
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Dados da Consulta - ${format(new Date(), 'dd-MM-yyyy')}.pdf`
      );

      document.body.appendChild(link);
      link.click();

      link?.parentNode?.removeChild(link);
    })
    .catch(() => {
      toast.error('Houve um erro na exportação');
    });
}
