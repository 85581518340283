import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { Header } from 'components/Header';
import { Dashboard } from './dashboard';
import { Historic } from './historic';

import { FIND_FULL_PATIENT_BY_ID } from 'services/graphql/queries/patient/getFullPatientById';

const ButtonBox = styled(Box)`
  background-color: white;
  color: #002169;
  cursor: pointer;
  width: 50%;
  float: left;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #cecece;
  height: 50px;
`;

const clicked = {
  backgroundColor: '#EDF6FF',
  color: '#008FBE',
  border: '1px solid #008FBE'
};

interface routeProps {
  id: number;
}

export function PatientDashboard() {
  const location = useLocation<routeProps>();
  const patientId = location.state?.id | 0;

  const { data: patient } = useQuery(FIND_FULL_PATIENT_BY_ID, {
    variables: { id: patientId || 1 }
  });
  const [selected, setSelected] = useState('historic');

  return (
    <>
      <Header />
      <Box bgcolor="paper" sx={{ height: '50px' }}>
        <ButtonBox
          sx={selected === 'historic' ? clicked : {}}
          onClick={() => setSelected('historic')}
        >
          Histórico do Paciente
        </ButtonBox>
        <ButtonBox
          sx={selected === 'dashboard' ? clicked : {}}
          onClick={() => setSelected('dashboard')}
        >
          Dashboard do Paciente
        </ButtonBox>
      </Box>

      {patient?.findPatient &&
        (selected === 'historic' ? (
          <Historic patient={patient.findPatient} />
        ) : (
          <Dashboard patient={patient.findPatient} />
        ))}
    </>
  );
}
