import { gql } from '@apollo/client';

const FIND_ECOG_BY_APPOINTMENT_ID = gql`
  query findEcogByAppointment($appointmentId: Float!) {
    findEcogByAppointmentId(appointmentId: $appointmentId) {
      id
      effortDiffculty
      longWalkDifficulty
      shortWalkDifficulty
      stayInBed
      helpFeed
      activityDiffeculty
      leisureDifficulty
      breathShort
      ache
      rest
      troubleSleep
      weak
      appetiteLack
      feelSick
      feelVomit
      constipation
      diarrhea
      tired
      acheActivities
      attetionDiffculty
      nervous
      worried
      riledup
      depressed
      rememberDifficulty
      familiarLife
      socialActivities
      financialDifficulty
      mentalHealth
      qualityOfLife
      functionalScale
      symptomsScale
      globalHealthStatus
    }
  }
`;

export { FIND_ECOG_BY_APPOINTMENT_ID };
