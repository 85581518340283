import { gql } from '@apollo/client';

const UPDATE_NUTRITIONAL_DATA = gql`
  mutation updateNutritionalData(
    $id: Float!
    $nutritionalData: NutritionalDataInput!
  ) {
    updateNutritionalData(id: $id, nutritionalData: $nutritionalData) {
      id
      appointmentId
    }
  }
`;

export { UPDATE_NUTRITIONAL_DATA };
