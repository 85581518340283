import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Table, tableClasses } from '@mui/material';
import { renderDifferentColors } from 'utils/renderDifferentColors';
import { IColors } from 'shared/colors';

interface TableRowProps {
  selected: boolean;
  colors: IColors;
  hasthirdcell: boolean;
}

const StyledFirstTableCell = styled(TableCell)<TableRowProps>(
  ({ theme, colors, selected }) => ({
    [`&.${tableCellClasses.body}`]: {
      width: '25%',
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      color: selected
        ? `${renderDifferentColors(colors.colors).textColor}`
        : theme.palette.grey[400],
      background: selected
        ? `${renderDifferentColors(colors.colors).backgroundColor}`
        : theme.palette.background.default,
      fontWeight: '500'
    }
  })
);

const StyledSecondTableCell = styled(TableCell)<TableRowProps>(
  ({ theme, colors, selected, hasthirdcell }) => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: 'center',
      width: hasthirdcell ? '32%' : '75%',
      background: selected
        ? `${renderDifferentColors(colors.colors).backgroundColor}`
        : theme.palette.background.paper,
      color: selected
        ? `${renderDifferentColors(colors.colors).textColor}`
        : theme.palette.grey[400],
      border: '1px solid',
      borderColor: theme.palette.divider
    }
  })
);

const StyledTable = styled(Table)(({ theme }) => ({
  [`&.${tableClasses.root}`]: {
    borderRadius: 15,
    border: '1px solid',
    borderColor: theme.palette.divider
  }
}));

export { StyledFirstTableCell, StyledSecondTableCell, StyledTable };
