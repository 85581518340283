import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';

import { colors } from 'styles/graphColors';

interface Group {
  name?: string;
  lines: LineData[];
}

interface DataSelectorProps {
  data: Group[];
  selected: number;
}

interface GanttCellProps {
  color?: string;
  bg?: boolean;
}

interface LineData {
  name: string;
  occurrences: number[];
}

interface Props {
  title: string;
  groups: Group[];
  totalSize: number;
  forceSelect?: boolean;
  showEmpty?: boolean;
}

function TitleCell({ title }: { title: string }) {
  return (
    <TableCell
      sx={{
        padding: '8px',
        borderBottom: '0px',
        width: '45px',
        maxWidth: 'fit-content'
      }}
    >
      {title}
    </TableCell>
  );
}

function GanttCell({ color, bg }: GanttCellProps) {
  return (
    <TableCell
      sx={{
        padding: '3px 0px',
        backgroundColor: bg ? '#F6F9FC' : 'transparent',
        borderBottom: '0px'
      }}
    >
      {color && (
        <div
          style={{
            backgroundColor: color,
            width: '100%',
            height: '16px'
          }}
        ></div>
      )}
    </TableCell>
  );
}

function GanttLine({
  line,
  coloridx,
  total
}: {
  line: LineData;
  coloridx: number;
  total: number;
}) {
  const cells = [];
  for (let i = 1; i <= total; i++) {
    const mark = line.occurrences.includes(i);
    cells.push(
      <GanttCell
        color={mark ? colors[coloridx] : 'transparent'}
        bg={i % 2 === 0}
        key={i}
      />
    );
  }
  return (
    <TableRow>
      <TitleCell title={line.name} />
      {cells}
    </TableRow>
  );
}

function CLine({ quant }: { quant: number }) {
  const sx = {
    padding: '3px 0px',
    minWidth: '40px',
    borderBottom: '0px',
    color: '#7B868C',
    fontSize: '10px'
  };
  const cells = [];
  cells.push(<TableCell sx={sx} key={0}></TableCell>);
  for (let i = 1; i <= quant; i++) {
    cells.push(
      <TableCell align="center" sx={sx} key={i}>
        {i < 10 ? 'C0' : 'C'}
        {i}
      </TableCell>
    );
  }
  return <TableRow>{cells}</TableRow>;
}

export function GanttGraph({
  title,
  groups,
  totalSize,
  forceSelect,
  showEmpty
}: Props) {
  const totalOccurrences = (group: Group) => {
    return group.lines.reduce(
      (qt: number, elm) => qt + elm.occurrences.length,
      0
    );
  };
  const ordered = groups
    .sort((a, b) => totalOccurrences(b) - totalOccurrences(a))
    .filter((g) => totalOccurrences(g) > 0 || showEmpty);
  const [selected, setSelected] = useState(0);
  const [plottedData, setPlottedData] = useState<LineData[]>(ordered[0]?.lines);

  const DataSelector = ({ data, selected }: DataSelectorProps) => {
    return (
      <Select
        value={selected.toString()}
        onChange={handleChange}
        variant="standard"
        label=""
      >
        {data.map((d, i) => (
          <MenuItem value={i} key={i}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    const sel = +event.target.value;
    setSelected(sel);
    setPlottedData(ordered[sel].lines);
  };

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          (ordered.length > 1 || forceSelect) && (
            <DataSelector data={ordered} selected={selected} />
          )
        }
      />
      <Divider />
      <CardContent>
        <Table>
          <TableBody
            sx={{
              padding: '15px',
              overflowX: 'scroll'
            }}
          >
            {plottedData?.map((l, i) => (
              <GanttLine
                line={l}
                total={totalSize}
                coloridx={i}
                key={`${l.name}_${i}`}
              />
            ))}
            <CLine quant={totalSize} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
