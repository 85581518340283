import { gql } from '@apollo/client';

const UPDATE_SYMPTOMS = gql`
  mutation updateSymptoms($id: Float!, $symptoms: SymptomsInput!) {
    updateSymptoms(id: $id, symptoms: $symptoms) {
      id
      appointmentId
    }
  }
`;

export { UPDATE_SYMPTOMS };
