import { gql } from '@apollo/client';

export const FIND_BIA_BY_APPOINTMENT_ID = gql`
  query findBiaByAppointmentId($appointmentId: Float!) {
    findBiaByAppointmentId(appointmentId: $appointmentId) {
      appointmentId
      id
      fatFreeMassPct
      fatFreeMassKg
      leanMassKg
      fatMassPct
      resistance
      reactance
      phaseAngle
      fatMassKg
    }
  }
`;
