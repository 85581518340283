import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  InputWithNumberMask,
  ChipComponent
} from 'components';
import { theme } from 'styles/theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import {
  CREATE_ANTHROPOMETRIC,
  UPDATE_ANTHROPOMETRIC,
  AnthropometricMutationVars
} from 'services/graphql/mutations/anthropometricEvaluation';
import { toast } from 'react-toastify';
import { AnthropometricData } from 'models/anthropometric';
import { CutoffTable } from 'components/CutoffTable/cutoffTable';

const validationSchema = yup.object().shape({
  cp: yup
    .number()
    .required('Insira uma circunferência')
    .max(100, 'A circunferência máxima é 100cm')
});

interface IFormData {
  cp: number;
}

const CPForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    sex: string | undefined;
    loadedCp: AnthropometricData | undefined;
    lastCp: AnthropometricData | undefined;
  }
> = ({ sex, loadedCp, lastCp }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(CREATE_ANTHROPOMETRIC);
  const [updateAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(UPDATE_ANTHROPOMETRIC);
  const [result, setResult] = useState('Aguardando circunferência');
  const watchcp = watch('cp');

  function verifycpResults() {
    if (!sex) return;
    if (sex === 'MALE' && watchcp !== 34) setResult('ANORMAL');
    if (sex === 'MALE' && watchcp === 34) setResult('NORMAL');
    if (sex === 'FEMALE' && watchcp !== 33) setResult('ANORMAL');
    if (sex === 'FEMALE' && watchcp === 33) setResult('NORMAL');
  }

  useEffect(() => {
    verifycpResults();
  }, [watchcp]);

  function setLoadedValue(data: AnthropometricData | undefined) {
    if (!data) return;
    setValue('cp', data.cp);
  }

  useEffect(() => {
    setLoadedValue(loadedCp);
  }, [loadedCp]);

  useEffect(() => {
    setLoadedValue(lastCp);
  }, [lastCp]);

  async function createAnthropometric({ cp }: IFormData) {
    if (loadedCp) return;
    await createAnthropometricMutation({
      variables: {
        antropometricData: {
          appointmentId,
          cp
        }
      },
      onCompleted: () => {
        toast.success('Dados antropométricos adicionados.');
      }
    });
  }

  async function updateAnthropometric({ cp }: IFormData) {
    if (!loadedCp) return;
    await updateAnthropometricMutation({
      variables: {
        antropometricData: {
          appointmentId,
          cp
        }
      },
      onCompleted: () => {
        toast.info('Dados antropométricos atualizados.');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createAnthropometric(data);
      updateAnthropometric(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          height="50vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Circunferência da panturrilha
              </Typography>
              <TooltipComponent
                content="
                Circunferência da panturrilha revela modificações da massa
                magra decorrentes do envelhecimento, diminuição de atividade física e
                da própria terapia antineoplásica proposta.
                "
              />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              name="cp"
              control={control}
              decimalScale={0}
              label={
                <Box>
                  <Typography>
                    Circunferência <i>(cm)</i>:
                  </Typography>
                </Box>
              }
              placeholder="Ex: 31cm"
              suffix="cm"
            />
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`RESULTADO: ${result}`}
              size="small"
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
          <Box width="100%">
            <CutoffTable
              title="Ponto de corte Circunferência da panturrilha"
              rows={[
                {
                  firstCell: '34cm',
                  secondCell: '33cm'
                }
              ]}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(CPForm);
