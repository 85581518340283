import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  ChipComponent,
  InputWithNumberMask
} from 'components';
import { theme } from 'styles/theme';
import { CutoffTable } from 'components/CutoffTable/cutoffTable';
import { limitDecimal } from 'utils/functions';
import { toast } from 'react-toastify';
import {
  CREATE_DXA,
  UPDATE_DXA,
  DxaMutationsVars
} from 'services/graphql/mutations/bodyCompositionEvaluation';
import { useMutation } from '@apollo/client';
import { Dxa } from 'models/dxa';
import { useHistory } from 'react-router-dom';

interface IFormType {
  appendicularMuscleMass: number;
}

export function calculateSkeletalMuscleMass(
  apendicularSkeletalMuscleMass: number,
  height: number
) {
  return limitDecimal(apendicularSkeletalMuscleMass / height);
}

const DXAForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    height: number;
    loadedData: Dxa | undefined;
    lastDxa: Dxa | undefined;
  }
> = ({ height, loadedData, lastDxa }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm<IFormType>({});

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [
    ApendicularSkeletalMuscleMassIndex,
    setApendicularSkeletalMuscleMassIndex
  ] = useState(0);
  const watchApendicularSkeletalMuscleMass = watch('appendicularMuscleMass');
  const [createDXAMutation] = useMutation<null, DxaMutationsVars>(CREATE_DXA);
  const [updateDXAMutation] = useMutation<null, DxaMutationsVars>(UPDATE_DXA);
  const history = useHistory();

  function calculateApendicularSkeletalMuscleMassIndex(
    apendicularSkeletalMuscleMass: number,
    height: number
  ) {
    if (!height) {
      toast.warning(
        'É necessário preencher a altura do paciente na etapa 6 para fazer o calculo'
      );
      return;
    }
    const appendicularMuscleMass = calculateSkeletalMuscleMass(
      apendicularSkeletalMuscleMass,
      height
    );
    return appendicularMuscleMass;
  }

  useEffect(() => {
    const value = calculateApendicularSkeletalMuscleMassIndex(
      watchApendicularSkeletalMuscleMass,
      height
    );
    if (!value) return;
    setApendicularSkeletalMuscleMassIndex(value);
  }, [watchApendicularSkeletalMuscleMass]);

  function setLoadedValuesToForm(data: Dxa | undefined) {
    if (!data) return;
    setValue('appendicularMuscleMass', data?.appendicularMuscleMass);
  }

  useEffect(() => {
    setLoadedValuesToForm(loadedData);
  }, [loadedData]);

  useEffect(() => {
    setLoadedValuesToForm(lastDxa);
  }, [lastDxa]);

  async function createDXA(data: IFormType) {
    if (loadedData) return;
    await createDXAMutation({
      variables: {
        dxa: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.success('DXA adicionada.');
      }
    });
  }

  async function updateDXA(data: IFormType) {
    if (!loadedData) return;
    await updateDXAMutation({
      variables: {
        dxa: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.info('DXA atualizada.');
      }
    });
  }

  function onSubmit(data: IFormType) {
    try {
      createDXA(data);
      updateDXA(data);
      history.push('/home/strength-functionality-evaluation');
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="55vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Densitometria por Dupla Emissão de Raio X (DXA)
              </Typography>
              <TooltipComponent content="Densitometria por Dupla Emissão de Raio X (DXA)" />
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box
              margin="30px 0"
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Quantidade de massa muscular apendicular em <i>kg</i>:
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="appendicularMuscleMass"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
            </Box>
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`Índice de massa muscular apendicular esquelético (IMEA): ${
                ApendicularSkeletalMuscleMassIndex
                  ? ApendicularSkeletalMuscleMassIndex
                  : '0'
              } kg/m²`}
            />
          </Box>
          <Box margin="25px 0">
            <CutoffTable
              title="Tomografia computadorizada (TC)"
              rows={[
                {
                  firstCell: 'IMEA = 7,0 kg/m²',
                  secondCell: 'IMEA = 5,5 kg/m²'
                },
                {
                  firstCell: 'IMEA = 7,26 kg/m²',
                  secondCell: 'IMEA = 5,45 kg/m²'
                },
                {
                  firstCell: 'IMEA = 7,8 kg/m²',
                  secondCell: 'IMEA = 5,6 kg/m²'
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(DXAForm);
