import { gql } from '@apollo/client';

export const UPDATE_MST = gql`
  mutation updateMST($id: Float!, $mst: MSTInput!) {
    updateMST(id: $id, mst: $mst) {
      id
      appointmentId
    }
  }
`;
