export const cid = [
  {
    label: 'C00 – Neoplasia Maligna do Lábio',
    value: 'C00',
    resume: 'Lábio'
  },
  {
    label: 'C01 – Neoplasia Maligna da Base da Língua',
    value: 'C01',
    resume: 'Base da Língua'
  },
  {
    label:
      'C02 – Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Língua',
    value: 'C02',
    resume: 'Outras partes da língua'
  },
  {
    label: 'C03 – Neoplasia Maligna da Gengiva',
    value: 'C03',
    resume: 'Gengiva'
  },
  {
    label: 'C04 – Neoplasia Maligna do Assoalho da Boca',
    value: 'C04',
    resume: 'Assoalho da boca'
  },
  {
    label: 'C05 – Neoplasia Maligna do Palato',
    value: 'C05',
    resume: 'Palato'
  },
  {
    label:
      'C06 – Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Boca',
    value: 'C06',
    resume: 'Outras partes da boca'
  },
  {
    label: 'C07 – Neoplasia Maligna da Glândula Parótida',
    value: 'C07',
    resume: 'Glândula Parótida'
  },
  {
    label:
      'C08 – Neoplasia Maligna de Outras Glândulas Salivares Maiores e as Não Especificadas',
    value: 'C08',
    resume: 'Outras Glândulas Salivares'
  },
  {
    label: 'C09 – Neoplasia Maligna da Amígdala',
    value: 'C09',
    resume: 'Amígdala'
  },
  {
    label: 'C10 – Neoplasia Maligna da Orofaringe',
    value: 'C10',
    resume: 'Orofaringe'
  },
  {
    label: 'C11 – Neoplasia Maligna da Nasofaringe',
    value: 'C11',
    resume: 'Nasofaringe'
  },
  {
    label: 'C12 – Neoplasia Maligna do Seio Piriforme',
    value: 'C12',
    resume: 'Seio Piriforme'
  },
  {
    label: 'C13 – Neoplasia Maligna da Hipofaringe',
    value: 'C13',
    resume: 'Hipofaringe'
  },
  {
    label:
      'C14 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definida, do Lábio, Cavidade Oral e Faringe',
    value: 'C14',
    resume: 'Outras Localizações so Lábio, Cavidade Oral e Faringe'
  },
  {
    label: 'C15 – Neoplasia Maligna do Esôfago',
    value: 'C15',
    resume: 'Esôfago'
  },
  {
    label: 'C16 – Neoplasia Maligna do Estômago',
    value: 'C16',
    resume: 'Estômago'
  },
  {
    label: 'C17 – Neoplasia Maligna do Intestino Delgado',
    value: 'C17',
    resume: 'Intestino Delgado'
  },
  {
    label: 'C18 – Neoplasia Maligna do Cólon',
    value: 'C18',
    resume: 'Cólon'
  },
  {
    label: 'C19 – Neoplasia Maligna da Junção Retossigmóide',
    value: 'C19',
    resume: 'Junção Retossigmóide'
  },
  {
    label: 'C20 – Neoplasia Maligna do Reto',
    value: 'C20',
    resume: 'Reto'
  },
  {
    label: 'C21 – Neoplasia Maligna do Ânus e do Canal Anal',
    value: 'C21',
    resume: 'Ânus e Canal Anal'
  },
  {
    label:
      'C22 – Neoplasia Maligna do Fígado e Das Vias Biliares Intra-hepáticas',
    value: 'C22',
    resume: 'Fígado e Vias Biliares Intra-hepáticas'
  },
  {
    label: 'C23 – Neoplasia Maligna da Vesícula Biliar',
    value: 'C23',
    resume: 'Vesícula Biliar'
  },
  {
    label:
      'C24 – Neoplasia Maligna de Outras Partes, e de Partes Não Especificadas Das Vias Biliares',
    value: 'C24',
    resume: 'Outras Partes das Vias Biliares'
  },
  {
    label: 'C25 – Neoplasia Maligna do Pâncreas',
    value: 'C25',
    resume: 'Pâncreas'
  },
  {
    label:
      'C26 – Neoplasia Maligna de Outros Órgãos Digestivos e de Localizações Mal Definidas no Aparelho Digestivo',
    value: 'C26',
    resume: 'Outros Órgãos Digestivos'
  },
  {
    label: 'C30 – Neoplasia Maligna da Cavidade Nasal e do Ouvido Médio',
    value: 'C30',
    resume: 'Cavidade Nasal e Ouvido Médio'
  },
  {
    label: 'C31 – Neoplasia Maligna Dos Seios da Face',
    value: 'C31',
    resume: 'Seios da Face'
  },
  {
    label: 'C32 – Neoplasia Maligna da Laringe',
    value: 'C32',
    resume: 'Laringe'
  },
  {
    label: 'C33 – Neoplasia Maligna da Traquéia',
    value: 'C33',
    resume: 'Traquéia'
  },
  {
    label: 'C34 – Neoplasia Maligna Dos Brônquios e Dos Pulmões',
    value: 'C34',
    resume: 'Brônquios e Pulmões'
  },
  {
    label: 'C37 – Neoplasia Maligna do Timo',
    value: 'C37',
    resume: 'Timo'
  },
  {
    label: 'C38 – Neoplasia Maligna do Coração, Mediastino e Pleura',
    value: 'C38',
    resume: 'Coração, Mediastino e Pleura'
  },
  {
    label:
      'C39 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas do Aparelho Respiratório e Dos Órgãos Intratorácicos',
    value: 'C39',
    resume:
      'Outras Localizações do Aparelho Respiratório e Dos Órgãos Intratorácicos'
  },
  {
    label:
      'C40 – Neoplasia Maligna Dos Ossos e Cartilagens Articulares Dos Membros',
    value: 'C40',
    resume: 'Ossos e Cartilagens Articulares Dos Membros'
  },
  {
    label:
      'C41 – Neoplasia Maligna Dos Ossos e Das Cartilagens Articulares de Outras Localizações e de Localizações Não Especificadas',
    value: 'C41',
    resume:
      'Ossos e Das Cartilagens Articulares de Outras Localizações e de Localizações Não Especificadas'
  },
  {
    label: 'C43 – Melanoma Maligno da Pele',
    value: 'C43',
    resume: 'Melanoma Maligno da Pele'
  },
  {
    label: 'C44 – Outras Neoplasias Malignas da Pele',
    value: 'C44',
    resume: 'Outras Neoplasias Malignas da Pele'
  },
  {
    label: 'C45 – Mesotelioma',
    value: 'C45',
    resume: 'Mesotelioma'
  },
  {
    label: 'C46 – Sarcoma de Kaposi',
    value: 'C46',
    resume: 'Sarcoma de Kaposi'
  },
  {
    label:
      'C47 – Neoplasia Maligna Dos Nervos Periféricos e do Sistema Nervoso Autônomo',
    value: 'C47',
    resume: 'Nervos Periféricos e Sistema Nervoso Autônomo'
  },
  {
    label:
      'C48 – Neoplasia Maligna Dos Tecidos Moles do Retroperitônio e do Peritônio',
    value: 'C48',
    resume: 'Tecidos Moles do Retroperitônio e Peritônio'
  },
  {
    label:
      'C49 – Neoplasia Maligna do Tecido Conjuntivo e de Outros Tecidos Moles',
    value: 'C49',
    resume: 'Tecido Conjuntivo e de Outros Tecidos Moles'
  },
  {
    label: 'C50 – Neoplasia Maligna da Mama',
    value: 'C50',
    resume: 'Mama'
  },
  {
    label: 'C51 – Neoplasia Maligna da Vulva',
    value: 'C51',
    resume: 'Vulva'
  },
  {
    label: 'C52 – Neoplasia Maligna da Vagina',
    value: 'C52',
    resume: 'Vagina'
  },
  {
    label: 'C53 – Neoplasia Maligna do Colo do Útero',
    value: 'C53',
    resume: 'Colo do Útero'
  },
  {
    label: 'C54 – Neoplasia Maligna do Corpo do Útero',
    value: 'C54',
    resume: 'Corpo do Útero'
  },
  {
    label: 'C55 – Neoplasia Maligna do Útero, Porção Não Especificada',
    value: 'C55',
    resume: 'Porção Não Especificada do Útero'
  },
  {
    label: 'C56 – Neoplasia Maligna do Ovário',
    value: 'C56',
    resume: 'Ovário'
  },
  {
    label:
      'C57 – Neoplasia Maligna de Outros Órgãos Genitais Femininos e Dos Não Especificados',
    value: 'C57',
    resume: 'Outros Órgãos Genitais Femininos'
  },
  {
    label: 'C58 – Neoplasia Maligna da Placenta',
    value: 'C58',
    resume: 'Placenta'
  },
  {
    label: 'C60 – Neoplasia Maligna do Pênis',
    value: 'C60',
    resume: 'Pênis'
  },
  {
    label: 'C61 – Neoplasia Maligna da Próstata',
    value: 'C61',
    resume: 'Próstata'
  },
  {
    label: 'C62 – Neoplasia Maligna Dos Testículos',
    value: 'C62',
    resume: 'Testículos'
  },
  {
    label:
      'C63 – Neoplasia Maligna de Outros Órgãos Genitais Masculinos e Dos Não Especificados',
    value: 'C63',
    resume: 'Outros Órgãos Genitais Masculinos'
  },
  {
    label: 'C64 – Neoplasia Maligna do Rim, Exceto Pelve Renal',
    value: 'C64',
    resume: 'Rim, Exceto Pelve Renal'
  },
  {
    label: 'C65 – Neoplasia Maligna da Pelve Renal',
    value: 'C65',
    resume: 'Pelve Renal'
  },
  {
    label: 'C66 – Neoplasia Maligna Dos Ureteres',
    value: 'C66',
    resume: 'Ureteres'
  },
  {
    label: 'C67 – Neoplasia Maligna da Bexiga',
    value: 'C67',
    resume: 'Bexiga'
  },
  {
    label:
      'C68 – Neoplasia Maligna de Outros Órgãos Urinários e Dos Não Especificados',
    value: 'C68',
    resume: 'Outros Órgãos Urinários'
  },
  {
    label: 'C69 – Neoplasia Maligna do Olho e Anexos',
    value: 'C69',
    resume: 'Olho e Anexos'
  },
  {
    label: 'C70 – Neoplasia Maligna Das Meninges',
    value: 'C70',
    resume: 'Meninges'
  },
  {
    label: 'C71 – Neoplasia Maligna do Encéfalo',
    value: 'C71',
    resume: 'Encéfalo'
  },
  {
    label:
      'C72 – Neoplasia Maligna da Medula Espinhal, Dos Nervos Cranianos e de Outras Partes do Sistema Nervoso Central',
    value: 'C72',
    resume:
      'Medula Espinhal, Dos Nervos Cranianos e de Outras Partes do Sistema Nervoso Central'
  },
  {
    label: 'C73 – Neoplasia Maligna da Glândula Tireóide',
    value: 'C73',
    resume: 'Glândula Tireóide'
  },
  {
    label: 'C74 – Neoplasia Maligna da Glândula Supra-renal (Glândula Adrenal)',
    value: 'C74',
    resume: 'Glândula Supra-renal (Glândula Adrenal)'
  },
  {
    label:
      'C75 – Neoplasia Maligna de Outras Glândulas Endócrinas e de Estruturas Relacionadas',
    value: 'C75',
    resume: 'Outras Glândulas Endócrinas'
  },
  {
    label:
      'C76 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas',
    value: 'C76',
    resume: 'Outras Localizações'
  },
  {
    label:
      'C77 – Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos',
    value: 'C77',
    resume: 'Secundária e Não Especificada Dos Gânglios Linfáticos'
  },
  {
    label:
      'C78 – Neoplasia Maligna Secundária Dos Órgãos Respiratórios e Digestivos',
    value: 'C78',
    resume: 'Secundária Dos Órgãos Respiratórios e Digestivos'
  },
  {
    label: 'C79 – Neoplasia Maligna Secundária de Outras Localizações',
    value: 'C79',
    resume: 'Secundária de Outras Localizações'
  },
  {
    label: 'C80 – Neoplasia Maligna, Sem Especificação de Localização',
    value: 'C80',
    resume: 'Sem Especificação de Localização'
  },
  {
    label: 'C81 – Doença de Hodgkin',
    value: 'C81',
    resume: 'Hodgkin'
  },
  {
    label: 'C82 – Linfoma Não-Hodgkin, Folicular (nodular)',
    value: 'C82',
    resume: 'Linfoma Não-Hodgkin, Folicular (nodular)'
  },
  {
    label: 'C83 – Linfoma Não-Hodgkin Difuso',
    value: 'C83',
    resume: 'Linfoma Não-Hodgkin Difuso'
  },
  {
    label: 'C84 – Linfomas de Células T Cutâneas e Periféricas',
    value: 'C84',
    resume: 'Linfomas de Células T Cutâneas e Periféricas'
  },
  {
    label:
      'C85 – Linfoma Não-Hodgkin de Outros Tipos e de Tipo Não Especificado',
    value: 'C85',
    resume: 'Linfoma Não-Hodgkin de Outros Tipos e de Tipo Não Especificado'
  },
  {
    label: 'C88 – Doenças Imunoproliferativas Malignas',
    value: 'C88',
    resume: 'Doenças Imunoproliferativas Malignas'
  },
  {
    label: 'C90 – Mieloma Múltiplo e Neoplasias Malignas de Plasmócitos',
    value: 'C90',
    resume: 'Mieloma Múltiplo e Neoplasias Malignas de Plasmócitos'
  },
  {
    label: 'C91 – Leucemia Linfóide',
    value: 'C91',
    resume: 'Leucemia Linfóide'
  },
  {
    label: 'C92 – Leucemia Mielóide',
    value: 'C92',
    resume: 'Leucemia Mielóide'
  },
  {
    label: 'C93 – Leucemia Monocítica',
    value: 'C93',
    resume: 'Leucemia Monocítica'
  },
  {
    label: 'C94 – Outras Leucemias de Células de Tipo Especificado',
    value: 'C94',
    resume: 'Outras Leucemias de Células de Tipo Especificado'
  },
  {
    label: 'C95 – Leucemia de Tipo Celular Não Especificado',
    value: 'C95',
    resume: 'Leucemia de Tipo Celular Não Especificado'
  },
  {
    label:
      'C96 – Outras Neoplasias Malignas e as Não Especificadas Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos',
    value: 'C96',
    resume:
      'Outras Neoplasias Malignas e as Não Especificadas Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos'
  },
  {
    label:
      'C97 – Neoplasias Malignas de Localizações Múltiplas Independentes (primárias)',
    value: 'C97',
    resume:
      'Neoplasias Malignas de Localizações Múltiplas Independentes (primárias)'
  }
];

export function parseCIDNameByCode(cidCode: string): string {
  switch (cidCode) {
    case 'C00':
      return 'C00 – Neoplasia Maligna do Lábio';

    case 'C01':
      return 'C01 – Neoplasia Maligna da Base da Língua';

    case 'C02':
      return 'C02 – Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Língua';

    case 'C03':
      return 'C03 – Neoplasia Maligna da Gengiva';

    case 'C04':
      return 'C04 – Neoplasia Maligna do Assoalho da Boca';

    case 'C05':
      return 'C05 – Neoplasia Maligna do Palato';

    case 'C06':
      return 'C06 – Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Boca';

    case 'C07':
      return 'C07 – Neoplasia Maligna da Glândula Parótida';

    case 'C08':
      return 'C08 – Neoplasia Maligna de Outras Glândulas Salivares Maiores e as Não Especificadas';

    case 'C09':
      return 'C09 – Neoplasia Maligna da Amígdala';

    case 'C10':
      return 'C10 – Neoplasia Maligna da Orofaringe';

    case 'C11':
      return 'C11 – Neoplasia Maligna da Nasofaringe';

    case 'C12':
      return 'C12 – Neoplasia Maligna do Seio Piriforme';

    case 'C13':
      return 'C13 – Neoplasia Maligna da Hipofaringe';

    case 'C14':
      return 'C14 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definida do Lábio Cavidade Oral e Faringe';

    case 'C15':
      return 'C15 – Neoplasia Maligna do Esôfago';

    case 'C16':
      return 'C16 – Neoplasia Maligna do Estômago';

    case 'C17':
      return 'C17 – Neoplasia Maligna do Intestino Delgado';

    case 'C18':
      return 'C18 – Neoplasia Maligna do Cólon';

    case 'C19':
      return 'C19 – Neoplasia Maligna da Junção Retossigmóide';

    case 'C20':
      return 'C20 – Neoplasia Maligna do Reto';

    case 'C21':
      return 'C21 – Neoplasia Maligna do Ânus e do Canal Anal';

    case 'C22':
      return 'C22 – Neoplasia Maligna do Fígado e Das Vias Biliares Intra-hepáticas';

    case 'C23':
      return 'C23 – Neoplasia Maligna da Vesícula Biliar';

    case 'C24':
      return 'C24 – Neoplasia Maligna de Outras Partes e de Partes Não Especificadas Das Vias Biliares';

    case 'C25':
      return 'C25 – Neoplasia Maligna do Pâncreas';

    case 'C26':
      return 'C26 – Neoplasia Maligna de Outros Órgãos Digestivos e de Localizações Mal Definidas no Aparelho Digestivo';

    case 'C30':
      return 'C30 – Neoplasia Maligna da Cavidade Nasal e do Ouvido Médio';

    case 'C31':
      return 'C31 – Neoplasia Maligna Dos Seios da Face';

    case 'C32':
      return 'C32 – Neoplasia Maligna da Laringe';

    case 'C33':
      return 'C33 – Neoplasia Maligna da Traquéia';

    case 'C34':
      return 'C34 – Neoplasia Maligna Dos Brônquios e Dos Pulmões';

    case 'C37':
      return 'C37 – Neoplasia Maligna do Timo';

    case 'C38':
      return 'C38 – Neoplasia Maligna do Coração Mediastino e Pleura';

    case 'C39':
      return 'C39 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas do Aparelho Respiratório e Dos Órgãos Intratorácicos';

    case 'C40':
      return 'C40 – Neoplasia Maligna Dos Ossos e Cartilagens Articulares Dos Membros';

    case 'C41':
      return 'C41 – Neoplasia Maligna Dos Ossos e Das Cartilagens Articulares de Outras Localizações e de Localizações Não Especificadas';

    case 'C43':
      return 'C43 – Melanoma Maligno da Pele';

    case 'C44':
      return 'C44 – Outras Neoplasias Malignas da Pele';

    case 'C45':
      return 'C45 – Mesotelioma';

    case 'C46':
      return 'C46 – Sarcoma de Kaposi';

    case 'C47':
      return 'C47 – Neoplasia Maligna Dos Nervos Periféricos e do Sistema Nervoso Autônomo';

    case 'C48':
      return 'C48 – Neoplasia Maligna Dos Tecidos Moles do Retroperitônio e do Peritônio';

    case 'C49':
      return 'C49 – Neoplasia Maligna do Tecido Conjuntivo e de Outros Tecidos Moles';

    case 'C50':
      return 'C50 – Neoplasia Maligna da Mama';

    case 'C51':
      return 'C51 – Neoplasia Maligna da Vulva';

    case 'C52':
      return 'C52 – Neoplasia Maligna da Vagina';

    case 'C53':
      return 'C53 – Neoplasia Maligna do Colo do Útero';

    case 'C54':
      return 'C54 – Neoplasia Maligna do Corpo do Útero';

    case 'C55':
      return 'C55 – Neoplasia Maligna do Útero Porção Não Especificada';

    case 'C56':
      return 'C56 – Neoplasia Maligna do Ovário';

    case 'C57':
      return 'C57 – Neoplasia Maligna de Outros Órgãos Genitais Femininos e Dos Não Especificados';

    case 'C58':
      return 'C58 – Neoplasia Maligna da Placenta';

    case 'C60':
      return 'C60 – Neoplasia Maligna do Pênis';

    case 'C61':
      return 'C61 – Neoplasia Maligna da Próstata';

    case 'C62':
      return 'C62 – Neoplasia Maligna Dos Testículos';

    case 'C63':
      return 'C63 – Neoplasia Maligna de Outros Órgãos Genitais Masculinos e Dos Não Especificados';

    case 'C64':
      return 'C64 – Neoplasia Maligna do Rim Exceto Pelve Renal';

    case 'C65':
      return 'C65 – Neoplasia Maligna da Pelve Renal';

    case 'C66':
      return 'C66 – Neoplasia Maligna Dos Ureteres';

    case 'C67':
      return 'C67 – Neoplasia Maligna da Bexiga';

    case 'C68':
      return 'C68 – Neoplasia Maligna de Outros Órgãos Urinários e Dos Não Especificados';

    case 'C69':
      return 'C69 – Neoplasia Maligna do Olho e Anexos';

    case 'C70':
      return 'C70 – Neoplasia Maligna Das Meninges';

    case 'C71':
      return 'C71 – Neoplasia Maligna do Encéfalo';

    case 'C72':
      return 'C72 – Neoplasia Maligna da Medula Espinhal Dos Nervos Cranianos e de Outras Partes do Sistema Nervoso Central';

    case 'C73':
      return 'C73 – Neoplasia Maligna da Glândula Tireóide';

    case 'C74':
      return 'C74 – Neoplasia Maligna da Glândula Supra-renal (Glândula Adrenal)';

    case 'C75':
      return 'C75 – Neoplasia Maligna de Outras Glândulas Endócrinas e de Estruturas Relacionadas';

    case 'C76':
      return 'C76 – Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas';

    case 'C77':
      return 'C77 – Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos';

    case 'C78':
      return 'C78 – Neoplasia Maligna Secundária Dos Órgãos Respiratórios e Digestivos';

    case 'C79':
      return 'C79 – Neoplasia Maligna Secundária de Outras Localizações';

    case 'C80':
      return 'C80 – Neoplasia Maligna Sem Especificação de Localização';

    case 'C81':
      return 'C81 – Doença de Hodgkin';

    case 'C82':
      return 'C82 – Linfoma Não-Hodgkin Folicular (nodular)';

    case 'C83':
      return 'C83 – Linfoma Não-Hodgkin Difuso';

    case 'C84':
      return 'C84 – Linfomas de Células T Cutâneas e Periféricas';

    case 'C85':
      return 'C85 – Linfoma Não-Hodgkin de Outros Tipos e de Tipo Não Especificado';

    case 'C88':
      return 'C88 – Doenças Imunoproliferativas Malignas';

    case 'C90':
      return 'C90 – Mieloma Múltiplo e Neoplasias Malignas de Plasmócitos';

    case 'C91':
      return 'C91 – Leucemia Linfóide';

    case 'C92':
      return 'C92 – Leucemia Mielóide';

    case 'C93':
      return 'C93 – Leucemia Monocítica';

    case 'C94':
      return 'C94 – Outras Leucemias de Células de Tipo Especificado';

    case 'C95':
      return 'C95 – Leucemia de Tipo Celular Não Especificado';

    case 'C96':
      return 'C96 – Outras Neoplasias Malignas e as Não Especificadas Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos';

    case 'C97':
      return 'C97 – Neoplasias Malignas de Localizações Múltiplas Independentes (primárias)';
  }
  return 'Código de CID inválido';
}
