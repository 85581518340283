import { ToggleButton, toggleButtonClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

interface AmountOfButtons {
  amount: number;
}

const StyledToggleButton = styled(ToggleButton)<AmountOfButtons>(
  ({ theme, amount, disabled }) => ({
    [`&.${toggleButtonClasses.root}`]: {
      color: disabled ? theme.palette.grey[200] : theme.palette.grey[900],
      width: 100 / amount + '%',
      transition: '200ms',
      textTransform: 'none',
      fontWeight: '400',
      backgroundColor: disabled ? theme.palette.grey[100] : ''
    },
    [`&.${toggleButtonClasses.selected}`]: {
      background: theme.palette.info.main,
      color: theme.palette.background.paper,
      fontWeight: '700'
    },
    [`&.${toggleButtonClasses.selected}:hover`]: {
      background: theme.palette.primary.light,
      color: theme.palette.background.paper
    },
    [`&:hover`]: {
      background: theme.palette.primary.light,
      color: theme.palette.background.paper
    }
  })
);

export { StyledToggleButton };
