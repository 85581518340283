import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';
import { colors } from 'styles/graphColors';
import { Patient } from 'models/patient';
import { CardPatient } from './cardPatient';
import { LineGraph } from './lineGraph';

import {
  prepareAntropometric,
  prepareBiochemParam,
  prepareCorporalComposition,
  prepareImc,
  prepareLifeQuality,
  prepareNutriDiag,
  prepareNutriEval,
  prepareNutriTriage,
  prepareStrengthFunc,
  prepareSymptoms,
  prepareSymptomsPeriod,
  prepareWeight
} from './dataPrepare';
import {
  GanttGraph,
  GroupedBar,
  PieChart,
  PieData
} from 'components/Dashboard';
import { TooltipComponent } from 'components/Tooltip';
import { Box } from '@mui/system';

type Props = {
  patient: Patient;
};

const strenghtTooltip = `
Hand grip: Reflete a força máxima derivada da contração combinada
dos músculos extrínsecos e intrínsecos da mão que levam à flexão
das articulações. É um preditor de resultados desfavoráveis aos
pacientes e está associada à perda de funcionalidade física e ao
impacto negativo na recuperação da saúde após uma doença ou cirurgia. 
Sentar e levantar da cadeira: pode ser usado como um substituto para 
avaliar a força muscular. Consiste em avaliar em 30 segundos o número 
de vezes em que o paciente se levanta da cadeira, sem usar os braços. 
Vale ressaltar que os pontos de corte foram validados com a população idosa.
TUG: Teste de velocidade de marcha. No Timed-Up and Go (TUG), avalia-se a 
funcionalidade solicitando que os indivíduos se levantem de uma cadeira, 
caminhem até um marcador a 3m de distância, virem e retornem para sentar-se novamente.
`;

function Container({
  children
}: {
  children: JSX.Element | JSX.Element[] | undefined;
}) {
  return (
    <Grid
      container
      spacing={3}
      justifyContent="left"
      sx={{ flexGrow: 1 }}
      style={{ padding: '20px 40px' }}
    >
      {children}
    </Grid>
  );
}

export function Dashboard({ patient }: Props) {
  const weights = prepareWeight(patient.appointments);
  const imcs = prepareImc(patient.appointments);
  const nutriDiags = prepareNutriDiag(patient.appointments);
  const nutriTriage = prepareNutriTriage(patient.appointments);
  const nutriEval = prepareNutriEval(patient.appointments);
  const corporalComp = prepareCorporalComposition(patient.appointments);
  const strenghtFunc = prepareStrengthFunc(patient.appointments);
  const lifeQuality = prepareLifeQuality(patient.appointments);
  const symptoms = prepareSymptoms(patient.appointments).flatMap((s) => s);
  const antropometric = prepareAntropometric(patient.appointments);

  const result = symptoms.reduce(
    (partial, curr: string) => (
      (partial[curr] = (partial[curr] || 0) + 1), partial
    ),
    Object.create(null)
  );
  const symptomsChart: PieData[] = [];
  for (const key in result) {
    symptomsChart.push({
      id: key,
      value: result[key],
      label: key || ''
    });
  }

  const symptomsPeriod = prepareSymptomsPeriod(patient.appointments);

  const {
    albumin,
    transferrin,
    prealbumin,
    leukocytes,
    platelets,
    neutrophyl,
    pcr
  } = prepareBiochemParam(patient.appointments);

  return (
    <>
      <Typography sx={{ padding: '20px 40px', color: '#565656' }} variant="h4">
        Dashboard paciente
      </Typography>

      <Container>
        <Grid md={4} sm={12} item>
          <CardPatient patient={patient} />
        </Grid>
        <Grid md={8} sm={12} item>
          <LineGraph title="Peso" data={weights} color={colors[0]} />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph title="IMC" data={imcs} color={colors[1]} />
        </Grid>
        <Grid item md={6} sm={12}>
          <GanttGraph
            title="Diagnóstico Nutricional"
            totalSize={patient.appointments.length}
            groups={nutriDiags}
            showEmpty
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={4} sm={12}>
          <Card>
            <CardHeader title="Principais Sintomas" />
            <Divider />
            <CardContent sx={{ height: '271px', width: '380px' }}>
              <PieChart data={symptomsChart} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <GanttGraph
            title="Periodicidade dos sintomas"
            totalSize={patient.appointments.length}
            groups={symptomsPeriod}
            forceSelect
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph
            title="Triagem Nutricional"
            data={nutriTriage}
            color={colors[3]}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LineGraph
            title="Avaliação Nutricional"
            data={nutriEval}
            color={colors[4]}
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={12} sm={12}>
          <GroupedBar title="Avaliação Antropométrica" data={antropometric} />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph
            title="Composição da Avaliação Corporal"
            data={corporalComp}
            color={colors[5]}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LineGraph
            title={
              <Box display="flex" alignItems="center">
                Avaliação da Força e Funcionalidade{' '}
                <TooltipComponent content={strenghtTooltip} />
              </Box>
            }
            data={strenghtFunc}
            color={colors[0]}
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={8} sm={12}>
          <LineGraph
            title={
              <Box display="flex" alignItems="center">
                Escala de Qualidade de Vida{' '}
                <TooltipComponent content="considera algumas neoplasias específicas, é composto por 30 questões em cinco escalas funcionais (desempenho físico, funcional, cognitivo, emocional e social), três escalas de sintomas (fadiga, dor, náusea e vômito) com escalas de QV e estado de saúde global." />
              </Box>
            }
            data={lifeQuality}
            color={colors[1]}
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph
            title={
              <Box display="flex" alignItems="center">
                Albumina{' '}
                <TooltipComponent content="Uso clínico: Índice prognóstico de gravidade + Meia vida: 14-21 dias" />
              </Box>
            }
            data={albumin}
            color={colors[2]}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LineGraph
            title={
              <Box display="flex" alignItems="center">
                Transferrina{' '}
                <TooltipComponent content="Uso clínico: Índice prognóstico e monitorização + Meia vida: 8-10 dias" />
              </Box>
            }
            data={transferrin}
            color={colors[3]}
          />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph
            title={
              <Box display="flex" alignItems="center">
                Pré-Albumina{' '}
                <TooltipComponent content="Uso clínico: Monitorização e depleção aguda + Meia vida: 2-3 dias" />
              </Box>
            }
            data={prealbumin}
            color={colors[4]}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LineGraph title="Leucócitos" data={leukocytes} color={colors[5]} />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph title="Plaquetas" data={platelets} color={colors[0]} />
        </Grid>
        <Grid item md={6} sm={12}>
          <LineGraph title="Neutrófilos" data={neutrophyl} color={colors[1]} />
        </Grid>
      </Container>

      <Container>
        <Grid item md={6} sm={12}>
          <LineGraph title="PCR" data={pcr} color={colors[2]} />
        </Grid>
      </Container>
    </>
  );
}
