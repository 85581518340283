import { gql } from '@apollo/client';

const FIND_PATIENT_BY_ID = gql`
  query findPatient($id: Float!) {
    findPatient(id: $id) {
      id
      name
      birthdate
      gender
      modality
      observations
      medicalRecord
      appointments {
        appointmentOrder
      }
    }
  }
`;

export { FIND_PATIENT_BY_ID };
