import React from 'react';
import { Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import { Login } from 'pages/Login';
import { mainRoutes } from './allRoutes';
import { history } from './history';
import { isAuthenticated } from 'shared/auth';
import { ForgotPassword } from 'pages/ForgotPassword';

const PrivateRoute = (props: RouteProps) =>
  isAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Redirect key={`${props.path}`} to="/" />
  );

function AppRoutes() {
  return (
    <Router history={history}>
      <Switch>
        <Route component={Login} exact path="/" />
        <Route component={ForgotPassword} exact path="/forgot-password" />
        {mainRoutes.map((route) => {
          return <PrivateRoute key={`${route.path}`} {...route} />;
        })}
      </Switch>
    </Router>
  );
}

export { AppRoutes };
