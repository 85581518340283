import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { HelpOutlineRounded } from '@mui/icons-material';

interface ITooltipProps {
  content: string;
  isSmall?: boolean;
}

function TooltipComponent({ content, isSmall }: ITooltipProps) {
  return (
    <Tooltip title={content} placement="top" arrow>
      <IconButton>
        <HelpOutlineRounded fontSize={isSmall ? 'small' : 'medium'} />
      </IconButton>
    </Tooltip>
  );
}

export { TooltipComponent };
