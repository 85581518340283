export interface IQuestionsType {
  name:
    | 'effortDiffculty'
    | 'longWalkDifficulty'
    | 'shortWalkDifficulty'
    | 'stayInBed'
    | 'helpFeed'
    | 'activityDiffeculty'
    | 'leisureDifficulty'
    | 'breathShort'
    | 'ache'
    | 'rest'
    | 'troubleSleep'
    | 'weak'
    | 'appetiteLack'
    | 'feelSick'
    | 'feelVomit'
    | 'constipation'
    | 'diarrhea'
    | 'tired'
    | 'acheActivities'
    | 'attetionDiffculty'
    | 'nervous'
    | 'worried'
    | 'riledup'
    | 'depressed'
    | 'rememberDifficulty'
    | 'familiarLife'
    | 'socialActivities'
    | 'financialDifficulty'
    | 'mentalHealth'
    | 'qualityOfLife';
  question: string;
}

export const firstStepQuestions: IQuestionsType[] = [
  {
    name: 'effortDiffculty',
    question: `Você tem qualquer dificuldade quando faz grandes esforços, por exemplo carregar uma bolsa de compras pesada ou uma mala?`
  },
  {
    name: 'longWalkDifficulty',
    question: 'Você tem qualquer dificuldade quando faz uma longa caminhada?'
  },
  {
    name: 'shortWalkDifficulty',
    question:
      'Você tem qualquer dificuldade quando faz uma curta caminhada fora de casa?'
  },
  {
    name: 'stayInBed',
    question: 'Você tem que ficar numa cama ou na cadeira durante o dia?'
  },
  {
    name: 'helpFeed',
    question:
      'Você precisa de ajuda para se alimentar, se vestir, se lavar ou usar o banheiro?'
  }
];

export const secondStepQuestions: IQuestionsType[] = [
  {
    name: 'activityDiffeculty',
    question: `Tem sido difícil fazer suas atividades diárias?`
  },
  {
    name: 'leisureDifficulty',
    question: 'Tem sido díficil ter atividades de divertimento ou lazer?'
  },
  {
    name: 'breathShort',
    question: 'Você teve falta de ar?'
  },
  {
    name: 'ache',
    question: 'Você tem tido dor?'
  },
  {
    name: 'rest',
    question: 'Você precisou repousar?'
  },
  {
    name: 'troubleSleep',
    question: 'Você tem tido problemas para dormir?'
  },
  {
    name: 'weak',
    question: 'Você tem se sentido fraco(a)?'
  },
  {
    name: 'appetiteLack',
    question: 'Você tem tido falta de apetite?'
  },
  {
    name: 'feelSick',
    question: 'Você tem se sentido enjoado(a)?'
  },
  {
    name: 'feelVomit',
    question: 'Você vomitou?'
  }
];

export const thirdStepQuestions: IQuestionsType[] = [
  {
    name: 'constipation',
    question: `Você tem tido prisão de ventre?`
  },
  {
    name: 'diarrhea',
    question: 'Você tem tido diarréia?'
  },
  {
    name: 'tired',
    question: 'Você esteve cansado(a)?'
  },
  {
    name: 'acheActivities',
    question: 'A dor interferiu nas suas atividades diárias?'
  },
  {
    name: 'attetionDiffculty',
    question:
      'Você tem tido dificuldade para se concentrar em coisa, como ler jornal ou ver televisão?'
  },
  {
    name: 'nervous',
    question: 'Você se sentiu nervoso(a)?'
  },
  {
    name: 'worried',
    question: 'Você esteve preocupado(a)?'
  },
  {
    name: 'riledup',
    question: 'Você se sentiu irritado(a) facilmente?'
  },
  {
    name: 'depressed',
    question: 'Você se sentiu deprimido(a)?'
  },
  {
    name: 'rememberDifficulty',
    question: 'Você tem tido dificuldade de se lembrar das coisas?'
  },
  {
    name: 'familiarLife',
    question:
      'A sua condição física ou o tratamento médico tem interferido em sua vida familiar?'
  },
  {
    name: 'socialActivities',
    question:
      'A sua condição física ou o tratamento médico tem interferido em suas atividade sociais?'
  },
  {
    name: 'financialDifficulty',
    question:
      'A sua condição física ou o tratamento médico tem lhe trazido dificuldade financeiras?'
  }
];

export const lastStepQuestions: IQuestionsType[] = [
  {
    name: 'mentalHealth',
    question: `Como classificaria a sua saúde em geral, durante a última semana?`
  },
  {
    name: 'qualityOfLife',
    question:
      'Como você classificaria a sua qualidade de vida geral, durante a última semana?'
  }
];
