import { RouteProps } from 'react-router-dom';
import { AddConsultationHome } from 'pages/AddConsultationHome';
import { AnthropometricEvaluation } from 'pages/AnthropometricEvaluation';
import { AppointmentAndPatientData } from 'pages/AppointmentAndPatientData';
import { BiochemicalParameters } from 'pages/BiochemicalParameters';
import { BodyCompositionEvaluation } from 'pages/BodyCompositionEvaluation';
import { DiseaseData } from 'pages/DiseaseData';
import { LifeQualityScale } from 'pages/LifeQualityScale';
import { NutritionalDataAndInformation } from 'pages/NutritionalDataAndInformation';
import { NutritionalDiagnosisAndClassification } from 'pages/NutritionalDiagnosisAndClassification';
import { NutritionalEvaluation } from 'pages/NutritionalEvaluation';
import { NutritionalIntervention } from 'pages/NutritionalIntervention';
import { NutritionalRiskScreening } from 'pages/NutritionalRiskScreening';
import { PatientDashboard } from 'pages/Dashboard/Patient';
import { StrenghtAndFuncionalityEvaluation } from 'pages/StrenghtAndFuncionalityEvaluation';
import { Symptons } from 'pages/Symptons';
import { NutritionistDashboard } from 'pages/Dashboard/NutritionistDashboard';
import { InstitutionalDashboard } from 'pages/Dashboard/InstitutionalDashboard';

const addConsultationRoutes: RouteProps[] = [
  {
    path: '/home/appointment-and-pacient-data',
    exact: false,
    component: AppointmentAndPatientData
  },
  {
    path: '/home/disease-data',
    exact: false,
    component: DiseaseData
  },
  {
    path: '/home/symptons',
    exact: false,
    component: Symptons
  },
  {
    path: '/home/life-quality-scale',
    exact: false,
    component: LifeQualityScale
  },
  {
    path: '/home/biochemical-parameters',
    exact: false,
    component: BiochemicalParameters
  },
  {
    path: '/home/nutritional-data-and-information',
    exact: false,
    component: NutritionalDataAndInformation
  },
  {
    path: '/home/nutritional-risk-screening',
    exact: false,
    component: NutritionalRiskScreening
  },
  {
    path: '/home/nutritional-evaluation',
    exact: false,
    component: NutritionalEvaluation
  },
  {
    path: '/home/anthropometric-evaluation',
    exact: false,
    component: AnthropometricEvaluation
  },
  {
    path: '/home/body-composition-evaluation',
    exact: false,
    component: BodyCompositionEvaluation
  },
  {
    path: '/home/strength-functionality-evaluation',
    exact: false,
    component: StrenghtAndFuncionalityEvaluation
  },
  {
    path: '/home/nutritional-diagnosis-and-classification',
    exact: false,
    component: NutritionalDiagnosisAndClassification
  },
  {
    path: '/home/nutritional-intervention',
    exact: false,
    component: NutritionalIntervention
  }
];

const mainRoutes: RouteProps[] = [
  //Só a clinica pode ver
  {
    path: '/home',
    component: AddConsultationHome,
    exact: false
    //role: Nutricionista
  },
  //clinica e corporativo pode ver
  {
    path: '/dashboard/nutritionist',
    exact: true,
    component: NutritionistDashboard
  },
  {
    path: '/dashboard/institutional',
    exact: true,
    component: InstitutionalDashboard
  },
  {
    path: '/dashboard/patient',
    exact: false,
    component: PatientDashboard
  }
];

export { mainRoutes, addConsultationRoutes };
