import { gql } from '@apollo/client';

export const CREATE_TC = gql`
  mutation createTc($tc: TcInput!) {
    createTc(tc: $tc) {
      appointmentId
      id
    }
  }
`;
