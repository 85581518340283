import { gql } from '@apollo/client';

const CREATE_DISEASE = gql`
  mutation createDisease($disease: DiseaseDataInput!) {
    createDisease(disease: $disease) {
      appointmentId
    }
  }
`;

export { CREATE_DISEASE };
