import { Appointment } from 'models/appointment';
import { calculateIMC } from 'utils/functions/calculateIMC';

export const prepareImc = (appointments: Appointment[] | undefined) => {
  if (appointments) {
    return [
      {
        points: appointments.map((a) =>
          a.NutritionalData
            ? calculateIMC(a.NutritionalData.height, a.NutritionalData.weight)
            : null
        )
      }
    ];
  } else {
    return [];
  }
};
