import React, { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  CircularProgress
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  Subtitle,
  ToggleButtonComponent,
  DropdownInputComponent,
  ArrayInputComponent,
  TooltipComponent
} from 'components';
import { theme } from 'styles/theme';
import { weeklyMonthlyAndDailyFrequency } from 'utils';
import { useHistory } from 'react-router-dom';
import { Symptoms } from '../interfaces';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SYMPTOMS,
  UPDATE_SYMPTOMS,
  DELETE_SYMPTOMS,
  CreateSymptomsVars,
  UpdateSymptomsVars,
  DeleteSymptomsVars
} from 'services/graphql/mutations/symptoms';
import { FIND_SYMPTOMS_BY_APPOINTMENT_ID } from 'services/graphql/queries/symptoms/findSymptomsByAppointmentId';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  firstColumn,
  generateLabel,
  secondColumn,
  symptomsKey
} from './constsAndInterfaces';
import { FindByAppointmentIdVar } from 'shared';

interface IQueryType {
  findSymptomsByAppointmentId: Symptoms;
}

const validationSchema = yup.object().shape({
  others: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Insira um nome para o sintoma'),
        frequency: yup.string().required('Insira uma frequência para o sintoma')
      })
    )
    .nullable(true)
});

const SymptonsForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastAppointmentSymptoms: Symptoms | undefined }
> = ({ lastAppointmentSymptoms }, ref) => {
  const appointmentId = Number(localStorage.getItem('appointmentId'));

  const history = useHistory();

  const [symptomsId, setSymptomsId] = useState<number>();
  const [symptoms, setSymptoms] = useState<Symptoms | undefined>({
    constipation: '',
    nausea: '',
    anosmia: '',
    quicklySatisfied: '',
    dryMouth: '',
    dysphagia: '',
    noAppetite: '',
    ache: '',
    diarrhea: '',
    vomit: '',
    smellBothers: '',
    inappetence: '',
    mucositis: '',
    tiredness: '',
    dysgeusia: ''
  });
  const { handleSubmit, control, setValue, watch, unregister, getValues } =
    useForm({
      defaultValues: symptoms,
      resolver: yupResolver(validationSchema)
    });

  const [hasOtherSymptons, setHasOtherSymptons] = useState(false);
  const [otherSymptonsChips, setOtherSymptonsChips] = useState<string[]>([]);

  const [createSymptomsMutation] = useMutation<null, CreateSymptomsVars>(
    CREATE_SYMPTOMS
  );
  const [updateSymptomsMutation] = useMutation<null, UpdateSymptomsVars>(
    UPDATE_SYMPTOMS
  );
  const [deleteSymptomsMutation] = useMutation<null, DeleteSymptomsVars>(
    DELETE_SYMPTOMS
  );
  const {
    data: symptomsData,
    loading,
    error
  } = useQuery<IQueryType, FindByAppointmentIdVar>(
    FIND_SYMPTOMS_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const watchOthers = watch('others');

  useEffect(() => {
    if (symptomsData && !error) {
      const data = symptomsData?.findSymptomsByAppointmentId;
      setLoadedFieldsValues(data);
      setSymptomsId(data?.id);
      setSymptoms(data);
    }
  }, [symptomsData, loading, error]);

  function setLoadedFieldsValues(symptoms: Symptoms | undefined) {
    if (symptoms && symptoms.id) {
      setSymptoms(symptoms);
      setValue('constipation', symptoms.constipation);
      setValue('nausea', symptoms.nausea);
      setValue('anosmia', symptoms.anosmia);
      setValue('quicklySatisfied', symptoms.quicklySatisfied);
      setValue('dryMouth', symptoms.dryMouth);
      setValue('dysphagia', symptoms.dysphagia);
      setValue('noAppetite', symptoms.noAppetite);
      setValue('ache', symptoms.ache);
      setValue('diarrhea', symptoms.diarrhea);
      setValue('vomit', symptoms.vomit);
      setValue('smellBothers', symptoms.smellBothers);
      setValue('inappetence', symptoms.inappetence);
      setValue('mucositis', symptoms.mucositis);
      setValue('tiredness', symptoms.tiredness);
      setValue('dysgeusia', symptoms.dysgeusia);
      if (symptoms.others?.length) {
        setHasOtherSymptons(true);
        symptoms.others?.forEach((other, index) => {
          setValue(`others.${index}.name`, other.name);
          setValue(`others.${index}.frequency`, other.frequency);
        });
        setOtherSymptonsChips(symptoms.others.map((other) => other.name));
      }
    }
  }

  useEffect(() => {
    setLoadedFieldsValues(lastAppointmentSymptoms);
  }, [lastAppointmentSymptoms]);

  useEffect(() => {
    if (symptoms === undefined) {
      setHasOtherSymptons(false);
      setOtherSymptonsChips([]);
      unregister('others');
    }
  }, [symptoms]);

  function changeSymptom(key: symptomsKey, value: string) {
    setSymptoms({
      ...symptoms,
      [key]: value ? 'oncePerDay' : null
    });
    setValue(key, value);
  }

  function generateColumn(items: symptomsKey[]) {
    return items.map((item) => {
      const symptom: string | undefined = symptoms ? symptoms[item] : undefined;
      return (
        <Box key={item} display="flex" flexDirection="column" width="95%">
          <FormControlLabel
            sx={{
              color: symptom ? theme.palette.info.main : theme.palette.grey[900]
            }}
            control={
              <Checkbox
                color="info"
                checked={symptom != undefined && symptom != ''}
                name={item}
                onChange={(event) =>
                  changeSymptom(item, event.target.checked ? 'oncePerDay' : '')
                }
              />
            }
            label={generateLabel(item)}
          />
          {symptom != undefined && symptom != '' ? (
            <DropdownInputComponent
              control={control}
              name={item}
              label="Periodicidade:"
              select={weeklyMonthlyAndDailyFrequency.map(({ label, value }) => {
                return { label, value };
              })}
            />
          ) : (
            ''
          )}
        </Box>
      );
    });
  }

  function setOthersNameValue(data: string[]) {
    if (data[0])
      data.forEach((value, index) => {
        setOtherSymptonsChips([...otherSymptonsChips, value]);
        setValue(`others.${index}.name`, value);
      });
  }

  function removeOthers(dataToDelete: string) {
    const rightIndex = otherSymptonsChips.findIndex(
      (item) => item === dataToDelete
    );
    unregister(`others.${rightIndex}`, {
      keepValue: false
    });
    setValue(
      'others',
      getValues('others')?.filter((item) => item)
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function createSymptoms(data: Symptoms) {
    await createSymptomsMutation({
      variables: { symptoms: { appointmentId, ...data } },
      onCompleted: () => {
        toast.success('Sintomas adicionados com sucesso.');
        history.push('/home/life-quality-scale');
      }
    });
  }

  async function updateSymptoms(id: number, data: Symptoms) {
    await updateSymptomsMutation({
      variables: { id, symptoms: { appointmentId, ...data } },
      onCompleted: () => {
        toast.info('Sintomas atualizados com sucesso.');
        history.push('/home/life-quality-scale');
      }
    });
  }

  async function deleteSymptoms() {
    await deleteSymptomsMutation({
      variables: {
        appointmentId
      },
      onCompleted: () => {
        toast.info('Nenhum sintoma foi adicionado.');
        history.push('/home/life-quality-scale');
      }
    });
  }

  async function onSubmit(data: Symptoms) {
    try {
      if (symptoms === undefined) {
        await deleteSymptoms();
      }
      if (symptomsId && symptoms !== undefined) {
        await updateSymptoms(symptomsId, data);
      } else if (symptoms !== undefined) {
        await createSymptoms(data);
      }
    } catch {
      toast.error('Houve um erro');
    }
  }

  if (loading) return <CircularProgress />;

  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="95vh"
          maxHeight="max-content"
        >
          <Box
            position="static"
            alignSelf="flex-start"
            marginBottom={symptoms ? '3%' : 'auto'}
            width="100%"
          >
            <ToggleButtonComponent
              toggleButtonProps={[
                {
                  title: 'Assintomático',
                  value: 1,
                  action: () => setSymptoms(undefined)
                },
                {
                  title: 'Sintomático',
                  value: 2,
                  action: () => setSymptoms({ appointmentId })
                }
              ]}
              selectedValue={symptoms ? 2 : 1}
            />
          </Box>
          {symptoms ? (
            <>
              <Divider />

              <Box display="flex" alignItems="center">
                <Subtitle title="Sintomas" />
                <TooltipComponent content="Adicione todos os sintomas e periodicidade relatados pelo paciente" />
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%">
                  {generateColumn(firstColumn)}

                  <Box display="flex" flexDirection="column" width="100%">
                    <FormControlLabel
                      sx={{ color: theme.palette.grey[900] }}
                      control={
                        <Checkbox
                          defaultChecked={otherSymptonsChips[0] ? true : false}
                          color="info"
                          value={hasOtherSymptons}
                          onChange={(event) =>
                            setHasOtherSymptons(event.target.checked)
                          }
                        />
                      }
                      label="Outros"
                    />
                    {hasOtherSymptons ? (
                      <ArrayInputComponent
                        label="Outros"
                        placeholder="Digite aqui..."
                        subtitle="Sintomas adicionados"
                        fieldData={otherSymptonsChips}
                        inputWidth="100%"
                        buttonWidth="30%"
                        actionOnAdd={(data) => setOthersNameValue(data)}
                        actionOnDelete={(dataToDelete) =>
                          removeOthers(dataToDelete)
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
                <Box width="50%">{generateColumn(secondColumn)}</Box>
              </Box>

              <Divider />
              <Subtitle title="Periodicidade dos sintomas adicionados" />

              {watchOthers
                ? watchOthers.map((other, index) => {
                    return (
                      <Box
                        key={other.name}
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography color="grey.900" paddingLeft="19%">
                          {other.name}
                        </Typography>
                        <DropdownInputComponent
                          sx={{ width: '49%' }}
                          control={control}
                          name={`others.${index}.frequency`}
                          label="Periodicidade:"
                          select={weeklyMonthlyAndDailyFrequency.map(
                            ({ label, value }) => {
                              return { label, value };
                            }
                          )}
                        />
                      </Box>
                    );
                  })
                : ''}
            </>
          ) : (
            ''
          )}
          <Button sx={{ display: 'none' }} ref={ref} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(SymptonsForm);
