import React, {
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Chip,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  Subtitle,
  InputWithNumberMask,
  TooltipComponent,
  ChipComponent,
  Input,
  ClassificationTable
} from 'components';
import { theme } from 'styles/theme';
import { IColors } from 'shared/colors';
import { HelpOutlineRounded } from '@mui/icons-material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_BIOPARAMS,
  UPDATE_BIOPARAMS,
  CreateBioparamsVars,
  UpdateBioparamsVars
} from 'services/graphql/mutations/bioparams';
import { FIND_BIOPARAMS_BY_APPOINTMENT_ID } from 'services/graphql/queries/bioparams/findBioparamsByAppointmentId';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { limitDecimal } from 'utils/functions/limitDecimal';
import { Bioparams } from 'models/bioparams';
import { Patient } from 'models/patient';
import { FIND_PATIENT_BY_ID } from 'services/graphql/queries/patient/getPatientById';
import { FindByAppointmentIdVar } from 'shared';

interface IQueryType {
  findBioparamsByAppointmentId: Bioparams;
}

interface FieldsType {
  name:
    | 'albumin'
    | 'transferrin'
    | 'preAlbumin'
    | 'retinol'
    | 'pcr'
    | 'additionalProteins'
    | 'hemoglobin'
    | 'hematocrit'
    | 'lymphocite'
    | 'neutrophyl'
    | 'creatinine'
    | 'urea'
    | 'platelets'
    | 'leukocytes'
    | 'tgo'
    | 'tgp'
    | 'additionalBllod'
    | 'additionalNitrogen';
  label: string;
  value: boolean;
  setState: React.Dispatch<SetStateAction<boolean>>;
  format?: string;
  decimalScale?: number;
  suffix?: string;
  tooltipText?: string;
  chip: IChip;
  placeholder: string;
}

interface IChip {
  colors: IColors;
  text: string;
}

const BiochemicalParametersForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastBioparams: Bioparams | undefined }
> = ({ lastBioparams }, ref) => {
  const { handleSubmit, unregister, control, watch, getValues, setValue } =
    useForm<Bioparams>({
      shouldUnregister: true
    });
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));

  const [createBioparamsMutation] = useMutation<null, CreateBioparamsVars>(
    CREATE_BIOPARAMS
  );
  const [updateBioparamsMutation] = useMutation<null, UpdateBioparamsVars>(
    UPDATE_BIOPARAMS
  );
  const {
    data: bioParams,
    error,
    loading
  } = useQuery<IQueryType, FindByAppointmentIdVar>(
    FIND_BIOPARAMS_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const loadedBioparams = useMemo(() => {
    if (bioParams && !error) {
      const loaded = bioParams.findBioparamsByAppointmentId;
      setAllValuesToUpdate(loaded);
      return loaded;
    }
  }, [bioParams, error, loading]);

  function setAllValuesToUpdate(data: Bioparams) {
    setValue('albumin', data.albumin);
    setValue('transferrin', data.transferrin);
    setValue('preAlbumin', data.preAlbumin);
    setValue('retinol', data.retinol);
    setValue('pcr', data.pcr);
    setValue('hemoglobin', data.hemoglobin);
    setValue('hematocrit', data.hematocrit);
    setValue('lymphocite', data.lymphocite);
    setValue('neutrophyl', data.neutrophyl);
    setValue('creatinine', data.creatinine);
    setValue('urea', data.urea);
    setValue('platelets', data.platelets);
    setValue('leukocytes', data.leukocytes);
    setValue('tgo', data.tgo);
    setValue('tgp', data.tgp);
    setValue('ingestedNitrogen', data.ingestedNitrogen);
    setValue('excretedNitrogen', data.excretedNitrogen);
    setValue('additionalProteins', data.additionalProteins);
    setValue('additionalBllod', data.additionalBllod);
    setValue('ingestedNitrogen', data.ingestedNitrogen);
    setValue('excretedNitrogen', data.excretedNitrogen);
    setValue('additionalNitrogen', data.additionalNitrogen);
  }

  const [albumin, setAlbumin] = useState(
    loadedBioparams?.albumin ? true : false
  );
  const [transferrin, setTransferrin] = useState(false);
  const [preAlbumin, setPreAlbumin] = useState(false);
  const [retinol, setRetinol] = useState(false);
  const [pcr, setPcr] = useState(false);
  const [hemoglobin, setHemoglobin] = useState(false);
  const [hematocrit, setHematocrit] = useState(false);
  const [lymphocite, setlymphocite] = useState(false);
  const [neutrophyl, setNeutrophyl] = useState(false);
  const [creatinine, setCreatinine] = useState(false);
  const [urea, setUrea] = useState(false);
  const [platelets, setPlatelets] = useState(false);
  const [leukocytes, setLeukocytes] = useState(false);
  const [tgo, setTgo] = useState(false);
  const [tgp, setTgp] = useState(false);

  const [albuminChip, setAlbuminChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [transferrinChip, setTransferrinChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [preAlbuminChip, setPreAlbuminChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [retinolChip, setRetinolChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [pcrChip, setPcrChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [hemoglobinChip, setHemoglobinChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [hematocritChip, setHematocritChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [lymphociteChip, setlymphociteChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [neutrophylChip, setNeutrophylChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [creatinineChip, setCreatinineChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [ureaChip, setUreaChip] = useState<IChip>({
    colors: { colors: 'light-yellow' },
    text: 'Depleção leve'
  });
  const [plateletsChip, setPlateletsChip] = useState<IChip>({
    colors: { colors: 'light-orange' },
    text: 'Plaquetopenia'
  });
  const [leukocytesChip, setLeukocytesChip] = useState<IChip>({
    colors: { colors: 'light-orange' },
    text: 'Leucopenia'
  });
  const [tgoChip, setTgoChip] = useState<IChip>({
    colors: { colors: 'light-orange' },
    text: 'Alerta para possível disfunção e/ou sobrecarga hepática'
  });
  const [tgpChip, setTgpChip] = useState<IChip>({
    colors: { colors: 'light-orange' },
    text: 'Alerta para possível disfunção e/ou sobrecarga hepática'
  });
  const [BNValue, setBNValue] = useState(0);

  const watchAlbumin = watch('albumin');
  const watchTransferrin = watch('transferrin');
  const watchPreAlbumin = watch('preAlbumin');
  const watchRetinol = watch('retinol');
  const watchPcr = watch('pcr');
  const watchHemoglobin = watch('hemoglobin');
  const watchHematocrit = watch('hematocrit');
  const watchlymphocite = watch('lymphocite');
  const watchNeutrophyl = watch('neutrophyl');
  const watchCreatinine = watch('creatinine');
  const watchUrea = watch('urea');
  const watchPlatelets = watch('platelets');
  const watchLeukocytes = watch('leukocytes');
  const watchTgo = watch('tgo');
  const watchTgp = watch('tgp');

  const watchIngestedNitrogen = watch('ingestedNitrogen');
  const watchExcretedNitrogen = watch('excretedNitrogen');

  const [sex, setSex] = useState('');
  const patientId = Number(localStorage.getItem('patientId'));

  const [loadPatientData, { refetch }] =
    useLazyQuery<{ findPatient: Patient }>(FIND_PATIENT_BY_ID);

  async function loadPatient() {
    await loadPatientData({
      variables: {
        id: patientId
      }
    });
    const data = (await refetch()).data.findPatient;
    if (!data) toast.warn('Não foi possível carregar o sexo do(a) paciente');
    setSex(data.gender);
  }

  useEffect(() => {
    if (!lastBioparams) return;
    setAllValuesToUpdate(lastBioparams);
  }, [lastBioparams]);

  function handleTurnInputOn(
    state: React.Dispatch<SetStateAction<boolean>>,
    name:
      | 'albumin'
      | 'transferrin'
      | 'preAlbumin'
      | 'retinol'
      | 'pcr'
      | 'additionalProteins'
      | 'hemoglobin'
      | 'hematocrit'
      | 'lymphocite'
      | 'neutrophyl'
      | 'creatinine'
      | 'urea'
      | 'platelets'
      | 'leukocytes'
      | 'tgo'
      | 'tgp'
      | 'additionalBllod'
      | 'additionalNitrogen',
    value: boolean
  ) {
    state(value);
    if (!value) {
      setValue(name, 0);
      unregister(name);
    }
  }

  function setChipToNormal(
    text: string,
    setChip: React.Dispatch<SetStateAction<IChip>>
  ) {
    setChip({ text, colors: { colors: 'light-green' } });
  }

  function setChipToLight(
    text: string,
    setChip: React.Dispatch<SetStateAction<IChip>>
  ) {
    setChip({ text, colors: { colors: 'light-yellow' } });
  }

  function setChipToModerate(
    text: string,
    setChip: React.Dispatch<SetStateAction<IChip>>
  ) {
    setChip({ text, colors: { colors: 'light-orange' } });
  }

  function setChipToSevere(
    text: string,
    setChip: React.Dispatch<SetStateAction<IChip>>
  ) {
    setChip({ text, colors: { colors: 'light-red' } });
  }

  function verifyAlbuminValue() {
    const albumin = getValues('albumin');
    if (albumin >= 3.5) {
      setChipToNormal('Normal', setAlbuminChip);
    } else if (albumin < 3.5 && albumin >= 3.0) {
      setChipToLight('Depleção leve', setAlbuminChip);
    } else if (albumin <= 2.9 && albumin >= 2.4) {
      setChipToModerate('Depleção moderada', setAlbuminChip);
    } else if (albumin < 2.4) {
      setChipToSevere('Depleção grave', setAlbuminChip);
    }
  }

  useEffect(() => {
    verifyAlbuminValue();
  }, [watchAlbumin]);

  function verifyTransferrinValue() {
    const transferrin = getValues('transferrin');
    if (transferrin < 400 && transferrin >= 200) {
      setChipToNormal('Normal', setTransferrinChip);
    } else if (transferrin < 199 && transferrin >= 150) {
      setChipToLight('Depleção leve', setTransferrinChip);
    } else if (transferrin <= 149 && transferrin >= 100) {
      setChipToModerate('Depleção moderada', setTransferrinChip);
    } else if (transferrin < 100) {
      setChipToSevere('Depleção grave', setTransferrinChip);
    }
  }

  useEffect(() => {
    verifyTransferrinValue();
  }, [watchTransferrin]);

  function verifyPreAlbuminValue() {
    const preAlbumin = getValues('preAlbumin');
    if (preAlbumin > 20) {
      setChipToNormal('Normal', setPreAlbuminChip);
    } else if (preAlbumin < 15 && preAlbumin >= 10) {
      setChipToLight('Depleção leve', setPreAlbuminChip);
    } else if (preAlbumin <= 10 && preAlbumin >= 5) {
      setChipToModerate('Depleção moderada', setPreAlbuminChip);
    } else if (preAlbumin < 5) {
      setChipToSevere('Depleção grave', setPreAlbuminChip);
    }
  }

  useEffect(() => {
    verifyPreAlbuminValue();
  }, [watchPreAlbumin]);

  function verifyRetinolValue() {
    const retinol = getValues('retinol');
    if (retinol >= 3) {
      setChipToNormal('Normal', setRetinolChip);
    } else if (retinol < 3) {
      setChipToSevere('Depleção', setRetinolChip);
    }
  }

  useEffect(() => {
    verifyRetinolValue();
  }, [watchRetinol]);

  function verifyPcrValue() {
    const pcr = getValues('pcr');
    if (pcr < 0.3) {
      setChipToNormal('Normal', setPcrChip);
    } else if (pcr > 0.3 && pcr <= 1) {
      setChipToLight('Normal ou elevação pequena', setPcrChip);
    } else if (pcr > 1 && pcr <= 10) {
      setChipToModerate('Elevação moderada', setPcrChip);
    } else if (pcr > 10 && pcr < 50) {
      setChipToSevere('Elevação importante', setPcrChip);
    } else if (pcr > 50) {
      setChipToSevere('Elevação severa', setPcrChip);
    }
  }

  useEffect(() => {
    verifyPcrValue();
  }, [watchPcr]);

  function verifyHemoglobinValue() {
    const hemoglobin = getValues('hemoglobin');
    if (sex === 'MALE' && hemoglobin > 12) {
      setChipToNormal('Desnutrição leve', setHemoglobinChip);
    } else if (sex === 'MALE' && hemoglobin > 10 && hemoglobin <= 12) {
      setChipToModerate('Desnutrição moderada', setHemoglobinChip);
    } else if (sex === 'MALE' && hemoglobin < 10) {
      setChipToSevere('Desnutrição grave', setHemoglobinChip);
    }

    if (sex === 'FEMALE' && hemoglobin > 10) {
      setChipToNormal('Desnutrição leve', setHemoglobinChip);
    } else if (sex === 'FEMALE' && hemoglobin > 8 && hemoglobin <= 10) {
      setChipToModerate('Desnutrição moderada', setHemoglobinChip);
    } else if (sex === 'FEMALE' && hemoglobin < 8) {
      setChipToSevere('Desnutrição grave', setHemoglobinChip);
    }
  }

  useEffect(() => {
    verifyHemoglobinValue();
  }, [watchHemoglobin]);

  function verifyHematocritValue() {
    const hematocrit = getValues('hematocrit');
    if (sex === 'MALE' && hematocrit > 36) {
      setChipToLight('Desnutrição leve', setHematocritChip);
    } else if (sex === 'MALE' && hematocrit <= 36 && hematocrit >= 31) {
      setChipToModerate('Desnutrição moderada', setHematocritChip);
    } else if (sex === 'MALE' && hematocrit < 31) {
      setChipToSevere('Desnutrição grave', setHematocritChip);
    }

    if (sex === 'FEMALE' && hematocrit > 31) {
      setChipToLight('Desnutrição leve', setHematocritChip);
    } else if (sex === 'FEMALE' && hematocrit <= 31 && hematocrit >= 24) {
      setChipToModerate('Desnutrição moderada', setHematocritChip);
    } else if (sex === 'FEMALE' && hematocrit < 24) {
      setChipToSevere('Desnutrição grave', setHematocritChip);
    }
  }

  useEffect(() => {
    verifyHematocritValue();
  }, [watchHematocrit]);

  function verifylymphociteValue() {
    const lymphocite = getValues('lymphocite');
    if (lymphocite >= 2000) {
      setChipToNormal('Normal', setlymphociteChip);
    } else if (lymphocite >= 1200 && lymphocite < 2000) {
      setChipToLight('Depleção leve', setlymphociteChip);
    } else if (lymphocite <= 1199 && lymphocite >= 800) {
      setChipToModerate('Depleção moderada', setlymphociteChip);
    } else if (lymphocite < 800) {
      setChipToSevere('Depleção grave', setlymphociteChip);
    }
  }

  useEffect(() => {
    verifylymphociteValue();
  }, [watchlymphocite]);

  function verifyNeutrophylValue() {
    const neutrophyl = getValues('neutrophyl');
    if (neutrophyl > 1500) {
      setChipToNormal('Normal', setNeutrophylChip);
    } else if (neutrophyl >= 1000 && neutrophyl <= 1500) {
      setChipToLight('Neutropenia leve', setNeutrophylChip);
    } else if (neutrophyl < 1000 && neutrophyl >= 500) {
      setChipToModerate('Neutropenia moderada', setNeutrophylChip);
    } else if (neutrophyl < 500) {
      setChipToSevere('Neutropenia grave', setNeutrophylChip);
    }
  }

  useEffect(() => {
    verifyNeutrophylValue();
  }, [watchNeutrophyl]);

  function verifyCreatinineValue() {
    const creatinine = getValues('creatinine');
    if (sex === 'MALE' && creatinine < 0.6) {
      setChipToModerate('Abaixo', setCreatinineChip);
    } else if (sex === 'MALE' && creatinine >= 0.6 && creatinine <= 1.2) {
      setChipToNormal('Normal', setCreatinineChip);
    } else if (sex === 'MALE' && creatinine > 1.2) {
      setChipToSevere('Acima', setCreatinineChip);
    }

    if (sex === 'FEMALE' && creatinine < 0.5) {
      setChipToModerate('Abaixo', setCreatinineChip);
    } else if (sex === 'FEMALE' && creatinine >= 0.5 && creatinine <= 1.1) {
      setChipToNormal('Normal', setCreatinineChip);
    } else if (sex === 'FEMALE' && creatinine > 1.1) {
      setChipToSevere('Acima', setCreatinineChip);
    }
  }

  useEffect(() => {
    verifyCreatinineValue();
  }, [watchCreatinine]);

  function verifyUreaValue() {
    const urea = getValues('urea');
    if (urea > 50) {
      setChipToSevere('Acima', setUreaChip);
    } else if (urea <= 50 && urea >= 10) {
      setChipToNormal('Normal', setUreaChip);
    } else if (urea < 10) {
      setChipToModerate('Abaixo', setUreaChip);
    }
  }

  useEffect(() => {
    verifyUreaValue();
  }, [watchUrea]);

  function verifyPlateletsValue() {
    const platelets = getValues('platelets');
    if (platelets < 140.0) {
      setChipToModerate('Plaquetopenia', setPlateletsChip);
    } else if (platelets >= 140.0 && platelets <= 450.0) {
      setChipToNormal('Sem classificação', setPlateletsChip);
    } else if (platelets > 450.0) {
      setChipToModerate('Trombocitose', setPlateletsChip);
    }
  }

  useEffect(() => {
    verifyPlateletsValue();
  }, [watchPlatelets]);

  function verifyLeukocytesValue() {
    const leukocytes = getValues('leukocytes');
    if (leukocytes <= 4.0) {
      setChipToModerate('Leucopenia', setLeukocytesChip);
    } else if (leukocytes >= 11.0) {
      setChipToModerate('Leucocitose', setLeukocytesChip);
    }
  }

  useEffect(() => {
    verifyLeukocytesValue();
  }, [watchLeukocytes]);

  function verifyTgoValue() {
    const tgo = getValues('tgo');
    if (tgo > 34) {
      setChipToModerate('Sobrecarga hepática', setTgoChip);
    } else if (tgo < 34) {
      setChipToModerate('Disfunção hepática', setTgoChip);
    } else if (tgo === 34) {
      setChipToNormal('Adequado', setTgoChip);
    }
  }

  useEffect(() => {
    verifyTgoValue();
  }, [watchTgo]);

  function verifyTgpValue() {
    const tgp = getValues('tgp');
    if (tgp <= 49 && tgp >= 10) {
      setChipToNormal('Adequado', setTgpChip);
    } else if (tgp < 10) {
      setChipToModerate('Disfunção hepática', setTgpChip);
    } else if (tgp > 49) {
      setChipToModerate('Sobrecarga hepática', setTgpChip);
    }
  }

  useEffect(() => {
    verifyTgpValue();
  }, [watchTgp]);

  const proteinFields: FieldsType[] = [
    {
      name: 'albumin',
      label: 'Albumina (g/dL)',
      value: albumin,
      setState: setAlbumin,
      decimalScale: 2,
      suffix: 'g',
      tooltipText:
        'Uso clínico: Índice prognóstico de gravidade + Meia vida: 14-21 dias',
      chip: albuminChip,
      placeholder: 'Ex: 00,00g/dL'
    },
    {
      name: 'transferrin',
      label: 'Transferrina (mg/dL)',
      value: transferrin,
      setState: setTransferrin,
      decimalScale: 1,
      suffix: 'mg',
      tooltipText:
        'Uso clínico: Índice prognóstico e monitorização + Meia vida: 8-10 dias',
      chip: transferrinChip,
      placeholder: 'Ex: 000,0mg/dL'
    },
    {
      name: 'preAlbumin',
      label: 'Pré-albumina (mg/dL)',
      value: preAlbumin,
      setState: setPreAlbumin,
      decimalScale: 1,
      suffix: 'mg',
      tooltipText:
        'Uso clínico: Monitorização e depleção aguda + Meia vida: 2-3 dias',
      chip: preAlbuminChip,
      placeholder: 'Ex: 00,0mg/dL'
    },
    {
      name: 'retinol',
      label: 'Proteína transportadora de retinol (mg/dL)',
      value: retinol,
      setState: setRetinol,
      decimalScale: 1,
      suffix: 'mg',
      tooltipText:
        'Uso clínico: Índice prognóstico de gravidade Meia vida: 12 horas',
      chip: retinolChip,
      placeholder: 'Ex: 0,0mg/dL'
    },
    {
      name: 'pcr',
      label: 'PCR (mg/dL)',
      value: pcr,
      setState: setPcr,
      decimalScale: 1,
      suffix: 'mg',
      chip: pcrChip,
      placeholder: 'Ex: 00,0mg/dL'
    }
  ];

  const bloodCountFields: FieldsType[] = [
    {
      name: 'hemoglobin',
      label: 'Hemoglobina (mg/dL)',
      value: hemoglobin,
      setState: setHemoglobin,
      decimalScale: 1,
      suffix: 'mg',
      chip: hemoglobinChip,
      placeholder: 'Ex: 00,0mg/dL'
    },
    {
      name: 'hematocrit',
      label: 'Hematócrito (%)',
      value: hematocrit,
      setState: setHematocrit,
      suffix: '%',
      tooltipText:
        'Uso clínico: Índice prognóstico e monitorização + Meia vida: 8-10 dias',
      chip: hematocritChip,
      decimalScale: 1,
      placeholder: 'Ex: 99%'
    },
    {
      name: 'lymphocite',
      label: 'Contagem total de linfócitos (mm³)',
      value: lymphocite,
      setState: setlymphocite,
      decimalScale: 1,
      suffix: 'mm³',
      chip: lymphociteChip,
      placeholder: 'Ex: 0000,0mm³'
    },
    {
      name: 'neutrophyl',
      label: 'Neutrófilos (mm³)',
      value: neutrophyl,
      setState: setNeutrophyl,
      decimalScale: 1,
      suffix: 'mg',
      chip: neutrophylChip,
      placeholder: 'Ex: 0000,0mm³'
    },
    {
      name: 'creatinine',
      label: 'Creatinina (mg/dL)',
      value: creatinine,
      setState: setCreatinine,
      decimalScale: 1,
      suffix: 'mg',
      chip: creatinineChip,
      placeholder: 'Ex: 0,0mg/dL'
    },
    {
      name: 'urea',
      label: 'Ureia (mg/dL)',
      value: urea,
      setState: setUrea,
      decimalScale: 1,
      suffix: 'mg',
      chip: ureaChip,
      placeholder: 'Ex: 000,0mg/dL'
    },
    {
      name: 'platelets',
      label: 'Plaquetas (uL)',
      value: platelets,
      setState: setPlatelets,
      decimalScale: 3,
      suffix: 'µL',
      chip: plateletsChip,
      placeholder: 'Ex: 000,000g/uL'
    },
    {
      name: 'leukocytes',
      label: 'Leucócitos (uL)',
      value: leukocytes,
      setState: setLeukocytes,
      decimalScale: 3,
      suffix: 'µL',
      chip: leukocytesChip,
      placeholder: 'Ex: 00,000g/uL'
    },
    {
      name: 'tgo',
      label: 'TGO (uL)',
      value: tgo,
      setState: setTgo,
      decimalScale: 2,
      suffix: 'µL',
      chip: tgoChip,
      placeholder: 'Ex: 000,00uL'
    },
    {
      name: 'tgp',
      label: 'TGP (uL)',
      value: tgp,
      setState: setTgp,
      decimalScale: 2,
      suffix: 'µL',
      chip: tgpChip,
      placeholder: 'Ex: 000,00uL'
    }
  ];

  function generateFields(fields: FieldsType[]) {
    const items = fields.map((item) => {
      return (
        <Box
          key={item.name}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Box>
              <FormControlLabel
                sx={{
                  color: item.value
                    ? theme.palette.info.main
                    : theme.palette.grey[900]
                }}
                control={
                  <Checkbox
                    color="info"
                    checked={
                      (getValues(item.name) !== undefined &&
                        getValues(item.name) !== null) ||
                      item.value
                        ? true
                        : false
                    }
                    value={item.value}
                    onChange={(event) =>
                      handleTurnInputOn(
                        item.setState,
                        item.name,
                        event.target.checked
                      )
                    }
                  />
                }
                label={item.label}
              />
              {item.tooltipText ? (
                <TooltipComponent content={item.tooltipText} />
              ) : (
                ''
              )}
            </Box>
            {item.value ||
            (getValues(item.name) !== undefined &&
              getValues(item.name) !== null) ? (
              <ChipComponent
                size="small"
                label={item.chip.text}
                color={item.chip.colors}
              />
            ) : (
              ''
            )}
          </Box>
          {item.value ||
          (getValues(item.name) !== undefined &&
            getValues(item.name) !== null) ? (
            <InputWithNumberMask
              autoFocus={item.value}
              suffix={item.suffix ? item.suffix : ''}
              decimalScale={item.decimalScale ? item.decimalScale : 0}
              sx={{ width: '45%' }}
              format={item.format}
              control={control}
              name={item.name}
              placeholder={item.placeholder}
              label="Dosagem:"
            />
          ) : (
            ''
          )}
        </Box>
      );
    });
    return items;
  }

  function setBNStateValue() {
    setBNValue(getValues('ingestedNitrogen') - getValues('excretedNitrogen'));
  }

  useEffect(() => {
    setBNStateValue();
  }, [watchIngestedNitrogen, watchExcretedNitrogen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function createBioparams(data: Bioparams) {
    if (!loadedBioparams) {
      const appointmentId = Number(localStorage.getItem('appointmentId'));
      await createBioparamsMutation({
        variables: {
          bioparams: { appointmentId, ...data }
        },
        onCompleted: () => {
          toast.success('Parâmetros bioquímicos criados com sucesso.');
          history.push('/home/nutritional-data-and-information');
        }
      });
    }
  }

  async function updateBioparams(data: Bioparams) {
    if (loadedBioparams) {
      const appointmentId = Number(localStorage.getItem('appointmentId'));
      await updateBioparamsMutation({
        variables: {
          bioparamsId: loadedBioparams.id,
          bioparams: { appointmentId, ...data }
        },
        onCompleted: () => {
          toast.info('Parâmetros bioquímicos atualizados.');
          history.push('/home/nutritional-data-and-information');
        }
      });
    }
  }

  useEffect(() => {
    loadPatient();
  }, []);

  function onSubmit(data: Bioparams) {
    try {
      createBioparams(data);
      updateBioparams(data);
    } catch {
      toast.error('Houve um erro');
    }
  }
  if (loading) return <CircularProgress />;
  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="75vh"
        >
          <Box>
            <Subtitle title="Proteínas circulantes" />
          </Box>
          {generateFields(proteinFields)}

          <Input
            name="additionalProteins"
            control={control}
            label="Exames adicionais"
            placeholder="Insira os exames adicionais"
          />
        </Box>

        <Box width="100%" margin="35px 0px"></Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="120vh"
        >
          <Box>
            <Subtitle title="Hemograma" />
          </Box>
          {generateFields(bloodCountFields)}

          <Input
            name="additionalBllod"
            control={control}
            label="Exames adicionais"
            placeholder="Insira os exames adicionais"
          />
        </Box>

        <Box width="100%" margin="35px 0"></Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="42vh"
        >
          <Box>
            <Subtitle title="Calcular balanço nitrogenado (BN)" />
          </Box>
          <Box marginTop="-5%" display="flex" justifyContent="space-between">
            <InputWithNumberMask
              decimalScale={2}
              placeholder="Ex: 000,00g"
              sx={{ width: '48%' }}
              suffix="g"
              control={control}
              name="ingestedNitrogen"
              label={
                <Box>
                  Valor do nitrogênio ingerido (g/dia):
                  <Tooltip
                    title="Ingestão proteica ingerida ou infundida (g) /6,25"
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <HelpOutlineRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />

            <InputWithNumberMask
              decimalScale={2}
              sx={{ width: '48%' }}
              suffix="g"
              name="excretedNitrogen"
              placeholder="Ex: 000,00g"
              control={control}
              label={
                <Box>
                  Valor do nitrogênio excretado (g/dia):
                  <Tooltip
                    title="Ureia urinária de 24h/g) x (volume urinário 24h/L) x 0,47 + 4 *
* fator de perda proteica em fezes, suor, ferimentos e fístulas"
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <HelpOutlineRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          </Box>
          <Chip
            sx={{
              marginTop: '-3%',
              width: '31%',
              background: theme.palette.info.light,
              fontWeight: '500'
            }}
            variant="outlined"
            size="small"
            color="info"
            label={`VALOR DO BN:${BNValue ? limitDecimal(BNValue) : 0} (g/dia)`}
          />

          <Input
            sx={{ marginTop: '-3%' }}
            name="additionalNitrogen"
            control={control}
            label="Exames adicionais"
            placeholder="Insira os exames adicionais"
          />
        </Box>

        <Box width="100%" display="flex" justifyContent="center">
          <Subtitle title="CLASSIFICAÇÃO BN" />
        </Box>

        <ClassificationTable
          rows={[
            {
              firstCellContent: '0 a -5',
              secondCellContent: 'Metabolismo normal',
              color: { colors: 'light-green' },
              selected: BNValue <= 0 && BNValue >= -5
            },
            {
              firstCellContent: '-5 a -10',
              secondCellContent: 'Hipermetabolismo leve ou nível de estresse 1',
              color: { colors: 'light-yellow' },
              selected: BNValue < -5 && BNValue >= -10
            },
            {
              firstCellContent: '-10 a -15',
              secondCellContent:
                'Hipermetabolismo moderado ou nível de estresse 2',
              color: { colors: 'light-orange' },
              selected: BNValue < -10 && BNValue >= -15
            },
            {
              firstCellContent: '< - 15',
              secondCellContent:
                'Hipermetabolismo grave ou nível de estresse 3',
              color: { colors: 'light-red' },
              selected: BNValue < -15
            }
          ]}
        />

        <Button sx={{ display: 'none' }} ref={ref} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(BiochemicalParametersForm);
