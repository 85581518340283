import { gql } from '@apollo/client';

export const FIND_ASG_BY_APPOINTMENT_ID = gql`
  query findNrAsg($appointmentId: Float!) {
    findNrAsgpppByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      currentWeight
      heightOver1m
      weight1Month
      weight6Month
      weightLastWeeks
      lastMonthFood
      currentFood
      normalFeed
      nausea
      constipation
      mucositis
      tasteless
      swallowProblem
      tiredness
      appetiteLack
      vomit
      diarrhea
      dryMouth
      quicklySatified
      smellsBother
      others
      pain
      activityLastMonth
    }
  }
`;
