import { gql } from '@apollo/client';

export const FIND_NE_MAN_BY_APPOINTMENT_ID = gql`
  query findNeManByAppointmentId($appointmentId: Float!) {
    findNeManByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      ingestDecrease
      weightLoss
      mobility
      stress
      neuroPsychological
      bodyMass
      ownHome
      threeMedicines
      eschar
      mealsByDay
      milkDerivatives
      vegetablesEggs
      meatFishBird
      fruits
      liquids
      wayEating
      nutritionalProblem
      ownHealth
      armCircumference
      lagCircumference
    }
  }
`;
