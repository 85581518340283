import { gql } from '@apollo/client';

export const FIND_TC_BY_APPOINTMENT_ID = gql`
  query findTcByAppointmentId($appointmentId: Float!) {
    findTcByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      skeletalMuscleMass
      adiposeTissue
    }
  }
`;
