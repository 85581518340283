import { Statistic } from 'models/dashboard';
import { parseDiagnosisResult } from './translateDiagnosis';

export const prepareDiagnosis = (diagnosis: Statistic[][]) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  let i = 1;
  for (const diag of diagnosis) {
    const data: any = {};
    data.id = i < 10 ? `C0${i}` : `C${i}`;

    if (diag) {
      for (const s of diag) {
        const name = parseDiagnosisResult(s.name);
        data[name] = s.quantity;
        ret.keys.add(name);
      }
    }

    ret.data.push(data);
    i++;
  }
  return ret;
};
