import { gql } from '@apollo/client';

const FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID = gql`
  query findNutritionalDataByAppointmentId($appointmentId: Float!) {
    findNutritionalDataByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      height
      weight
      habitualWeight
      weightLossTime
    }
  }
`;

export { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID };
