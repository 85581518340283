import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AdvanceFormFooter,
  MainContentContainer,
  ToggleButtonComponent
} from 'components';
import CPForm from './forms/cp';
import CBForm from './forms/cb';
import DCTForm from './forms/dct';
import CMBForm from './forms/cmb';
import AMBForm from './forms/amb';
import { Patient } from 'models/patient';
import { FIND_PATIENT_BY_ID } from 'services/graphql/queries/patient/getPatientById';
import { useLazyQuery, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { AnthropometricData } from 'models/anthropometric';
import { FIND_ANTHROPOMETRIC_BY_APPOINTMENT_ID } from 'services/graphql/queries/anthropometricEvaluation/findByAppointmentId';
import { calculatePatientAge } from 'utils/functions';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FindByAppointmentIdVar } from 'shared';

interface PatientQueryData {
  findPatient: Patient;
}

interface AnthropometricQueryData {
  findAntropometricByAppointmentId: AnthropometricData;
}

function AnthropometricEvaluation() {
  const history = useHistory();
  const patientId = Number(localStorage.getItem('patientId'));
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState('cp');
  const [toggleButtonSelectedValue, setToggleButtonSelectedValue] = useState(1);
  const {
    data: patientData,
    loading: loadingPatient,
    error: patientError
  } = useQuery<PatientQueryData>(FIND_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId
    }
  });
  const [
    loadAnthropometric,
    {
      data: antropometricData,
      error: antropometricError,
      loading: antropometricLoading
    }
  ] = useLazyQuery<AnthropometricQueryData, FindByAppointmentIdVar>(
    FIND_ANTHROPOMETRIC_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  function submitFormAndSetForm(form: string, selectedValue: number) {
    submitButtonRef.current?.click();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setForm(form);
      setToggleButtonSelectedValue(selectedValue);
      loadAnthropometric();
    }, 2000);
  }

  function switchForm() {
    switch (form) {
      case 'cp':
        submitFormAndSetForm('cb', 2);
        break;
      case 'cb':
        submitFormAndSetForm('dct', 3);
        break;
      case 'dct':
        submitFormAndSetForm('cmb', 4);
        break;
      case 'cmb':
        setForm('amb');
        setToggleButtonSelectedValue(5);
        break;
      case 'amb':
        submitForm();
    }
  }
  function submitForm() {
    submitButtonRef.current?.click();
  }

  const [lastAnthropometric, setLastAnthropometric] = useState<
    AnthropometricData | undefined
  >();
  const { data: lastAppointment } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentAnthropometric() {
    setLastAnthropometric(
      lastAppointment?.getLastPatientAppointment?.antropometric
    );
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    loadAnthropometric();
  }, []);

  useEffect(() => {
    if (lastAppointment?.getLastPatientAppointment?.antropometric)
      setHasLastAppointment(true);
  }, [lastAppointment]);

  const parsedAnthropometricData = useMemo(() => {
    if (
      antropometricData?.findAntropometricByAppointmentId &&
      !antropometricError
    ) {
      return antropometricData.findAntropometricByAppointmentId;
    }
  }, [antropometricData, antropometricError, antropometricLoading]);

  const parsedPatientData = useMemo(() => {
    if (patientData?.findPatient && !patientError) {
      const { birthdate, gender } = patientData.findPatient;
      const age = calculatePatientAge(birthdate);
      if (age > 64) {
        setForm('cp');
        setToggleButtonSelectedValue(1);
      }
      if (age < 64) {
        setForm('cb');
        setToggleButtonSelectedValue(2);
      }
      return { age, gender };
    }
    if (patientError) {
      toast.warn('Não foi possível carregar os dados do(a) paciente');
    }
    return { gender: '', age: 0 };
  }, [patientData, patientError]);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="9. Avaliação antropométrica"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: hasLastAppointment,
          action: lastAppointmentAnthropometric
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <ToggleButtonComponent
            selectedValue={toggleButtonSelectedValue}
            toggleButtonProps={[
              {
                title: 'CP',
                disabled: parsedPatientData?.age < 64,
                value: 1,
                action: () => {
                  setForm('cp');
                  setToggleButtonSelectedValue(1);
                }
              },
              {
                title: 'CB',
                value: 2,
                action: () => {
                  setForm('cb');
                  setToggleButtonSelectedValue(2);
                }
              },
              {
                title: 'DCT',
                value: 3,
                action: () => {
                  setForm('dct');
                  setToggleButtonSelectedValue(3);
                }
              },
              {
                title: 'CMB',
                value: 4,
                action: () => {
                  setForm('cmb');
                  setToggleButtonSelectedValue(4);
                }
              },
              {
                title: 'AMB',
                value: 5,
                action: () => {
                  setForm('amb');
                  setToggleButtonSelectedValue(5);
                }
              }
            ]}
          />
          {loadingPatient || antropometricLoading || loading ? (
            <CircularProgress />
          ) : (
            <>
              {form === 'cp' ? (
                <CPForm
                  lastCp={lastAnthropometric}
                  loadedCp={parsedAnthropometricData}
                  sex={parsedPatientData?.gender}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'cb' ? (
                <CBForm
                  lastCb={lastAnthropometric}
                  loadedCb={parsedAnthropometricData}
                  age={parsedPatientData?.age}
                  sex={parsedPatientData?.gender}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'dct' ? (
                <DCTForm
                  lastDct={lastAnthropometric}
                  loadedDct={parsedAnthropometricData}
                  age={parsedPatientData?.age}
                  sex={parsedPatientData?.gender}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'cmb' ? (
                <CMBForm
                  age={parsedPatientData?.age}
                  sex={parsedPatientData.gender}
                  cb={parsedAnthropometricData?.cb || lastAnthropometric?.cb}
                  dct={parsedAnthropometricData?.dct || lastAnthropometric?.dct}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'amb' ? (
                <AMBForm
                  age={parsedPatientData?.age}
                  sex={parsedPatientData?.gender}
                  cb={parsedAnthropometricData?.cb || lastAnthropometric?.cb}
                  dct={parsedAnthropometricData?.dct || lastAnthropometric?.dct}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/nutritional-evaluation')}
          action={() => switchForm()}
        />
      </Box>
    </Box>
  );
}

export { AnthropometricEvaluation };
