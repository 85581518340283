import { gql } from '@apollo/client';

export const UPDATE_NUTRITIONAL_INTERVENTION = gql`
  mutation updateIntervention(
    $nutritionalIntervention: NutritionalInterventionInput!
  ) {
    updateNutritionalIntervention(
      nutritionalIntervention: $nutritionalIntervention
    ) {
      id
    }
  }
`;
