import React from 'react';
import { Grid } from '@mui/material';
import { DashCard } from 'components/Dashboard';

export type CardsProps = {
  cardsData: {
    totalAppointments: number;
    totalMonth: number;
    totalIntervention: number;
    eutrophics: number;
    malnutritionRisk: number;
    malnourished: number;
    sarcopenia: number;
  };
};

export function Cards({ cardsData }: CardsProps) {
  return (
    <Grid
      justifyContent="center"
      container
      spacing={2}
      sx={{ flexGrow: 1 }}
      style={{ marginBottom: '30px' }}
    >
      <DashCard
        color="#008FBE"
        title="Quantidade de atendimentos"
        quant={cardsData.totalAppointments}
      />
      <DashCard
        color="#FF73C3"
        title="Total de atendimentos do mês"
        quant={cardsData.totalMonth}
      />
      <DashCard
        color="#FFBD14"
        title="Pacientes sob intervenção nutricional"
        quant={cardsData.totalIntervention}
      />
      <DashCard
        color="#008FBE"
        title="Quantidade pacientes Eutróficos"
        quant={cardsData.eutrophics}
      />
      <DashCard
        color="#FF73C3"
        title="Quant. pacientes Risco de Desnutrição"
        quant={cardsData.malnutritionRisk}
      />
      <DashCard
        color="#FFBD14"
        title="Quantidade pacientes Desnutridos"
        quant={cardsData.malnourished}
      />
      <DashCard
        color="#008FBE"
        title="Quantidade pacientes Sarcopênicos"
        quant={cardsData.sarcopenia}
      />
    </Grid>
  );
}
