import { gql } from '@apollo/client';

export const FIND_NUTRITIONAL_INTERVENTION_BY_APPOINTMENT_ID = gql`
  query createIntervention($appointmentId: Float!) {
    findNutritionalInterventionByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      usedWeight
      energyNeed
      calorificValue
      proteinNeed
      proteinValue
      foodIntake
      nutritionalTherapy
      tno
      tne
      therapyDosage
      TherapyProtein
      therapyCalorific
    }
  }
`;
