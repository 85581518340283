import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { Subtitle } from 'components/Subtitle';
import { theme } from 'styles/theme';
import { limitDecimal } from 'utils/functions/limitDecimal';
import { verifyWeightLossPercentage } from 'utils/functions/weightLossPercentage';
import { verifyIMCClassification } from 'utils/functions/verifyIMCClassification';
import { calculateIMC } from 'utils/functions/calculateIMC';
import { calculatePatientAge } from 'utils/functions/calculateLoadedPatientAge';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './editAppointment';
import { verifyWeightLossClassification } from '../../../NutritionalDiagnosisAndClassification/form/functions/renderNutritionalDataResult';

interface NutritionalDataProps {
  birthdate: Date;
  appointment: Appointment;
}

export function NutritionalData({
  appointment,
  birthdate
}: NutritionalDataProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const nutritionalData = appointment.NutritionalData;
  if (!nutritionalData)
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |6. Dados e informações nutricionais
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );

  const age = calculatePatientAge(birthdate);

  const tableRows = [
    {
      firstCell: 'Houve alteração de peso?',
      secondCell:
        nutritionalData.habitualWeight !== nutritionalData.weight
          ? 'Sim'
          : 'Não'
    },
    {
      firstCell: 'Valor total da perda de peso:',
      secondCell:
        nutritionalData.habitualWeight - nutritionalData.weight < 0
          ? '-'
          : limitDecimal(
              nutritionalData.habitualWeight - nutritionalData.weight
            ) || 0
    },
    {
      firstCell: 'Valor total de ganho de peso:',
      secondCell:
        nutritionalData.weight - nutritionalData.habitualWeight < 0
          ? '-'
          : limitDecimal(
              nutritionalData.weight - nutritionalData.habitualWeight
            ) || 0
    },
    {
      firstCell: 'Peso ideal:',
      secondCell: Math.round(Math.pow(nutritionalData.height, 2) * 21) || 0
    },
    {
      firstCell: 'IMC:',
      secondCell:
        calculateIMC(nutritionalData.height, nutritionalData.weight) || 0
    },
    {
      firstCell: 'Classificação IMC:',
      secondCell: verifyIMCClassification(
        calculateIMC(nutritionalData.height, nutritionalData.weight),
        age
      )
    },
    {
      firstCell: 'Percentual da perda de peso:',
      secondCell:
        verifyWeightLossPercentage(
          nutritionalData.weight,
          nutritionalData.habitualWeight
        ) + '%'
    },
    {
      firstCell: 'Classificação da perda de peso:',
      secondCell: verifyWeightLossClassification(
        verifyWeightLossPercentage(
          nutritionalData.weight,
          nutritionalData.habitualWeight
        ),
        nutritionalData.weightLossTime
      )
    }
  ];

  function generateWeightLossLabel(value: string) {
    switch (value) {
      case 'oneWeek':
        return '1 semana';
      case 'oneMonth':
        return '1 mês';
      case 'threeMonths':
        return '3 meses';
      case 'sixMonths':
        return '6 meses';
      case 'noWeightLoss':
        return 'Sem perda de peso';
    }
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |6. Dados e informações nutricionais
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-data-and-information')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box margin="15px 0" width="33%">
            <Label title="Altura:" />
            <ResultText title={`${nutritionalData.height} m`} />
          </Box>
          <Box margin="15px 0" width="33%">
            <Label title="Peso Atual:" />
            <ResultText title={`${nutritionalData.weight} kg`} />
          </Box>
          <Box margin="15px 0" width="33%">
            <Label title="Peso habitual:" />
            <ResultText title={`${nutritionalData.habitualWeight} m`} />
          </Box>
          <Box margin="15px 0" width="33%">
            <Label title="Tempo de perda de peso:" />
            <ResultText
              title={`${generateWeightLossLabel(
                nutritionalData.weightLossTime
              )}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="CALCULO DOS ÍNDICES:" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          padding="10px 20px"
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box width="100%">
            <TableContainer>
              <Table
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.divider
                }}
              >
                <TableBody
                  sx={{
                    height: '35px'
                  }}
                >
                  {tableRows.map(({ firstCell, secondCell }) => {
                    return (
                      <TableRow
                        key={firstCell}
                        sx={{
                          '&.MuiTableRow-root': {
                            minHeight: '45px'
                          }
                        }}
                      >
                        <TableCell
                          size="small"
                          sx={{
                            borderColor: theme.palette.divider,
                            color: theme.palette.grey[300]
                          }}
                          width="55%"
                        >
                          {firstCell}
                        </TableCell>
                        <TableCell
                          size="small"
                          sx={{
                            background: theme.palette.background.default,
                            border: '1px solid',
                            borderColor: theme.palette.divider,
                            color: theme.palette.grey[900],
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          {secondCell}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
