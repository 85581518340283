import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  AdvanceFormFooter,
  MainContentContainer,
  ToggleButtonComponent
} from 'components';
import BIAForm from './forms/bia';
import TCForm from './forms/tc';
import DXAForm from './forms/dxa';
import { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalData/findNutritionalDataByAppointmentId';
import { useLazyQuery, useQuery } from '@apollo/client';
import { NutritionalData } from 'models/nutritionalData';
import { toast } from 'react-toastify';
import { Bia } from 'models/bia';
import { FIND_BIA_BY_APPOINTMENT_ID } from 'services/graphql/queries/bodyCompositionEvaluation/findBiaByAppointmentId';
import { Tc } from 'models/tc';
import { FIND_TC_BY_APPOINTMENT_ID } from 'services/graphql/queries/bodyCompositionEvaluation/findTcByAppointmentId';
import { Dxa } from 'models/dxa';
import { FIND_DXA_BY_APPOINTMENT_ID } from 'services/graphql/queries/bodyCompositionEvaluation/findDxaByAppointmentId';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FindByAppointmentIdVar } from 'shared';

interface NutritionalDataQueryType {
  findNutritionalDataByAppointmentId: NutritionalData;
}

interface BiaQueryType {
  findBiaByAppointmentId: Bia;
}

interface TcQueryType {
  findTcByAppointmentId: Tc;
}

interface DxaQueryType {
  findDxaByAppointmentId: Dxa;
}

function BodyCompositionEvaluation() {
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [form, setForm] = useState('bia');
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);
  const [toggleButtonSelectedValue, setToggleButtonSelectedValue] = useState(1);

  const {
    data: nutritionalData,
    loading: loadingNutritionalData,
    error: nutritionalError
  } = useQuery<NutritionalDataQueryType, FindByAppointmentIdVar>(
    FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const [loadBIA, { data: dataBia, loading: loadingBia }] = useLazyQuery<
    BiaQueryType,
    FindByAppointmentIdVar
  >(FIND_BIA_BY_APPOINTMENT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      appointmentId
    }
  });

  const [loadTC, { data: dataTC, loading: loadingTC }] = useLazyQuery<
    TcQueryType,
    FindByAppointmentIdVar
  >(FIND_TC_BY_APPOINTMENT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      appointmentId
    }
  });

  const [loadDXA, { data: dataDXA, loading: loadingDXA }] = useLazyQuery<
    DxaQueryType,
    FindByAppointmentIdVar
  >(FIND_DXA_BY_APPOINTMENT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      appointmentId
    }
  });

  const parsedNutritionalData = useMemo(() => {
    if (nutritionalData?.findNutritionalDataByAppointmentId) {
      return nutritionalData.findNutritionalDataByAppointmentId;
    }
    if (nutritionalError) {
      toast.warning('Não foi possível carregar os dados nutricionais');
    }
  }, [nutritionalData, loadingNutritionalData]);

  const loadedBia = useMemo(() => {
    if (dataBia?.findBiaByAppointmentId) {
      return dataBia.findBiaByAppointmentId;
    }
  }, [dataBia, loadingBia]);

  const loadedTC = useMemo(() => {
    if (dataTC?.findTcByAppointmentId) {
      return dataTC.findTcByAppointmentId;
    }
  }, [dataTC, loadingTC]);

  const loadedDXA = useMemo(() => {
    if (dataDXA?.findDxaByAppointmentId) {
      return dataDXA.findDxaByAppointmentId;
    }
  }, [dataDXA, loadingDXA]);

  function submitFormAndSetForm(form: string, selectedValue: number) {
    submitButtonRef.current?.click();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setForm(form);
      setToggleButtonSelectedValue(selectedValue);
      loadBIA();
      loadTC();
      loadDXA();
    }, 2000);
  }

  function switchForm() {
    switch (form) {
      case 'bia':
        submitFormAndSetForm('tc', 2);
        break;
      case 'tc':
        submitFormAndSetForm('dxa', 3);
        break;
      case 'dxa':
        submitForm();
    }
  }
  function submitForm() {
    submitButtonRef.current?.click();
  }

  const [lastBia, setLastBia] = useState<Bia | undefined>();
  const [lastTc, setLastTc] = useState<Tc | undefined>();
  const [lastDxa, setLastDxa] = useState<Dxa | undefined>();

  const patientId = Number(localStorage.getItem('patientId'));
  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentBodyComp() {
    if (data?.getLastPatientAppointment?.bodyCompositionBia)
      setLastBia(data?.getLastPatientAppointment?.bodyCompositionBia);
    if (data?.getLastPatientAppointment?.bodyCompositionTc)
      setLastTc(data?.getLastPatientAppointment?.bodyCompositionTc);
    if (data?.getLastPatientAppointment?.bodyCompositionDxa)
      setLastDxa(data?.getLastPatientAppointment?.bodyCompositionDxa);
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.bodyCompositionBia)
      setHasLastAppointment(true);
    if (data?.getLastPatientAppointment?.bodyCompositionTc)
      setHasLastAppointment(true);
    if (data?.getLastPatientAppointment?.bodyCompositionDxa)
      setHasLastAppointment(true);
  }, [data]);

  useEffect(() => {
    loadBIA();
    loadTC();
    loadDXA();
  }, []);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="10. Avaliação da composição corporal"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: hasLastAppointment,
          action: lastAppointmentBodyComp
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <ToggleButtonComponent
            selectedValue={toggleButtonSelectedValue}
            toggleButtonProps={[
              {
                title: 'BIA',
                value: 1,
                action: () => {
                  setForm('bia');
                  setToggleButtonSelectedValue(1);
                }
              },
              {
                title: 'TC',
                value: 2,
                action: () => {
                  setForm('tc');
                  setToggleButtonSelectedValue(2);
                }
              },
              {
                title: 'DXA',
                value: 3,
                action: () => {
                  setForm('dxa');
                  setToggleButtonSelectedValue(3);
                }
              },
              {
                title: 'Nenhuma',
                value: 4,
                action: () => {
                  setForm('none');
                  setToggleButtonSelectedValue(4);
                }
              }
            ]}
          />
          {loading ||
          loadingBia ||
          loadingDXA ||
          loadingTC ||
          loadingNutritionalData ? (
            <CircularProgress />
          ) : (
            <>
              {form === 'bia' ? (
                <BIAForm
                  lastBia={lastBia}
                  loadedData={loadedBia}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'tc' ? (
                <TCForm
                  lastTc={lastTc}
                  loadedData={loadedTC}
                  height={parsedNutritionalData?.height || 0}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'dxa' ? (
                <DXAForm
                  lastDxa={lastDxa}
                  loadedData={loadedDXA}
                  height={parsedNutritionalData?.height || 0}
                  ref={submitButtonRef}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/anthropometric-evaluation')}
          action={() => {
            form === 'none'
              ? history.push('/home/strength-functionality-evaluation')
              : switchForm();
          }}
        />
      </Box>
    </Box>
  );
}

export { BodyCompositionEvaluation };
