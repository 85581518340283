import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { editAppointment } from './editAppointment';

interface NutritionalClassificationProps {
  appointment: Appointment;
}

export function renderParsedDiagnose(classification: string) {
  switch (classification) {
    case 'eutrophy':
      return 'Eutrofia';
      break;
    case 'malnutrition':
      return 'Desnutrição';
      break;
    case 'sarcopenia':
      return 'Sarcopenia';
      break;
    case 'obesity':
      return 'Obesidade';
      break;
    case 'sarcopenic obesity':
      return 'Obesidade sarcopênica';
      break;
    case 'myopenia':
      return 'Miopenia';
      break;
    case 'overweight':
      return 'Sobrepeso';
      break;
    case 'cachexia':
      return 'Caquexia';
      break;
    default:
      return '';
  }
}

export function NutritionalClassification({
  appointment
}: NutritionalClassificationProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const NutritionalClassification = appointment.diagnosis;

  if (!NutritionalClassification) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |12. Classificação e diagnóstico nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |12. Classificação e diagnóstico nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-diagnosis-and-classification')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title="Diagnóstico nutricional do paciente:" />
            <ResultText
              title={`${renderParsedDiagnose(NutritionalClassification)}`}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
