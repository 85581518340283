import { gql } from '@apollo/client';

export const CREATE_DXA = gql`
  mutation createDxa($dxa: DxaInput!) {
    createDxa(dxa: $dxa) {
      appointmentId
      id
    }
  }
`;
