import { gql } from '@apollo/client';

const FIND_BIOPARAMS_BY_APPOINTMENT_ID = gql`
  query findBioparamsByAppointmentId($appointmentId: Float!) {
    findBioparamsByAppointmentId(appointmentId: $appointmentId) {
      id
      albumin
      transferrin
      preAlbumin
      retinol
      pcr
      additionalProteins
      hemoglobin
      hematocrit
      lymphocite
      neutrophyl
      creatinine
      urea
      platelets
      leukocytes
      tgo
      tgp
      additionalBllod
      ingestedNitrogen
      excretedNitrogen
      additionalNitrogen
    }
  }
`;

export { FIND_BIOPARAMS_BY_APPOINTMENT_ID };
