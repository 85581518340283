export function parseDiagnosisResult(value: string): string {
  switch (value) {
    case 'eutrophy':
      return 'Eutrofia';
      break;
    case 'malnutrition':
      return 'Desnutrição';
      break;
    case 'sarcopenia':
      return 'Sarcopenia';
      break;
    case 'obesity':
      return 'Obesidade';
      break;
    case 'sarcopenic obesity':
      return 'Obes sarcop';
      break;
    case 'myopenia':
      return 'Miopenia';
      break;
    case 'overweight':
      return 'Sobrepeso';
      break;
    case 'cachexia':
      return 'Caquexia';
    default:
      return 'Sem dados';
  }
}
