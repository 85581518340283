import React from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { theme } from 'styles/theme';

interface IMuiDropdownComponentProps extends Pick<SelectProps, 'sx' | 'label'> {
  control: Control<any>;
  name: string;
  select: SelectItemType[];
}

interface SelectItemType {
  label: string;
  value: string;
}

function DropdownInputComponent({
  control,
  name,
  select,
  label,
  ...props
}: IMuiDropdownComponentProps) {
  function generateSelectOptions() {
    return select.map(({ label, value }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  }
  return (
    <FormControl {...props}>
      <FormLabel sx={{ fontSize: '14px', color: theme.palette.grey[900] }}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Select
              error={!!error}
              size="small"
              sx={{ color: theme.palette.grey[400] }}
              variant="standard"
              onChange={onChange}
              value={value}
            >
              {generateSelectOptions()}
            </Select>
            <FormHelperText sx={{ color: theme.palette.error.main }}>
              {error ? error.message : null}
            </FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}

export { DropdownInputComponent };
