import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { colors } from 'styles/graphColors';

export type PieData = {
  id: string;
  label: string;
  value: number;
};

type PieProps = {
  data: PieData[];
};

export function PieChart({ data }: PieProps) {
  // const colors = shuffleColors();

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 45, right: 0, bottom: 45, left: -150 }}
      innerRadius={0}
      padAngle={0.7}
      cornerRadius={1}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={colors}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]]
      }}
      enableArcLinkLabels={false}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: -60,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 24,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    />
  );
}
