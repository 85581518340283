import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AdvanceFormFooter, MainContentContainer } from 'components';
import { FullAppointment } from 'models/fullAppointment';
import { NutritionalIntervention as NutritionalInterventionInterface } from 'models/nutritionalIntervention';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import NutritionalInterventionForm from './form/index';

function NutritionalIntervention() {
  const history = useHistory();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const patientId = Number(localStorage.getItem('patientId'));

  const [lastIntervention, setLastIntervention] = useState<
    NutritionalInterventionInterface | undefined
  >();

  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentIntervention() {
    if (data?.getLastPatientAppointment.strengthFunctionality)
      setLastIntervention(
        data?.getLastPatientAppointment.nutritionalIntervention
      );
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.nutritionalIntervention)
      setHasLastAppointment(true);
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="13. Intervenção nutricional"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: hasLastAppointment,
          action: lastAppointmentIntervention
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <NutritionalInterventionForm
            lastIntervention={lastIntervention}
            ref={submitButtonRef}
          />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          buttonTitle="Concluir"
          back={() =>
            history.push('/home/nutritional-diagnosis-and-classification')
          }
          action={() => submitButtonRef.current?.click()}
        />
      </Box>
    </Box>
  );
}

export { NutritionalIntervention };
