import React, {
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  parseCIDNameByCode,
  parseTreatmentsNames,
  urinaryHabitFrequency,
  waterIntakeFrequency,
  weeklyMonthlyAndDailyFrequency,
  stagings,
  generateStagingLabel,
  cid,
  treatments
} from 'utils/constants';
import {
  TooltipComponent,
  DropdownInputComponent,
  RadioButtonInput,
  Subtitle,
  AutoCompleteComponent,
  AutocompleteOptionType,
  ArrayInputComponent
} from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_DISEASE,
  UPDATE_DISEASE,
  CreateDiseaseReturn,
  CreateDiseaseVars,
  UpdateDiseaseVars
} from 'services/graphql/mutations/diseaseData';
import { toast } from 'react-toastify';
import {
  FIND_DISEASE_DATA_BY_APPOINTMENT_ID,
  FindDiseaseType
} from 'services/graphql/queries/diseaseData';
import { DiseaseData } from 'models/diseaseData';
import {
  IFormInput,
  othersType,
  defaultValues,
  othersInput
} from './constsAndInterfaces';
import { validationSchema } from './schema';
import { FindByAppointmentIdVar } from 'shared';

const DiseaseDataForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastDisease: DiseaseData | undefined }
> = ({ lastDisease }, ref) => {
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createDiseaseMutation] = useMutation<
    CreateDiseaseReturn,
    CreateDiseaseVars
  >(CREATE_DISEASE);

  const [updateDiseaseMutation] = useMutation<
    CreateDiseaseReturn,
    UpdateDiseaseVars
  >(UPDATE_DISEASE);

  const { data, loading, error } = useQuery<
    FindDiseaseType,
    FindByAppointmentIdVar
  >(FIND_DISEASE_DATA_BY_APPOINTMENT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      appointmentId
    }
  });

  const [cidChips, setCidChips] = useState<AutocompleteOptionType[]>([]);
  const [treatmentChips, setTreatmentChips] = useState<
    AutocompleteOptionType[]
  >([]);
  const [intolerancesInput, setIntolerancesInput] = useState<othersInput[]>([]);
  const [preExistentDiseasesInput, setPreExistentDiseasesInput] = useState<
    othersInput[]
  >([]);
  const [allergiesInput, setAllergiesInput] = useState<othersInput[]>([]);
  const [familyBackgroundInput, setFamilyBackgroundInput] = useState<
    othersInput[]
  >([]);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const watchSmoking = watch('smoking', defaultValues.smoking);
  const watchAlcoholism = watch('alcoholism', defaultValues.alcoholism);

  function setCIDValue(data: AutocompleteOptionType[]) {
    const stagings = getValues('cids');
    const codes = data.map((data) => {
      return {
        code: data.value,
        staging:
          stagings.find((staging) => staging.code === data.value)?.staging || ''
      };
    });
    setValue(`cids`, codes);
    setCidChips(data);
  }

  function addOthers(data: string[], type: othersType) {
    const parsedData = data.map((data) => {
      return { description: data, type };
    });
    switch (type) {
      case 'ALLERGIES':
        setAllergiesInput(parsedData);
        break;
      case 'FAMILY_BACKGROUND':
        setFamilyBackgroundInput(parsedData);
        break;
      case 'INTOLERANCES':
        setIntolerancesInput(parsedData);
        break;
      case 'PRE_EXISTENT':
        setPreExistentDiseasesInput(parsedData);
    }
  }

  function removeOthers(
    dataToDelete: string,
    stateToUpdate: othersInput[],
    setStateToUpdate: React.Dispatch<SetStateAction<othersInput[]>>
  ) {
    setStateToUpdate(
      stateToUpdate.filter((data) => data.description !== dataToDelete)
    );
  }

  useEffect(() => {
    const allOthers = [
      ...intolerancesInput,
      ...familyBackgroundInput,
      ...allergiesInput,
      ...preExistentDiseasesInput
    ];
    setValue('others', allOthers);
  }, [
    intolerancesInput,
    familyBackgroundInput,
    allergiesInput,
    preExistentDiseasesInput
  ]);

  function setTreatmentInputValue(data: AutocompleteOptionType[]) {
    const stagings = data.map((value) => {
      return { code: value.value };
    });
    setValue(`treatments`, stagings);
  }

  function setStagingInputValue(
    data: AutocompleteOptionType[],
    stagingIndex: number
  ) {
    const stagings = data.map((value) => {
      return value.value;
    });
    setValue(`cids.${stagingIndex}.staging`, stagings.join(';'));
  }

  const loadedDiseaseData = useMemo<DiseaseData | undefined>(() => {
    if (data && !error) {
      setLoadedValuesToFields(data?.findDisease);
      return data.findDisease;
    }
    return;
  }, [data, error]);

  function setLoadedStagingValues(
    cidCode: string,
    diseaseData: DiseaseData | undefined
  ) {
    if (!diseaseData) return;
    const filteredStaging = diseaseData.cids.filter(
      (cid) => cid.code === cidCode
    );
    const stagings = filteredStaging.map((cid) => {
      return {
        label: generateStagingLabel(cid.staging) || '',
        value: cid.staging.split(';')
      };
    });
    let stagingLabels: { label: string }[] = [];
    let stagingValues: { value: string }[] = [];
    stagings.forEach(({ label, value }) => {
      const labels = label.map((label) => {
        return { label: label || '' };
      });
      const values = value.map((value) => {
        return { value: value || '' };
      });
      stagingLabels = labels;
      stagingValues = values;
    });

    const result = stagingLabels.map(({ label }, index) => ({
      label,
      value: stagingValues[index].value
    }));
    return result;
  }

  function setLoadedValuesToFields(disease: DiseaseData | undefined) {
    if (!disease) return;
    const rightOthers = disease.others.map(({ description, type }) => ({
      type,
      description
    }));
    const allergies = rightOthers.filter((item) => item.type === 'ALLERGIES');
    const preDiseases = rightOthers.filter(
      (item) => item.type === 'PRE_EXISTENT'
    );
    const familyBackground = rightOthers.filter(
      (item) => item.type === 'FAMILY_BACKGROUND'
    );
    const intolerances = rightOthers.filter(
      (item) => item.type === 'INTOLERANCES'
    );
    setIntolerancesInput(intolerances);
    setPreExistentDiseasesInput(preDiseases);
    setAllergiesInput(allergies);
    setFamilyBackgroundInput(familyBackground);
    setValue(
      'cids',
      disease.cids.map(({ code, staging }) => ({ code, staging }))
    );
    setValue(
      'treatments',
      disease.treatments.map(({ code }) => ({ code }))
    );
    const cids = disease.cids.map((cid) => {
      return { label: parseCIDNameByCode(cid.code), value: cid.code };
    });
    const treatments = disease.treatments.map((treatment) => {
      return {
        label: parseTreatmentsNames(treatment.code),
        value: treatment.code
      };
    });
    setValue('alcoholism', disease.alcoholism ? 'yes' : 'no');
    setValue('urinaryHabit', disease.urinaryHabit);
    setValue('waterIntake', disease.waterIntake);
    setValue('smoking', disease.smoking ? 'yes' : 'no');
    setValue('bowelHabit', disease.bowelHabit);
    setCidChips(cids);
    setTreatmentChips(treatments);
  }

  useEffect(() => {
    setLoadedValuesToFields(lastDisease);
  }, [lastDisease]);

  async function createDisease(disease: IFormInput) {
    if (loadedDiseaseData) return;
    await createDiseaseMutation({
      variables: {
        disease: {
          appointmentId,
          ...disease,
          alcoholism: disease.alcoholism === 'yes' ? true : false,
          smoking: disease.smoking === 'yes' ? true : false
        }
      },
      onCompleted: () => {
        toast.success('Dados da doença criados.');
        history.push('/home/symptons');
      }
    });
  }
  async function updateDisease(disease: IFormInput) {
    if (!loadedDiseaseData) return;
    await updateDiseaseMutation({
      variables: {
        diseaseData: {
          appointmentId,
          ...disease,
          alcoholism: disease.alcoholism === 'yes' ? true : false,
          smoking: disease.smoking === 'yes' ? true : false
        }
      },
      onCompleted: () => {
        toast.info('Dados da doença atualizados.');
        history.push('/home/symptons');
      }
    });
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      createDisease(data);
      updateDisease(data);
    } catch {
      toast.error('Houve um erro.');
    }
  };
  if (loading) return <CircularProgress />;
  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          minHeight="170vh"
        >
          <AutoCompleteComponent
            loadedValues={cidChips}
            errorFromForm={errors.cids}
            chipsHeader={`CID's adicionados:`}
            label={
              <Box display="flex">
                Pesquisar por CID da Neoplasia (considerando palavra chave ou
                número)<Typography color="error.main">*</Typography>
              </Box>
            }
            actionOnChange={(data) => setCIDValue(data)}
            options={cid}
            placeholder="Insira o CID"
          />

          <Divider />
          <Typography fontWeight="500" color="grey.900">
            É possível adicionar mais de uma opção a cada item:
          </Typography>
          <ArrayInputComponent
            label="Doenças pré-existentes:"
            placeholder="Digite aqui"
            subtitle="Doenças adicionadas:"
            actionOnAdd={(data) => addOthers(data, 'PRE_EXISTENT')}
            actionOnDelete={(dataToDelete) =>
              removeOthers(
                dataToDelete,
                preExistentDiseasesInput,
                setPreExistentDiseasesInput
              )
            }
            specialFieldData={preExistentDiseasesInput}
          />
          <Divider />

          <ArrayInputComponent
            label="Antecedentes familiares:"
            placeholder="Digite aqui"
            subtitle="Antecedentes adicionados:"
            actionOnDelete={(dataToDelete) =>
              removeOthers(
                dataToDelete,
                familyBackgroundInput,
                setFamilyBackgroundInput
              )
            }
            actionOnAdd={(data) => addOthers(data, 'FAMILY_BACKGROUND')}
            specialFieldData={familyBackgroundInput}
          />
          <Divider />

          <ArrayInputComponent
            label="Alergias:"
            placeholder="Digite aqui"
            subtitle="Alergias adicionadas:"
            actionOnAdd={(data) => addOthers(data, 'ALLERGIES')}
            actionOnDelete={(dataToDelete) =>
              removeOthers(dataToDelete, allergiesInput, setAllergiesInput)
            }
            specialFieldData={allergiesInput}
          />
          <Divider />

          <ArrayInputComponent
            label="Intolerâncias:"
            placeholder="Digite aqui"
            subtitle="Intolerâncias adicionados:"
            actionOnDelete={(dataToDelete) =>
              removeOthers(
                dataToDelete,
                intolerancesInput,
                setIntolerancesInput
              )
            }
            actionOnAdd={(data) => addOthers(data, 'INTOLERANCES')}
            specialFieldData={intolerancesInput}
          />

          <Divider />

          <Box
            height="8vh"
            alignItems="center"
            display="grid"
            gridTemplateColumns="1fr 1fr"
          >
            <Box width="50%">
              <RadioButtonInput
                title="Tabagismo?:"
                disabled={false}
                control={control}
                name="smoking"
                radioButtons={[
                  {
                    label: 'Sim',
                    value: 'yes',
                    checked: watchSmoking == 'yes'
                  },
                  { label: 'Não', value: 'no', checked: watchSmoking == 'no' }
                ]}
              />
            </Box>

            <Box width="50%">
              <RadioButtonInput
                title="Etilismo?:"
                disabled={false}
                control={control}
                name="alcoholism"
                radioButtons={[
                  {
                    label: 'Sim',
                    value: 'yes',
                    checked: watchAlcoholism == 'yes'
                  },
                  {
                    label: 'Não',
                    value: 'no',
                    checked: watchAlcoholism == 'no'
                  }
                ]}
              />
            </Box>
          </Box>

          <Divider />

          <Box
            height="15vh"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <DropdownInputComponent
              label="Hábito intestinal:"
              sx={{ width: '30%' }}
              control={control}
              name="bowelHabit"
              select={weeklyMonthlyAndDailyFrequency.map(({ label, value }) => {
                return { label, value };
              })}
            />
            <DropdownInputComponent
              label="Hábito urinário:"
              sx={{ width: '30%' }}
              control={control}
              name="urinaryHabit"
              select={urinaryHabitFrequency.map(({ label, value }) => {
                return { label, value };
              })}
            />
            <DropdownInputComponent
              label="Ingestão hídrica:"
              sx={{ width: '30%' }}
              control={control}
              name="waterIntake"
              select={waterIntakeFrequency.map(({ label, value }) => {
                return { label, value };
              })}
            />
          </Box>

          <Divider />

          <Box
            width="100%"
            height="50px"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography fontSize="18px" fontWeight="500" color="grey.400">
              Estadiamento
            </Typography>
            <TooltipComponent content="subclassificação do CID – sistema de classificação TNM (tumores malignos)" />
          </Box>

          {cidChips[0]
            ? cidChips.map(({ label, value }, index) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={value}
                  >
                    <Typography width="50%" fontSize="16px" color="grey.900">
                      {label}
                    </Typography>
                    <Box width="50%">
                      <AutoCompleteComponent
                        loadedValues={setLoadedStagingValues?.(
                          value,
                          loadedDiseaseData || lastDisease
                        )}
                        errorFromForm={errors.cids}
                        chipsHeader={`Estadiamentos adicionados:`}
                        label={
                          <Box display="flex">
                            Pesquisar estadiamento
                            <Typography color="error.main">*</Typography>:
                          </Box>
                        }
                        options={stagings}
                        placeholder="Insira o estadiamento"
                        actionOnChange={(data) =>
                          setStagingInputValue(data, index)
                        }
                      />
                    </Box>
                  </Box>
                );
              })
            : ''}
          <Divider />

          <Subtitle title="Tratamento" />
          <Typography fontWeight="500" color="grey.900">
            É possível adicionar mais de uma opção a cada item:
          </Typography>
          <AutoCompleteComponent
            loadedValues={treatmentChips}
            errorFromForm={errors.treatments}
            chipsHeader="Tratamentos adicionados:"
            label={
              <Box display="flex">
                Tratamento Anti-neoplástico
                <Typography color="error.main">*</Typography>:
              </Box>
            }
            options={treatments}
            placeholder="Insira o tratamento"
            actionOnChange={(data) => setTreatmentInputValue(data)}
          />
          <Button sx={{ display: 'none' }} ref={ref} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(DiseaseDataForm);
