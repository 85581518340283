import { Appointment } from 'models/appointment';

export const prepareBiochemParam = (appointments: Appointment[]) => {
  return {
    albumin: [
      { points: appointments.map((a) => a.bioParams?.albumin || null) }
    ],
    transferrin: [
      { points: appointments.map((a) => a.bioParams?.transferrin || null) }
    ],
    prealbumin: [
      { points: appointments.map((a) => a.bioParams?.preAlbumin || null) }
    ],
    leukocytes: [
      { points: appointments.map((a) => a.bioParams?.leukocytes || null) }
    ],
    platelets: [
      { points: appointments.map((a) => a.bioParams?.platelets || null) }
    ],
    neutrophyl: [
      { points: appointments.map((a) => a.bioParams?.neutrophyl || null) }
    ],
    pcr: [{ points: appointments.map((a) => a.bioParams?.pcr || null) }]
  };
};
