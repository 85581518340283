import { Result } from '..';
import { FullAppointment } from 'models/fullAppointment';
import {
  calculateAMBValue,
  calculateManAMBPercentil,
  calculateWomanAMBPercentil
} from 'pages/AnthropometricEvaluation/forms/amb';
import {
  calculateManArmCircumferencePercentil,
  calculateWomanArmCircumferencePercentil
} from 'pages/AnthropometricEvaluation/forms/cb';
import {
  calculateCMBTextResult,
  calculateCMBValue,
  calculateManCMBPercentil,
  calculateWomanCMBPercentil
} from 'pages/AnthropometricEvaluation/forms/cmb';
import {
  calculateManDCTPercentil,
  calculateWomanDCTPercentil
} from 'pages/AnthropometricEvaluation/forms/dct';

export function generateAnthropometricEvaluationCPResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string
): Result {
  if (!loadedAppointment?.antropometric) {
    return {
      contentTitle: 'CP',
      content: 'Não há dados para exibir'
    };
  }
  if (loadedAppointment?.antropometric.cp && sex === 'MALE') {
    return {
      contentTitle: 'CP',
      content: loadedAppointment?.antropometric.cp !== 34 ? 'Anormal' : 'Normal'
    };
  }
  if (loadedAppointment?.antropometric.cp && sex === 'FEMALE') {
    return {
      contentTitle: 'CP',
      content: loadedAppointment?.antropometric.cp !== 33 ? 'Anormal' : 'Normal'
    };
  }
  return {
    contentTitle: 'CP',
    content: 'Não há dados para exibir'
  };
}

function CBorDCTResults(result: number) {
  if (result < 70) return 'Desnutrição grave';
  if (result >= 70 && result < 80) return 'Desnutrição moderada';
  if (result >= 80 && result < 90) return 'Desnutrição leve';
  if (result >= 90 && result <= 110) return 'Eutrofia';
  if (result > 110 && result < 120) return 'Sobrepeso';
  if (result >= 120) return 'Obesidade';
}

export function generateAnthropometricEvaluationCBResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string,
  age: number
): Result {
  if (!loadedAppointment?.antropometric) {
    return {
      contentTitle: 'CB',
      content: 'Não há dados para exibir'
    };
  }
  if (loadedAppointment?.antropometric.cb) {
    const CBPercentil =
      sex === 'MALE'
        ? calculateManArmCircumferencePercentil(sex, age)
        : calculateWomanArmCircumferencePercentil(sex, age);
    const result = (loadedAppointment?.antropometric.cb / CBPercentil) * 100;
    return {
      contentTitle: 'CB',
      content: CBorDCTResults(result)
    };
  }
  return {
    contentTitle: 'CB',
    content: 'Não há dados para exibir'
  };
}

export function generateAnthropometricEvaluationDCTResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string,
  age: number
): Result {
  if (!loadedAppointment?.antropometric) {
    return {
      contentTitle: 'DCT',
      content: 'Não há dados para exibir'
    };
  }
  if (loadedAppointment?.antropometric.dct) {
    const DCTPercentil =
      sex === 'MALE'
        ? calculateManDCTPercentil(sex, age)
        : calculateWomanDCTPercentil(sex, age);
    const result = (loadedAppointment.antropometric.dct / DCTPercentil) * 100;
    return {
      contentTitle: 'DCT',
      content: CBorDCTResults(result)
    };
  }
  return {
    contentTitle: 'DCT',
    content: 'Não há dados para exibir'
  };
}
export function generateAnthropometricEvaluationCMBResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string,
  age: number
): Result {
  if (loadedAppointment?.antropometric) {
    const { dct, cb } = loadedAppointment?.antropometric;
    const CMBPercentil =
      sex === 'MALE'
        ? calculateManCMBPercentil(sex, age)
        : calculateWomanCMBPercentil(sex, age);
    const result = (calculateCMBValue(cb || 0, dct || 0) / CMBPercentil) * 100;
    return {
      contentTitle: 'CMB',
      content: calculateCMBTextResult(result)
    };
  }
  return {
    contentTitle: 'CMB',
    content: 'Não há dados para exibir'
  };
}
export function generateAnthropometricEvaluationAMBResults(
  loadedAppointment: FullAppointment | undefined,
  sex: string,
  age: number
): Result {
  if (loadedAppointment?.antropometric) {
    const { dct, cb } = loadedAppointment?.antropometric;
    const AMBPercentil =
      sex === 'MALE'
        ? calculateManAMBPercentil(sex, age)
        : calculateWomanAMBPercentil(sex, age);
    const result =
      (calculateAMBValue(sex, cb || 0, dct || 0) / AMBPercentil) * 100;
    return {
      contentTitle: 'AMB',
      content: calculateCMBTextResult(result)
    };
  }
  return {
    contentTitle: 'AMB',
    content: 'Não há dados para exibir'
  };
}
