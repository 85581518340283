import { gql } from '@apollo/client';

export const UPDATE_STRENGHT_AND_FUNCTIONALITY = gql`
  mutation updateStrenghtAndFunctionality(
    $strengthFunctionality: StrengthFuncionalityInput!
  ) {
    updateStrengthFunctionality(strengthFunctionality: $strengthFunctionality) {
      id
      appointmentId
    }
  }
`;
