import { gql } from '@apollo/client';

export const FIND_NE_AGS_BY_APPOINTMENT_ID = gql`
  query findAGS($appointmentId: Float!) {
    findNeAgsByAppointmentId(appointmentId: $appointmentId) {
      id
      lost6Mont
      wheighthangeLastWeeks
      changeIngestion
      changeIngDuration
      changeIngType
      noGastroSymptoms
      nausea
      vomit
      diarrhea
      anorexia
      functionalCapacity
      functionalCapDuration
      functionalCapType
      primaryDiagnosis
      metabolicDemand
      fatLoss
      muscleLoss
      ankleEdema
      sacralEdema
      ascites
      diseaseChangeLastWeeks
      subjectiveEvaluation
      appointmentId
    }
  }
`;
