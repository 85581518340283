import { gql } from '@apollo/client';

export const UPDATE_NE_ASGPPP = gql`
  mutation updateNeASGPPP($neAsgppp: NeAsgpppInput!) {
    updateNeAsgppp(neAsgppp: $neAsgppp) {
      id
      appointmentId
    }
  }
`;
