import React, { useState } from 'react';
import { Box, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { NeASG as AsgInterface } from 'models/neAsg';
import { ChipComponent } from 'components/Chip';
import { Subtitle } from 'components/Subtitle';
import { verifyWeightLossPercentage } from 'utils/functions/weightLossPercentage';
import { IColors } from 'shared/colors';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface AsgProps {
  appointment: Appointment;
}

type AsgKeys =
  | 'currentWeight'
  | 'heightOver1m'
  | 'weight1Month'
  | 'weight6Month'
  | 'weightLastWeeks'
  | 'lastMonthFood'
  | 'currentFood'
  | 'normalFeed'
  | 'nausea'
  | 'constipation'
  | 'mucositis'
  | 'tasteless'
  | 'swallowProblem'
  | 'tiredness'
  | 'appetiteLack'
  | 'vomit'
  | 'diarrhea'
  | 'dryMouth'
  | 'quicklySatified'
  | 'smellsBother'
  | 'pain'
  | 'others'
  | 'activityLastMonth'
  | 'weight1MonthSec'
  | 'weight6MonthSec'
  | 'relevantDiagnosis'
  | 'primaryStaging'
  | 'primaryStagingOther'
  | 'cancer'
  | 'cachexia'
  | 'trauma'
  | 'renalInsufficiency'
  | 'aids'
  | 'decubitusUlcer'
  | 'moreThan65'
  | 'corporalTemperature'
  | 'fever'
  | 'feverDuration'
  | 'corticosteroids'
  | 'temple'
  | 'clavicle'
  | 'shoulder'
  | 'hands'
  | 'shoulderBlade'
  | 'thigh'
  | 'calf'
  | 'muscleGeneralState'
  | 'periorbital'
  | 'triceps'
  | 'lowerRibs'
  | 'fatDeficitGeneralState'
  | 'ankle'
  | 'sacralEdema'
  | 'hidratationGeneralState'
  | 'globalCategory';

interface AsgObjectKey {
  key: AsgKeys;
}

const weightASGQuestions: AsgObjectKey[] = [
  { key: 'currentWeight' },
  { key: 'heightOver1m' },
  { key: 'weight1Month' },
  { key: 'weight6Month' },
  { key: 'weightLastWeeks' }
];

const foodASGQuestions: AsgObjectKey[] = [
  { key: 'lastMonthFood' },
  { key: 'currentFood' }
];

const symptomsASGQuestions: AsgObjectKey[] = [
  { key: 'normalFeed' },
  { key: 'nausea' },
  { key: 'constipation' },
  { key: 'mucositis' },
  { key: 'tasteless' },
  { key: 'swallowProblem' },
  { key: 'tiredness' },
  { key: 'appetiteLack' },
  { key: 'vomit' },
  { key: 'diarrhea' },
  { key: 'dryMouth' },
  { key: 'quicklySatified' },
  { key: 'smellsBother' }
];
const secondWeightQuestion: AsgObjectKey[] = [
  { key: 'weight1Month' },
  { key: 'weight6Month' }
];

const diseasesNutritionalRelation: AsgObjectKey[] = [
  { key: 'relevantDiagnosis' },
  { key: 'primaryStaging' },
  { key: 'primaryStagingOther' }
];

const diseasesNutritionalRelationConditions: AsgObjectKey[] = [
  { key: 'cancer' },
  { key: 'cachexia' },
  { key: 'trauma' },
  { key: 'renalInsufficiency' },
  { key: 'aids' },
  { key: 'decubitusUlcer' },
  { key: 'moreThan65' }
];

const metabolicDemand: AsgObjectKey[] = [
  { key: 'corporalTemperature' },
  { key: 'fever' },
  { key: 'feverDuration' },
  { key: 'corticosteroids' }
];

const muscularState: AsgObjectKey[] = [
  { key: 'temple' },
  { key: 'clavicle' },
  { key: 'shoulder' },
  { key: 'hands' },
  { key: 'shoulderBlade' },
  { key: 'thigh' },
  { key: 'calf' },
  { key: 'muscleGeneralState' }
];

const fatState: AsgObjectKey[] = [
  { key: 'periorbital' },
  { key: 'triceps' },
  { key: 'lowerRibs' },
  { key: 'fatDeficitGeneralState' }
];

const hidratationState: AsgObjectKey[] = [
  { key: 'ankle' },
  { key: 'sacralEdema' },
  { key: 'hidratationGeneralState' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newAsgType = MappedType<AsgInterface>;

export function NeAsg({ appointment }: AsgProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Asg: newAsgType = appointment.neAsgppp;
  if (!Asg) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |8. Avaliação nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedAsgQuestion(value: string) {
    switch (value) {
      case 'currentWeight':
        return 'Eu atualmente peso aproximadamente:';
      case 'heightOver1m':
        return 'Eu tenho aproximadamente 1 metro e:';
      case 'weight1Month':
        return 'Há 1 mês eu costumava pesar:';
      case 'weight6Month':
        return 'Há 6 meses eu costumava pesar:';
      case 'weightLastWeeks':
        return 'Durante as duas últimas semanas o meu peso:';
      case 'lastMonthFood':
        return 'Comparada com minha alimentação habitual, no último mês, eu tenho comido:';
      case 'currentFood':
        return 'Atualmente, eu estou comendo:';
      case 'normalFeed':
        return 'sem problemas para me alimentar (0)';
      case 'nausea':
        return 'náuseas (enjoos) (1)';
      case 'constipation':
        return 'obstipação (intestino preso) (1)';
      case 'mucositis':
        return 'feridas na boca (2)';
      case 'tasteless':
        return 'as coisas têm gosto estranho ou não têm gosto (1)';
      case 'swallowProblem':
        return 'problemas para engolir (2)';
      case 'tiredness':
        return 'cansaço (fadiga) (1)';
      case 'appetiteLack':
        return 'sem apetite, apenas sem vontade de comer (3)';
      case 'vomit':
        return 'vômitos (3)';
      case 'diarrhea':
        return 'diarreia (3)';
      case 'dryMouth':
        return 'boca seca (1)';
      case 'quicklySatified':
        return 'me sinto rapidamente satisfeito (1)';
      case 'smellsBother':
        return 'os cheiros me incomodam (1)';
      case 'activityLastMonth':
        return 'Atividades e função';
      case 'weight1MonthSec':
        return 'Peso de 1 mês atrás (kg):';
      case 'weight6MonthSec':
        return 'Peso de 6 meses atrás (kg):';
      case 'relevantDiagnosis':
        return 'Outros diagnósticos relevantes:';
      case 'primaryStaging':
        return 'Estadiamento da doença primária:';
      case 'primaryStagingOther':
        return 'Outro:';
      case 'cancer':
        return 'Câncer';
      case 'cachexia':
        return 'Caquexia Cardíaca ou Pulmonar';
      case 'trauma':
        return 'Presença de trauma';
      case 'renalInsufficiency':
        return 'Insuficiência Renal Crônica';
      case 'aids':
        return 'AIDS';
      case 'decubitusUlcer':
        return 'Úlcera de decúbito, ferida aberta ou fístula';
      case 'moreThan65':
        return 'Idade maior que 65 anos';
      case 'corporalTemperature':
        return 'Temperatura corporal:';
      case 'fever':
        return 'Febre:';
      case 'feverDuration':
        return 'Duração da Febre:';
      case 'corticosteroids':
        return 'Corticosteroides:';
      case 'temple':
        return 'Têmporas (músculos temporais):';
      case 'clavicle':
        return 'Clavículas (peitorais e deltoides):';
      case 'shoulder':
        return 'Ombros (deltoides):';
      case 'hands':
        return 'Musculatura interóssea (mãos):';
      case 'shoulderBlade':
        return 'Escápula (dorsal maior, trapézio, deltoide):';
      case 'thigh':
        return 'Coxa (quadríceps):';
      case 'calf':
        return 'Panturrilha (gastrocnêmio):';
      case 'muscleGeneralState':
        return 'Avaliação geral do estado muscular:';
      case 'periorbital':
        return 'Região periorbital:';
      case 'triceps':
        return 'Prega cutânea do tríceps:';
      case 'lowerRibs':
        return 'Gordura sobre as costelas inferiores:';
      case 'fatDeficitGeneralState':
        return 'Avaliação geral do déficit de gordura:';
      case 'ankle':
        return 'Edema do tornozelo:';
      case 'sacralEdema':
        return 'Edema sacral:';
      case 'hidratationGeneralState':
        return 'Avaliação geral do estado de hidratação:';
      case 'globalCategory':
        return 'Avaliação global das categorias da PG-SGA';
      default:
        return 'Não há dados';
    }
  }

  function parsedWeightAsgAnswers(
    key: string,
    value: string | number | boolean
  ) {
    if (key === 'currentWeight') {
      return value + 'kg';
    }
    if (key === 'heightOver1m') {
      return value + 'cm';
    }
    if (key === 'weight1Month') {
      return value + 'kg';
    }
    if (key === 'weight6Month') {
      return value + 'kg';
    }
    if (key === 'weightLastWeeks') {
      switch (value) {
        case 'lessWeight':
          return 'Diminuiu (1)';
        case 'sameWeight':
          return 'Ficou igual (0)';
        case 'heavier':
          return 'Aumentou (0)';
      }
    }
  }

  function getWeightPoints() {
    const question = parsedWeightAsgAnswers(
      'weightLastWeeks',
      Asg.weightLastWeeks
    );
    if (!question) return 0;
    const matches = question.match(/(\d+)/);
    if (matches) return Number(matches[0]);
    return 0;
  }

  function parsedFoodAsgAnswers(key: string, value: string | number | boolean) {
    if (key === 'lastMonthFood') {
      switch (value) {
        case 'same':
          return 'A mesma coisa (0)';
        case 'more':
          return 'Mais do que o habitual (0)';
        case 'less':
          return 'Menos do que o habitual (1)';
      }
    }
    if (key === 'currentFood') {
      switch (value) {
        case 'lessSolidFood':
          return 'a mesma comida (sólida) em menor quantidade do que o habitual (1)';
        case 'muchLessSolidFood':
          return 'a mesma comida (sólida) em pouca quantidade (2)';
        case 'liquidFood':
          return 'apenas alimentos líquidos (3)';
        case 'suplements':
          return 'apenas suplementos nutricionais (3)';
        case 'littleFood':
          return 'muito pouca quantidade de qualquer alimento (4)';
        case 'tube':
          return 'apenas alimentação por sonda ou pela veia (0)';
      }
    }
  }

  function getFoodPoints() {
    const question1 = parsedFoodAsgAnswers('lastMonthFood', Asg.lastMonthFood);
    const question2 = parsedFoodAsgAnswers('currentFood', Asg.currentFood);
    if (!question1 || !question2) return 0;
    const result1 = question1.match(/(\d+)/);
    const result2 = question2.match(/(\d+)/);
    if (result1 && result2) return Number(result1[0]) + Number(result2[0]);
    return 0;
  }

  function getSymptomsPoints() {
    let result = 0;
    const currentSymptoms = symptomsASGQuestions.filter(
      ({ key }) => Asg[key] === true
    );
    currentSymptoms.forEach((value) => {
      const question = parsedAsgQuestion(value.key);
      const point = question.match(/(\d+)/);
      if (!point) return;
      result += Number(point[0]);
    });
    if (Asg.pain && Asg.others) return result + 4;
    if (Asg.pain) return result + 3;
    if (Asg.others) return result + 1;
    return result;
  }

  function getActivityPoints() {
    const question = parsedActivityAsgAnswers(Asg.activityLastMonth);
    if (!question) return 0;
    const matches = question.match(/(\d+)/);
    if (matches) return Number(matches[0]);
    return 0;
  }

  function parsedActivityAsgAnswers(activity: string) {
    switch (activity) {
      case 'normal':
        return 'Normal, sem nenhuma limitação (0)';
      case 'almostNormal':
        return 'Não totalmente normal, mas capaz de manter quase todas as atividades normais (1)';
      case 'tired':
        return 'Sem disposição para a maioria das coisas, mas ficando na cama ou na cadeira menos da metade do dia (2)';
      case 'mostTimeInBed':
        return 'Capaz de fazer pouca atividade e passando a maior parte do dia na cadeira ou na cama (3)';
      case 'laidUp':
        return 'Praticamente acamado, raramente fora da cama (3)';
    }
  }

  function parsedSecondWeightAsgAnswers(
    key: string,
    value: string | number | boolean
  ) {
    if (key === 'weight1Month') {
      return value + 'kg';
    }
    if (key === 'weight6Month') {
      return value + 'kg';
    }
  }

  function calculateWeightLossPoints() {
    const weightLossPercentage = verifyWeightLossPercentage(
      Asg.currentWeight,
      Asg.weight1Month
    );
    if (weightLossPercentage >= 10) return 4;
    if (weightLossPercentage >= 5 && weightLossPercentage <= 9) return 3;
    if (weightLossPercentage >= 3 && weightLossPercentage <= 4.9) return 2;
    if (weightLossPercentage >= 2 && weightLossPercentage <= 2.9) return 1;
    if (weightLossPercentage >= 0 && weightLossPercentage <= 1.9) return 0;
    return 0;
  }

  function parsedDiseasesNutritionalRelation(
    key: string,
    value: number | boolean | string
  ) {
    if (key === 'relevantDiagnosis') {
      return value;
    }
    if (key === 'primaryStaging') {
      switch (value.toString()) {
        case '1':
          return 'I';
        case '2':
          return 'II';
        case '3':
          return 'III';
        case '4':
          return 'IV';
        case '5':
          return 'Outro';
      }
    }
    if (key === 'primaryStagingOther' && value) {
      return value;
    }
  }

  function calculateConditionsPoints() {
    let points = 0;
    diseasesNutritionalRelationConditions.forEach(({ key }) => {
      Asg[key] ? (points += 1) : (points += 0);
    });
    return points;
  }

  function switchMetabolicDemandResults(value: number | boolean | string) {
    const parsedValue = value.toString();
    switch (parsedValue) {
      case '0':
        return 'SEM STRESS';
      case '1':
        return 'BAIXO STRESS';
      case '2':
        return 'STRESS MODERADO';
      case '3':
        return 'STRESS ELEVADO';
    }
  }

  function switchBodyStateResults(value: number | boolean | string) {
    const parsedValue = value.toString();
    switch (parsedValue) {
      case '0':
        return 'Sem déficit';
      case '1':
        return 'Déficit leve';
      case '2':
        return 'Déficit moderado';
      case '3':
        return 'Déficit grave';
    }
  }

  function parsedMetabolicDemand(
    key: string,
    value: number | boolean | string
  ) {
    if (key === 'corporalTemperature') {
      return value + 'ºC';
    }
    if (key === 'fever') {
      return switchMetabolicDemandResults(value);
    }
    if (key === 'feverDuration') {
      return switchMetabolicDemandResults(value);
    }
    if (key === 'corticosteroids') {
      return switchMetabolicDemandResults(value);
    }
  }

  function calculateMetabolicDemandPoints() {
    return Asg.fever + Asg.feverDuration + Asg.corticosteroids;
  }

  function bodyStatePoints() {
    const bodyPoints =
      Asg.temple +
      Asg.clavicle +
      Asg.shoulder +
      Asg.hands +
      Asg.shoulderBlade +
      Asg.thigh +
      Asg.calf +
      Asg.muscleGeneralState +
      Asg.periorbital +
      Asg.triceps +
      Asg.lowerRibs +
      Asg.fatDeficitGeneralState +
      Asg.ankle +
      Asg.sacralEdema +
      Asg.hidratationGeneralState;
    return bodyPoints;
  }

  function parsedMuscleState(key: string, value: number | boolean | string) {
    if (key === 'temple') {
      return switchBodyStateResults(value);
    }
    if (key === 'clavicle') {
      return switchBodyStateResults(value);
    }
    if (key === 'shoulder') {
      return switchBodyStateResults(value);
    }
    if (key === 'hands') {
      return switchBodyStateResults(value);
    }
    if (key === 'shoulderBlade') {
      return switchBodyStateResults(value);
    }
    if (key === 'thigh') {
      return switchBodyStateResults(value);
    }
    if (key === 'calf') {
      return switchBodyStateResults(value);
    }
    if (key === 'muscleGeneralState') {
      return switchBodyStateResults(value);
    }
  }

  function parsedFatState(key: string, value: number | boolean | string) {
    if (key === 'periorbital') {
      return switchBodyStateResults(value);
    }
    if (key === 'triceps') {
      return switchBodyStateResults(value);
    }
    if (key === 'lowerRibs') {
      return switchBodyStateResults(value);
    }
    if (key === 'fatDeficitGeneralState') {
      return switchBodyStateResults(value);
    }
  }

  function parsedHidratationState(
    key: string,
    value: number | boolean | string
  ) {
    if (key === 'ankle') {
      return switchBodyStateResults(value);
    }
    if (key === 'sacralEdema') {
      return switchBodyStateResults(value);
    }
    if (key === 'hidratationGeneralState') {
      return switchBodyStateResults(value);
    }
  }

  function parsedGlobalCategory() {
    switch (Asg.globalCategory) {
      case 'a':
        return 'BEM NUTRIDO';
      case 'b':
        return 'DESNUTRIÇÃO SUSPEITA OU MODERADA';
      case 'c':
        return 'GRAVEMENTE DESNUTRIDO';
    }
  }

  const result =
    getWeightPoints() +
    getFoodPoints() +
    getSymptomsPoints() +
    getActivityPoints() +
    calculateWeightLossPoints() +
    calculateConditionsPoints() +
    calculateMetabolicDemandPoints() +
    bodyStatePoints();

  function setFinalResult(): { status: string; color: IColors } {
    if (result >= 0 && result <= 1) {
      return {
        status: 'Nenhuma intervenção necessária no momento',
        color: { colors: 'light-green' }
      };
    }

    if (result >= 2 && result <= 3) {
      return {
        status: 'Aconselhamento ao paciente e a seus familiares',
        color: { colors: 'light-yellow' }
      };
    }

    if (result >= 4 && result <= 8) {
      return {
        status: 'Requer intervenção de nutricionista',
        color: { colors: 'light-orange' }
      };
    }

    if (result >= 9) {
      return {
        status:
          'Indica uma necessidade urgente de conduta para a melhora dos sintomas e/ ou opções de intervenção nutricional',
        color: { colors: 'light-red' }
      };
    }
    return { status: 'Não há dados', color: { colors: 'light-blue' } };
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |8. Avaliação nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-evaluation')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              ASG-PPP: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="PESO" />
          <Box
            width="93%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {weightASGQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText
                  title={`${parsedWeightAsgAnswers(key, Asg[key])}`}
                />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getWeightPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="INGESTÃO ALIMENTAR" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {foodASGQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText title={`${parsedFoodAsgAnswers(key, Asg[key])}`} />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getFoodPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="SINTOMAS" />
          <Box
            width="90%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {symptomsASGQuestions.map(({ key }, index) => {
            return Asg[key] ? (
              <Box key={index} margin="15px 0" marginRight="15px">
                <Chip
                  sx={{
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={parsedAsgQuestion(key)}
                />
              </Box>
            ) : (
              ''
            );
          })}
          <Box margin="15px 0" marginRight="15px">
            {Asg.pain?.split(';').map((value, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    marginRight: '15px',
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={`Dor: ${value}`}
                />
              );
            })}
            {Asg.others?.split(';').map((value, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    marginRight: '15px',
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={`Outros: ${value}`}
                />
              );
            })}
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getSymptomsPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ATIVIDADES E FUNÇÃO" />
          <Box
            width="82%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title={parsedAsgQuestion('activityLastMonth')} />
            <ResultText
              title={`${parsedActivityAsgAnswers(Asg.activityLastMonth)}`}
            />
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getActivityPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="PONTUANDO PERDA DE PESO" />
          <Box
            width="75%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {secondWeightQuestion.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText
                  title={`${parsedSecondWeightAsgAnswers(key, Asg[key])}`}
                />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${calculateWeightLossPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="DOENÇAS E SUAS RELAÇÕES COM AS NECESSIDADES NUTRICIONAIS" />
          <Box
            width="47%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Box display="flex" width="100%" flexWrap="wrap">
            {diseasesNutritionalRelation.map(({ key }, index) => {
              return Asg[key] ? (
                <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                  <Label title={parsedAsgQuestion(key)} />
                  <ResultText
                    title={`${parsedDiseasesNutritionalRelation(
                      key,
                      Asg[key]
                    )}`}
                  />
                </Box>
              ) : (
                ''
              );
            })}
          </Box>
          <Box display="flex" width="100%" flexWrap="wrap">
            {diseasesNutritionalRelationConditions.map(({ key }, index) => {
              return Asg[key] ? (
                <Box key={index} margin="15px 0" marginRight="15px">
                  <Chip
                    sx={{
                      color: 'grey.900',
                      fontWeight: '500'
                    }}
                    size="small"
                    variant="filled"
                    label={parsedAsgQuestion(key)}
                  />
                </Box>
              ) : (
                ''
              );
            })}
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${calculateConditionsPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="DEMANDA METABÓLICA" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {metabolicDemand.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText title={`${parsedMetabolicDemand(key, Asg[key])}`} />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${calculateMetabolicDemandPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="EXAME FÍSICO" />
          <Box
            width="85%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Typography
          fontWeight="700"
          color="grey.900"
          padding="0 20px"
          sx={{ textTransform: 'uppercase', marginTop: '20px' }}
        >
          Estado Muscular:
        </Typography>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {muscularState.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText title={`${parsedMuscleState(key, Asg[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Typography
          fontWeight="700"
          color="grey.900"
          padding="0 20px"
          sx={{ textTransform: 'uppercase', marginTop: '20px' }}
        >
          Reservas de gordura:
        </Typography>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {fatState.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText title={`${parsedFatState(key, Asg[key])}`} />
              </Box>
            );
          })}
        </Box>
        <Typography
          fontWeight="700"
          color="grey.900"
          padding="0 20px"
          sx={{ textTransform: 'uppercase', marginTop: '20px' }}
        >
          Estado de hidratação:
        </Typography>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {hidratationState.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText
                  title={`${parsedHidratationState(key, Asg[key])}`}
                />
              </Box>
            );
          })}
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${result}`}
          />
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="AVALIAÇÃO GLOBAL DAS CATEGORIAS DA PG-SGA" />
          <Box
            width="60%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <ResultText title={`${parsedGlobalCategory()}`} />
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={setFinalResult().color}
            label={`${setFinalResult().status}`}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
