import { gql } from '@apollo/client';

export const FIND_NRS_BY_APPOINTMENT_ID = gql`
  query findNRSByAppointmentId($appointmentId: Float!) {
    findNRSByAppointmentId(appointmentId: $appointmentId) {
      id
      imcLessThan20
      weightLoss
      eatingLess
      seriouslyIll
      nutritionalStatusDeterioration
      diseaseLevel
      appointmentId
    }
  }
`;
