import { gql } from '@apollo/client';

export const UPDATE_MAN = gql`
  mutation updateMan($id: Float!, $man: ManInput!) {
    updateMan(id: $id, man: $man) {
      id
      appointmentId
    }
  }
`;
