import { Statistic } from 'models/dashboard';
import { PieData } from 'components/Dashboard/';

export const prepareCids = (data: Statistic[]): PieData[] => {
  return data.map((c: Statistic) => ({
    id: c.name,
    label: c.name,
    value: c.quantity
  }));
};
