export const stagings = [
  {
    value: 'one',
    label: 'T0'
  },
  {
    value: 'two',
    label: 'T1'
  },
  {
    value: 'three',
    label: 'T2'
  },
  {
    value: 'four',
    label: 'T3'
  },
  {
    value: 'five',
    label: 'T4'
  },
  {
    value: 'six',
    label: 'TX'
  },
  {
    value: 'seven',
    label: 'N0'
  },
  {
    value: 'eight',
    label: 'N1'
  },
  {
    value: 'nine',
    label: 'N2'
  },
  {
    value: 'ten',
    label: 'N3'
  },
  {
    value: 'eleven',
    label: 'NX'
  },
  {
    value: 'twelve',
    label: 'M0'
  },
  {
    value: 'thirteen',
    label: 'M1'
  },
  {
    value: 'fourteen',
    label: 'MX'
  },
  {
    value: 'fifteen',
    label: 'EC0 – in situ'
  },
  {
    value: 'sixteen',
    label: 'EC I'
  },
  {
    value: 'seventeen',
    label: 'EC II'
  },
  {
    value: 'eighteen',
    label: 'EC III'
  },
  {
    value: 'nineteen',
    label: 'EC IV'
  }
];

export function generateStagingLabel(value: string) {
  const parsedValue = value.split(';');
  const result = parsedValue.map((value) => {
    switch (value) {
      case 'one':
        return 'T0';
      case 'two':
        return 'T1';
      case 'three':
        return 'T2';
      case 'four':
        return 'T3';
      case 'five':
        return 'T4';
      case 'six':
        return 'TX';
      case 'seven':
        return 'N0';
      case 'eight':
        return 'N1';
      case 'nine':
        return 'N2';
      case 'ten':
        return 'N3';
      case 'eleven':
        return 'NX';
      case 'twelve':
        return 'M0';
      case 'thirteen':
        return 'M1';
      case 'fourteen':
        return 'MX';
      case 'fifteen':
        return 'EC0 – in situ';
      case 'sixteen':
        return 'EC I';
      case 'seventeen':
        return 'EC II';
      case 'eighteen':
        return 'EC II';
      case 'nineteen':
        return 'EC III';
      case 'twenty':
        return 'EC IV';
    }
  });
  return result;
}
