import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  ArrayInputComponent,
  ChipComponent,
  InputWithNumberMask,
  RadioButtonInput,
  Subtitle,
  TooltipComponent,
  ClassificationTable,
  ToggleButtonComponent,
  Input
} from 'components';
import { theme } from 'styles/theme';
import * as yup from 'yup';
import { verifyWeightLossPercentage } from 'utils/functions/weightLossPercentage';
import { useMutation } from '@apollo/client';
import {
  CREATE_NE_ASGPPP,
  UPDATE_NE_ASGPPP,
  NeAsgMutationsVars
} from 'services/graphql/mutations/nutritional-evaluation/asgppp';
import { useHistory } from 'react-router-dom';
import { NeASG } from 'models/neAsg';
import { ASG } from 'models/asg';
import { GlobalEvaluationTable } from '../components/globalEvaluationTable';

interface ASGPPPForm {
  currentWeight: number;
  heightOver1m: number;
  weight1Month: number;
  weight6Month: number;
  weightLastWeeks: string;
  lastMonthFood: string;
  currentFood: string;
  normalFeed: boolean;
  nausea: boolean;
  constipation: boolean;
  mucositis: boolean;
  tasteless: boolean;
  swallowProblem: boolean;
  tiredness: boolean;
  appetiteLack: boolean;
  vomit: boolean;
  diarrhea: boolean;
  dryMouth: boolean;
  quicklySatified: boolean;
  smellsBother: boolean;
  others: string;
  pain: string;
  activityLastMonth: string;

  weight1MonthSec: number;
  weight6MonthSec: number;
  relevantDiagnosis: string;
  primaryStaging: string; //number;
  primaryStagingOther: string;
  cancer: boolean;
  cachexia: boolean;
  trauma: boolean;
  renalInsufficiency: boolean;
  aids: boolean;
  decubitusUlcer: boolean;
  moreThan65: boolean;
  corporalTemperature: number;
  fever: number;
  feverDuration: number;
  corticosteroids: number;
  temple: number;
  clavicle: number;
  shoulder: number;
  hands: number;
  shoulderBlade: number;
  thigh: number;
  calf: number;
  muscleGeneralState: number;
  periorbital: number;
  triceps: number;
  lowerRibs: number;
  fatDeficitGeneralState: number;
  ankle: number;
  sacralEdema: number;
  hidratationGeneralState: number;
  globalCategory: string;
}

type formValuesKey =
  | 'currentWeight'
  | 'heightOver1m'
  | 'weight1Month'
  | 'weight6Month'
  | 'weightLastWeeks'
  | 'lastMonthFood'
  | 'currentFood'
  | 'normalFeed'
  | 'nausea'
  | 'constipation'
  | 'mucositis'
  | 'tasteless'
  | 'swallowProblem'
  | 'tiredness'
  | 'appetiteLack'
  | 'vomit'
  | 'diarrhea'
  | 'dryMouth'
  | 'quicklySatified'
  | 'smellsBother'
  | 'others'
  | 'pain'
  | 'activityLastMonth'
  | 'weight1MonthSec'
  | 'weight6MonthSec'
  | 'relevantDiagnosis'
  | 'primaryStaging'
  | 'primaryStagingOther'
  | 'cancer'
  | 'cachexia'
  | 'trauma'
  | 'renalInsufficiency'
  | 'aids'
  | 'decubitusUlcer'
  | 'moreThan65'
  | 'corporalTemperature'
  | 'fever'
  | 'feverDuration'
  | 'corticosteroids'
  | 'temple'
  | 'clavicle'
  | 'shoulder'
  | 'hands'
  | 'shoulderBlade'
  | 'thigh'
  | 'calf'
  | 'muscleGeneralState'
  | 'periorbital'
  | 'triceps'
  | 'lowerRibs'
  | 'fatDeficitGeneralState'
  | 'ankle'
  | 'sacralEdema'
  | 'hidratationGeneralState';

interface SymptomsType {
  name: formValuesKey;
  title: string;
  value: number;
}

interface ToggleButton {
  name: formValuesKey;
  buttonOptions?: string[];
  question: string;
}

interface BodyQuestion {
  title: string;
  toggleButtons: ToggleButton[];
}

const validationSchema = yup.object().shape({
  currentWeight: yup
    .number()
    .required('Insira um peso atual')
    .max(600.99, 'O peso máximo é 600.99'),
  heightOver1m: yup
    .number()
    .required('Insira uma altura em cm acima de 1 metro')
    .max(170, 'A altura acima de 1 metro em cm máxima é de 170'),
  weight1Month: yup
    .number()
    .required('Insira um peso de um mês atrás')
    .max(600.99, 'O peso máximo é 600.99'),
  weight6Month: yup
    .number()
    .required('Insira um peso de seis meses atrás')
    .max(600.99, 'O peso máximo é 600.99'),
  corporalTemperature: yup
    .number()
    .required('Insira uma temperatura')
    .max(45, 'A temperatura máxima é de 42ºC')
});

export function calculateWwightLossPoints(lossPercentagem: number) {
  if (lossPercentagem >= 10) return 4;
  if (lossPercentagem >= 5 && lossPercentagem <= 9) return 3;
  if (lossPercentagem >= 3 && lossPercentagem <= 4.9) return 2;
  if (lossPercentagem >= 2 && lossPercentagem <= 2.9) return 1;
  if (lossPercentagem >= 0 && lossPercentagem <= 1.9) return 0;
  return 0;
}

export function setWeightPointsFunction(weightLoss: string) {
  switch (weightLoss) {
    case 'lessWeight':
      return 1;
    case 'sameWeight':
      return 0;
    case 'heavier':
      return 0;
  }
  return 0;
}

export function setFoodIngestionQuantityPointsFunction(
  foodIngestionLastMonth: string
) {
  switch (foodIngestionLastMonth) {
    case 'same':
      return 0;
    case 'more':
      return 0;
    case 'less':
      return 1;
  }
  return 0;
}

export function setFoodIngestionTypePointsFunction(foodIngestionNow: string) {
  switch (foodIngestionNow) {
    case 'lessSolidFood':
      return 1;
    case 'muchLessSolidFood':
      return 2;
    case 'liquidFood':
      return 3;
    case 'suplements':
      return 3;
    case 'littleFood':
      return 4;
    case 'tube':
      return 0;
  }
  return 0;
}

export function setActivityLastMonthPointsFunction(activityLastMonth: string) {
  switch (activityLastMonth) {
    case 'normal':
      return 0;
    case 'almostNormal':
      return 1;
    case 'tired':
      return 2;
    case 'mostTimeInBed':
      return 3;
    case 'laidUp':
      return 3;
  }
  return 0;
}

const ASGPPPForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    height: number;
    weight: number;
    loadedData: NeASG | undefined;
    loadedNrASG: ASG | undefined;
    lastAsg: NeASG | undefined;
  }
> = ({ height, weight, loadedData, loadedNrASG, lastAsg }, ref) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { isValid, errors, isSubmitted }
  } = useForm<ASGPPPForm>({
    defaultValues: {
      weightLastWeeks: 'lessWeight',
      lastMonthFood: 'same',
      currentFood: 'lessSolidFood',
      activityLastMonth: 'normal',
      normalFeed: false,
      nausea: false,
      constipation: false,
      mucositis: false,
      tasteless: false,
      swallowProblem: false,
      tiredness: false,
      appetiteLack: false,
      vomit: false,
      diarrhea: false,
      dryMouth: false,
      quicklySatified: false,
      smellsBother: false,
      others: '',
      primaryStaging: '1',
      cancer: false,
      cachexia: false,
      trauma: false,
      renalInsufficiency: false,
      aids: false,
      decubitusUlcer: false,
      moreThan65: false,
      fever: 0,
      feverDuration: 0,
      corticosteroids: 0,
      temple: 0,
      clavicle: 0,
      shoulder: 0,
      hands: 0,
      shoulderBlade: 0,
      thigh: 0,
      calf: 0,
      muscleGeneralState: 0,
      periorbital: 0,
      triceps: 0,
      lowerRibs: 0,
      fatDeficitGeneralState: 0,
      ankle: 0,
      sacralEdema: 0,
      hidratationGeneralState: 0,
      globalCategory: 'a'
    },
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (
      (!isValid && errors.currentWeight) ||
      errors.heightOver1m ||
      errors.weight1Month ||
      errors.weight6Month ||
      errors.corporalTemperature
    ) {
      toast.error(
        'Preencha todos os campos do formulário corretamente para avançar'
      );
    }
  }, [isSubmitted]);

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createASGMutation] = useMutation<null, NeAsgMutationsVars>(
    CREATE_NE_ASGPPP
  );
  const [updateASGMutation] = useMutation<null, NeAsgMutationsVars>(
    UPDATE_NE_ASGPPP
  );
  const history = useHistory();

  const watchWeight = watch('currentWeight');
  const watchWeightLastMonth = watch('weight1Month');
  const watchWeight6Month = watch('weight6Month');
  const watchWeightLoss = watch('weightLastWeeks');
  const watchFoodIngestionLastMonth = watch('lastMonthFood');
  const watchFoodIngestionNow = watch('currentFood');
  const watchActivityLastMonth = watch('activityLastMonth');
  const watchPrimaryStaging = watch('primaryStaging');
  const watchBodyTemperature = watch('corporalTemperature');

  const watchFever = watch('fever');
  const watchFeverDuration = watch('feverDuration');
  const watchCorticosteroids = watch('corticosteroids');

  const watchTemple = watch('temple');
  const watchClavicle = watch('clavicle');
  const watchShoulder = watch('shoulder');
  const watchHands = watch('hands');
  const watchShoulderBlade = watch('shoulderBlade');
  const watchThigh = watch('thigh');
  const watchCalf = watch('calf');
  const watchMuscleGeneralState = watch('muscleGeneralState');
  const watchPeriorbital = watch('periorbital');
  const watchTriceps = watch('triceps');
  const watchLowerRibs = watch('lowerRibs');
  const watchFatDeficitGeneralState = watch('fatDeficitGeneralState');
  const watchAnkle = watch('ankle');
  const watchSacralEdema = watch('sacralEdema');
  const watchHidratationGeneralState = watch('hidratationGeneralState');
  const watchGlobalCategory = watch('globalCategory');

  const [symptomsPoints, setSymptomsPoints] = useState(0);
  const [hasOtherSymptoms, setHasOtherSymptoms] = useState(false);
  const [otherSymptomsChips, setOtherSymptomsChips] = useState<string[]>([]);
  const [hasPain, setHasPain] = useState(false);
  const [painChips, setPainChips] = useState<string[]>([]);
  const [normalFeed, setNormalFeed] = useState(false);
  const [weightLossPercentage, setWeightLossPercentage] = useState(0);
  const [conditionsPoints, setConditionsPoints] = useState(0);
  const [temperatureQuestionsPoints, setTemperatureQuestionsPoints] =
    useState(0);
  const [finalFormClassification, setFinalFormClassification] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    verifyWeightLoss();
  }, [watchWeight, watchWeightLastMonth]);

  function calculateHeight() {
    if (!height) return;
    const [meters, centimeters] = height.toString().split('.', 2);
    if (Number(meters) > 1.99 && !centimeters) setValue('heightOver1m', 100);
    else if (Number(meters) > 0.99 && !centimeters) setValue('heightOver1m', 0);
    else if (centimeters.length === 1)
      setValue('heightOver1m', Number(centimeters) * 10);
    else if (Number(meters) >= 2)
      setValue('heightOver1m', Number(centimeters) + 100);
    else setValue('heightOver1m', Number(centimeters));

    if (centimeters && centimeters.length === 1 && Number(meters) >= 2)
      setValue('heightOver1m', Number(centimeters) * 10 + 100);
  }

  function verifyWeightLoss() {
    const weight = getValues('currentWeight');
    const weightLastMonth = getValues('weight1Month');
    if (weight < weightLastMonth) setValue('weightLastWeeks', 'lessWeight');
    if (weight === weightLastMonth) setValue('weightLastWeeks', 'sameWeight');
    if (weight > weightLastMonth) setValue('weightLastWeeks', 'heavier');
  }

  const firstColumnSymptoms: SymptomsType[] = [
    {
      name: 'nausea',
      title: 'náuseas (enjoos) (1)',
      value: 1
    },
    {
      name: 'constipation',
      title: 'obstipação (intestino preso) (1)',
      value: 1
    },
    {
      name: 'mucositis',
      title: 'feridas na boca (2)',
      value: 2
    },
    {
      name: 'tasteless',
      title: 'os alimentos têm gosto estranho ou não têm gosto (1)',
      value: 1
    },
    {
      name: 'swallowProblem',
      title: 'problemas para engolir (2)',
      value: 2
    },
    {
      name: 'tiredness',
      title: 'cansaço (fadiga) (1)',
      value: 1
    }
  ];

  const secondColumnSymptoms: SymptomsType[] = [
    {
      name: 'appetiteLack',
      title: 'sem apetite, apenas sem vontade de comer (3)',
      value: 3
    },
    {
      name: 'vomit',
      title: 'vômitos (3)',
      value: 3
    },
    {
      name: 'diarrhea',
      title: 'diarréia (3)',
      value: 3
    },
    {
      name: 'dryMouth',
      title: 'boca seca (1)',
      value: 1
    },
    {
      name: 'quicklySatified',
      title: 'me sinto rapidamente satisfeito (1)',
      value: 1
    },
    {
      name: 'smellsBother',
      title: 'os cheiros me incomodam (1)',
      value: 1
    }
  ];

  const firstConditions: SymptomsType[] = [
    {
      name: 'cancer',
      title: 'Câncer',
      value: 1
    },
    {
      name: 'cachexia',
      title: 'Caquexia Cardíaca ou Pulmonar',
      value: 1
    },
    {
      name: 'trauma',
      title: 'Presença de trauma',
      value: 1
    },
    {
      name: 'renalInsufficiency',
      title: 'Insuficiência Renal Crônica',
      value: 1
    }
  ];
  const secondConditions: SymptomsType[] = [
    {
      name: 'aids',
      title: 'AIDS',
      value: 1
    },
    {
      name: 'decubitusUlcer',
      title: 'Úlcera de decúbito, ferida aberta ou fístula',
      value: 1
    },
    {
      name: 'moreThan65',
      title: 'Idade maior que 65 anos',
      value: 1
    }
  ];
  function generateSymptoms(symptoms: SymptomsType[]) {
    return symptoms.map(({ name, title, value }) => {
      return (
        <Box
          key={name}
          display="flex"
          flexDirection="column"
          margin="5px 0"
          width="95%"
        >
          <FormControlLabel
            sx={{
              color: theme.palette.grey[900],
              '& .MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            control={
              <Checkbox
                color="info"
                checked={getValues(name) === true ? true : false}
                name={name}
                onChange={(event) =>
                  setSymptonsValue(
                    name,
                    value,
                    event.target.checked ? true : false
                  )
                }
              />
            }
            label={title}
          />
        </Box>
      );
    });
  }

  function setSymptonsValue(
    name: formValuesKey,
    value: number,
    operationType: boolean
  ) {
    setValue(name, operationType);
    calculateSymptomsPoinst(value, name);
  }

  function calculateSymptomsPoinst(value: number, name: formValuesKey) {
    if (!getValues(name)) setSymptomsPoints(symptomsPoints - value);

    if (getValues(name)) setSymptomsPoints(symptomsPoints + value);
  }

  function generateConditions(conditions: SymptomsType[]) {
    return conditions.map(({ name, title, value }) => {
      return (
        <Box
          key={name}
          display="flex"
          flexDirection="column"
          margin="5px 0"
          width="95%"
        >
          <FormControlLabel
            sx={{
              color: theme.palette.grey[900],
              '& .MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            control={
              <Checkbox
                color="info"
                checked={getValues(name) ? true : false}
                value={getValues(name)}
                name={name}
                onChange={(event) =>
                  setConditionsValue(
                    name,
                    value,
                    event.target.checked ? true : false
                  )
                }
              />
            }
            label={title}
          />
        </Box>
      );
    });
  }

  function setConditionsValue(
    name: formValuesKey,
    value: number,
    operationType: boolean
  ) {
    setValue(name, operationType);
    calculateConditionsPoints(value, name);
  }

  function calculateConditionsPoints(value: number, name: formValuesKey) {
    if (!getValues(name)) setConditionsPoints(conditionsPoints - value);

    if (getValues(name)) setConditionsPoints(conditionsPoints + value);
  }

  useEffect(() => {
    if (weight) setValue('currentWeight', weight);
    calculateHeight();
  }, []);

  useEffect(() => {
    setValue('others', otherSymptomsChips.join(';'));
  }, [otherSymptomsChips]);

  function setHasOtherSymptomsFunction(value: boolean) {
    setHasOtherSymptoms(value);
    if (!value) {
      setOtherSymptomsChips([]);
      setSymptomsPoints(symptomsPoints - 1);
    } else {
      setSymptomsPoints(symptomsPoints + 1);
    }
  }

  useEffect(() => {
    setValue('pain', painChips.join(';'));
  }, [painChips]);

  function setPainFunction(value: boolean) {
    setHasPain(value);
    if (!value) {
      setPainChips([]);
      setSymptomsPoints(symptomsPoints - 3);
    } else {
      setSymptomsPoints(symptomsPoints + 3);
    }
  }

  function setNormalFeedValue(value: boolean) {
    setNormalFeed(value);
    setValue('normalFeed', value);
  }

  function setWeightLossPercentagePoints() {
    const percentage1Month = verifyWeightLossPercentage(
      watchWeight,
      watchWeightLastMonth
    );
    /* const percentage6Month = verifyWeightLossPercentage(
      watchWeight,
      watchWeight6Month
    ); */
    setWeightLossPercentage(percentage1Month);
    //if (!watchWeight6Month) setWeightLossPercentage(percentage1Month);
    //if (!watchWeightLastMonth) setWeightLossPercentage(percentage6Month);
    //return setWeightLossPercentage(percentage1Month);
  }

  useEffect(() => {
    setWeightLossPercentagePoints();
  }, [watchWeight6Month, watchWeightLastMonth]);

  useEffect(() => {
    if (watchBodyTemperature > 37.2 && watchBodyTemperature < 38.3) {
      setValue('fever', 1);
      setTemperaturePointsValue();
    }
    if (watchBodyTemperature >= 38.3 && watchBodyTemperature < 38.9) {
      setValue('fever', 2);
      setTemperaturePointsValue();
    }
    if (watchBodyTemperature >= 38.9) {
      setValue('fever', 3);
      setTemperaturePointsValue();
    }
    if (watchBodyTemperature <= 37.2) {
      setValue('fever', 0);
      setTemperaturePointsValue();
    }
  }, [watchBodyTemperature]);

  const muscleStateQuestions: BodyQuestion[] = [
    {
      title: 'Estado muscular',
      toggleButtons: [
        {
          name: 'temple',
          question: 'Têmporas (músculos temporais)'
        },
        {
          name: 'clavicle',
          question: 'Clavículas (peitorais e deltoides)'
        },
        {
          name: 'shoulder',
          question: 'Ombros (deltoides)'
        },
        {
          name: 'hands',
          question: 'Musculatura interóssea'
        },
        {
          name: 'shoulderBlade',
          question: 'Escápula (dorsal maior, trapézio, deltoide)'
        },
        {
          name: 'thigh',
          question: 'Coxa (gastrocnêmio)'
        },
        {
          name: 'calf',
          question: 'Panturrilha (gastrocnêmio)'
        },
        {
          name: 'muscleGeneralState',
          question: 'Avaliação geral do estado muscular'
        }
      ]
    }
  ];

  const fatStateQuestions: BodyQuestion[] = [
    {
      title: 'Reservas de gordura:',
      toggleButtons: [
        {
          name: 'periorbital',
          question: 'Região periorbital'
        },
        {
          name: 'triceps',
          question: 'Prega cutânea do tríceps'
        },
        {
          name: 'lowerRibs',
          question: 'Gordura sobre as costelas inferiores'
        },
        {
          name: 'fatDeficitGeneralState',
          question: 'Avaliação geral do déficit de gordura'
        }
      ]
    }
  ];

  const hidratationStateQuestions: BodyQuestion[] = [
    {
      title: 'Estado de hidratação:',
      toggleButtons: [
        {
          name: 'ankle',
          question: 'Edema do tornozelo'
        },
        {
          name: 'sacralEdema',
          question: 'Edema sacral'
        },
        {
          name: 'hidratationGeneralState',
          question: 'Avaliação geral do estado de hidratação'
        }
      ]
    }
  ];

  const temperatureQuestions: BodyQuestion[] = [
    {
      title: 'Temperatura:',
      toggleButtons: [
        {
          name: 'fever',
          buttonOptions: [
            'Sem febre',
            '>37,2 e <38,3°C',
            '≥38,3 e <38,9°C',
            '≥38,9°C'
          ],
          question: 'Febre'
        },
        {
          name: 'feverDuration',
          buttonOptions: ['Sem febre', '<72 horas', '72 horas', '>72 horas'],
          question: 'Duração da febre'
        },
        {
          name: 'corticosteroids',
          buttonOptions: [
            'Sem corticosteroides',
            'Dose baixa (<10mg equival. prednisona/dia)',
            'Dose moderada (≥10 a <30mg equival. prednisona/dia)',
            'Dose elevada (≥30mg equival. prednisona/dia)'
          ],
          question: 'Corticosteroides'
        }
      ]
    }
  ];

  function setTemperaturePointsValue() {
    setTemperatureQuestionsPoints(
      getValues().fever +
        getValues().feverDuration +
        getValues().corticosteroids
    );
  }

  function renderTemperatureQuestions(temperatureQuestions: BodyQuestion[]) {
    return temperatureQuestions.map(({ toggleButtons }, index) => {
      return (
        <Box margin="20px 0" key={index}>
          <Box
            display="grid"
            justifyContent="space-around"
            width="70%"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            fontSize="12px"
            textAlign="center"
            alignSelf="flex-start"
            marginLeft="auto"
            marginBottom="10px"
            color={theme.palette.grey[900]}
          >
            <Typography fontSize="12px" fontWeight="500">
              SEM STRESS <br /> (0 pts)
            </Typography>
            <Typography fontSize="12px" fontWeight="500">
              BAIXO STRESS <br /> (1 pts)
            </Typography>
            <Typography fontSize="12px" fontWeight="500">
              STRESS MODERADO <br /> (2 pts)
            </Typography>
            <Typography fontSize="12px" fontWeight="500">
              STRESS ELEVADO <br /> (3 pts)
            </Typography>
          </Box>
          {toggleButtons.map(({ question, name, buttonOptions }, index) => {
            const [text0, text1, text2, text3] = buttonOptions || [''];
            return (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="30%"
                  position="relative"
                  height="100%"
                >
                  <Typography
                    paddingLeft="15px"
                    fontSize="14px"
                    color={theme.palette.grey[900]}
                    fontWeight="500"
                  >
                    {question}
                  </Typography>
                </Box>
                <Box width="70%" alignSelf="flex-end">
                  <ToggleButtonComponent
                    selectedValue={Number(getValues(name))}
                    toggleButtonProps={[
                      {
                        value: 0,
                        action: () => {
                          {
                            setValue(name, 0);
                            setTemperaturePointsValue();
                          }
                        },
                        title: text0 ? text0 : '0'
                      },
                      {
                        value: 1,
                        action: () => {
                          setValue(name, 1);
                          setTemperaturePointsValue();
                        },
                        title: text1 ? text1 : '1'
                      },
                      {
                        value: 2,
                        action: () => {
                          setValue(name, 2);
                          setTemperaturePointsValue();
                        },
                        title: text2 ? text2 : '2'
                      },
                      {
                        value: 3,
                        action: () => {
                          setValue(name, 3);
                          setTemperaturePointsValue();
                        },
                        title: text3 ? text3 : '3'
                      }
                    ]}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    });
  }

  function renderBodyQuestions(bodyQuestions: BodyQuestion[]) {
    return bodyQuestions.map(({ title, toggleButtons }, index) => {
      return (
        <Box margin="20px 0" key={index}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize="16px"
              color={theme.palette.grey[900]}
              fontWeight="500"
            >
              {title}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              width="55%"
              fontSize="12px"
              textAlign="center"
              color={theme.palette.grey[900]}
            >
              <Typography fontSize="12px" fontWeight="500">
                Sem <br /> déficit
              </Typography>
              <Typography fontSize="12px" fontWeight="500">
                Déficit <br /> leve
              </Typography>
              <Typography fontSize="12px" fontWeight="500">
                Déficit <br /> moderado
              </Typography>
              <Typography fontSize="12px" fontWeight="500">
                Déficit <br /> grave
              </Typography>
            </Box>
          </Box>
          {toggleButtons.map(({ question, name }, index) => {
            return (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  border="1px solid"
                  borderColor="grey.200"
                  borderRight="0"
                  bgcolor="#F6F9FC"
                  width="50%"
                  height="38px"
                >
                  <Typography
                    paddingLeft="15px"
                    fontSize="14px"
                    color={theme.palette.grey[900]}
                    fontWeight="500"
                  >
                    {question}
                  </Typography>
                </Box>
                <Box width="60%" alignSelf="flex-end">
                  <ToggleButtonComponent
                    selectedValue={Number(getValues(name))}
                    toggleButtonProps={[
                      {
                        value: 0,
                        action: () => setValue(name, 0),
                        title: '0'
                      },
                      {
                        value: 1,
                        action: () => setValue(name, 1),
                        title: '1+'
                      },
                      {
                        value: 2,
                        action: () => setValue(name, 2),
                        title: '2+'
                      },
                      {
                        value: 3,
                        action: () => setValue(name, 3),
                        title: '3+'
                      }
                    ]}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    });
  }

  useEffect(() => {
    setFinalFormClassification(
      setFoodIngestionQuantityPointsFunction(watchFoodIngestionLastMonth) +
        setFoodIngestionTypePointsFunction(watchFoodIngestionNow) +
        setWeightPointsFunction(watchWeightLoss) +
        setActivityLastMonthPointsFunction(watchActivityLastMonth) +
        symptomsPoints +
        calculateWwightLossPoints(weightLossPercentage) +
        conditionsPoints +
        temperatureQuestionsPoints +
        watchTemple +
        watchClavicle +
        watchShoulder +
        watchHands +
        watchShoulderBlade +
        watchThigh +
        watchCalf +
        watchMuscleGeneralState +
        watchPeriorbital +
        watchTriceps +
        watchLowerRibs +
        watchFatDeficitGeneralState +
        watchAnkle +
        watchSacralEdema +
        watchHidratationGeneralState
    );
  }, [
    watchActivityLastMonth,
    watchFoodIngestionNow,
    watchFoodIngestionLastMonth,
    watchWeightLoss,
    symptomsPoints,
    weightLossPercentage,
    conditionsPoints,
    temperatureQuestionsPoints,
    watchFever,
    watchFeverDuration,
    watchCorticosteroids,
    watchTemple,
    watchClavicle,
    watchShoulder,
    watchHands,
    watchShoulderBlade,
    watchThigh,
    watchCalf,
    watchMuscleGeneralState,
    watchPeriorbital,
    watchTriceps,
    watchLowerRibs,
    watchFatDeficitGeneralState,
    watchAnkle,
    watchSacralEdema,
    watchHidratationGeneralState
  ]);

  function setLoadedNrAsgDataToForm() {
    let total = 0;
    if (loadedData) return;
    if (loadedNrASG && !loadedData) {
      setLoading(true);
      setValue('currentWeight', loadedNrASG.currentWeight);
      setValue('heightOver1m', loadedNrASG.heightOver1m);
      setValue('weight1Month', loadedNrASG.weight1Month);
      setValue('weight6Month', loadedNrASG.weight6Month);
      setValue('weightLastWeeks', loadedNrASG.weightLastWeeks);
      setValue('lastMonthFood', loadedNrASG.lastMonthFood);
      setValue('currentFood', loadedNrASG.currentFood);
      if (loadedNrASG.normalFeed) {
        setValue('normalFeed', true);
        setNormalFeed(true);
      }
      if (loadedNrASG.nausea) {
        setValue('nausea', true);
        total = total + 1;
      }
      if (loadedNrASG.constipation) {
        setValue('constipation', true);
        total = total + 1;
      }
      if (loadedNrASG.mucositis) {
        setValue('mucositis', true);
        total = total + 2;
      }
      if (loadedNrASG.tasteless) {
        setValue('tasteless', true);
        total = total + 1;
      }
      if (loadedNrASG.swallowProblem) {
        setValue('swallowProblem', true);
        total = total + 2;
      }
      if (loadedNrASG.tiredness) {
        setValue('tiredness', true);
        total = total + 1;
      }
      if (loadedNrASG.appetiteLack) {
        setValue('appetiteLack', true);
        total = total + 3;
      }
      if (loadedNrASG.vomit) {
        setValue('vomit', true);
        total = total + 3;
      }
      if (loadedNrASG.diarrhea) {
        setValue('diarrhea', true);
        total = total + 3;
      }
      if (loadedNrASG.dryMouth) {
        setValue('dryMouth', true);
        total = total + 1;
      }
      if (loadedNrASG.quicklySatified) {
        setValue('quicklySatified', true);
        total = total + 1;
      }
      if (loadedNrASG.smellsBother) {
        setValue('smellsBother', true);
        total = total + 1;
      }
      setValue('pain', loadedNrASG.pain);
      setValue('others', loadedNrASG.others);
      setValue('activityLastMonth', loadedNrASG.activityLastMonth);
      if (getValues('others') !== '') {
        setOtherSymptomsChips(loadedNrASG.others.split(';'));
        setHasOtherSymptoms(true);
        total = total + 1;
      }
      if (getValues('pain') !== '') {
        setPainChips(loadedNrASG.pain.split(';'));
        setHasPain(true);
        total = total + 3;
      }
      setSymptomsPoints(total);
      setLoading(false);
    }
  }

  function setFormValues(data: NeASG | undefined) {
    let total = 0;
    if (!data) return;
    setValue('currentWeight', data.currentWeight);
    setValue('heightOver1m', data.heightOver1m);
    setValue('weight1Month', data.weight1Month);
    setValue('weight6Month', data.weight6Month);
    setValue('weightLastWeeks', data.weightLastWeeks);
    setValue('lastMonthFood', data.lastMonthFood);
    setValue('currentFood', data.currentFood);
    if (data.normalFeed) {
      setValue('normalFeed', true);
      setNormalFeed(true);
    }
    if (data.nausea) {
      setValue('nausea', true);
      total = total + 1;
    }
    if (data.constipation) {
      setValue('constipation', true);
      total = total + 1;
    }
    if (data.mucositis) {
      setValue('mucositis', true);
      total = total + 2;
    }
    if (data.tasteless) {
      setValue('tasteless', true);
      total = total + 1;
    }
    if (data.swallowProblem) {
      setValue('swallowProblem', true);
      total = total + 2;
    }
    if (data.tiredness) {
      setValue('tiredness', true);
      total = total + 1;
    }
    if (data.appetiteLack) {
      setValue('appetiteLack', true);
      total = total + 3;
    }
    if (data.vomit) {
      setValue('vomit', true);
      total = total + 3;
    }
    if (data.diarrhea) {
      setValue('diarrhea', true);
      total = total + 3;
    }
    if (data.dryMouth) {
      setValue('dryMouth', true);
      total = total + 1;
    }
    if (data.quicklySatified) {
      setValue('quicklySatified', true);
      total = total + 1;
    }
    if (data.smellsBother) {
      setValue('smellsBother', true);
      total = total + 1;
    }
    setValue('pain', data.pain);
    setValue('others', data.others);
    setValue('activityLastMonth', data.activityLastMonth);
    if (getValues('others') !== '') {
      setOtherSymptomsChips(data.others.split(';'));
      setHasOtherSymptoms(true);
      total = total + 1;
    }
    if (getValues('pain') !== '') {
      setPainChips(data.pain.split(';'));
      setHasPain(true);
      total = total + 3;
    }
    let conditions = 0;
    if (data.cancer) {
      setValue('cancer', true);
      conditions = conditions + 1;
    }
    if (data.cachexia) {
      setValue('cachexia', true);
      conditions = conditions + 1;
    }
    if (data.trauma) {
      setValue('trauma', true);
      conditions = conditions + 1;
    }
    if (data.renalInsufficiency) {
      setValue('renalInsufficiency', true);
      conditions = conditions + 1;
    }
    if (data.aids) {
      setValue('aids', true);
      conditions = conditions + 1;
    }
    if (data.decubitusUlcer) {
      setValue('decubitusUlcer', true);
      conditions = conditions + 1;
    }
    if (data.moreThan65) {
      setValue('moreThan65', true);
      conditions = conditions + 1;
    }
    setSymptomsPoints(total);
    setValue('weight1MonthSec', data.weight1MonthSec);
    setValue('weight6MonthSec', data.weight6MonthSec);
    setValue('relevantDiagnosis', data.relevantDiagnosis);
    setValue('primaryStaging', data.primaryStaging.toString());
    setValue('primaryStagingOther', data.primaryStagingOther);
    setConditionsPoints(conditions);
    setValue('corporalTemperature', data.corporalTemperature);
    setValue('fever', data.fever);
    setValue('feverDuration', data.feverDuration);
    setValue('corticosteroids', data.corticosteroids);
    setValue('temple', data.temple);
    setValue('clavicle', data.clavicle);
    setValue('shoulder', data.shoulder);
    setValue('hands', data.hands);
    setValue('shoulderBlade', data.shoulderBlade);
    setValue('thigh', data.thigh);
    setValue('calf', data.calf);
    setValue('muscleGeneralState', data.muscleGeneralState);
    setValue('periorbital', data.periorbital);
    setValue('triceps', data.triceps);
    setValue('lowerRibs', data.lowerRibs);
    setValue('fatDeficitGeneralState', data.fatDeficitGeneralState);
    setValue('ankle', data.ankle);
    setValue('sacralEdema', data.sacralEdema);
    setValue('hidratationGeneralState', data.hidratationGeneralState);
    setValue('globalCategory', data.globalCategory);
    setLoading(false);
  }

  useEffect(() => {
    setLoadedNrAsgDataToForm();
  }, [loadedNrASG]);

  useEffect(() => {
    setLoading(true);
    if (!loadedData) {
      setLoading(false);
      return;
    }
    setFormValues(loadedData);
  }, [loadedData]);

  useEffect(() => {
    setFormValues(lastAsg);
  }, [lastAsg]);
  async function createASG(data: ASGPPPForm) {
    if (!appointmentId) return;
    if (loadedData) return;
    await createASGMutation({
      variables: {
        neAsgppp: {
          appointmentId,
          ...data,
          primaryStaging: Number(data.primaryStaging)
        }
      },
      onCompleted: () => {
        toast.success('Avaliação nutricional ASG-PPP criada com sucesso.');
        history.push('/home/anthropometric-evaluation');
      }
    });
  }

  async function updateASG(data: ASGPPPForm) {
    if (!appointmentId || !loadedData) return;
    if (loadedData)
      await updateASGMutation({
        variables: {
          neAsgppp: {
            appointmentId,
            ...data,
            primaryStaging: Number(data.primaryStaging)
          }
        },
        onCompleted: () => {
          toast.info('Avaliação nutricional ASG-PPP atualizada com sucesso.');
          history.push('/home/anthropometric-evaluation');
        }
      });
  }

  function onSubmit(form: ASGPPPForm) {
    try {
      createASG(form);
      updateASG(form);
    } catch {
      toast.error('Houve um erro');
    }
  }

  if (loading) return <CircularProgress />;

  return (
    <Box width="100%">
      <form
        onKeyDown={(e) => {
          e.key === 'Enter' ? e.preventDefault() : null;
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="470vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Avaliação Subjetiva Global - Preenchida pelo Paciente
            </Typography>
            <TooltipComponent content="Utilizada em pacientes adultos e idosos oncológicos" />
          </Box>
          <Divider />
          <ChipComponent
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${finalFormClassification} PONTOS`}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="50vh"
          >
            <Box>
              <Subtitle title="Peso:" />
              <Typography
                fontSize="14px"
                fontWeight="500"
                color={theme.palette.grey[900]}
              >
                Resumindo meu peso atual e recente:
              </Typography>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                suffix="kg"
                placeholder="Ex: 00,0 kg"
                control={control}
                name="currentWeight"
                label="Eu atualmente peso aproximadamente (kg):"
              />
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={0}
                placeholder="Ex: 00 cm"
                suffix="cm"
                control={control}
                name="heightOver1m"
                label="Eu tenho aproximadamente 1 metro e (cm):"
              />
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                suffix="kg"
                placeholder="Ex: 00,0 kg"
                control={control}
                name="weight1Month"
                label="Há 1 mês eu costumava pesar (kg):"
              />
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                placeholder="Ex: 00,0 kg"
                suffix="kg"
                control={control}
                name="weight6Month"
                label="Há 6 meses eu costumava pesar (kg):"
              />
            </Box>

            <Box>
              <RadioButtonInput
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 5px'
                }}
                control={control}
                name="weightLastWeeks"
                disabled={false}
                title="Durante as duas últimas semanas o meu peso:"
                radioButtons={[
                  {
                    checked: watchWeightLoss === 'lessWeight',
                    label: 'Diminuiu (1)',
                    value: 'lessWeight'
                  },
                  {
                    checked: watchWeightLoss === 'sameWeight',
                    label: 'Ficou igual (0)',
                    value: 'sameWeight'
                  },
                  {
                    checked: watchWeightLoss === 'heavier',
                    label: 'Aumentou (0)',
                    value: 'heavier'
                  }
                ]}
              />
            </Box>

            <ChipComponent
              sx={{ width: '18%' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${setWeightPointsFunction(watchWeightLoss)} PONTOS`}
            />
          </Box>
          <Divider />
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box margin="10px 0">
              <Subtitle title="Ingestão alimentar:" />
            </Box>
            <Box height="max-content" marginBottom="15px">
              <RadioButtonInput
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                control={control}
                name="lastMonthFood"
                disabled={false}
                title="Comparada com minha alimentação habitual, no último mês, eu tenho comido:"
                radioButtons={[
                  {
                    checked: watchFoodIngestionLastMonth === 'same',
                    label: 'A mesma coisa (0)',
                    value: 'same'
                  },
                  {
                    checked: watchFoodIngestionLastMonth === 'more',
                    label: 'Mais do que o habitual (0)',
                    value: 'more'
                  },
                  {
                    checked: watchFoodIngestionLastMonth === 'less',
                    label: 'Menos do que o habitual (1)',
                    value: 'less'
                  }
                ]}
              />
            </Box>
            <Box height="max-content" marginBottom="15px">
              <RadioButtonInput
                sx={{
                  marginTop: '10px',
                  height: 'max-content',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr'
                }}
                control={control}
                name="currentFood"
                disabled={false}
                title="Atualmente, eu estou comendo:"
                radioButtons={[
                  {
                    checked: watchFoodIngestionNow === 'lessSolidFood',
                    label: `a mesma comida (sólida) em menor quantidade do que o habitual (1)`,
                    value: 'lessSolidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'muchLessSolidFood',
                    label: 'a mesma comida (sólida) em pouca quantidade (2)',
                    value: 'muchLessSolidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'liquidFood',
                    label: 'apenas alimentos líquidos (3)',
                    value: 'liquidFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'suplements',
                    label: 'apenas suplementos nutricionais (3)',
                    value: 'suplements'
                  },
                  {
                    checked: watchFoodIngestionNow === 'littleFood',
                    label: 'muito pouca quantidade de qualquer alimento (4)',
                    value: 'littleFood'
                  },
                  {
                    checked: watchFoodIngestionNow === 'tube',
                    label: 'apenas alimentação por sonda ou pela veia (0)',
                    value: 'tube'
                  }
                ]}
              />
            </Box>

            <ChipComponent
              sx={{ width: '18%' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${
                setFoodIngestionQuantityPointsFunction(
                  watchFoodIngestionLastMonth
                ) + setFoodIngestionTypePointsFunction(watchFoodIngestionNow)
              } PONTOS`}
            />
          </Box>
          <Box margin="20px 0">
            <Divider />
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            <Box>
              <Subtitle title="Sintomas" />
              <Typography
                fontSize="14px"
                fontWeight="500"
                color={theme.palette.grey[900]}
              >
                Durante as duas últimas semanas, eu tenho tido os seguintes
                problemas que me impedem de comer o suficiente (marque todos os
                que estiver sentindo):
              </Typography>
            </Box>
            <Box width="100%" display="flex">
              <Box width="50%">
                <FormControlLabel
                  sx={{
                    color: theme.palette.grey[900],
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px'
                    }
                  }}
                  control={
                    <Checkbox
                      checked={normalFeed ? true : false}
                      color="info"
                      value={normalFeed}
                      onChange={(event) =>
                        setNormalFeedValue(event.target.checked)
                      }
                    />
                  }
                  label="sem problemas para me alimentar (0)"
                />
                {generateSymptoms(firstColumnSymptoms)}
                <Box display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    sx={{ color: theme.palette.grey[900] }}
                    control={
                      <Checkbox
                        checked={hasPain ? true : false}
                        color="info"
                        value={hasPain}
                        onChange={(event) =>
                          setPainFunction(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography
                          fontSize="14px"
                          color={theme.palette.grey[900]}
                        >
                          dor, onde?
                        </Typography>
                        <TooltipComponent
                          isSmall
                          content="Ao incluir dores, será considerado automaticamente, três pontos adicionais"
                        />
                      </Box>
                    }
                  />
                  {hasPain ? (
                    <ArrayInputComponent
                      label="Onde?"
                      placeholder="Digite aqui..."
                      subtitle="Dores adicionadas"
                      fieldData={painChips}
                      setFieldData={setPainChips}
                      inputWidth="100%"
                      buttonWidth="30%"
                    />
                  ) : (
                    ''
                  )}
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    sx={{ color: theme.palette.grey[900] }}
                    control={
                      <Checkbox
                        checked={hasOtherSymptoms ? true : false}
                        color="info"
                        value={hasOtherSymptoms}
                        onChange={(event) =>
                          setHasOtherSymptomsFunction(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography
                          fontSize="14px"
                          color={theme.palette.grey[900]}
                        >
                          outros
                        </Typography>
                        <TooltipComponent
                          isSmall
                          content="Ao incluir outros sintomas, será considerado automaticamente, um ponto adicional"
                        />
                      </Box>
                    }
                  />
                  {hasOtherSymptoms ? (
                    <ArrayInputComponent
                      label="Outros"
                      placeholder="Digite aqui..."
                      subtitle="Sintomas adicionados"
                      fieldData={otherSymptomsChips}
                      setFieldData={setOtherSymptomsChips}
                      inputWidth="100%"
                      buttonWidth="30%"
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Box width="50%">{generateSymptoms(secondColumnSymptoms)}</Box>
            </Box>
            <ChipComponent
              sx={{ width: '18%', margin: '20px 0' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${symptomsPoints} PONTOS`}
            />
          </Box>
          <Divider />
          <Box width="100%" display="flex" flexDirection="column">
            <Subtitle title="Atividades e função" />
            <Box>
              <RadioButtonInput
                control={control}
                name="activityLastMonth"
                disabled={false}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr 1fr 1fr'
                }}
                title="No último mês, de um modo geral, eu consideraria
                a minha atividade (função) como:"
                radioButtons={[
                  {
                    checked: watchActivityLastMonth === 'normal',
                    label: 'Normal, sem nenhuma limitação (0)',
                    value: 'normal'
                  },
                  {
                    checked: watchActivityLastMonth === 'almostNormal',
                    label: `Não totalmente normal, mas capaz de manter quase todas as atividades normais (1)`,
                    value: 'almostNormal'
                  },
                  {
                    checked: watchActivityLastMonth === 'tired',
                    label:
                      'Sem disposição para a maioria das coisas, mas ficando na cama ou na cadeira menos da metade do dia (2)',
                    value: 'tired'
                  },
                  {
                    checked: watchActivityLastMonth === 'mostTimeInBed',
                    label:
                      'Capaz de fazer pouca atividade e passando a maior parte do dia na cadeira ou na cama (3)',
                    value: 'mostTimeInBed'
                  },
                  {
                    checked: watchActivityLastMonth === 'laidUp',
                    label: 'Praticamente acamado, raramente fora da cama (3)',
                    value: 'laidUp'
                  }
                ]}
              />
            </Box>
            <ChipComponent
              sx={{ width: '18%', margin: '20px 0' }}
              color={{ colors: 'light-blue' }}
              label={`SOMA: ${setActivityLastMonthPointsFunction(
                watchActivityLastMonth
              )} PONTOS`}
            />
          </Box>
          <ChipComponent
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${finalFormClassification} PONTOS`}
          />
          <Box
            minHeight="65vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              borderTop="1px solid"
              borderColor={theme.palette.grey[200]}
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Formulários
              </Typography>
            </Box>
            <Subtitle title="Pontuando a perda de peso" />
            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                suffix="kg"
                placeholder="Ex: 00,0 kg"
                control={control}
                name="weight1Month"
                label="Há 1 mês eu costumava pesar (kg):"
              />
              <InputWithNumberMask
                sx={{ width: '47%' }}
                decimalScale={1}
                placeholder="Ex: 00,0 kg"
                suffix="kg"
                control={control}
                name="weight6Month"
                label="Há 6 meses eu costumava pesar (kg):"
              />
            </Box>
            <Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                padding="0 30px"
              >
                <Typography
                  fontSize="14px"
                  fontWeight="700"
                  color={theme.palette.info.main}
                >
                  Perda de peso em 1 mês
                </Typography>
                <Typography
                  marginRight="45px"
                  fontSize="14px"
                  fontWeight="700"
                  color={theme.palette.info.main}
                >
                  Pontos
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight="700"
                  color={theme.palette.info.main}
                >
                  Perda de peso em 6 meses
                </Typography>
              </Box>
              <ClassificationTable
                rows={[
                  {
                    color: { colors: 'light-red' },
                    firstCellContent: 'Maior que 10%',
                    secondCellContent: '4',
                    thirdCellContent: 'Maior que 20%',
                    selected: weightLossPercentage >= 10
                  },
                  {
                    color: { colors: 'light-orange' },
                    firstCellContent: 'Entre 5% e 9%',
                    secondCellContent: '3',
                    thirdCellContent: 'Entre 10% e 19,9%',
                    selected:
                      weightLossPercentage >= 5 && weightLossPercentage <= 9
                  },
                  {
                    color: { colors: 'light-yellow' },
                    firstCellContent: 'Entre 3% e 4,9%',
                    secondCellContent: '2',
                    thirdCellContent: 'Entre 6% e 9,9%',
                    selected:
                      weightLossPercentage >= 3 && weightLossPercentage <= 4.9
                  },
                  {
                    color: { colors: 'light-green' },
                    firstCellContent: 'Entre 2% e 2,9%',
                    secondCellContent: '1',
                    thirdCellContent: 'Entre 2% e 5,9%',
                    selected:
                      weightLossPercentage >= 2 && weightLossPercentage <= 2.9
                  },
                  {
                    color: { colors: 'light-blue' },
                    firstCellContent: 'Entre 0% e 1,9%',
                    secondCellContent: '0',
                    thirdCellContent: 'Entre 0% e 1,9%',
                    selected: weightLossPercentage <= 1.9
                  }
                ]}
              />
            </Box>
          </Box>
          <Box
            marginTop="30px"
            marginBottom="10px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="39vh"
          >
            <Subtitle title="Doenças e suas relações com as necessidades nutricionais:" />
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="relevantDiagnosis"
              label="Outros diagnósticos relevantes (especifique):"
              placeholder="Ex: paciente tem diabetes"
            />
            <RadioButtonInput
              control={control}
              disabled={false}
              name="primaryStaging"
              title="Estadiamento da doença primária (circule se conhecido ou apropriado):"
              radioButtons={[
                {
                  label: 'I',
                  checked: watchPrimaryStaging === '1',
                  value: '1'
                },
                {
                  label: 'II',
                  checked: watchPrimaryStaging === '2',
                  value: '2'
                },
                {
                  label: 'III',
                  checked: watchPrimaryStaging === '3',
                  value: '3'
                },
                {
                  label: 'IV',
                  checked: watchPrimaryStaging === '4',
                  value: '4'
                },
                {
                  label: 'Outro',
                  checked: watchPrimaryStaging === '5',
                  value: '5'
                }
              ]}
            />
            <Input
              sx={{ width: '100%' }}
              control={control}
              disabled={watchPrimaryStaging !== '5'}
              name="primaryStagingOther"
              label="Outro:"
              placeholder="Digite aqui"
            />
          </Box>
          <Box display="flex">
            <Box width="50%">{generateConditions(firstConditions)}</Box>
            <Box width="50%">{generateConditions(secondConditions)}</Box>
          </Box>
          <ChipComponent
            sx={{ width: '18%', marginBottom: '15px' }}
            color={{ colors: 'light-blue' }}
            label={`SOMA: ${conditionsPoints} PONTOS`}
          />
          <Divider />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="75vh"
        >
          <Box>
            <Subtitle title="Demanda metabólica" />
            <Typography
              fontSize="14px"
              fontWeight="500"
              color={theme.palette.grey[900]}
            >
              A pontuação para o stress metabólico é determinada pelo número de
              variáveis que aumentam as necessidades proteicas e calóricas.
              Nota: pontuar a intensidade da febre ou sua duração (o que for
              maior). A pontuação é aditiva, então o paciente que tem febre{' '}
              {'>'}38,9ºC (3 pontos) por menos de 72 horas (1 ponto) e toma 10mg
              de prednisona cronicamente (2 pontos) terá uma pontuação de 5
              pontos para esta seção.
            </Typography>
          </Box>
          <InputWithNumberMask
            sx={{ width: '20%' }}
            name="corporalTemperature"
            control={control}
            decimalScale={1}
            label="Temperatura corporal:"
            placeholder="Ex: 36°C"
            suffix="°C"
          />
          {renderTemperatureQuestions(temperatureQuestions)}
          <ChipComponent
            sx={{ width: '18%', marginBottom: '15px' }}
            color={{ colors: 'light-blue' }}
            label={`SOMA: ${temperatureQuestionsPoints} PONTOS`}
          />
        </Box>
        <Box minHeight="160vh">
          <Box margin="15px 0">
            <Subtitle title="Exame físico" />
            <Typography
              fontSize="14px"
              fontWeight="500"
              color={theme.palette.grey[900]}
            >
              O exame físico inclui a avaliação subjetiva de 3 aspectos da
              composição corporal: músculo, gordura e estado de hidratação. Como
              é subjetivo, cada item do exame é graduado pelo grau de déficit. O
              déficit muscular tem maior impacto no escore do que o déficit de
              gordura. Definição das categorias: 0 = sem déficit, 1+ = déficit
              leve, 2+ = déficit moderado, 3+ = déficit grave. A avaliação dos
              déficits nessas categorias não é aditiva, mas são usadas para
              avaliar clinicamente o grau global de déficit (ou presença de
              líquidos em excesso).
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {renderBodyQuestions(muscleStateQuestions)}
            {renderBodyQuestions(fatStateQuestions)}
            {renderBodyQuestions(hidratationStateQuestions)}
          </Box>
          <ChipComponent
            sx={{ width: '100%', margin: '15px 0' }}
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${finalFormClassification} PONTOS`}
          />
        </Box>
        <Box
          minHeight="70vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box marginBottom="20px">
            <Subtitle title="Avaliação global das categorias da PG-SGA" />
            <Typography
              fontSize="14px"
              fontWeight="500"
              color={theme.palette.grey[900]}
            >
              A avaliação global é subjetiva e pretende refletir uma avaliação
              das caixas 1 a 4 e do Formulário 4 (exame físico). Assinale em
              cada item e, conforme os resultados obtidos, selecione a categoria
              (A, B ou C).
            </Typography>
          </Box>
          <RadioButtonInput
            sx={{
              justifyContent: 'space-between',
              padding: '0 50px'
            }}
            control={control}
            disabled={false}
            name="globalCategory"
            title="Escolha uma categoria:"
            radioButtons={[
              {
                label: 'CATEGORIA A',
                value: 'a',
                checked: watchGlobalCategory === 'a'
              },
              {
                label: 'CATEGORIA B',
                value: 'b',
                checked: watchGlobalCategory === 'b'
              },
              {
                label: 'CATEGORIA C',
                value: 'c',
                checked: watchGlobalCategory === 'c'
              }
            ]}
          />
          <GlobalEvaluationTable
            firstColumnselected={watchGlobalCategory === 'a'}
            secondColumnselected={watchGlobalCategory === 'b'}
            thirdColumnselected={watchGlobalCategory === 'c'}
            rows={[
              {
                firstCell:
                  'PESO: Sem perda de peso OU ganho recente de peso (não hídrico)',
                secondCell:
                  'PESO: < 5% de perda de peso em 1 mês (ou < 10% em 6 meses) OU perda de peso progressiva',
                thirdCell:
                  'PESO: > 5% de perda de peso em 1 mês (ou > 10% em 6 meses) OU perda de peso progressiva'
              },
              {
                firstCell:
                  'INGESTÃO DE NUTRIENTES: Sem déficit OU melhoras ignificativa recente',
                secondCell:
                  'INGESTÃO DE NUTRIENTES: Diminuição evidente da ingestão',
                thirdCell: 'INGESTÃO DE NUTRIENTES: Grave déficit da ingestão'
              },
              {
                firstCell:
                  'SINTOMAS DE IMPACTO NUTRICONAL: Nenhum OU melhora significativa recente permitindo ingestão adequadas',
                secondCell:
                  'SINTOMAS DE IMPACTO NUTRICONAL: Presença de sintomas de impacto nutricional (caixa 3)',
                thirdCell:
                  'SINTOMAS DE IMPACTO NUTRICONAL: Presença de sintomas de impacto nutricional (caixa 3)'
              },
              {
                firstCell:
                  'FUNÇÃO: Sem déficit OU melhora significativa recente',
                secondCell:
                  'FUNÇÃO: Déficit funcional moderado OU piora recente',
                thirdCell: 'FUNÇÃO: Grave défict funcional OU piora recente'
              },
              {
                firstCell:
                  'EXAME FÍSICO: Sem déficit OU déficit crônico mas com melhora clínica recente',
                secondCell:
                  'EXAME FÍSICO: Evidência de perda leve a moderada de massa muscular e/ou tônus muscular à palpação e/ ou perda de gordura subcutânea',
                thirdCell:
                  'EXAME FÍSICO: Sinais óbvios de desnutrição (ex. Perda intensa de massa muscular, gordura e possível edema)'
              }
            ]}
          />
        </Box>
        <Box
          minHeight="46vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            width="100%"
            fontWeight="500"
            borderBottom="1px solid"
            borderColor="grey.200"
            fontSize="18px"
            color="grey.900"
          >
            Classificação
          </Typography>
          <ClassificationTable
            rows={[
              {
                color: { colors: 'light-green' },
                firstCellContent: '0-1',
                secondCellContent: 'Nenhuma intervenção necessária no momento',
                selected:
                  finalFormClassification >= 0 && finalFormClassification <= 1
              },
              {
                color: { colors: 'light-yellow' },
                firstCellContent: '2-3',
                secondCellContent:
                  'Aconselhamento ao paciente e a seus familiares',
                selected:
                  finalFormClassification >= 2 && finalFormClassification <= 3
              },
              {
                color: { colors: 'light-orange' },
                firstCellContent: '4-8',
                secondCellContent: 'Requer intervenção de nutricionista',
                selected:
                  finalFormClassification >= 4 && finalFormClassification <= 8
              },
              {
                color: { colors: 'light-red' },
                firstCellContent: 'Maior ou igual a 9',
                secondCellContent:
                  'Indica uma necessidade urgente de conduta para a melhora dos sintomas e/ ou opções de intervenção nutricional',
                selected: finalFormClassification >= 9
              }
            ]}
          />
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(ASGPPPForm);
