import { Result } from '..';
import { Bioparams } from 'models/bioparams';

export function verifyAlbuminValue(albumin: number | undefined): Result {
  if (!albumin) return { content: undefined, contentTitle: undefined };
  if (albumin >= 3.5) {
    return { content: 'Normal', contentTitle: 'Albumina: ' + albumin + 'g' };
  } else if (albumin < 3.5 && albumin >= 3.0) {
    return {
      content: 'Depleção leve',
      contentTitle: 'Albumina: ' + albumin + 'g'
    };
  } else if (albumin <= 2.9 && albumin >= 2.4) {
    return {
      content: 'Depleção moderada',
      contentTitle: 'Albumina: ' + albumin + 'g'
    };
  } else if (albumin < 2.4) {
    return {
      content: 'Depleção grave',
      contentTitle: 'Albumina: ' + albumin + 'µL'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyTransferrinValue(
  transferrin: number | undefined
): Result {
  if (!transferrin) return { content: undefined, contentTitle: undefined };
  if (transferrin < 400 && transferrin >= 200) {
    return {
      content: 'Normal',
      contentTitle: 'Transferrina: ' + transferrin + 'mg'
    };
  } else if (transferrin < 199 && transferrin >= 150) {
    return {
      content: 'Depleção leve',
      contentTitle: 'Transferrina: ' + transferrin + 'mg'
    };
  } else if (transferrin <= 149 && transferrin >= 100) {
    return {
      content: 'Depleção moderada',
      contentTitle: 'Transferrina: ' + transferrin + 'mg'
    };
  } else if (transferrin < 100) {
    return {
      content: 'Depleção grave',
      contentTitle: 'Transferrina: ' + transferrin + 'mg'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyPreAlbuminValue(preAlbumin: number | undefined): Result {
  if (!preAlbumin) return { content: undefined, contentTitle: undefined };
  if (preAlbumin > 20) {
    return {
      content: 'Normal',
      contentTitle: 'Pré-albumina: ' + preAlbumin + 'mg'
    };
  } else if (preAlbumin < 15 && preAlbumin >= 10) {
    return {
      content: 'Depleção leve',
      contentTitle: 'Pré-albumina: ' + preAlbumin + 'mg'
    };
  } else if (preAlbumin <= 10 && preAlbumin >= 5) {
    return {
      content: 'Depleção moderada',
      contentTitle: 'Pré-albumina: ' + preAlbumin + 'mg'
    };
  } else if (preAlbumin < 5) {
    return {
      content: 'Depleção grave',
      contentTitle: 'Pré-albumina: ' + preAlbumin + 'mg'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyRetinolValue(retinol: number | undefined): Result {
  if (!retinol) return { content: undefined, contentTitle: undefined };
  if (retinol >= 3) {
    return {
      content: 'Normal',
      contentTitle: 'Preoteína transportadora de retinol: ' + retinol + 'mg'
    };
  } else if (retinol < 3) {
    return {
      content: 'Depleção',
      contentTitle: 'Preoteína transportadora de retinol: ' + retinol + 'mg'
    };
  }
  return {
    content: 'Não há dados',
    contentTitle: ''
  };
}

export function verifyPcrValue(pcr: number | undefined): Result {
  if (!pcr) return { content: undefined, contentTitle: undefined };
  if (pcr < 0.3) {
    return { content: 'Normal', contentTitle: 'PCR: ' + pcr + 'mg' };
  } else if (pcr > 0.3 && pcr <= 1) {
    return {
      content: 'Normal ou elevação pequena',
      contentTitle: 'PCR: ' + pcr + 'mg'
    };
  } else if (pcr > 1 && pcr <= 10) {
    return { content: 'Elevação moderada', contentTitle: 'PCR: ' + pcr + 'mg' };
  } else if (pcr > 10 && pcr < 50) {
    return {
      content: 'Elevação importante',
      contentTitle: 'PCR: ' + pcr + 'mg'
    };
  } else if (pcr > 50) {
    return { content: 'Elevação severa', contentTitle: 'PCR: ' + pcr + 'mg' };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyHemoglobinValue(
  hemoglobin: number | undefined,
  gender: string
): Result {
  if (!hemoglobin) return { content: undefined, contentTitle: undefined };
  if (gender === 'MALE' && hemoglobin > 12) {
    return { content: 'Desnutrição leve', contentTitle: '' };
  } else if (gender === 'MALE' && hemoglobin > 10 && hemoglobin <= 12) {
    return {
      content: 'Desnutrição moderada',
      contentTitle: 'Hemoglobina: ' + hemoglobin + 'mg'
    };
  } else if (gender === 'MALE' && hemoglobin < 10) {
    return {
      content: 'Desnutrição grave',
      contentTitle: 'Hemoglobina: ' + hemoglobin + 'mg'
    };
  }

  if (gender === 'FEMALE' && hemoglobin > 10) {
    return {
      content: 'Desnutrição leve',
      contentTitle: 'Hemoglobina: ' + hemoglobin + 'mg'
    };
  } else if (gender === 'FEMALE' && hemoglobin > 8 && hemoglobin <= 10) {
    return {
      content: 'Desnutrição moderada',
      contentTitle: 'Hemoglobina: ' + hemoglobin + 'mg'
    };
  } else if (gender === 'FEMALE' && hemoglobin < 8) {
    return {
      content: 'Desnutrição grave',
      contentTitle: 'Hemoglobina: ' + hemoglobin + 'mg'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyHematocritValue(
  hematocrit: number | undefined,
  gender: string
): Result {
  if (!hematocrit) return { content: undefined, contentTitle: undefined };
  if (gender === 'MALE' && hematocrit > 36) {
    return { content: 'Desnutrição leve', contentTitle: '%' };
  } else if (gender === 'MALE' && hematocrit <= 36 && hematocrit >= 31) {
    return {
      content: 'Desnutrição moderada',
      contentTitle: 'Hematócrito: ' + hematocrit + '%'
    };
  } else if (gender === 'MALE' && hematocrit < 31) {
    return {
      content: 'Desnutrição grave',
      contentTitle: 'Hematócrito: ' + hematocrit + '%'
    };
  }

  if (gender === 'FEMALE' && hematocrit > 31) {
    return {
      content: 'Desnutrição leve',
      contentTitle: 'Hematócrito: ' + hematocrit + '%'
    };
  } else if (gender === 'FEMALE' && hematocrit <= 31 && hematocrit >= 24) {
    return {
      content: 'Desnutrição moderada',
      contentTitle: 'Hematócrito: ' + hematocrit + '%'
    };
  } else if (gender === 'FEMALE' && hematocrit < 24) {
    return {
      content: 'Desnutrição grave',
      contentTitle: 'Hematócrito: ' + hematocrit + '%'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyLymphocyteValue(lymphocyte: number | undefined): Result {
  if (!lymphocyte) return { content: undefined, contentTitle: undefined };
  if (lymphocyte >= 2000) {
    return {
      content: 'Normal',
      contentTitle: 'Contagem total de linfócitos: ' + lymphocyte + 'mm³'
    };
  } else if (lymphocyte >= 1200 && lymphocyte < 2000) {
    return {
      content: 'Depleção leve',
      contentTitle: 'Contagem total de linfócitos: ' + lymphocyte + 'mm³'
    };
  } else if (lymphocyte <= 1199 && lymphocyte >= 800) {
    return {
      content: 'Depleção moderada',
      contentTitle: 'Contagem total de linfócitos: ' + lymphocyte + 'mm³'
    };
  } else if (lymphocyte < 800) {
    return {
      content: 'Depleção grave',
      contentTitle: 'Contagem total de linfócitos: ' + lymphocyte + 'mm³'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyNeutrophylValue(neutrophyl: number | undefined): Result {
  if (!neutrophyl) return { content: undefined, contentTitle: undefined };
  if (neutrophyl > 1500) {
    return {
      content: 'Normal',
      contentTitle: 'Neutrófilos: ' + neutrophyl + 'mg'
    };
  } else if (neutrophyl >= 1000 && neutrophyl <= 1500) {
    return {
      content: 'Neutropenia leve',
      contentTitle: 'Neutrófilos: ' + neutrophyl + 'mg'
    };
  } else if (neutrophyl < 1000 && neutrophyl >= 500) {
    return {
      content: 'Neutropenia moderada',
      contentTitle: 'Neutrófilos: ' + neutrophyl + 'mg'
    };
  } else if (neutrophyl < 500) {
    return {
      content: 'Neutropenia grave',
      contentTitle: 'Neutrófilos: ' + neutrophyl + 'mg'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyCreatinineValue(
  creatinine: number | undefined,
  gender: string
): Result {
  if (!creatinine) return { content: undefined, contentTitle: undefined };
  if (gender === 'MALE' && creatinine < 0.6) {
    return {
      content: 'Abaixo',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  } else if (gender === 'MALE' && creatinine >= 0.6 && creatinine <= 1.2) {
    return {
      content: 'Normal',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  } else if (gender === 'MALE' && creatinine > 1.2) {
    return {
      content: 'Acima',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  }

  if (gender === 'FEMALE' && creatinine < 0.5) {
    return {
      content: 'Abaixo',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  } else if (gender === 'FEMALE' && creatinine >= 0.5 && creatinine <= 1.1) {
    return {
      content: 'Normal',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  } else if (gender === 'FEMALE' && creatinine > 1.1) {
    return {
      content: 'Acima',
      contentTitle: 'Creatinina: ' + creatinine + 'mg'
    };
  }
  return { content: undefined, contentTitle: undefined };
}
export function verifyUreaValue(urea: number | undefined): Result {
  if (!urea) return { content: undefined, contentTitle: undefined };
  if (urea > 50) {
    return { content: 'Acima', contentTitle: 'Ureia: ' + urea + 'mg' };
  } else if (urea <= 50 && urea >= 10) {
    return { content: 'Normal', contentTitle: 'Ureia: ' + urea + 'mg' };
  } else if (urea < 10) {
    return { content: 'Abaixo', contentTitle: 'Ureia: ' + urea + 'mg' };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyPlateletsValue(platelets: number | undefined): Result {
  if (!platelets) return { content: undefined, contentTitle: undefined };
  if (platelets < 140.0) {
    return {
      content: 'Plaquetopenia',
      contentTitle: 'Plaquetas: ' + platelets + 'µL'
    };
  } else if (platelets >= 140.0 && platelets < 450.0) {
    return {
      content: 'Sem classificação',
      contentTitle: 'Plaquetas: ' + platelets + 'µL'
    };
  } else if (platelets > 450.0) {
    return {
      content: 'Trombocitose',
      contentTitle: 'Plaquetas: ' + platelets + 'µL'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyLeukocytesValue(leukocytes: number | undefined): Result {
  if (!leukocytes) return { content: undefined, contentTitle: undefined };
  if (leukocytes <= 4.0) {
    return {
      content: 'Leucopenia',
      contentTitle: 'Leucócitos: ' + leukocytes + 'µL'
    };
  } else if (leukocytes >= 11.0) {
    return {
      content: 'Leucocitose',
      contentTitle: 'Leucócitos: ' + leukocytes + 'µL'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyTgoValue(tgo: number | undefined): Result {
  if (!tgo) return { content: undefined, contentTitle: undefined };
  if (tgo > 34) {
    return {
      content: 'Sobrecarga hepática',
      contentTitle: 'TGO: ' + tgo + 'µL'
    };
  } else if (tgo < 34) {
    return {
      content: 'Disfunção hepática',
      contentTitle: 'TGO: ' + tgo + 'µL'
    };
  } else if (tgo === 34) {
    return { content: 'Adequado', contentTitle: 'TGO: ' + tgo + 'µL' };
  }
  return { content: undefined, contentTitle: undefined };
}

export function verifyTgpValue(tgp: number | undefined): Result {
  if (!tgp) return { content: undefined, contentTitle: undefined };
  if (tgp <= 49 && tgp >= 10) {
    return { content: 'Adequado', contentTitle: 'tgp: ' + tgp + 'µL' };
  } else if (tgp < 10) {
    return {
      content: 'Disfunção hepática',
      contentTitle: 'tgp: ' + tgp + 'µL'
    };
  } else if (tgp > 49) {
    return {
      content: 'Sobrecarga hepática',
      contentTitle: 'tgp: ' + tgp + 'µL'
    };
  }
  return { content: undefined, contentTitle: undefined };
}

function calculateNitrogenBN(
  ingestedNitrogen: number,
  excretedNitrogen: number
) {
  const result = ingestedNitrogen - excretedNitrogen;

  if (result <= 0 && result >= -5) {
    return 'Metabolismo normal';
  }
  if (result < -5 && result >= -10) {
    return 'Hipermetabolismo leve ou nível de estresse 1';
  }
  if (result < -10 && result >= -15) {
    return 'Hipermetabolismo moderado ou nível de estresse 2';
  }
  if (result < -15) {
    return 'Hipermetabolismo grave ou nível de estresse 3';
  }
}

export function generateBioParamsBNResults(
  bioParams: Bioparams | undefined
): Result {
  if (!bioParams) {
    return {
      contentTitle: 'Não há dados para exibir',
      content: ''
    };
  }
  const ingestedNitrogen = bioParams.ingestedNitrogen;
  const excretedNitrogen = bioParams.excretedNitrogen;
  if (bioParams && ingestedNitrogen && excretedNitrogen) {
    return {
      contentTitle: 'Classificação BN',
      content: calculateNitrogenBN(ingestedNitrogen, excretedNitrogen)
    };
  }
  return {
    contentTitle: 'Não há dados para exibir',
    content: ''
  };
}
