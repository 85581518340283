import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AdvanceFormFooter } from 'components/AdvanceFormFooter';
import { MainContentContainer } from 'components/MainContentContainer';
import SymptonsForm from './form/index';
import { useQuery } from '@apollo/client';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { Symptoms } from './interfaces';

function Symptons() {
  const submitFormButtonRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();

  function handleSubmitSymptonsForm() {
    submitFormButtonRef.current?.click();
  }

  const [lastSymptoms, setLastSymptoms] = useState<Symptoms | undefined>();
  const patientId = Number(localStorage.getItem('patientId'));
  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.symptoms) setHasLastAppointment(true);
  }, [data]);

  function lastAppointmentSymptoms() {
    setLastSymptoms(data?.getLastPatientAppointment?.symptoms);
  }

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="3. Sintomas"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentSymptoms,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <SymptonsForm
            lastAppointmentSymptoms={lastSymptoms}
            ref={submitFormButtonRef}
          />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/disease-data')}
          action={() => handleSubmitSymptonsForm()}
        />
      </Box>
    </Box>
  );
}

export { Symptons };
