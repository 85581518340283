import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

import { Patient } from 'models/patient';
import { CardPatient } from './cardPatient';
import { SymptomsGraph } from './symptomsGraph';
import { FullAppointment } from './fullAppointment';

type Props = {
  patient: Patient;
};

export function Historic({ patient }: Props) {
  return (
    <>
      <Typography sx={{ padding: '20px 40px', color: '#565656' }} variant="h4">
        Histórico do paciente
      </Typography>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ flexGrow: 1 }}
        style={{ padding: '0px 40px' }}
      >
        {patient && (
          <>
            <Grid md={4} item>
              <CardPatient patient={patient} />
            </Grid>
            <Grid md={8} minWidth="65%" item>
              <SymptomsGraph appointments={patient.appointments} />
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ flexGrow: 1 }}
        style={{ padding: '30px 40px 0px 40px' }}
      >
        <Typography
          sx={{ float: 'left', paddingLeft: '15px', fontWeight: 500 }}
          color="#565656"
        >
          Histórico de consultas
        </Typography>
        <Divider
          sx={{
            flexGrow: 1,
            marginLeft: '10px',
            position: 'relative',
            top: '-10px'
          }}
        />
      </Grid>
      {patient?.appointments.map((app) => (
        <Grid container key={app.id} sx={{ padding: '10px 40px' }}>
          <FullAppointment
            gender={patient.gender}
            birthdate={patient.birthdate}
            appointment={app}
          />
        </Grid>
      ))}
    </>
  );
}
