import { theme } from 'styles/theme';

interface IColors {
  backgroundColor: string;
  textColor: string;
}

const renderDifferentColors = (
  color:
    | 'light-blue'
    | 'light-red'
    | 'light-yellow'
    | 'light-orange'
    | 'light-green'
    | 'green'
    | 'grey'
): IColors => {
  switch (color) {
    case 'light-blue':
      return {
        backgroundColor: theme.palette.info.light,
        textColor: theme.palette.info.main
      };
    case 'light-red':
      return {
        backgroundColor: theme.palette.error.light,
        textColor: theme.palette.error.main
      };
    case 'light-yellow':
      return {
        backgroundColor: theme.palette.secondary.light,
        textColor: theme.palette.secondary.main
      };
    case 'light-orange':
      return {
        backgroundColor: theme.palette.warning.light,
        textColor: theme.palette.warning.main
      };
    case 'light-green':
      return {
        backgroundColor: theme.palette.success.light,
        textColor: theme.palette.success.main
      };
    case 'green':
      return {
        backgroundColor: theme.palette.success.contrastText,
        textColor: theme.palette.background.paper
      };
    case 'grey':
      return {
        backgroundColor: theme.palette.background.default,
        textColor: theme.palette.grey[900]
      };
    default:
      return {
        backgroundColor: theme.palette.background.default,
        textColor: theme.palette.grey[400]
      };
  }
};

export { renderDifferentColors };
