import React from 'react';
import { Grid, OutlinedInput, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ButtonComponent } from 'components/Button';
import { useHistory } from 'react-router-dom';

type Props = {
  setPatientName: (name: string) => void;
};

export function Toolbar({ setPatientName }: Props) {
  const history = useHistory();
  return (
    <Grid
      justifyContent="center"
      container
      spacing={2}
      sx={{ flexGrow: 1 }}
      style={{ marginBottom: '30px' }}
    >
      <Grid item md={8} sm={8} xs={12}>
        <OutlinedInput
          onChange={(e) => {
            setPatientName(e.target.value);
          }}
          sx={{
            width: '100%',
            backgroundColor: 'white'
          }}
          placeholder="Pesquisar por pacientes ou prontuários..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: '#CECECE' }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item md={2} sm={4} xs={12} sx={{ marginTop: '10px' }}>
        <ButtonComponent
          width="100%"
          title="+ Adicionar retorno"
          onClick={() => {
            history.push({
              pathname: '/home/appointment-and-pacient-data',
              state: { type: 'return' }
            });
          }}
          isTransparent={false}
        />
      </Grid>
    </Grid>
  );
}
