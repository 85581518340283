import { gql } from '@apollo/client';

export const FIND_MST_BY_APPOINTMENT_ID = gql`
  query findMstByAppointmentId($appointmentId: Float!) {
    findMstByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      eatingLess
      weightLoss
      weightLossQuantity
    }
  }
`;
