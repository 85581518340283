import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { Symptoms as SymptomsInterface } from 'pages/Symptons/interfaces';
import { parseWeeklyMonthlyAndDailyText } from 'utils/constants/weeklyMonthlyAndDailyFrequency';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { editAppointment } from './editAppointment';

type SymptomsKey =
  | 'constipation'
  | 'nausea'
  | 'anosmia'
  | 'quicklySatisfied'
  | 'dryMouth'
  | 'dysphagia'
  | 'noAppetite'
  | 'ache'
  | 'diarrhea'
  | 'vomit'
  | 'smellBothers'
  | 'inappetence'
  | 'mucositis'
  | 'tiredness'
  | 'dysgeusia';

interface symptomsObjectArray {
  key: SymptomsKey;
}

const allSymptoms: symptomsObjectArray[] = [
  { key: 'constipation' },
  { key: 'nausea' },
  { key: 'anosmia' },
  { key: 'quicklySatisfied' },
  { key: 'dryMouth' },
  { key: 'dysphagia' },
  { key: 'noAppetite' },
  { key: 'ache' },
  { key: 'diarrhea' },
  { key: 'vomit' },
  { key: 'smellBothers' },
  { key: 'inappetence' },
  { key: 'mucositis' },
  { key: 'tiredness' },
  { key: 'dysgeusia' }
];

export function parseSymptomName(value: SymptomsKey | string) {
  switch (value) {
    case 'constipation':
      return 'Obstipação';
    case 'nausea':
      return 'Náusea';
    case 'anosmia':
      return 'Anosmia';
    case 'quicklySatisfied':
      return 'Se sente rapidamente satisfeito';
    case 'dryMouth':
      return 'Boca seca - Xerostomia';
    case 'dysphagia':
      return 'Problemas para engolir – Disfagia';
    case 'noAppetite':
      return 'Sem apetite, apenas sem vontade para comer';
    case 'ache':
      return 'Dor';
    case 'diarrhea':
      return 'Diarreia';
    case 'vomit':
      return 'Vômito';
    case 'smellBothers':
      return 'Os cheiros incomodam';
    case 'inappetence':
      return 'Inapetência';
    case 'mucositis':
      return 'Feridas na boca - Mucosite';
    case 'tiredness':
      return 'Cansaço';
    case 'dysgeusia':
      return 'Disgeusia';
  }
}

export function parseResumedSymptomName(value: SymptomsKey | string) {
  switch (value) {
    case 'constipoation': // Fix existent error inserting wrong data
      return 'Obstipação';
    case 'constipation':
      return 'Obstipação';
    case 'nausea':
      return 'Náusea';
    case 'anosmia':
      return 'Anosmia';
    case 'quicklySatisfied':
      return 'Rapid. satisfeito';
    case 'dryMouth':
      return 'Xerostomia';
    case 'dysphagia':
      return 'Disfagia';
    case 'noAppetite':
      return 'Sem apetite';
    case 'ache':
      return 'Dor';
    case 'diarrhea':
      return 'Diarreia';
    case 'vomit':
      return 'Vômito';
    case 'smellBothers':
      return 'Cheiros incomodam';
    case 'inappetence':
      return 'Inapetência';
    case 'mucositis':
      return 'Mucosite';
    case 'tiredness':
      return 'Cansaço';
    case 'dysgeusia':
      return 'Disgeusia';
  }
}

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newSymptomsType = MappedType<SymptomsInterface>;

export function Symptoms({ appointment }: { appointment: Appointment }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const symptoms: newSymptomsType = appointment.symptoms;
  if (!symptoms)
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |3. Sintomas
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  const others = symptoms?.others?.map(({ name, frequency }) => {
    return { frequency: parseWeeklyMonthlyAndDailyText(frequency) || '', name };
  });
  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();
  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |3. Sintomas
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/symptons')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          padding="10px 20px"
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allSymptoms.map((value, index) => {
            if (symptoms[value.key])
              return (
                <Box margin="30px 0" key={index} minWidth="33%">
                  <Label title={parseSymptomName(value.key) + ':' || ''} />
                  <ResultText
                    title={
                      parseWeeklyMonthlyAndDailyText(
                        symptoms[value.key] || ''
                      ) || ''
                    }
                  />
                </Box>
              );
            else return;
          })}
          {others &&
            others.map(({ frequency, name }) => {
              return (
                <Box margin="10px 0" minWidth="33%" key="frequency">
                  <Label title={name} />
                  <ResultText title={frequency} />
                </Box>
              );
            })}
        </Box>
      </Collapse>
    </Box>
  );
}
