import { gql } from '@apollo/client';

export const CREATE_STRENGHT_AND_FUNCTIONALITY = gql`
  mutation createStrenghtAndFunctionality(
    $strengthFunctionality: StrengthFuncionalityInput!
  ) {
    createStrengthFunctionality(strengthFunctionality: $strengthFunctionality) {
      id
      appointmentId
    }
  }
`;
