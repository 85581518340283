import * as yup from 'yup';
import { NutritionalData } from 'models/nutritionalData';
import { NutritionalIntervention } from 'models/nutritionalIntervention';

export interface NutritionalDataQueryType {
  findNutritionalDataByAppointmentId: NutritionalData;
}
export interface InterventionQueryType {
  findNutritionalInterventionByAppointmentId: NutritionalIntervention;
}
export interface IFormData {
  usedWeight: string;
  energyNeed: string;
  calorificValue: number;
  proteinNeed: string;
  proteinValue: number;
  foodIntake: string;
  nutritionalTherapy: string; //boolean;
  tno: string;
  tne: string;
  therapyDosage: number;
  TherapyProtein: number;
  therapyCalorific: number;
}

export const TNOOptions = [
  'Suplemento nutricional hiper/hiper',
  'Suplemento nutricional hipercalórico',
  'Suplemento nutricional hiperproteico',
  'Suplemento nutricional imunomodulador',
  'Módulo proteico',
  'Módulo de fibra'
];
export const TNEOptions = [
  'NE hiper/hiper',
  'NE hipercalórica',
  'NE hiperproteica',
  'NE imunomoduladora'
];

export const validationSchema = yup.object().shape({
  nutritionalTherapy: yup.string(),
  proteinValue: yup.number().required('Insira um valor proteíco'),
  calorificValue: yup.number().required('Insita um valor calórico'),
  TherapyProtein: yup.number().when('nutritionalTherapy', {
    is: 'yes',
    then: yup.number().required('Insira um valor proteíco').nullable(),
    otherwise: yup.number().notRequired().nullable()
  }),
  therapyCalorific: yup.number().when('nutritionalTherapy', {
    is: 'yes',
    then: yup.number().required('Insira um valor proteíco').nullable(),
    otherwise: yup.number().notRequired().nullable()
  })
});
