import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import { InputWithNumberMask } from 'components/InputWithNumberMask';
import { ChipComponent, ClassificationTable } from 'components';
import { toast } from 'react-toastify';
import { limitDecimal } from 'utils/functions/limitDecimal';

export function calculateManCMBPercentil(sex: string, age: number) {
  if (sex === 'MALE') {
    if (age >= 1 && age <= 1.9) return 12.7;
    if (age >= 2 && age <= 2.9) return 13;
    if (age >= 3 && age <= 3.9) return 13.7;
    if (age >= 4 && age <= 4.9) return 14.1;
    if (age >= 5 && age <= 5.9) return 14.7;
    if (age >= 6 && age <= 6.9) return 15.1;
    if (age >= 7 && age <= 7.9) return 16.0;
    if (age >= 8 && age <= 8.9) return 16.2;
    if (age >= 9 && age <= 9.9) return 17;
    if (age >= 10 && age <= 10.9) return 18;
    if (age >= 11 && age <= 11.9) return 18.3;
    if (age >= 12 && age <= 12.9) return 19.5;
    if (age >= 13 && age <= 13.9) return 21.1;
    if (age >= 14 && age <= 14.9) return 22.3;
    if (age >= 15 && age <= 15.9) return 23.7;
    if (age >= 16 && age <= 16.9) return 24.9;
    if (age >= 17 && age <= 17.9) return 25.8;
    if (age >= 18 && age <= 18.9) return 26.4;
    if (age >= 19 && age <= 24.9) return 27.3;
    if (age >= 25 && age <= 34.9) return 27.9;
    if (age >= 35 && age <= 44.9) return 28.6;
    if (age >= 45 && age <= 54.9) return 28.1;
    if (age >= 55 && age <= 64.9) return 27.8;
    if (age >= 65 /* && age <= 74.9 */) return 26.8;
  }
  return 0;
}

export function calculateWomanCMBPercentil(sex: string, age: number) {
  if (sex === 'FEMALE') {
    if (age >= 1 && age <= 1.9) return 12.4;
    if (age >= 2 && age <= 2.9) return 12.6;
    if (age >= 3 && age <= 3.9) return 13.2;
    if (age >= 4 && age <= 4.9) return 13.6;
    if (age >= 5 && age <= 5.9) return 14.2;
    if (age >= 6 && age <= 6.9) return 14.5;
    if (age >= 7 && age <= 7.9) return 15.1;
    if (age >= 8 && age <= 8.9) return 16.0;
    if (age >= 9 && age <= 9.9) return 16.7;
    if (age >= 10 && age <= 10.9) return 17.0;
    if (age >= 11 && age <= 11.9) return 18.1;
    if (age >= 12 && age <= 12.9) return 19.1;
    if (age >= 13 && age <= 13.9) return 19.8;
    if (age >= 14 && age <= 14.9) return 20.1;
    if (age >= 15 && age <= 15.9) return 20.2;
    if (age >= 16 && age <= 16.9) return 20.2;
    if (age >= 17 && age <= 17.9) return 20.5;
    if (age >= 18 && age <= 18.9) return 20.2;
    if (age >= 19 && age <= 24.9) return 20.7;
    if (age >= 25 && age <= 34.9) return 21.2;
    if (age >= 35 && age <= 44.9) return 21.8;
    if (age >= 45 && age <= 54.9) return 22;
    if (age >= 55 && age <= 64.9) return 22.5;
    if (age >= 65 /* && age <= 74.9 */) return 22.5;
  }
  return 0;
}

export function calculateCMBTextResult(result: number) {
  if (result < 70) return 'Desnutrição grave';
  if (result >= 70 && result < 80) return 'Desnutrição moderada';
  if (result >= 80 && result < 90) return 'Desnutrição leve';
  if (result >= 90) return 'Eutrofia';
  if (!result) return 'Aguardando circunferência';
}

export function calculateCMBValue(cb: number, dct: number) {
  const cmbValue = cb - dct * 0.314;
  return limitDecimal(cmbValue);
}

const CMBForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { age: number; sex: string; cb: number | undefined; dct: number | undefined }
> = ({ sex, age, cb, dct }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm();
  const watchCmbCircumference = watch('cmb');
  const [result, setResult] = useState(0);
  const [CMBPercentil, setCMBPercentil] = useState(0);

  useEffect(() => {
    setCMBPercentil(
      sex === 'MALE'
        ? calculateManCMBPercentil(sex, age)
        : calculateWomanCMBPercentil(sex, age)
    );
  }, []);

  function calculateCMBAdequacy(CMB: number) {
    const result = (CMB / CMBPercentil) * 100;
    setResult(result);
  }

  useEffect(() => {
    calculateCMBAdequacy(watchCmbCircumference);
  }, [watchCmbCircumference]);

  useEffect(() => {
    if (!cb) {
      toast.warn(
        'Para que o calculo automático seja feito corretamente, é preciso preencher as etapas CB e DCT'
      );
      return;
    }
    if (!dct) return;
    const cmbValue = calculateCMBValue(cb, dct);
    setValue('cmb', cmbValue);
  }, [dct]);

  function onSubmit() {
    return;
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          height="50vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                lineHeight="40px"
                color={theme.palette.grey[900]}
              >
                Circunferência muscular do braço
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              name="cmb"
              control={control}
              decimalScale={1}
              label={
                <Box>
                  <Typography>
                    Circunferência <i>(cm)</i>:
                  </Typography>
                </Box>
              }
              placeholder="Ex: 31cm"
              suffix="cm"
            />
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`RESULTADO: ${calculateCMBTextResult(result)}`}
              size="small"
            />
          </Box>
          <Box>
            <ClassificationTable
              size="small"
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Menor que 70%',
                  secondCellContent: 'Desnutrição grave',
                  selected: result < 70
                },
                {
                  color: { colors: 'light-orange' },
                  firstCellContent: 'Entre 70% e 80%',
                  secondCellContent: 'Desnutrição moderada',
                  selected: result >= 70 && result < 80
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 80% e 90%',
                  secondCellContent: 'Desnutrição leve',
                  selected: result >= 80 && result < 90
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: 'Entre 90 e 110%',
                  secondCellContent: 'Eutrofia',
                  selected: result >= 90
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(CMBForm);
