import { gql } from '@apollo/client';

export const CREATE_MUST = gql`
  mutation createMust($must: MUSTInput!) {
    createMust(must: $must) {
      id
      appointmentId
    }
  }
`;
