import { gql } from '@apollo/client';

export const CREATE_BIA = gql`
  mutation createBia($bia: BiaInput!) {
    createBia(bia: $bia) {
      appointmentId
      id
    }
  }
`;
