import React, { forwardRef, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  InputWithNumberMask,
  RadioButtonInput,
  TooltipComponent
} from 'components';
import { Patient } from 'models/patient';
import { FIND_PATIENT_BY_ID } from 'services/graphql/queries/patient/getPatientById';
import { theme } from 'styles/theme';
import { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalData/findNutritionalDataByAppointmentId';
import {
  CREATE_NUTRITIONAL_DATA,
  UPDATE_NUTRITIONAL_DATA,
  CreateNutritionalDataVars,
  UpdateNutritionalDataVars
} from 'services/graphql/mutations/nutritionalData';
import { useHistory } from 'react-router-dom';
import { NutritionalData } from 'models/nutritionalData';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  limitDecimal,
  calculateIMC,
  verifyWeightLossPercentage,
  verifyIMCClassification,
  calculatePatientAge
} from 'utils/functions';
import { FindByAppointmentIdVar } from 'shared';

const validationSchema = yup.object().shape({
  height: yup
    .number()
    .required('Insira uma altura')
    .max(270, 'A altura máxima é 2.70'),
  weight: yup
    .number()
    .required('Insira um peso')
    .max(600.99, 'O peso máximo é 600.99'),
  habitualWeight: yup
    .number()
    .required('Insira um peso habitual')
    .max(600.99, 'O peso máximo é 600.99')
});

interface IFormData {
  height: number;
  weight: number;
  habitualWeight: number;
  weightLossTime: string;
}

const defaultValues = {
  weightLossTime: 'oneWeek'
};

interface IQueryType {
  findNutritionalDataByAppointmentId: NutritionalData;
}

interface IQueryPatientType {
  findPatient: Patient;
}

const NutritionalDataForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { lastNutritionalData: NutritionalData | undefined }
> = ({ lastNutritionalData }, ref) => {
  const history = useHistory();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const patientId = Number(localStorage.getItem('patientId'));

  const { handleSubmit, control, watch, setValue } = useForm<IFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const {
    data: patientData,
    loading: loadingPatient,
    error: patientError
  } = useQuery<IQueryPatientType>(FIND_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId
    }
  });
  const {
    data: nutritionalData,
    loading,
    error
  } = useQuery<IQueryType, FindByAppointmentIdVar>(
    FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );
  const [createNutritionalData] = useMutation<null, CreateNutritionalDataVars>(
    CREATE_NUTRITIONAL_DATA
  );
  const [updateNutritionalData] = useMutation<null, UpdateNutritionalDataVars>(
    UPDATE_NUTRITIONAL_DATA
  );

  useEffect(() => {
    if (!lastNutritionalData) return;
    setNutritionalDataValues(lastNutritionalData);
  }, [lastNutritionalData]);

  const patientAge = useMemo<number>(() => {
    if (patientData && !patientError) {
      const { birthdate } = patientData.findPatient;
      const age = calculatePatientAge(birthdate);
      return age;
    }
    return 0;
  }, [patientData, patientError]);

  const loadedNutritionalData = useMemo<NutritionalData | undefined>(() => {
    if (nutritionalData?.findNutritionalDataByAppointmentId && !error) {
      const data = nutritionalData?.findNutritionalDataByAppointmentId;
      setNutritionalDataValues(data);
      return data;
    }
    return;
  }, [nutritionalData, error]);

  function setNutritionalDataValues(data: IFormData) {
    setValue('height', data.height * 100);
    setValue('weight', data.weight);
    setValue('habitualWeight', data.habitualWeight);
    setValue('weightLossTime', data.weightLossTime);
  }

  const watchHeight = watch('height') / 100;
  const watchWeight = watch('weight');
  const watchHabitualWeight = watch('habitualWeight');
  const watchWeightLoss = watch('weightLossTime');

  function verifyWeightLossClassification() {
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) > 0.1 &&
      watchWeightLoss === 'noWeightLoss'
    ) {
      setValue('weightLossTime', 'oneWeek');
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) >= 1 &&
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) <= 2 &&
      watchWeightLoss === 'oneWeek'
    ) {
      return 'Perda Significativa';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) > 2 &&
      watchWeightLoss === 'oneWeek'
    ) {
      return 'Perda Grave';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) === 5 &&
      watchWeightLoss === 'oneMonth'
    ) {
      return 'Perda Significativa';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) > 5 &&
      watchWeightLoss === 'oneMonth'
    ) {
      return 'Perda Grave';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) === 7.5 &&
      watchWeightLoss === 'threeMonths'
    ) {
      return 'Perda Significativa';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) > 7.5 &&
      watchWeightLoss === 'threeMonths'
    ) {
      return 'Perda Grave';
    }

    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) === 10 &&
      watchWeightLoss === 'sixMonths'
    ) {
      return 'Perda Significativa';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) > 10 &&
      watchWeightLoss === 'sixMonths'
    ) {
      return 'Perda Grave';
    }
    if (watchWeightLoss === 'noWeightLoss') {
      return 'Não houve perda';
    }
    if (
      verifyWeightLossPercentage(watchWeight, watchHabitualWeight) <= 0 &&
      watchWeightLoss !== 'noWeightLoss'
    ) {
      setValue('weightLossTime', 'noWeightLoss');
      return 'Não houve perda';
    }
    return 'Sem perda significativa';
  }

  const tableRows = [
    {
      firstCell: 'Houve alteração de peso?',
      secondCell: watchHabitualWeight !== watchWeight ? 'Sim' : 'Não'
    },
    {
      firstCell: 'Valor total da perda de peso:',
      secondCell:
        watchHabitualWeight - watchWeight < 0
          ? '-'
          : limitDecimal(watchHabitualWeight - watchWeight) || 0
    },
    {
      firstCell: 'Valor total de ganho de peso:',
      secondCell:
        watchWeight - watchHabitualWeight < 0
          ? '-'
          : limitDecimal(watchWeight - watchHabitualWeight) || 0
    },
    {
      firstCell: 'Peso ideal:',
      secondCell: Math.round(Math.pow(watchHeight, 2) * 21) || 0
    },
    {
      firstCell: 'IMC:',
      secondCell: calculateIMC(watchHeight, watchWeight) || 0
    },
    {
      firstCell: 'Classificação IMC:',
      secondCell: verifyIMCClassification(
        calculateIMC(watchHeight, watchWeight),
        patientAge
      )
    },
    {
      firstCell: 'Percentual da perda de peso:',
      secondCell:
        verifyWeightLossPercentage(watchWeight, watchHabitualWeight) + '%'
    },
    {
      firstCell: 'Classificação da perda de peso:',
      secondCell: verifyWeightLossClassification(),
      tooltipText:
        'A classificação leva em consideração o tempo da perda de peso com o percentual da perda de peso'
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function createNutritionalDataFunction(nutritionalData: IFormData) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (!loadedNutritionalData) {
      await createNutritionalData({
        variables: {
          nutritionalData: {
            appointmentId,
            ...nutritionalData,
            height: watchHeight
          }
        },
        onCompleted: () => {
          toast.success('Dados nutricionais criados');
          history.push('/home/nutritional-risk-screening');
        }
      });
    }
  }

  async function updateNutritionalDataFunction(nutritionalData: IFormData) {
    const appointmentId = Number(localStorage.getItem('appointmentId'));
    if (loadedNutritionalData) {
      await updateNutritionalData({
        variables: {
          id: loadedNutritionalData.id,
          nutritionalData: {
            appointmentId,
            ...nutritionalData,
            height: watchHeight
          }
        },
        onCompleted: () => {
          toast.info('Dados nutricionais atualizados');
          history.push('/home/nutritional-risk-screening');
        }
      });
    }
  }

  function onSubmit(data: IFormData) {
    try {
      createNutritionalDataFunction(data);
      updateNutritionalDataFunction(data);
    } catch {
      toast.error('Houve um erro os criando dados nutricionais');
    }
  }

  if (loading || loadingPatient) return <CircularProgress />;

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="70vh"
        >
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <InputWithNumberMask
              format="#.##"
              decimalScale={2}
              fixedDecimalScale={true}
              name="height"
              suffix="m"
              control={control}
              placeholder="Ex: 0,00m"
              label="Altura (m):"
            />

            <InputWithNumberMask
              decimalScale={1}
              name="weight"
              suffix="kg"
              control={control}
              placeholder="Ex: 000,0kg"
              label="Peso Atual (kg):"
            />

            <InputWithNumberMask
              decimalScale={1}
              name="habitualWeight"
              suffix="kg"
              control={control}
              placeholder="Ex: 000,0kg"
              label="Peso Habitual (kg):"
            />
          </Box>
          <Box>
            <RadioButtonInput
              disabled={false}
              title="Tempo de perda de peso:"
              control={control}
              name="weightLossTime"
              radioButtons={[
                {
                  checked: watchWeightLoss === 'oneWeek',
                  label: '1 semana',
                  value: 'oneWeek'
                },
                {
                  checked: watchWeightLoss === 'oneMonth',
                  label: '1 mês',
                  value: 'oneMonth'
                },
                {
                  checked: watchWeightLoss === 'threeMonths',
                  label: '3 meses',
                  value: 'threeMonths'
                },
                {
                  checked: watchWeightLoss === 'sixMonths',
                  label: '6 meses',
                  value: 'sixMonths'
                },
                {
                  checked: watchWeightLoss === 'noWeightLoss',
                  label: 'Sem perda de peso',
                  value: 'noWeightLoss'
                }
              ]}
            />
          </Box>

          <TableContainer>
            <Typography
              fontWeight="500"
              fontSize="16px"
              color={theme.palette.grey[400]}
            >
              CALCULO DO INDÍCES:
            </Typography>
            <Table
              sx={{
                border: '1px solid',
                borderColor: theme.palette.divider
              }}
            >
              <TableBody
                sx={{
                  height: '35px'
                }}
              >
                {tableRows.map(({ firstCell, secondCell, tooltipText }) => {
                  return (
                    <TableRow
                      key={firstCell}
                      sx={{
                        '&.MuiTableRow-root': {
                          minHeight: '45px'
                        }
                      }}
                    >
                      <TableCell
                        size="small"
                        sx={{
                          borderColor: theme.palette.divider,
                          color: theme.palette.grey[300]
                        }}
                        width="55%"
                      >
                        {firstCell}
                        {tooltipText ? (
                          <TooltipComponent content={tooltipText} />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{
                          background: theme.palette.background.default,
                          border: '1px solid',
                          borderColor: theme.palette.divider,
                          color: theme.palette.grey[900],
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      >
                        {secondCell}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button type="submit" ref={ref} sx={{ display: 'none' }}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(NutritionalDataForm);
