import { Statistic } from 'models/dashboard';
import { parseResumedSymptomName } from '../../Patient/historicAppointmentSteps/symptoms';
import { translateTneName } from './prepareTne';

export const prepareSymptomsTne = (
  data: {
    key: string;
    statistics: Statistic[];
  }[]
) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  for (const t of data) {
    const tne: any = {};
    tne.id = parseResumedSymptomName(t.key);

    for (const c of t.statistics) {
      const name = translateTneName(c.name);
      tne[name] = c.quantity;
      ret.keys.add(name);
    }

    ret.data.push(tne);
  }

  return ret;
};
