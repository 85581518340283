import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type CidsDialogProps = {
  open: boolean;
  onClose: () => void;
  cidData: CidData[];
};

export type CidData = {
  name: string;
  cid: string;
  quant: number;
};

const BlueCell = ({ children }: any) => {
  return (
    <TableCell sx={{ color: '#002169', backgroundColor: '#EDF6FF' }}>
      {children}
    </TableCell>
  );
};

export function CidsDialog({ open, onClose, cidData }: CidsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography sx={{ paddingRight: '24px' }}>
          Todos os CIDs atendidos
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <TableContainer sx={{ width: '600px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <BlueCell>Nome</BlueCell>
                <BlueCell>CID</BlueCell>
                <BlueCell>Quant Casos</BlueCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cidData.map((cid) => (
                <TableRow key={cid.cid}>
                  <TableCell>{cid.name}</TableCell>
                  <TableCell>{cid.cid}</TableCell>
                  <TableCell>{cid.quant}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
