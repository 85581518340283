import { Statistic } from 'models/dashboard';
import { parseDiagnosisResult } from './translateDiagnosis';

export const prepareCidNutriDiag = (
  data: {
    key: string;
    statistics: Statistic[];
  }[]
) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  for (const dt of data) {
    const cid: any = {};
    cid.id = dt.key;

    for (const c of dt.statistics) {
      const name = parseDiagnosisResult(c.name);
      cid[name] = c.quantity;
      ret.keys.add(name);
    }

    ret.data.push(cid);
  }

  return ret;
};
