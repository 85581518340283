import { gql } from '@apollo/client';

const NUTRITIONIST_DASHBOARD_TOTALS = gql`
  query nutriDashTotals {
    nutriDashTotals {
      totalAppointments
      totalMonth
      totalIntervention
      eutrophics
      malnutritionRisk
      malnourished
      sarcopenia
      neoplasms
    }
  }
`;

export { NUTRITIONIST_DASHBOARD_TOTALS };
