export const colors = [
  '#008FBE',
  '#65AC28',
  '#E35518',
  '#FFBD14',
  '#FF73C3',
  '#7B868C',
  '#FFBD14',
  '#FF8A65',
  '#E64A19',
  '#8D6E63',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#f95d6a',
  '#ff7c43'
];

export const shuffleColors = () => {
  const arr = colors;
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};
