import { Appointment } from 'models/appointment';

export function prepareSymptoms(appointments: Appointment[]) {
  return appointments.map((a) => {
    const ret = [];
    a.symptoms?.constipation && ret.push('Constipação');
    a.symptoms?.nausea && ret.push('Náusea');
    a.symptoms?.anosmia && ret.push('Anosmia');
    a.symptoms?.quicklySatisfied && ret.push('Rapidamente Satisfeito');
    a.symptoms?.dryMouth && ret.push('Boca Seca');
    a.symptoms?.dysphagia && ret.push('Disfagia');
    a.symptoms?.noAppetite && ret.push('Sem apetite');
    a.symptoms?.ache && ret.push('Dor');
    a.symptoms?.diarrhea && ret.push('Diarreia');
    a.symptoms?.vomit && ret.push('Vômito');
    a.symptoms?.smellBothers && ret.push('Cheiros incomodam');
    a.symptoms?.inappetence && ret.push('Inapetência');
    a.symptoms?.mucositis && ret.push('Mucosite');
    a.symptoms?.tiredness && ret.push('Cansaço');
    a.symptoms?.dysgeusia && ret.push('Disgeusia');
    return ret;
  });
}
