import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EcoGForm from './forms/eco-g';
import {
  TooltipComponent,
  DetailedRadioButton,
  Subtitle,
  AdvanceFormFooter,
  MainContentContainer
} from 'components';
import { useQuery } from '@apollo/client';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FullAppointment } from 'models/fullAppointment';
import { Ecog } from 'models/ecog';
import {
  FIND_ECOG_BY_APPOINTMENT_ID,
  FindEcogReturn
} from 'services/graphql/queries/ecog';
import { FindByAppointmentIdVar } from 'shared';

function LifeQualityScale() {
  const history = useHistory();
  const [form, setForm] = useState('none');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [ecogValue] = useState(30);
  const {
    data: ecogData,
    loading,
    error
  } = useQuery<FindEcogReturn, FindByAppointmentIdVar>(
    FIND_ECOG_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: { appointmentId }
    }
  );

  const loadedData = useMemo(() => {
    if (ecogData && !error) {
      setForm('eco-g');
      return ecogData.findEcogByAppointmentId;
    }
  }, [ecogData, error, loading]);

  const [lastEcog, setLastEcog] = useState<Ecog | undefined>();
  const patientId = Number(localStorage.getItem('patientId'));
  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentEcog() {
    setLastEcog(data?.getLastPatientAppointment.ecog);
    if (data) setForm('eco-g');
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.ecog) setHasLastAppointment(true);
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="4. Escala de qualidade de vida"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentEcog,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box display="flex" alignItems="center" width="100%">
            <Subtitle title="Escolher escala" />
            <TooltipComponent content="O escore de ambas ferramentas variam de 0 a 100, onde 0 representa pior estado de saúde e 100 melhor estado de saúde, com exceção das escalas de sintomas nas quais maior escore representa mais sintomas e pior qualidade de vida." />
          </Box>

          <DetailedRadioButton
            buttons={[
              {
                title: 'Nenhum',
                description:
                  'Nenhuma escala de qualidade de vida será adicionada aos dados do paciente.',
                action: () => setForm('none'),
                checked: form === 'none',
                value: 'none',
                chipData: `SEM PONTOS`,
                tooltipText:
                  'Nenhuma escala de qualidade de vida será adicionada.'
              },
              {
                title: 'EORTC-C30 ',
                description:
                  'Considera algumas neoplasias específicas, é composto por 30 questões',
                action: () => setForm('eco-g'),
                checked: form === 'eco-g',
                value: 'eco-g',
                chipData: `RESULTADO TOTAL: ${ecogValue} PONTOS`,
                tooltipText:
                  'Considera algumas neoplasias específicas, é composto por 30 questões em cinco escalas funcionais (desempenho físico, funcional, cognitivo, emocional e social), três escalas de sintomas (fadiga, dor, náusea e vômito) com escalas de QV e estado de saúde global.'
              }
            ]}
          />
          <Box width="100%" margin="30px 0">
            <Divider sx={{ height: '10px' }} />
          </Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <span>
              {form === 'eco-g' && (
                <EcoGForm
                  loadedEcog={loadedData}
                  lastEcog={lastEcog}
                  ref={submitButtonRef}
                />
              )}
              {form === 'none' && ''}
            </span>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/symptons')}
          action={() =>
            form === 'none'
              ? history.push('/home/biochemical-parameters')
              : submitButtonRef.current?.click()
          }
        />
      </Box>
    </Box>
  );
}

export { LifeQualityScale };
