import React from 'react';
import { NavigateNext } from '@mui/icons-material';
import { ConsultationStepsList } from 'components/ConsultationStepsList';
import { theme } from 'styles/theme';
import { Header } from 'components/Header';
import { AddConsultationRoutes } from 'routes/AddConsultationRoutes';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Breadcrumbs } from '@mui/material';

function AddConsultationHome() {
  const history = useHistory();
  return (
    <>
      <>
        <Header />
        <Box display="flex" padding="15px 0" flexDirection="column">
          <Box padding="10px 55px">
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Typography
                sx={{
                  cursor: 'pointer'
                }}
                fontSize="12px"
                onClick={() => history.push('/dashboard/nutritionist')}
              >
                Dashboard
              </Typography>
              <Typography
                sx={{
                  cursor: 'pointer'
                }}
                fontSize="12px"
                fontWeight="500"
              >
                Adicionar consulta
              </Typography>
            </Breadcrumbs>
            <Typography
              fontWeight="700"
              color={theme.palette.grey[900]}
              variant="h6"
            >
              Adicionar consulta
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-around"
            padding="0 22px"
          >
            <ConsultationStepsList />
            <AddConsultationRoutes />
          </Box>
        </Box>
      </>
    </>
  );
}

export { AddConsultationHome };
