import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdvanceFormFooter, MainContentContainer } from 'components';
import { FullAppointment } from 'models/fullAppointment';
import { NutritionalData } from 'models/nutritionalData';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import NutritionalDataForm from './form';

function NutritionalDataAndInformation() {
  const history = useHistory();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [lastNutritionalData, setLastNutritionalData] = useState<
    NutritionalData | undefined
  >();
  const patientId = Number(localStorage.getItem('patientId'));
  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function lastAppointmentNutritionalData() {
    setLastNutritionalData(data?.getLastPatientAppointment.NutritionalData);
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.NutritionalData)
      setHasLastAppointment(true);
  }, [data]);

  function submitForm() {
    submitButtonRef.current?.click();
  }

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="6. Dados e informações nutricionais"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentNutritionalData,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <NutritionalDataForm
            lastNutritionalData={lastNutritionalData}
            ref={submitButtonRef}
          />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/biochemical-parameters')}
          action={() => submitForm()}
        />
      </Box>
    </Box>
  );
}

export { NutritionalDataAndInformation };
