import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { MainContentContainer } from 'components/MainContentContainer';
import { AdvanceFormFooter } from 'components/AdvanceFormFooter';
import { useHistory } from 'react-router-dom';
import { Subtitle } from 'components/Subtitle';
import DiseaseDataForm from './form/index';
import { useQuery } from '@apollo/client';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { DiseaseData as DiseaseDataInterface } from 'models/diseaseData';

function DiseaseData() {
  const history = useHistory();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [lastDisease, setLastDisease] = useState<
    DiseaseDataInterface | undefined
  >();
  const patientId = Number(localStorage.getItem('patientId'));
  const { data } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  function submitDiseaseDataForm() {
    submitButtonRef.current?.click();
  }

  function lastAppointmentDisease() {
    setLastDisease(data?.getLastPatientAppointment.diseaseData);
  }
  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    if (data?.getLastPatientAppointment?.diseaseData)
      setHasLastAppointment(true);
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="2. Dados da doença"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: hasLastAppointment,
          action: lastAppointmentDisease
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box width="100%">
            <Subtitle title="Diagnóstico principal" />
          </Box>
          <DiseaseDataForm lastDisease={lastDisease} ref={submitButtonRef} />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/appointment-and-pacient-data')}
          action={() => submitDiseaseDataForm()}
        />
      </Box>
    </Box>
  );
}

export { DiseaseData };
