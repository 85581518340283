import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  InputWithNumberMask,
  ChipComponent
} from 'components';
import { theme } from 'styles/theme';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { StrengthFunctionality } from 'models/strenghtFunctionality';
import { useMutation } from '@apollo/client';
import {
  CREATE_STRENGHT_AND_FUNCTIONALITY,
  UPDATE_STRENGHT_AND_FUNCTIONALITY,
  StrenghtFunctionalityMutationsVars
} from 'services/graphql/mutations/strenghtAndFunctionality';

interface IFormData {
  tug: number;
}

export function setTugStatusFunction(tug: number) {
  if (tug < 20) return 'Alterado';
  if (tug === 20) return 'Normal';
  if (tug > 20) return 'Baixa funcionalidade';
  return 'Aguardando dados';
}

const TUGForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    loadedStrenght: StrengthFunctionality | undefined;
    lastStrenght: StrengthFunctionality | undefined;
  }
> = ({ loadedStrenght, lastStrenght }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm({});
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const watchTug = watch('tug');
  const [tugStatus, setTugStatus] = useState('Aguardando dados');
  const [createStrenghtTUGMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(CREATE_STRENGHT_AND_FUNCTIONALITY);
  const [updateStrenghtTUGMutation] = useMutation<
    null,
    StrenghtFunctionalityMutationsVars
  >(UPDATE_STRENGHT_AND_FUNCTIONALITY);

  useEffect(() => {
    setTugStatus(setTugStatusFunction(watchTug));
  }, [watchTug]);

  const history = useHistory();

  function setLoadedTUGValue(data: StrengthFunctionality | undefined) {
    if (!data) return;
    setValue('tug', data.tug);
  }

  useEffect(() => {
    setLoadedTUGValue(loadedStrenght);
  }, [loadedStrenght]);

  useEffect(() => {
    setLoadedTUGValue(lastStrenght);
  }, [lastStrenght]);

  async function createStrenght({ tug }: IFormData) {
    if (loadedStrenght || !tug) return;
    await createStrenghtTUGMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          tug
        }
      },
      onCompleted: () => {
        toast.success('Avaliação de força e funcionalidade TUG adicionada');
      }
    });
  }

  async function updateStrenght({ tug }: IFormData) {
    if (!loadedStrenght) return;
    await updateStrenghtTUGMutation({
      variables: {
        strengthFunctionality: {
          appointmentId,
          tug
        }
      },
      onCompleted: () => {
        toast.info('Avaliação de força e funcionalidade TUG adicionada');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createStrenght(data);
      updateStrenght(data);
      history.push('/home/nutritional-diagnosis-and-classification');
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="25vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Teste de velocidade de marcha
              </Typography>
              <TooltipComponent
                content="Teste de velocidade de marcha. No Timed-Up and Go (TUG), 
                avalia-se a funcionalidade solicitando que os indivíduos se 
                levantem de uma cadeira, caminhem até um marcador a 3m de distância, 
                virem e retornem para sentar-se novamente.
                "
              />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              sx={{ width: '40%' }}
              name="tug"
              control={control}
              decimalScale={2}
              label={
                <Box>
                  <Typography>
                    Teste de velocidade de marcha <i>(segundos):</i>
                  </Typography>
                </Box>
              }
              placeholder="Ex: 00,00 segundos"
              suffix=" segundos"
            />
          </Box>
          <ChipComponent
            sx={{ width: '40%' }}
            color={{
              colors: tugStatus === 'Alterado' ? 'light-red' : 'light-blue'
            }}
            label={`Mobilidade funcional: ${tugStatus}`}
            size="small"
          />
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(TUGForm);
