import { gql } from '@apollo/client';

export const FIND_DISEASE_DATA_BY_APPOINTMENT_ID = gql`
  query findDisease($appointmentId: Float!) {
    findDisease(appointmentId: $appointmentId) {
      id
      appointmentId
      smoking
      alcoholism
      bowelHabit
      urinaryHabit
      waterIntake
      others {
        id
        type
        description
      }
      cids {
        id
        code
        staging
      }
      treatments {
        id
        code
      }
    }
  }
`;
