import { gql } from '@apollo/client';

const CREATE_APPOINTMENT = gql`
  mutation createAppointment($appointment: AppointmentInput!) {
    createAppointment(appointment: $appointment) {
      id
      appointmentOrder
      patientId
      clinicId
      userId
    }
  }
`;

export { CREATE_APPOINTMENT };
