import { Result } from '..';
import { FullAppointment } from 'models/fullAppointment';
import { Man } from 'models/man';
import { Mst } from 'models/mst';
import { Must } from 'models/must';
import { Nrs } from 'models/nrs';
import { calculateNEASGPPPResults } from './renderNutritionalEvaluationResults';

function calculateNRMANResult(MAN: Man | undefined) {
  if (MAN) {
    const {
      foodIntakeDecrease,
      weightLoss,
      mobility,
      recentStress,
      neurologicalProblems,
      imc
    } = MAN;
    const result =
      foodIntakeDecrease +
      weightLoss +
      mobility +
      recentStress +
      neurologicalProblems +
      imc;

    if (Number(result) <= 7) return 'Desnutrido';
    if (Number(result) >= 8 && Number(result) <= 11)
      return 'Risco de desnutrição';
    if (Number(result) >= 12 && Number(result) <= 14) return 'Normal';
  }
}

function calculateNRMSTResult(Mst: Mst) {
  if (Mst) {
    const { eatingLess, weightLoss, weightLossQuantity } = Mst;
    const result = eatingLess + weightLoss + weightLossQuantity;

    if (Number(result) === 0) return 'Baixo risco';
    if (Number(result) === 1) return 'Médio risco';
    if (Number(result) >= 2) return 'Alto risco';
  }
}

function calculateNRMUSTResult(Must: Must) {
  if (Must) {
    const { cannotFeed, imcPontuation, weightLoss } = Must;
    const result = cannotFeed + weightLoss + imcPontuation;

    if (Number(result) === 0) return 'Baixo risco';
    if (Number(result) === 1) return 'Médio risco';
    if (Number(result) >= 2) return 'Alto risco';
  }
}

function calculateNRSResult(Nrs: Nrs) {
  if (Nrs) {
    const { diseaseLevel, nutritionalStatusDeterioration } = Nrs;
    const result = diseaseLevel + nutritionalStatusDeterioration;

    if (Number(result) <= 3) return 'Risco nutricional';
    if (Number(result) > 3) return 'Reavaliar semanalmente';
  }
}

export function generateNutritionalRiskResults(
  loadedAppointment: FullAppointment | undefined
): Result {
  if (loadedAppointment?.nrAsgppp) {
    return {
      contentTitle: 'ASG-PPP',
      content:
        calculateNEASGPPPResults(loadedAppointment) === ''
          ? 'Etapa de avaliação nutricional ASG-PPP não preenchida'
          : calculateNEASGPPPResults(loadedAppointment)
    };
  }
  if (loadedAppointment?.MAN) {
    return {
      contentTitle: 'MAN',
      content: calculateNRMANResult(loadedAppointment.MAN)
    };
  }
  if (loadedAppointment?.Mst) {
    return {
      contentTitle: 'MST',
      content: calculateNRMSTResult(loadedAppointment.Mst)
    };
  }
  if (loadedAppointment?.Must) {
    return {
      contentTitle: 'MUST',
      content: calculateNRMUSTResult(loadedAppointment.Must)
    };
  }
  if (loadedAppointment?.Nrs) {
    return {
      contentTitle: 'NRS',
      content: calculateNRSResult(loadedAppointment.Nrs)
    };
  }
  return {
    contentTitle: 'Não há dados para exibir',
    content: ''
  };
}
