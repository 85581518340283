export const urinaryHabitFrequency = [
  {
    label: '1x por dia',
    value: 'oncePerDay'
  },
  {
    label: 'Mais de 1x por dia',
    value: 'moreThanOncePerDay'
  }
];

export function parseUrinaryHabitName(value: string) {
  switch (value) {
    case 'oncePerDay':
      return '1x por dia';
    case 'moreThanOncePerDay':
      return 'Mais de 1x por dia';
    default:
      return 'Não há dados';
  }
}
