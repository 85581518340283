import { gql } from '@apollo/client';

export const FIND_ANTHROPOMETRIC_BY_APPOINTMENT_ID = gql`
  query antropometric($appointmentId: Float!) {
    findAntropometricByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      cp
      cb
      dct
    }
  }
`;
