import { gql } from '@apollo/client';

export const FIND_STRENGHT_AND_FUNCTIONALITY = gql`
  query findStrenghtAndFunctionality($appointmentId: Float!) {
    findStFncByAppointmentId(appointmentId: $appointmentId) {
      id
      appointmentId
      handGrip
      tug
      sitGetup
    }
  }
`;
