import React from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { StyledFormControlLabel } from './style';

interface IMUIRadioButtonProps
  extends Pick<RadioGroupProps, 'sx' | 'defaultValue' | 'onChange' | 'value'> {
  name: string;
  control: Control<any>;
  radioButtons: IRadioButtonContent[];
  disabled: boolean;
  title: string | React.ReactNode;
}

interface IRadioButtonContent {
  label: string;
  value: string;
  checked: boolean;
}

function RadioButtonInput({
  name,
  control,
  radioButtons,
  disabled,
  title,
  ...props
}: IMUIRadioButtonProps) {
  const generateRadioOptions = () => {
    return radioButtons.map((singleOption) => (
      <StyledFormControlLabel
        checked={singleOption.checked}
        key={singleOption.label}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio size="small" />}
      />
    ));
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ width: '100%' }} disabled={disabled}>
          <FormLabel sx={{ fontSize: '14px' }}>{title}</FormLabel>
          <RadioGroup row {...props} value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

export { RadioButtonInput };
