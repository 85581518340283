import { Appointment } from 'models/appointment';
import { ASG } from 'models/asg';
import {
  firstColumnSymptoms,
  secondColumnSymptoms,
  setActivityLastMonthPointsFunction,
  setFoodIngestionQuantityPointsFunction,
  setFoodIngestionTypePointsFunction,
  setWeightPointsFunction
} from 'pages/NutritionalRiskScreening/forms/asgInterfacesAndFunctions';

const symptomsValues = [...firstColumnSymptoms, ...secondColumnSymptoms];
const getValue = (symptom: string): number => {
  const s = symptomsValues.find((s) => s.name === symptom);
  return s ? s.value : 0;
};

const calcAsg = (asg: ASG) => {
  let symptomsPoints = 0;
  if (asg.nausea) symptomsPoints += getValue('nausea');
  if (asg.constipation) symptomsPoints += getValue('constipation');
  if (asg.mucositis) symptomsPoints += getValue('mucositis');
  if (asg.tasteless) symptomsPoints += getValue('tasteless');
  if (asg.swallowProblem) symptomsPoints += getValue('swallowProblem');
  if (asg.tiredness) symptomsPoints += getValue('tiredness');
  if (asg.appetiteLack) symptomsPoints += getValue('appetiteLack');
  if (asg.vomit) symptomsPoints += getValue('vomit');
  if (asg.diarrhea) symptomsPoints += getValue('diarrhea');
  if (asg.dryMouth) symptomsPoints += getValue('dryMouth');
  if (asg.quicklySatified) symptomsPoints += getValue('quicklySatified');
  if (asg.smellsBother) symptomsPoints += getValue('smellsBother');

  const others = asg.others?.split(';').length ? 1 : 0;
  const pain = asg.pain?.split(';').length ? 3 : 0;
  return (
    symptomsPoints +
    setFoodIngestionQuantityPointsFunction(asg.lastMonthFood) +
    setFoodIngestionTypePointsFunction(asg.currentFood) +
    setWeightPointsFunction(asg.weightLastWeeks) +
    setActivityLastMonthPointsFunction(asg.activityLastMonth) +
    others +
    pain
  );
};

export const prepareNutriTriage = (appointments: Appointment[]) => {
  if (appointments) {
    return [
      {
        tag: 'ASG-PPP',
        points: appointments.map((a) =>
          a.nrAsgppp ? calcAsg(a.nrAsgppp) : null
        )
      },
      {
        tag: 'MAN',
        points: appointments.map((a) => {
          const m = a.MAN;
          return m
            ? m.foodIntakeDecrease +
                m.imc +
                m.mobility +
                m.neurologicalProblems +
                m.recentStress +
                m.weightLoss
            : null;
        })
      },
      {
        tag: 'MST',
        points: appointments.map((a) => {
          return a.Mst
            ? a.Mst.weightLoss + a.Mst.eatingLess + a.Mst.weightLossQuantity
            : null;
        })
      },
      {
        tag: 'MUST',
        points: appointments.map((a) => {
          return a.Must
            ? a.Must.imcPontuation + a.Must.cannotFeed + a.Must.cannotFeed
            : null;
        })
      },
      {
        tag: 'NRS-2002',
        points: appointments.map((a) => {
          return a.Nrs ? a.Nrs.diseaseLevel + a.Nrs.diseaseLevel : null;
        })
      }
    ];
  } else {
    return [
      {
        points: []
      }
    ];
  }
};
