import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  ChipComponent,
  InputWithNumberMask,
  ClassificationTable
} from 'components';
import { theme } from 'styles/theme';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { limitDecimal } from 'utils/functions/limitDecimal';

export function calculateManAMBPercentil(sex: string, age: number) {
  if (sex === 'MALE') {
    if (age >= 2 && age <= 2.9) return 14.5;
    if (age >= 3 && age <= 3.9) return 16.1;
    if (age >= 4 && age <= 4.9) return 16.9;
    if (age >= 5 && age <= 5.9) return 18.1;
    if (age >= 6 && age <= 6.9) return 19.8;
    if (age >= 7 && age <= 7.9) return 21.5;
    if (age >= 8 && age <= 8.9) return 23.2;
    if (age >= 9 && age <= 9.9) return 25.1;
    if (age >= 10 && age <= 10.9) return 27.2;
    if (age >= 11 && age <= 11.9) return 30.2;
    if (age >= 12 && age <= 12.9) return 34.1;
    if (age >= 13 && age <= 13.9) return 38.7;
    if (age >= 14 && age <= 14.9) return 43.7;
    if (age >= 15 && age <= 15.9) return 48.3;
    if (age >= 16 && age <= 16.9) return 52.5;
    if (age >= 17 && age <= 17.9) return 56;
    if (age >= 18 && age <= 18.9) return 58.6;
    if (age >= 19 && age <= 19.9) return 59.4;
    if (age >= 20 && age <= 29.9) return 63.8;
    if (age >= 30 && age <= 39.9) return 68.1;
    if (age >= 40 && age <= 49.9) return 69.1;
    if (age >= 50 && age <= 59.9) return 67.8;
    if (age >= 60 && age <= 69.9) return 64.4;
    if (age >= 70 && age <= 79.9) return 59.2;
    if (age >= 80 && age <= 90.9) return 52.4;
  }
  return 0;
}

export function calculateWomanAMBPercentil(sex: string, age: number) {
  if (sex === 'FEMALE') {
    if (age >= 2 && age <= 2.9) return 14;
    if (age >= 3 && age <= 3.9) return 15.5;
    if (age >= 4 && age <= 4.9) return 16.8;
    if (age >= 5 && age <= 5.9) return 17.8;
    if (age >= 6 && age <= 6.9) return 18.8;
    if (age >= 7 && age <= 7.9) return 19.6;
    if (age >= 8 && age <= 8.9) return 20.7;
    if (age >= 9 && age <= 9.9) return 22.5;
    if (age >= 10 && age <= 10.9) return 25.5;
    if (age >= 11 && age <= 11.9) return 29.1;
    if (age >= 12 && age <= 12.9) return 32.5;
    if (age >= 13 && age <= 13.9) return 35;
    if (age >= 14 && age <= 14.9) return 36.1;
    if (age >= 15 && age <= 15.9) return 36.2;
    if (age >= 16 && age <= 16.9) return 36.1;
    if (age >= 17 && age <= 17.9) return 36.1;
    if (age >= 18 && age <= 18.9) return 36.3;
    if (age >= 19 && age <= 19.9) return 36.9;
    if (age >= 20 && age <= 29.9) return 38.4;
    if (age >= 30 && age <= 39.9) return 41.3;
    if (age >= 40 && age <= 49.9) return 44.1;
    if (age >= 50 && age <= 59.9) return 44.8;
    if (age >= 60 && age <= 69.9) return 43.1;
    if (age >= 70 && age <= 79.9) return 42.1;
    if (age >= 80 && age <= 90.9) return 40.1;
  }
  return 0;
}

export function calculateAMBValue(sex: string, cb: number, dct: number) {
  if (sex === 'MALE') {
    const value = Math.pow(cb - dct * 0.31416, 2) / 12.56 - 10;
    return limitDecimal(value);
  }
  if (sex === 'FEMALE') {
    const value = Math.pow(cb - dct * 0.31416, 2) / 12.56 - 6.5;
    return limitDecimal(value);
  }
  return 0;
}

const AMBForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { age: number; sex: string; cb: number | undefined; dct: number | undefined }
> = ({ sex, age, cb, dct }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm();
  const history = useHistory();
  const watchAmbCircumference = watch('amb');
  const [result, setResult] = useState(0);
  const [AMBPercentil, setAMBPercentil] = useState(0);

  useEffect(() => {
    setAMBPercentil(
      sex === 'MALE'
        ? calculateManAMBPercentil(sex, age)
        : calculateWomanAMBPercentil(sex, age)
    );
  }, []);

  function calculateAMBAdequacy(AMB: number) {
    const result = (AMB / AMBPercentil) * 100;
    setResult(result);
  }

  useEffect(() => {
    calculateAMBAdequacy(watchAmbCircumference);
  }, [watchAmbCircumference]);

  useEffect(() => {
    if (!cb) {
      toast.warn(
        'Para que o calculo automático seja feito corretamente, é preciso preencher as etapas CB e DCT'
      );
      return;
    }
    if (!dct) return;
    const ambValue = calculateAMBValue(sex, cb, dct);
    setValue('amb', ambValue);
  }, [dct]);

  function calculateResultText() {
    if (result < 70) return 'Desnutrição grave';
    if (result >= 70 && result < 80) return 'Desnutrição moderada';
    if (result >= 80 && result <= 90) return 'Desnutrição leve';
    if (result > 90) return 'Eutrofia';
    if (!result) return 'Aguardando circunferência';
  }
  function onSubmit() {
    history.push('/home/body-composition-evaluation');
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          height="50vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Área muscular do braço
              </Typography>
              <TooltipComponent content="Revela a estimativa da massa muscular " />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              name="amb"
              control={control}
              decimalScale={1}
              label={
                <Box>
                  <Typography>
                    Circunferência <i>(cm)</i>:
                  </Typography>
                </Box>
              }
              placeholder="Ex: 31cm"
              suffix="cm"
            />
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`RESULTADO: ${calculateResultText()}`}
              size="small"
            />
          </Box>
          <Box>
            <ClassificationTable
              size="small"
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Menor que 70%',
                  secondCellContent: 'Desnutrição grave',
                  selected: result < 70
                },
                {
                  color: { colors: 'light-orange' },
                  firstCellContent: 'Entre 70% e 80%',
                  secondCellContent: 'Desnutrição moderada',
                  selected: result >= 70 && result < 80
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 80% e 90%',
                  secondCellContent: 'Desnutrição leve',
                  selected: result >= 80 && result <= 90
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: 'Entre 90 e 110%',
                  secondCellContent: 'Eutrofia',
                  selected: result > 90
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(AMBForm);
