import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdvanceFormFooter,
  MainContentContainer,
  ToggleButtonComponent
} from 'components';
import {
  calculateIMC,
  limitDecimal,
  verifyWeightLossPercentage
} from 'utils/functions';
import ASGForm from './forms/asg';
import MANForm from './forms/man';
import MSTForm from './forms/mst';
import MUSTForm from './forms/must';
import NRSForm from './forms/nrs';
import { FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritionalData/findNutritionalDataByAppointmentId';
import { FIND_MAN_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/man/findManByAppointmentId';
import { FIND_MST_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/mst/findMstByAppointmentId';
import { FIND_MUST_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/must/findMustByAppointmentId';
import { FIND_NRS_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/nrs/findNrsByAppointmentId';
import { FIND_ASG_BY_APPOINTMENT_ID } from 'services/graphql/queries/nutritional-risk/asg/findAsgByAppointmentId';
import { FIND_LAST_APPOINTMENT_PATIENT_BY_ID } from 'services/graphql/queries/appointment/findLastAppointment';
import { FindByAppointmentIdVar } from 'shared';

import { Nrs } from 'models/nrs';
import { Must } from 'models/must';
import { Man } from 'models/man';
import { Mst } from 'models/mst';
import { ASG } from 'models/asg';
import { NutritionalData } from 'models/nutritionalData';
import { FullAppointment } from 'models/fullAppointment';

interface ASGQueryType {
  findNrAsgpppByAppointmentId: ASG;
}

interface MANQueryType {
  findManByAppointmentId: Man;
}

interface MSTQueryType {
  findMstByAppointmentId: Mst;
}

interface MUSTQueryType {
  findMustByAppointmentId: Must;
}

interface NRSQueryType {
  findNRSByAppointmentId: Nrs;
}

interface NutritionalDataQueryType {
  findNutritionalDataByAppointmentId: NutritionalData;
}

function NutritionalRiskScreening() {
  const history = useHistory();
  const formSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const [form, setForm] = useState('');
  const appointmentId = Number(localStorage.getItem('appointmentId'));

  const [lastAsg, setLastAsg] = useState<ASG | undefined>();
  const [lastMan, setLastMan] = useState<Man | undefined>();
  const [lastMst, setLastMst] = useState<Mst | undefined>();
  const [lastMust, setLastMust] = useState<Must | undefined>();
  const [lastNrs, setLastNrs] = useState<Nrs | undefined>();

  const patientId = Number(localStorage.getItem('patientId'));
  const { data: lastAppointment, refetch: refetchLastAppointment } = useQuery<{
    getLastPatientAppointment: FullAppointment;
  }>(FIND_LAST_APPOINTMENT_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      patientId
    }
  });

  const {
    data: nutritionalData,
    error: nutritionalDataError,
    loading: nutritionalDataLoading
  } = useQuery<NutritionalDataQueryType, FindByAppointmentIdVar>(
    FIND_NUTRITIONAL_DATA_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: asgData,
    error: asgError,
    loading: loadingAsg
  } = useQuery<ASGQueryType, FindByAppointmentIdVar>(
    FIND_ASG_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: manData,
    error: manError,
    loading: loadingMan
  } = useQuery<MANQueryType, FindByAppointmentIdVar>(
    FIND_MAN_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: mstData,
    error: mstError,
    loading: loadingMst
  } = useQuery<MSTQueryType, FindByAppointmentIdVar>(
    FIND_MST_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: mustData,
    error: mustError,
    loading: loadingMust
  } = useQuery<MUSTQueryType, FindByAppointmentIdVar>(
    FIND_MUST_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  const {
    data: nrsData,
    error: nrsError,
    loading: loadingNrs
  } = useQuery<NRSQueryType, FindByAppointmentIdVar>(
    FIND_NRS_BY_APPOINTMENT_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId
      }
    }
  );

  function lastAppointmentNutritionalData() {
    if (lastAppointment?.getLastPatientAppointment?.nrAsgppp) {
      setForm('asg');
      setLastAsg(lastAppointment?.getLastPatientAppointment?.nrAsgppp);
    }
    if (lastAppointment?.getLastPatientAppointment?.MAN) {
      setLastMan(lastAppointment?.getLastPatientAppointment?.MAN);
      setForm('man');
    }
    if (lastAppointment?.getLastPatientAppointment?.Mst) {
      setLastMst(lastAppointment?.getLastPatientAppointment?.Mst);
      setForm('mst');
    }
    if (lastAppointment?.getLastPatientAppointment?.Must) {
      setLastMust(lastAppointment?.getLastPatientAppointment?.Must);
      setForm('must');
    }
    if (lastAppointment?.getLastPatientAppointment?.Nrs) {
      setLastNrs(lastAppointment?.getLastPatientAppointment?.Nrs);
      setForm('nrs');
    }
  }

  const [hasLastAppointment, setHasLastAppointment] = useState(false);

  useEffect(() => {
    refetchLastAppointment();
    if (lastAppointment?.getLastPatientAppointment?.nrAsgppp)
      setHasLastAppointment(true);
    if (lastAppointment?.getLastPatientAppointment?.MAN)
      setHasLastAppointment(true);
    if (lastAppointment?.getLastPatientAppointment?.Mst)
      setHasLastAppointment(true);
    if (lastAppointment?.getLastPatientAppointment?.Must)
      setHasLastAppointment(true);
    if (lastAppointment?.getLastPatientAppointment?.Nrs)
      setHasLastAppointment(true);
  }, [lastAppointment]);

  const parsedNutritionalData = useMemo(() => {
    if (nutritionalData?.findNutritionalDataByAppointmentId) {
      const data = nutritionalData.findNutritionalDataByAppointmentId;
      const IMC = calculateIMC(data.height, data.weight);
      const { height, weight, habitualWeight } = data;
      const weightLoss = limitDecimal(habitualWeight - weight);
      return { IMC, height, weight, weightLoss, habitualWeight };
    }
    if (nutritionalDataError) {
      toast.warn('Não foi possível carregar dados nutricionais');
      return;
    }
  }, [nutritionalData, nutritionalDataError, nutritionalDataLoading]);

  const loadedAsg = useMemo(() => {
    if (asgData?.findNrAsgpppByAppointmentId) {
      setForm('asg');
      return asgData.findNrAsgpppByAppointmentId;
    }
    return;
  }, [asgData, asgError, loadingAsg]);

  const loadedMan = useMemo(() => {
    if (manData?.findManByAppointmentId) {
      setForm('man');
      return manData.findManByAppointmentId;
    }
    return;
  }, [manData, manError, loadingMan]);

  const loadedMst = useMemo(() => {
    if (mstData?.findMstByAppointmentId) {
      setForm('mst');
      return mstData.findMstByAppointmentId;
    }
    return;
  }, [mstData, mstError, loadingMst]);

  const loadedMust = useMemo(() => {
    if (mustData?.findMustByAppointmentId) {
      setForm('must');
      return mustData.findMustByAppointmentId;
    }
    return;
  }, [mustData, mustError, loadingMust]);

  const loadedNrs = useMemo(() => {
    if (nrsData?.findNRSByAppointmentId) {
      setForm('nrs');
      return nrsData.findNRSByAppointmentId;
    }
    return;
  }, [nrsData, nrsError, loadingNrs]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loadedAsg && !loadedMan && !loadedMst && !loadedMust && !loadedNrs) {
      setForm('asg');
    }
  }, [loadedAsg, loadedMan, loadedMst, loadedMust, loadedNrs]);

  function verifyFormPosition() {
    switch (form) {
      case 'asg':
        return 1;
      case 'man':
        return 2;
      case 'mst':
        return 3;
      case 'must':
        return 4;
      case 'nrs':
        return 5;
    }
  }

  function submitForm() {
    formSubmitButtonRef.current?.click();
  }

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="7. Triagem do risco nutricional"
        useDataFromLastAppointmentSwitch={{
          action: lastAppointmentNutritionalData,
          hasLastAppointment: hasLastAppointment
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {nutritionalDataLoading ||
          loadingAsg ||
          loadingMan ||
          loadingMst ||
          loadingMust ||
          loadingNrs ? (
            <CircularProgress />
          ) : (
            <>
              <ToggleButtonComponent
                selectedValue={verifyFormPosition()}
                toggleButtonProps={[
                  { value: 1, title: 'ASG-PPP', action: () => setForm('asg') },
                  { value: 2, title: 'MAN', action: () => setForm('man') },
                  { value: 3, title: 'MST', action: () => setForm('mst') },
                  { value: 4, title: 'MUST', action: () => setForm('must') },
                  { value: 5, title: 'NRS-2002', action: () => setForm('nrs') }
                ]}
              />
              {form === 'asg' ? (
                <ASGForm
                  lastAsg={lastAsg}
                  loadedData={loadedAsg}
                  height={
                    nutritionalData?.findNutritionalDataByAppointmentId
                      ?.height || 0
                  }
                  weight={
                    nutritionalData?.findNutritionalDataByAppointmentId
                      ?.weight || 0
                  }
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'man' ? (
                <MANForm
                  lastMan={lastMan}
                  imc={parsedNutritionalData?.IMC || 0}
                  loadedValues={loadedMan}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'mst' ? (
                <MSTForm
                  lastMst={lastMst}
                  loadedValues={loadedMst}
                  weightLoss={parsedNutritionalData?.weightLoss || 0}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'must' ? (
                <MUSTForm
                  lastMust={lastMust}
                  imc={parsedNutritionalData?.IMC || 0}
                  weightLossPercentage={verifyWeightLossPercentage(
                    parsedNutritionalData?.weight || 0,
                    parsedNutritionalData?.habitualWeight || 0
                  )}
                  loadedValues={loadedMust}
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
              {form === 'nrs' ? (
                <NRSForm
                  lastNrs={lastNrs}
                  loadedValues={loadedNrs}
                  imc={parsedNutritionalData?.IMC || 0}
                  weightLossTime={
                    nutritionalData?.findNutritionalDataByAppointmentId
                      ?.weightLossTime || ''
                  }
                  ref={formSubmitButtonRef}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/nutritional-data-and-information')}
          action={() => submitForm()}
        />
      </Box>
    </Box>
  );
}

export { NutritionalRiskScreening };
