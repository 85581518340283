import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  cids: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required('Insira um valor de CID'),
        staging: yup.string().required('Insira um valor de estadiamento')
      })
    )
    .min(1, 'Insira pelo menos 1 CID'),
  treatments: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required('Insira um valor de tratamento')
      })
    )
    .min(1, 'Insira pelo menos 1 tratamento')
});
