import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Input } from 'components/Input';
import { theme } from 'styles/theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyQuery } from '@apollo/client';
import { SING_IN } from 'services/graphql/queries/login/singIn';
import { toast } from 'react-toastify';
import logo from 'assets/newLogo.png';
import jwt_decode from 'jwt-decode';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Insira um e-mail')
    .email('Insira um e-mail válido'),
  password: yup
    .string()
    .required('Insira um senha')
    .min(6, 'Uma senha deve ter pelo menos 6 caracteres')
});

const defaultValues = {
  email: '',
  password: ''
};

interface IFormType {
  email: string;
  password: string;
}

interface QueryType {
  login: string;
}

function Login() {
  const history = useHistory();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const [showPassword, setShowPassword] = useState(false);
  const [login] = useLazyQuery<QueryType>(SING_IN, {
    onCompleted: (data) => {
      if (data) {
        localStorage.setItem('user_token', data.login);
        toast.success('Login realizado com sucesso.');
        const decoded_token: { role: string } = jwt_decode(data.login);
        history.push(
          decoded_token.role === 'NUTRITIONIST'
            ? 'dashboard/nutritionist'
            : 'dashboard/institutional'
        );
      }
    },
    onError: () => {
      toast.error('Verifique suas credenciais');
    },
    fetchPolicy: 'network-only'
  });

  async function createSession(email: string, password: string) {
    await login({
      variables: {
        email,
        password
      }
    });
  }

  function onSubmit({ email, password }: IFormType) {
    try {
      createSession(email, password);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <>
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.16)"
          width="30%"
          minHeight="75vh"
          borderRadius="4px"
          bgcolor="background.paper"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="95%" padding="20px 20px" paddingBottom="20px">
            <Typography
              fontSize="28px"
              color={theme.palette.info.main}
              fontWeight="900"
            >
              IQNO
            </Typography>
            <Typography fontSize="18px" color={theme.palette.grey[900]}>
              Bem-vindo a Plataforma de{' '}
              <strong>
                Indicadores de Qualidade Nutricionais da Oncologia
              </strong>
            </Typography>
          </Box>
          <Box
            padding="0 15px"
            width="92%"
            minHeight="40vh"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="email"
              label="Email"
              placeholder="Digite aqui"
            />
            <Input
              sx={{ width: '100%' }}
              control={control}
              name="password"
              label="Senha"
              placeholder="Digite aqui"
              type={showPassword ? 'text' : 'password'}
              icon={
                <IconButton
                  sx={{ color: theme.palette.grey[200] }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              minHeight="fit-content"
            >
              <FormControlLabel
                color="grey.900"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    color: theme.palette.grey[900],
                    lineHeight: '30px'
                  }
                }}
                label="Mantenha-me conectado"
                control={<Checkbox size="small" color="info" />}
              />
              <Typography
                sx={{ textDecoration: 'underline' }}
                fontSize="14px"
                color={theme.palette.info.main}
                onClick={() => history.push('/forgot-password')}
              >
                Esqueceu a senha?
              </Typography>
            </Box>
            <Button
              sx={{
                width: '100%',
                textTransform: 'none',
                alignSelf: 'flex-end'
              }}
              color="info"
              variant="contained"
              type="submit"
            >
              Entrar
            </Button>
          </Box>
          <Box minHeight="fit-content">
            <img height="100px" src={logo} alt="Nestlé Health Science" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export { Login };
