export const weeklyMonthlyAndDailyFrequency = [
  {
    label: '1x por dia',
    value: 'oncePerDay'
  },
  {
    label: 'Mais de 1x no dia',
    value: 'moreThanOncePerDay'
  },
  {
    label: '1x na semana',
    value: 'oncePerWeek'
  },
  {
    label: 'Mais de 1x na semana',
    value: 'moreThanOncePerWeek'
  },
  {
    label: '1 x no mês',
    value: 'oncePerMonth'
  },
  {
    label: 'Raramente',
    value: 'rarely'
  }
];

export function parseWeeklyMonthlyAndDailyText(value: string) {
  switch (value) {
    case 'oncePerDay':
      return '1x por dia';
    case 'moreThanOncePerDay':
      return 'Mais de 1x no dia';
    case 'oncePerWeek':
      return '1x na semana';
    case 'moreThanOncePerWeek':
      return 'Mais de 1x na semana';
    case 'oncePerMonth':
      return '1 x no mês';
    case 'rarely':
      return 'Raramente';
    default:
      return 'Não há dados';
  }
}
