import React, { useState } from 'react';
import { Box, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { ASG as AsgInterface } from 'models/asg';
import { ChipComponent } from 'components/Chip';
import { Subtitle } from 'components/Subtitle';
import { ExpandMore } from '../../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from '../editAppointment';
import { useHistory } from 'react-router-dom';

interface AsgProps {
  appointment: Appointment;
}

type AsgKeys =
  | 'currentWeight'
  | 'heightOver1m'
  | 'weight1Month'
  | 'weight6Month'
  | 'weightLastWeeks'
  | 'lastMonthFood'
  | 'currentFood'
  | 'normalFeed'
  | 'nausea'
  | 'constipation'
  | 'mucositis'
  | 'tasteless'
  | 'swallowProblem'
  | 'tiredness'
  | 'appetiteLack'
  | 'vomit'
  | 'diarrhea'
  | 'dryMouth'
  | 'quicklySatified'
  | 'smellsBother'
  | 'pain'
  | 'others'
  | 'activityLastMonth';

interface AsgObjectKey {
  key: AsgKeys;
}

const weightASGQuestions: AsgObjectKey[] = [
  { key: 'currentWeight' },
  { key: 'heightOver1m' },
  { key: 'weight1Month' },
  { key: 'weight6Month' },
  { key: 'weightLastWeeks' }
];

const foodASGQuestions: AsgObjectKey[] = [
  { key: 'lastMonthFood' },
  { key: 'currentFood' }
];

const symptomsASGQuestions: AsgObjectKey[] = [
  { key: 'normalFeed' },
  { key: 'nausea' },
  { key: 'constipation' },
  { key: 'mucositis' },
  { key: 'tasteless' },
  { key: 'swallowProblem' },
  { key: 'tiredness' },
  { key: 'appetiteLack' },
  { key: 'vomit' },
  { key: 'diarrhea' },
  { key: 'dryMouth' },
  { key: 'quicklySatified' },
  { key: 'smellsBother' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newAsgType = MappedType<AsgInterface>;

export function Asg({ appointment }: AsgProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Asg: newAsgType = appointment.nrAsgppp;
  if (!Asg) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parsedAsgQuestion(value: string) {
    switch (value) {
      case 'currentWeight':
        return 'Eu atualmente peso aproximadamente:';
      case 'heightOver1m':
        return 'Eu tenho aproximadamente 1 metro e:';
      case 'weight1Month':
        return 'Há 1 mês eu costumava pesar:';
      case 'weight6Month':
        return 'Há 6 meses eu costumava pesar:';
      case 'weightLastWeeks':
        return 'Durante as duas últimas semanas o meu peso:';
      case 'lastMonthFood':
        return 'Comparada com minha alimentação habitual, no último mês, eu tenho comido:';
      case 'currentFood':
        return 'Atualmente, eu estou comendo:';
      case 'normalFeed':
        return 'sem problemas para me alimentar (0)';
      case 'nausea':
        return 'náuseas (enjoos) (1)';
      case 'constipation':
        return 'obstipação (intestino preso) (1)';
      case 'mucositis':
        return 'feridas na boca (2)';
      case 'tasteless':
        return 'as coisas têm gosto estranho ou não têm gosto (1)';
      case 'swallowProblem':
        return 'problemas para engolir (2)';
      case 'tiredness':
        return 'cansaço (fadiga) (1)';
      case 'appetiteLack':
        return 'sem apetite, apenas sem vontade de comer (3)';
      case 'vomit':
        return 'vômitos (3)';
      case 'diarrhea':
        return 'diarreia (3)';
      case 'dryMouth':
        return 'boca seca (1)';
      case 'quicklySatified':
        return 'me sinto rapidamente satisfeito (1)';
      case 'smellsBother':
        return 'os cheiros me incomodam (1)';
      case 'activityLastMonth':
        return 'Atividades e função';
      default:
        return 'Não há dados';
    }
  }

  function parsedWeightAsgAnswers(
    key: string,
    value: string | number | boolean
  ) {
    if (key === 'currentWeight') {
      return value + 'kg';
    }
    if (key === 'heightOver1m') {
      return value + 'cm';
    }
    if (key === 'weight1Month') {
      return value + 'kg';
    }
    if (key === 'weight6Month') {
      return value + 'kg';
    }
    if (key === 'weightLastWeeks') {
      switch (value) {
        case 'lessWeight':
          return 'Diminuiu (1)';
        case 'sameWeight':
          return 'Ficou igual (0)';
        case 'heavier':
          return 'Aumentou (0)';
      }
    }
  }

  function getWeightPoints() {
    const question = parsedWeightAsgAnswers(
      'weightLastWeeks',
      Asg.weightLastWeeks
    );
    if (!question) return 0;
    const matches = question.match(/(\d+)/);
    if (matches) return Number(matches[0]);
    return 0;
  }

  function parsedFoodAsgAnswers(key: string, value: string | number | boolean) {
    if (key === 'lastMonthFood') {
      switch (value) {
        case 'same':
          return 'A mesma coisa (0)';
        case 'more':
          return 'Mais do que o habitual (0)';
        case 'less':
          return 'Menos do que o habitual (1)';
      }
    }
    if (key === 'currentFood') {
      switch (value) {
        case 'lessSolidFood':
          return 'a mesma comida (sólida) em menor quantidade do que o habitual (1)';
        case 'muchLessSolidFood':
          return 'a mesma comida (sólida) em pouca quantidade (2)';
        case 'liquidFood':
          return 'apenas alimentos líquidos (3)';
        case 'suplements':
          return 'apenas suplementos nutricionais (3)';
        case 'littleFood':
          return 'muito pouca quantidade de qualquer alimento (4)';
        case 'tube':
          return 'apenas alimentação por sonda ou pela veia (0)';
      }
    }
  }

  function getFoodPoints() {
    const question1 = parsedFoodAsgAnswers('lastMonthFood', Asg.lastMonthFood);
    const question2 = parsedFoodAsgAnswers('currentFood', Asg.currentFood);
    if (!question1 || !question2) return 0;
    const result1 = question1.match(/(\d+)/);
    const result2 = question2.match(/(\d+)/);
    if (result1 && result2) return Number(result1[0]) + Number(result2[0]);
    return 0;
  }

  function getSymptomsPoints() {
    let result = 0;
    const currentSymptoms = symptomsASGQuestions.filter(
      ({ key }) => Asg[key] === true
    );
    currentSymptoms.forEach((value) => {
      const question = parsedAsgQuestion(value.key);
      const point = question.match(/(\d+)/);
      if (!point) return;
      result += Number(point[0]);
    });
    if (Asg.pain && Asg.others) return result + 4;
    if (Asg.pain) return result + 3;
    if (Asg.others) return result + 1;
    return result;
  }

  function getActivityPoints() {
    const question = parsedActivityAsgAnswers(Asg.activityLastMonth);
    if (!question) return 0;
    const matches = question.match(/(\d+)/);
    if (matches) return Number(matches[0]);
    return 0;
  }

  function parsedActivityAsgAnswers(activity: string) {
    switch (activity) {
      case 'normal':
        return 'Normal, sem nenhuma limitação (0)';
      case 'almostNormal':
        return 'Não totalmente normal, mas capaz de manter quase todas as atividades normais (1)';
      case 'tired':
        return 'Sem disposição para a maioria das coisas, mas ficando na cama ou na cadeira menos da metade do dia (2)';
      case 'mostTimeInBed':
        return 'Capaz de fazer pouca atividade e passando a maior parte do dia na cadeira ou na cama (3)';
      case 'laidUp':
        return 'Praticamente acamado, raramente fora da cama (3)';
    }
  }

  const result =
    getWeightPoints() +
    getFoodPoints() +
    getSymptomsPoints() +
    getActivityPoints();

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |7. Triagem do risco nutricional
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/nutritional-risk-screening')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ChipComponent
          sx={{ width: '15%', margin: '20px' }}
          size="small"
          color={{ colors: 'light-blue' }}
          label={
            <Typography fontSize="12px">
              ASG-PPP: <strong>{result} PONTOS</strong>
            </Typography>
          }
        />
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="PESO" />
          <Box
            width="93%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {weightASGQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText
                  title={`${parsedWeightAsgAnswers(key, Asg[key])}`}
                />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getWeightPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="INGESTÃO ALIMENTAR" />
          <Box
            width="80%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {foodASGQuestions.map(({ key }, index) => {
            return (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parsedAsgQuestion(key)} />
                <ResultText title={`${parsedFoodAsgAnswers(key, Asg[key])}`} />
              </Box>
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getFoodPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="SINTOMAS" />
          <Box
            width="93%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {symptomsASGQuestions.map(({ key }, index) => {
            return Asg[key] ? (
              <Box key={index} margin="15px 0" marginRight="15px">
                <Chip
                  sx={{
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={parsedAsgQuestion(key)}
                />
              </Box>
            ) : (
              ''
            );
          })}
          <Box margin="15px 0" marginRight="15px">
            {Asg.pain?.split(';').map((value, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    marginRight: '15px',
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={`Dor: ${value}`}
                />
              );
            })}
            {Asg.others?.split(';').map((value, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    marginRight: '15px',
                    color: 'grey.900',
                    fontWeight: '500'
                  }}
                  size="small"
                  variant="filled"
                  label={`Outros: ${value}`}
                />
              );
            })}
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getSymptomsPoints()}`}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          padding="0 20px"
          display="flex"
          justifyContent="space-between"
        >
          <Subtitle title="ATIVIDADES E FUNÇÃO" />
          <Box
            width="82%"
            borderBottom="1px solid"
            borderColor="divider"
            marginBottom="13px"
          ></Box>
        </Box>
        <Box
          display="flex"
          padding="0 20px"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title={parsedAsgQuestion('activityLastMonth')} />
            <ResultText
              title={`${parsedActivityAsgAnswers(Asg.activityLastMonth)}`}
            />
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <ChipComponent
              sx={{
                fontWeight: '500'
              }}
              color={{ colors: 'grey' }}
              label={`SOMA TOTAL: ${getActivityPoints()}`}
            />
          </Box>
        </Box>
        <Box width="100%" margin="15px 0">
          <ChipComponent
            sx={{
              width: '100%',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            color={{ colors: 'light-blue' }}
            label={`RESULTADO TOTAL: ${result}`}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
