import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AdvanceFormFooter } from 'components/AdvanceFormFooter';
import { MainContentContainer } from 'components/MainContentContainer';
import NutritionalDiagnosisForm from './form/index';

function NutritionalDiagnosisAndClassification() {
  const history = useHistory();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Box display="flex" flexDirection="column" width="60%">
      <MainContentContainer
        headerTitle="12. Classificação e diagnóstico nutricional"
        useDataFromLastAppointmentSwitch={{
          hasLastAppointment: false
        }}
      >
        <Box
          padding="25px 20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <NutritionalDiagnosisForm ref={submitButtonRef} />
        </Box>
      </MainContentContainer>
      <Box alignSelf="flex-end" marginTop="-6%">
        <AdvanceFormFooter
          back={() => history.push('/home/strength-functionality-evaluation')}
          action={() => submitButtonRef.current?.click()}
        />
      </Box>
    </Box>
  );
}

export { NutritionalDiagnosisAndClassification };
