import React, { forwardRef, useEffect } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TooltipComponent, InputWithNumberMask, Input } from 'components';
import { theme } from 'styles/theme';
import { CutoffTable } from 'components/CutoffTable/cutoffTable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  CREATE_BIA,
  UPDATE_BIA,
  BiaMutationsVars
} from 'services/graphql/mutations/bodyCompositionEvaluation';
import { Bia } from 'models/bia';

const validationSchema = yup.object().shape({
  fatFreeMassPct: yup
    .number()
    .required('Insira um valor de massa livre de gordura')
    .max(99.99, 'O valor máximo é 99,99'),
  fatFreeMassKg: yup
    .number()
    .required('Insira um valor de massa livre de gordura em kg')
    .max(150, 'O valor máximo é 150'),
  leanMassKg: yup
    .number()
    .required('Insira um valor de massa magra em kg')
    .max(150, 'O valor máximo é 150'),
  fatMassPct: yup
    .number()
    .required('Insira um valor de massa de gordura')
    .max(99.99, 'O valor máximo é 99,99'),
  fatMassKg: yup
    .number()
    .required('Insira um valor de massa de gordura em kg')
    .max(150, 'O valor máximo é 150'),
  resistance: yup.number().required('Insira um valor de resistência'),
  reactance: yup.number().required('Insira um valor de reatância'),
  phaseAngle: yup.number().required('Insira um valor de reatância')
});

interface IFormData {
  fatFreeMassPct: number;
  fatFreeMassKg: number;
  leanMassKg: number;
  fatMassPct: number;
  fatMassKg: number;
  resistance: number;
  reactance: number;
  phaseAngle: number;
}

const BIAForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  { loadedData: Bia | undefined; lastBia: Bia | undefined }
> = ({ loadedData, lastBia }, ref) => {
  const { handleSubmit, control, setValue } = useForm<IFormData>({
    resolver: yupResolver(validationSchema)
  });

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const [createBIAMutation] = useMutation<null, BiaMutationsVars>(CREATE_BIA);
  const [updateBIAMutation] = useMutation<null, BiaMutationsVars>(UPDATE_BIA);

  function setLoadedValuesToForm(data: Bia | undefined) {
    if (!data) return;
    setValue('fatFreeMassPct', data.fatFreeMassPct);
    setValue('fatFreeMassKg', data.fatFreeMassKg);
    setValue('leanMassKg', data.leanMassKg);
    setValue('fatMassPct', data.fatMassPct);
    setValue('fatMassKg', data.fatMassKg);
    setValue('resistance', data.resistance);
    setValue('reactance', data.reactance);
    setValue('phaseAngle', data.phaseAngle);
  }

  useEffect(() => {
    setLoadedValuesToForm(loadedData);
  }, [loadedData]);

  useEffect(() => {
    setLoadedValuesToForm(lastBia);
  }, [lastBia]);

  async function createBIA(data: IFormData) {
    if (loadedData) return;
    await createBIAMutation({
      variables: {
        bia: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.success('Bioimpedância adicionada.');
      }
    });
  }

  async function updateBIA(data: IFormData) {
    if (!loadedData) return;
    await updateBIAMutation({
      variables: {
        bia: {
          appointmentId,
          ...data
        }
      },
      onCompleted: () => {
        toast.info('Bioimpedância atualizada.');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createBIA(data);
      updateBIA(data);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="80vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Bioimpedância – BIA
              </Typography>
              <TooltipComponent content="Bioimpedância (BIA)" />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="44vh"
          >
            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Percentual de Massa Livre de Gordura (FFM) <i>(%)</i>:
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.00%"
                name="fatFreeMassPct"
                decimalScale={2}
                suffix="%"
                control={control}
              />
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Quantidade em <i>kg</i> de Massa Livre de Gordura (FFM):
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="fatFreeMassKg"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Quantidade em <i>kg</i> de Massa Magra (LBM):
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="leanMassKg"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Percentual de Massa Gordura (FM) <i>(%)</i>:
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.00%"
                name="fatMassPct"
                decimalScale={2}
                suffix="%"
                control={control}
              />
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between">
              <InputWithNumberMask
                label={
                  <Box>
                    <Typography>
                      Quantidade em <i>kg</i> de Massa Gordura (FM):
                    </Typography>
                  </Box>
                }
                sx={{ width: '48%' }}
                placeholder="Ex: 00.0kg"
                name="fatMassKg"
                decimalScale={1}
                suffix="kg"
                control={control}
              />
              <Box width="48%" display="flex" justifyContent="space-between">
                <Input
                  label="Resistência:"
                  sx={{ width: '48%' }}
                  name="resistance"
                  placeholder="Digite aqui"
                  type="number"
                  control={control}
                />
                <Input
                  label="Reatância:"
                  sx={{ width: '48%' }}
                  name="reactance"
                  placeholder="Digite aqui"
                  type="number"
                  control={control}
                />
              </Box>
            </Box>
            <Input
              label="Ângulo de fase:"
              placeholder="Digite aqui"
              sx={{ width: '48%' }}
              type="number"
              name="phaseAngle"
              control={control}
            />
          </Box>
          <Box>
            <CutoffTable
              title="Bioimpedância elétrica (BIA)"
              rows={[
                {
                  firstCell: 'IME = 8,50 kg/m²',
                  secondCell: 'IME = 5,75 kg/m²'
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(BIAForm);
