import { gql } from '@apollo/client';

export const FIND_MUST_BY_APPOINTMENT_ID = gql`
  query findMustByAppointmentId($appointmentId: Float!) {
    findMustByAppointmentId(appointmentId: $appointmentId) {
      id
      imcPontuation
      weightLoss
      cannotFeed
      appointmentId
    }
  }
`;
