import { Statistic } from 'models/dashboard';
import { parseResumedSymptomName } from '../../Patient/historicAppointmentSteps/symptoms';
import { translateTnoName } from './prepareTno';

export const prepareSymptomsTno = (
  data: {
    key: string;
    statistics: Statistic[];
  }[]
) => {
  const ret: { data: any[]; keys: Set<string> } = { data: [], keys: new Set() };

  for (const t of data) {
    const tno: any = {};
    tno.id = parseResumedSymptomName(t.key);

    for (const c of t.statistics) {
      const name = translateTnoName(c.name);
      tno[name] = c.quantity;
      ret.keys.add(name);
    }

    ret.data.push(tno);
  }

  return ret;
};
