import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { theme } from 'styles/theme';
import { blue } from '@mui/material/colors';

interface IButtonComponentProps extends Pick<ButtonProps, 'onClick' | 'sx'> {
  isTransparent: boolean;
  title: string;
  width?: string;
}

function ButtonComponent({
  isTransparent,
  title,
  ...props
}: IButtonComponentProps) {
  return (
    <Button
      sx={{
        background: isTransparent ? 'none' : theme.palette.info.main,
        borderColor: isTransparent ? theme.palette.grey[400] : '',
        color: isTransparent ? theme.palette.grey[900] : '',
        textTransform: 'none',
        '&:hover': {
          background: isTransparent ? 'none' : blue[800]
        },
        width: props.width || 'inherit'
      }}
      {...props}
      variant={isTransparent ? 'outlined' : 'contained'}
    >
      {title}
    </Button>
  );
}

export { ButtonComponent };
