import { gql } from '@apollo/client';

const UPDATE_PATIENT = gql`
  mutation updatePatient($patient: PatientInput!, $id: Float!) {
    updatePatient(patient: $patient, id: $id) {
      id
      name
      gender
      birthdate
      modality
      observations
    }
  }
`;

export { UPDATE_PATIENT };
