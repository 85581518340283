import { StepResult } from '..';
import { DiseaseData } from 'models/diseaseData';
import { generateStagingLabel } from 'utils/constants/stagings';

function parseCIDAndStaging(diseaseData: DiseaseData | undefined) {
  if (!diseaseData) return;
  const cid = diseaseData.cids.map((cid) => {
    return cid.code;
  });
  const staging = diseaseData.cids.map((cid) => {
    return cid.staging;
  });
  const treatments = diseaseData.treatments.map((treatment) => {
    return treatment.code;
  });
  return { cid, staging, treatments };
}

export function generateDiseaseDataResults(
  diseaseData: DiseaseData | undefined
): StepResult {
  const cid = parseCIDAndStaging(diseaseData)?.cid;
  const staging = parseCIDAndStaging(diseaseData)?.staging;
  const treatments = parseCIDAndStaging(diseaseData)?.treatments;
  if (!cid || !staging || !treatments)
    return {
      title: 'Dados da doença',
      contents: [
        {
          contentTitle: 'Não há dados para exibir',
          content: ''
        }
      ]
    };
  const stagingResult = staging.map((staging) => {
    return staging;
  });
  const cidResult = cid.map((cid, index) => {
    return {
      contentTitle: `CID ${index + 1}`,
      content: `${cid}, Estadiamento: ${generateStagingLabel(
        stagingResult[index]
      )} `
    };
  });
  const treatmentsResult = treatments.map((treatment, index) => {
    return {
      contentTitle: `Tratamento ${index + 1}`,
      content: `${treatment}`
    };
  });
  return {
    title: 'Dados da doença',
    contents: [...cidResult, ...treatmentsResult]
  };
}
