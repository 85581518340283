export const diagnoses = [
  { value: 'eutrophy', label: 'Eutrofia' },
  { value: 'malnutrition', label: 'Desnutrição' },
  { value: 'sarcopenia', label: 'Sarcopenia' },
  { value: 'obesity', label: 'Obesidade' },
  { value: 'sarcopenic obesity', label: 'Obesidade sarcopênica' },
  { value: 'myopenia', label: 'Miopenia' },
  { value: 'overweight', label: 'Sobrepeso' },
  { value: 'cachexia', label: 'Caquexia' }
];
