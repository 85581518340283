import React, { useEffect, useState } from 'react';
import {
  Button,
  ListItem,
  StandardTextFieldProps,
  TextField,
  Box,
  Typography
} from '@mui/material';
import { theme } from 'styles/theme';
import { RemovableChipComponent } from '../RemovableChipComponent';
import { regexOnlyText, regexEmptyString } from 'utils';
import { othersInput } from 'pages/DiseaseData/form/constsAndInterfaces';

interface ArrayInputComponent
  extends Pick<StandardTextFieldProps, 'label' | 'placeholder'> {
  subtitle: string;
  setFieldData?: React.Dispatch<React.SetStateAction<string[]>>;
  fieldData?: string[];
  inputWidth?: string;
  buttonWidth?: string;
  actionOnDelete?: (dataToDelete: string) => void;
  actionOnAdd?: (data: string[]) => void;
  specialFieldData?: othersInput[] | undefined;
}

function ArrayInputComponent({
  subtitle,
  setFieldData,
  fieldData,
  inputWidth,
  buttonWidth,
  specialFieldData,
  actionOnDelete,
  actionOnAdd,
  ...props
}: ArrayInputComponent) {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState<string[]>([]);
  const [specialData, setSpecialData] = useState<othersInput[]>([]);

  function onChangeInput(value: string) {
    if (value.match(regexOnlyText) || value.match(regexEmptyString)) {
      setInputValue(value);
    }
  }

  function addNewChip(newData: string) {
    if (!verifyIfChipAlreadyExists()) {
      setData([...data, newData]);
      if (actionOnAdd) {
        if (specialFieldData) {
          actionOnAdd([
            ...specialData.map(({ description }) => description),
            newData
          ]);
          setInputValue('');
          return;
        }
        actionOnAdd([...data, newData]);
      }
      setInputValue('');
    }
  }

  useEffect(() => {
    if (setFieldData) {
      setFieldData(data);
    }
  }, [data]);

  function verifyIfChipAlreadyExists() {
    const dataExists = data.find((chip) => chip === inputValue);
    if (dataExists || inputValue === '') {
      return true;
    }
    return false;
  }

  function removeChip(dataToDelete: string) {
    setData(() => data.filter((data) => data !== dataToDelete));
    if (actionOnDelete) actionOnDelete(dataToDelete);
  }

  useEffect(() => {
    if (fieldData) setData(fieldData);
  }, []);

  useEffect(() => {
    if (specialFieldData) setSpecialData(specialFieldData);
  }, [specialFieldData]);

  return (
    <>
      <Box display="flex" alignItems="flex-end">
        <TextField
          onChange={(e) => onChangeInput(e.target.value)}
          value={inputValue}
          sx={{ width: inputWidth ? inputWidth : '40%' }}
          variant="standard"
          {...props}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          onClick={() => addNewChip(inputValue)}
          sx={{
            textTransform: 'none',
            marginLeft: '20px',
            height: '32px',
            width: buttonWidth ? buttonWidth : '15%',
            border: '1px solid',
            borderColor: theme.palette.info.main
          }}
          color="info"
        >
          Adicionar
        </Button>
      </Box>
      {data[0] ? (
        <Typography fontSize="14px" color="grey.900">
          {subtitle}
        </Typography>
      ) : (
        ''
      )}
      <ListItem
        sx={{
          width: 'max-content',
          maxWidth: '55vw',
          padding: '0',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {specialFieldData
          ? specialData.map(({ description }) => {
              return (
                <RemovableChipComponent
                  key={description}
                  chipTitle={description}
                  deleteChip={() => removeChip(description)}
                />
              );
            })
          : data.map((data) => {
              return (
                <RemovableChipComponent
                  key={data}
                  chipTitle={data}
                  deleteChip={() => removeChip(data)}
                />
              );
            })}
      </ListItem>
    </>
  );
}

export { ArrayInputComponent };
