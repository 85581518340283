import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { Cards } from './cards';
import { Toolbar } from './toolbar';
import { Content } from './content';
import { NUTRITIONIST_DASHBOARD_TOTALS } from 'services/graphql/queries/dashboard/nutritionist/cards';

export function NutriDashboard() {
  const { data: totals } = useQuery(NUTRITIONIST_DASHBOARD_TOTALS);
  const [cardsData, setCardsData] = useState({
    totalAppointments: 0,
    totalMonth: 0,
    totalIntervention: 0,
    eutrophics: 0,
    malnutritionRisk: 0,
    malnourished: 0,
    sarcopenia: 0
  });
  const [neoplasms, setNeoplasms] = useState([]);

  useEffect(() => {
    if (totals) {
      setCardsData(totals.nutriDashTotals);
      setNeoplasms(totals.nutriDashTotals.neoplasms);
    }
  }, [totals]);

  const [patientName, setPatientName] = useState<string>('');
  return (
    <>
      <Cards cardsData={cardsData} />
      <Toolbar setPatientName={setPatientName} />
      <Content patientName={patientName} neoplasms={neoplasms} />
    </>
  );
}
