import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { StrengthFunctionality as StrenghtFunctionalityInterface } from 'models/strenghtFunctionality';
import { calculatePatientAge } from 'utils/functions/calculateLoadedPatientAge';
import {
  calculateAdultManHandGripPercentil,
  calculateAdultWomanHandGripPercentil,
  calculateElderlyManHandGrip,
  calculateElderlyWomanHandGrip,
  calculateHandGripResult
} from 'pages/StrenghtAndFuncionalityEvaluation/forms/handGrip';
import {
  calculateManStandUpReferenceNumber,
  calculateWomanStandUpReferenceNumber,
  setStandUpStatusFunction
} from 'pages/StrenghtAndFuncionalityEvaluation/forms/standUp';
import { setTugStatusFunction } from 'pages/StrenghtAndFuncionalityEvaluation/forms/tug';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { editAppointment } from './editAppointment';
import { Edit } from '@mui/icons-material';

interface StrenghtFunctionalityProps {
  appointment: Appointment;
  gender: string;
  birthdate: Date;
}

type StrenghtFunctionalityKeys = 'handGrip' | 'sitGetup' | 'tug';

interface StrenghtFunctionalityObjectKey {
  key: StrenghtFunctionalityKeys;
  functionToRenderResult: (value: number) => { value: string };
}

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newStrenghtFunctionalityType = MappedType<StrenghtFunctionalityInterface>;

export function StrenghtFunctionality({
  appointment,
  gender,
  birthdate
}: StrenghtFunctionalityProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const StrenghtFunctionality: newStrenghtFunctionalityType =
    appointment.strengthFunctionality;
  const age = calculatePatientAge(birthdate);

  if (!StrenghtFunctionality) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |11. Avaliação da força e funcionalidade
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parseStrenghtFunctionalityQuestion(value: string) {
    switch (value) {
      case 'handGrip':
        return 'Hand Grip:';
      case 'sitGetup':
        return 'Sentar e levantar da cadeira:';
      case 'tug':
        return 'Teste de velocidade de marcha:';
      default:
        return 'Não há dados';
    }
  }

  function generateHandGripResults(handGrip: number) {
    if (age > 65 && gender === 'MALE') {
      return {
        value: handGrip + 'kg: ' + calculateElderlyManHandGrip(handGrip)
      };
    }
    if (age > 65 && gender === 'FEMALE') {
      return {
        value:
          handGrip + 'kg: ' + calculateElderlyWomanHandGrip(gender, handGrip)
      };
    }
    if (age <= 65 && gender === 'MALE') {
      const percentil = calculateAdultManHandGripPercentil(age);
      return {
        value: handGrip + 'kg: ' + calculateHandGripResult(percentil, handGrip)
      };
    }
    if (age <= 65 && gender === 'FEMALE') {
      const percentil = calculateAdultWomanHandGripPercentil(age);
      return {
        value: handGrip + 'kg: ' + calculateHandGripResult(percentil, handGrip)
      };
    }
    return { value: 'Não há dados' };
  }

  function generateSitGetUpResults(standUpTimes: number) {
    if (gender === 'MALE') {
      const minStandUpTimes = calculateManStandUpReferenceNumber(age);
      return {
        value:
          standUpTimes +
          ' vezes: ' +
          setStandUpStatusFunction(minStandUpTimes, standUpTimes)
      };
    }
    if (gender === 'FEMALE') {
      const minStandUpTimes = calculateWomanStandUpReferenceNumber(age);
      return {
        value:
          standUpTimes +
          ' vezes: ' +
          setStandUpStatusFunction(minStandUpTimes, standUpTimes)
      };
    }
    return {
      value: 'Não há dados'
    };
  }

  function generateTUGResults(tug: number) {
    return {
      value: tug + ' segundos: ' + setTugStatusFunction(tug)
    };
  }

  const allStrenghtFunctionalityFields: StrenghtFunctionalityObjectKey[] = [
    { key: 'handGrip', functionToRenderResult: generateHandGripResults },
    { key: 'sitGetup', functionToRenderResult: generateSitGetUpResults },
    { key: 'tug', functionToRenderResult: generateTUGResults }
  ];

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |11. Avaliação da força e funcionalidade
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/strength-functionality-evaluation')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allStrenghtFunctionalityFields.map(
            ({ key, functionToRenderResult }, index) => {
              return StrenghtFunctionality[key] ? (
                <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                  <Label title={parseStrenghtFunctionalityQuestion(key)} />
                  <ResultText
                    title={`${
                      functionToRenderResult(StrenghtFunctionality[key]).value
                    }`}
                  />
                </Box>
              ) : (
                ''
              );
            }
          )}
        </Box>
      </Collapse>
    </Box>
  );
}
