import { gql } from '@apollo/client';

const FIND_PATIENTS_BY_NAME = gql`
  query findPatientsByName($name: String!) {
    findPatientsByName(name: $name) {
      id
      name
      birthdate
      gender
      modality
      observations
      medicalRecord
      appointments {
        appointmentOrder
      }
    }
  }
`;

export { FIND_PATIENTS_BY_NAME };
