import { gql } from '@apollo/client';

const CREATE_PATIENT = gql`
  mutation createPatient($patient: PatientInput!) {
    createPatient(patient: $patient) {
      id
    }
  }
`;

export { CREATE_PATIENT };
