import React from 'react';
import { Box, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { ChipComponent } from 'components/Chip';
import { calculateTCMuscleMass } from 'pages/BodyCompositionEvaluation/forms/tc';

interface TcProps {
  appointment: Appointment;
}

export function Tc({ appointment }: TcProps) {
  const Tc = appointment.bodyCompositionTc;
  if (!Tc) {
    return <></>;
  }
  const muscleMass = calculateTCMuscleMass(
    Tc.skeletalMuscleMass || 0,
    appointment.NutritionalData.height || 0
  );
  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Typography
        sx={{ padding: '0 20px' }}
        fontSize="16px"
        fontWeight="700"
        color="grey.900"
      >
        Tomografia Computadorizada (TC)
      </Typography>
      <Box
        display="flex"
        padding="0 20px"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Box margin="15px 0" marginRight="15px" width="48%">
          <Label title="Massa muscular esquelética em kg – L3:" />
          <ResultText title={Tc.skeletalMuscleMass + 'kg'} />
        </Box>
        <Box margin="15px 0" marginRight="15px" width="48%">
          <Label title="Tecido adiposo em kg – L3:" />
          <ResultText title={Tc.adiposeTissue + 'kg'} />
        </Box>
        <ChipComponent
          label={`Índice de massa muscular esquelética (IME): ${muscleMass}cm²`}
          color={{ colors: 'light-blue' }}
        />
      </Box>
    </Box>
  );
}
