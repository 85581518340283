import { TableCell, tableCellClasses, TableRowProps } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableCell = styled(TableCell)<TableRowProps>(
  ({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      background: theme.palette.grey[100],
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      fontWeight: '500',
      color: theme.palette.grey[300]
    }
  })
);
