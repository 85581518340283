import React, { useState } from 'react';
import { ChipComponent } from 'components/Chip';
import { parseCIDNameByCode } from 'utils/constants/cid';
import { parseWeeklyMonthlyAndDailyText } from 'utils/constants/weeklyMonthlyAndDailyFrequency';
import { parseUrinaryHabitName } from 'utils/constants/urinaryHabitFrequency';
import { parseWaterIntakeValue } from 'utils/constants/waterIntakeFrequency';
import { Subtitle } from 'components/Subtitle';
import { generateStagingLabel } from 'utils/constants/stagings';
import { parseTreatmentsNames } from 'utils/constants/treatments';
import {
  Box,
  Typography,
  Tooltip,
  Button,
  Collapse,
  IconButton
} from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import { editAppointment } from './editAppointment';
import { useHistory } from 'react-router-dom';

export function DiseaseData({ appointment }: { appointment: Appointment }) {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  if (!appointment.diseaseData)
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |2. Dados da doença
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  const disease = appointment.diseaseData;
  const otherDiseases = appointment.diseaseData.others?.filter(
    (others) => others.type === 'PRE_EXISTENT'
  );
  const otherFamilyBackground = appointment.diseaseData.others?.filter(
    (others) => others.type === 'FAMILY_BACKGROUND'
  );
  const otherAllergies = appointment.diseaseData.others?.filter(
    (others) => others.type === 'ALLERGIES'
  );
  const otherIntolerances = appointment.diseaseData.others?.filter(
    (others) => others.type === 'INTOLERANCES'
  );
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |2. Dados da doença
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/disease-data')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          padding="10px 20px"
          minHeight="50vh"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box width="33%">
            <Label title="CIDs adicionados:" />
            {disease.cids?.map((cid) => {
              return (
                <Tooltip
                  arrow
                  placement="top"
                  key={cid.code}
                  title={parseCIDNameByCode(cid.code)}
                >
                  <Button sx={{ maxWidth: '40%' }}>
                    <ChipComponent
                      sx={{
                        maxWidth: '100%'
                      }}
                      size="small"
                      color={{ colors: 'grey' }}
                      label={parseCIDNameByCode(cid.code)}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
          <Box width="33%">
            <Label title="Doenças adicionados:" />
            {otherDiseases?.map((others) => {
              return (
                <Tooltip
                  arrow
                  placement="top"
                  key={others.description}
                  title={others.description}
                >
                  <Button sx={{ maxWidth: '40%' }}>
                    <ChipComponent
                      sx={{
                        maxWidth: '100%'
                      }}
                      size="small"
                      color={{ colors: 'grey' }}
                      label={others.description}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
          <Box width="33%">
            <Label title="Antecedentes adicionados:" />
            {otherFamilyBackground?.map((others) => {
              return (
                <Tooltip
                  arrow
                  placement="top"
                  key={others.description}
                  title={others.description}
                >
                  <Button sx={{ maxWidth: '40%' }}>
                    <ChipComponent
                      sx={{
                        maxWidth: '100%'
                      }}
                      size="small"
                      color={{ colors: 'grey' }}
                      label={others.description}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
          <Box width="33%">
            <Label title="Alergias adicionadas:" />
            {otherAllergies?.map((others) => {
              return (
                <Tooltip
                  arrow
                  placement="top"
                  key={others.description}
                  title={others.description}
                >
                  <Button sx={{ maxWidth: '40%' }}>
                    <ChipComponent
                      sx={{
                        maxWidth: '100%'
                      }}
                      size="small"
                      color={{ colors: 'grey' }}
                      label={others.description}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
          <Box width="33%">
            <Label title="Intolerâncias adicionadas:" />
            {otherIntolerances?.map((others) => {
              return (
                <Tooltip
                  arrow
                  placement="top"
                  key={others.description}
                  title={others.description}
                >
                  <Button sx={{ maxWidth: '40%' }}>
                    <ChipComponent
                      sx={{
                        maxWidth: '100%'
                      }}
                      size="small"
                      color={{ colors: 'grey' }}
                      label={others.description}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
          <Box width="33%" textAlign="justify">
            <Label title="Tabagismo:" />
            <ResultText title={disease.smoking ? 'Sim' : 'Não'} />
          </Box>
          <Box width="33%" textAlign="justify">
            <Label title="Etilismo:" />
            <ResultText title={disease.alcoholism ? 'Sim' : 'Não'} />
          </Box>
          <Box width="33%" textAlign="justify">
            <Label title="Hábito intestinal:" />
            <ResultText
              title={parseWeeklyMonthlyAndDailyText(disease.bowelHabit || '')}
            />
          </Box>
          <Box width="33%" textAlign="justify">
            <Label title="Hábito urinário:" />
            <ResultText
              title={parseUrinaryHabitName(disease.urinaryHabit || '')}
            />
          </Box>
          <Box width="33%" textAlign="justify">
            <Label title="Ingestão hídrica:" />
            <ResultText
              title={parseWaterIntakeValue(disease.waterIntake || '')}
            />
          </Box>
        </Box>
        <Box padding="10px 20px" height="20vh">
          <Box display="flex" justifyContent="space-between">
            <Subtitle title="ESTADIAMENTO" />
            <Box
              width="87%"
              borderBottom="1px solid"
              borderColor="divider"
              marginBottom="13px"
            ></Box>
          </Box>
          <Box width="100%" display="flex" flexWrap="wrap">
            {disease.cids?.map(({ code, staging }) => {
              return (
                <Box margin="15px 0" key={code} width="33%">
                  <Label title={parseCIDNameByCode(code)} />
                  <ResultText title={generateStagingLabel(staging).join(',')} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box padding="10px 20px" height="20vh">
          <Box display="flex" justifyContent="space-between">
            <Subtitle title="TRATAMENTO" />
            <Box
              width="87%"
              borderBottom="1px solid"
              borderColor="divider"
              marginBottom="13px"
            ></Box>
          </Box>
          <Box width="100%" display="flex" flexWrap="wrap">
            {disease.treatments?.map(({ code }) => {
              return (
                <Box margin="15px 0" key={code} width="33%">
                  <Label title="Tratamento Anti-neoplásico" />
                  <ResultText title={parseTreatmentsNames(code)} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
