import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';

import { Appointment } from 'models/appointment';
import { colors } from 'styles/graphColors';
import { prepareSymptoms } from './dataPrepare/prepareSymptoms';

interface SymptomCellProps {
  color?: string;
  bg?: boolean;
}

interface Symptom {
  name: string;
  ocurrences: number[];
}

function TitleCell({ title }: { title: string }) {
  return (
    <TableCell
      sx={{
        padding: '10px',
        borderBottom: '0px',
        width: '45px',
        maxWidth: 'fit-content'
      }}
    >
      {title}
    </TableCell>
  );
}

function SymptomCell({ color, bg }: SymptomCellProps) {
  return (
    <TableCell
      sx={{
        padding: '5px 0px',
        backgroundColor: bg ? '#F6F9FC' : 'none',
        borderBottom: '0px'
      }}
    >
      {color && (
        <div
          style={{
            backgroundColor: color,
            width: '100%',
            height: '16px'
          }}
        ></div>
      )}
    </TableCell>
  );
}

function SymptomLine({
  symptom,
  coloridx,
  total
}: {
  symptom: Symptom;
  coloridx: number;
  total: number;
}) {
  const cells = [];
  for (let i = 1; i <= total; i++) {
    const mark = symptom.ocurrences.includes(i);
    cells.push(
      <SymptomCell
        color={mark ? colors[coloridx] : 'none'}
        bg={i % 2 === 0}
        key={i}
      />
    );
  }
  return (
    <TableRow>
      <TitleCell title={symptom.name} />
      {cells}
    </TableRow>
  );
}

function CLine({ quant }: { quant: number }) {
  const sx = {
    padding: '5px 0px',
    borderBottom: '0px',
    color: '#7B868C',
    fontSize: '10px',
    minWidth: '40px'
  };
  const cells = [];
  cells.push(<TableCell sx={sx} key={0}></TableCell>);
  for (let i = 1; i <= quant; i++) {
    cells.push(
      <TableCell align="center" sx={sx} key={i}>
        {i < 10 ? 'C0' : 'C'}
        {i}
      </TableCell>
    );
  }
  return <TableRow>{cells}</TableRow>;
}

interface Props {
  appointments: Appointment[];
}

export function SymptomsGraph({ appointments }: Props) {
  const symptoms = prepareSymptoms(appointments);

  const apps = symptoms;

  const groupBySymptom = (): Symptom[] => {
    const ret: Symptom[] = [];

    // list all symptoms, including repetitions
    const allSymptoms = symptoms.flatMap((s) => s);
    // remove repetitions
    const unique = allSymptoms.filter((v, i) => allSymptoms.indexOf(v) === i);

    for (let i = 0; i < unique.length && i < 5; i++) {
      const ocurrences = [];
      for (let n = 0; n < symptoms.length; n++) {
        if (symptoms[n].indexOf(unique[i]) >= 0) ocurrences.push(n + 1);
      }
      ret.push({ name: unique[i], ocurrences });
    }
    ret.sort((a, b) => {
      return a.ocurrences.length < b.ocurrences.length ? 1 : -1;
    });
    return ret;
  };

  return (
    <Card>
      <CardHeader title="Principais sintomas" />
      <Divider />
      <CardContent>
        <Table>
          <TableBody sx={{ padding: '15px' }}>
            {groupBySymptom().map((s, i) => (
              <SymptomLine
                symptom={s}
                total={apps.length}
                coloridx={i}
                key={i}
              />
            ))}
            <CLine quant={apps.length} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
