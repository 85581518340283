import { Appointment } from 'models/appointment';
import { diagnoses } from 'utils/constants/diagnoses';

export const prepareNutriDiag = (appointments: Appointment[]) => {
  return [
    {
      name: '',
      lines: diagnoses.map((d) => ({
        name: d.label,
        occurrences: appointments.reduce(
          (arr: number[], elm, i) =>
            elm.diagnosis === d.value ? [...arr, i + 1] : arr,
          []
        )
      }))
    }
  ];
};
