import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  TooltipComponent,
  ChipComponent,
  ButtonComponent,
  Input
} from 'components';
import { InfoBox } from './components/InfoBox';
import { useMutation, useQuery } from '@apollo/client';
import { FullAppointment } from 'models/fullAppointment';
import { FIND_FULL_APPOINTMENT_BY_ID } from 'services/graphql/queries/appointment/findFullAppointment';
import { Patient } from 'models/patient';
import { FIND_PATIENT_BY_ID } from 'services/graphql/queries/patient/getPatientById';
import { toast } from 'react-toastify';
import { DropdownInputComponent } from 'components/DropdownInputComponent';
import { diagnoses } from 'utils/constants/diagnoses';
import { SET_APPOINTMENT_DIAGNOSIS } from 'services/graphql/mutations/appointments/setAppointmentDiagnosis';
import { exportAppointmentData } from './functions/exportAppointmentData';
import { renderAllAppointmentDataParsed } from './functions/renderAllDataParsed';
import { renderShortAppointmentResume } from './functions/renderShortAppointmentResume';
import {
  calculateIMC,
  calculatePatientAge,
  verifyIMCClassification
} from 'utils';

export interface StepResult {
  title: string;
  contents: Result[] | undefined;
}

export interface Result {
  contentTitle: string | undefined;
  content: string | undefined;
}

interface IFormData {
  diagnosis: string;
  therapeuticPlan: string;
}

interface ParsedAppointmentDataReturn {
  fullAppointmentResume?: StepResult[];
  shortAppointmentResume?: StepResult[];
}

const NutritionalDiagnosisForm: ForwardRefRenderFunction<HTMLButtonElement> = (
  props,
  ref
) => {
  const history = useHistory();
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      diagnosis: 'eutrophy',
      therapeuticPlan: ''
    }
  });
  const watchDiagnosis = watch('diagnosis');
  const watchTherapeutic = watch('therapeuticPlan');
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const patientId = Number(localStorage.getItem('patientId'));
  const id = Number(localStorage.getItem('appointmentId'));

  const { data: appointmentQuery, loading: loadingAppointment } = useQuery<{
    findAppointment: FullAppointment;
  }>(FIND_FULL_APPOINTMENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id
    }
  });
  const { data: patientData, loading: patientLoading } = useQuery<{
    findPatient: Patient;
  }>(FIND_PATIENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId
    }
  });
  const [setAppointmentDiagnosisMutation] = useMutation(
    SET_APPOINTMENT_DIAGNOSIS
  );
  const [diagnosis, setDiagnosis] = useState('Eutrofia');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appointmentData = useMemo<
    ParsedAppointmentDataReturn | undefined
  >(() => {
    if (!appointmentQuery || !patientData) return;
    if (appointmentQuery && patientData) {
      const loadedAppointment = appointmentQuery.findAppointment;
      const patient = patientData.findPatient;
      const appointmentData = renderAllAppointmentDataParsed(
        loadedAppointment,
        patient
      );
      const shortAppointmentData = renderShortAppointmentResume(
        loadedAppointment,
        patient
      );
      if (loadedAppointment.diagnosis) {
        setValue('diagnosis', loadedAppointment.diagnosis);
        setValue('therapeuticPlan', loadedAppointment?.therapeuticPlan || '');
      }
      return {
        fullAppointmentResume: appointmentData,
        shortAppointmentResume: shortAppointmentData
      };
    }
    return;
  }, [appointmentQuery, patientData]);

  const { height, weight, imc, imcClassification } = useMemo(() => {
    if (appointmentQuery && patientData) {
      const loadedAppointment = appointmentQuery?.findAppointment;
      const { height, weight } = loadedAppointment.NutritionalData;
      const imc = calculateIMC(height, weight);
      const patient = patientData.findPatient;
      const age = calculatePatientAge(patient.birthdate);
      return {
        height: String(height) + 'm',
        weight: String(weight) + 'kg',
        imc: String(imc),
        imcClassification: verifyIMCClassification(imc, age) || 'Não encontrado'
      };
    }
    return {
      height: 'Não encontrada',
      weight: 'Não encontrado',
      imc: 'Não encontrado',
      imcClassification: 'Não encontrado'
    };
  }, [appointmentQuery, patientData]);

  function copyText() {
    const parsedAppointmentAsStringArray =
      appointmentData?.fullAppointmentResume?.flatMap(({ contents, title }) => {
        const parsedContents = contents?.map((item) => {
          if (item.contentTitle?.includes('Valor total do ganho')) {
            return String(`${item.contentTitle}:  ${item.content} \n`);
          }
          return String(`${item.contentTitle}:  ${item.content}`);
        });
        if (title.includes('Dados e informações nutricionais')) {
          return [
            `\n- ${title} 🔵️\n`,
            `  ${parsedContents?.toString()} \n\n` || ['']
          ];
        }
        return [`- ${title}`, `${parsedContents?.toString()} \n` || ['']];
      });
    if (!parsedAppointmentAsStringArray)
      return toast.error('Houve um erro para copiar os dados');
    parsedAppointmentAsStringArray.push([
      `🔵️ Plano terapêutico: `,
      watchTherapeutic
    ]);
    const appointmentAsString =
      parsedAppointmentAsStringArray.toString().replace(/,/g, ' ') || '';
    navigator.clipboard.writeText(appointmentAsString);
    toast.success('Dados copiados!');
  }

  useEffect(() => {
    switch (watchDiagnosis) {
      case 'eutrophy':
        setDiagnosis('Eutrofia');
        break;
      case 'malnutrition':
        setDiagnosis('Desnutrição');
        break;
      case 'sarcopenia':
        setDiagnosis('Sarcopenia');
        break;
      case 'obesity':
        setDiagnosis('Obesidade');
        break;
      case 'sarcopenic obesity':
        setDiagnosis('Obesidade sarcopênica');
        break;
      case 'myopenia':
        setDiagnosis('Miopenia');
        break;
      case 'overweight':
        setDiagnosis('Sobrepeso');
        break;
      case 'cachexia':
        setDiagnosis('Caquexia');
    }
  }, [watchDiagnosis]);

  async function setAppointmentDiagnosis(diagnosis: IFormData) {
    await setAppointmentDiagnosisMutation({
      variables: {
        diagnosis: diagnosis.diagnosis,
        therapeuticPlan: diagnosis.therapeuticPlan,
        appointmenId: appointmentId
      },
      onCompleted: () => {
        toast.success('Diagnóstico definido com sucesso.');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      setAppointmentDiagnosis(data);
      history.push('/home/nutritional-intervention');
    } catch {
      toast.error('Houve um erro');
    }
  }
  if (loadingAppointment && patientLoading) return <CircularProgress />;
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          xs={12}
          spacing={4}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{ textTransform: 'uppercase', cursor: 'default' }}
                fontWeight="700"
                fontSize="14px"
                color="grey.900"
              >
                Diagnóstico avaliado pela ferramenta:
              </Typography>
              <TooltipComponent
                content="Classificar o diagnóstico nutricional
             do paciente com base na realidade clínica, 
             triagem e avaliação nutricional realizada.
             "
              />
            </Box>
            <Typography
              sx={{ cursor: 'default' }}
              marginBottom="15px"
              fontSize="14px"
              color="grey.300"
            >
              A classificação nutricional diante das ferramentas aplicadas foi:
            </Typography>
            <ChipComponent
              sx={{
                textTransform: 'uppercase',
                width: '60%',
                marginBottom: '30px'
              }}
              color={{ colors: 'light-blue' }}
              label={`diagnóstico nutricional do paciente: ${diagnosis}`}
            />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" display="flex" gap={4}>
              <ButtonComponent
                width="fit-content"
                isTransparent={false}
                onClick={() =>
                  exportAppointmentData(
                    appointmentData?.fullAppointmentResume || [],
                    diagnosis,
                    watchTherapeutic,
                    {
                      name:
                        patientData?.findPatient.name || 'Nome não encontrado',
                      height,
                      weight,
                      imc,
                      imcClassification
                    }
                  )
                }
                title="Exportar dados"
              />
              <ButtonComponent
                width="fit-content"
                isTransparent={false}
                onClick={copyText}
                title="Copiar dados"
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography
              sx={{ cursor: 'default' }}
              fontSize="14px"
              fontWeight="500"
              color="grey.900"
            >
              RESULTADO DO RISCO E/OU AVALIAÇÃO NUTRICIONAL:
            </Typography>
            {appointmentData?.shortAppointmentResume?.map((data, index) => {
              return (
                <InfoBox
                  key={data.title}
                  title={data.title}
                  contents={data?.contents || []}
                  backgroundHighlight={index % 2 === 0}
                />
              );
            })}
            <InfoBox title="Plano terapêutico">
              <Box width="100%">
                <Input
                  sx={{ width: '100%' }}
                  name="therapeuticPlan"
                  control={control}
                  label="Insira aqui a descrição do plano terapêutico..."
                />
              </Box>
            </InfoBox>
          </Grid>
          <Grid item>
            <Typography
              sx={{ cursor: 'default' }}
              fontSize="14px"
              fontWeight="500"
              color="grey.900"
            >
              RESUMO:
            </Typography>
            {appointmentData?.fullAppointmentResume?.map((data, index) => {
              return (
                <InfoBox
                  key={data.title}
                  title={data.title}
                  contents={data?.contents || []}
                  backgroundHighlight={index % 2 === 0}
                />
              );
            })}
          </Grid>
          <Grid item margin="15px 0">
            <Typography
              sx={{ cursor: 'default' }}
              fontSize="14px"
              fontWeight="500"
              color="grey.900"
            >
              DEFINIR DIAGNÓSTICO:
            </Typography>
            <DropdownInputComponent
              sx={{ width: '50%' }}
              select={diagnoses}
              control={control}
              name="diagnosis"
              label="Diagnóstico escolhido"
            />
          </Grid>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default forwardRef(NutritionalDiagnosisForm);
