import { Box, Chip, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';
import { TooltipComponent } from '../Tooltip';
import { StyledFormControlLabel } from './styles';

interface RadioButtonType {
  action(): void;
  checked: boolean;
  title: string;
  description: string;
  value: string;
  tooltipText?: string;
  chipData?: string;
}

interface IDetailedButtonProps {
  buttons: RadioButtonType[];
}

function DetailedRadioButton({ buttons }: IDetailedButtonProps) {
  return (
    <RadioGroup
      row
      sx={{
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      {buttons.map(
        ({
          action,
          description,
          title,
          checked,
          value,
          chipData,
          tooltipText
        }) => {
          return (
            <Box
              key={title}
              sx={{
                padding: '10px',
                width: '48%',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: checked
                  ? theme.palette.info.main
                  : theme.palette.divider,
                background: checked ? theme.palette.info.light : ''
              }}
            >
              <Box display="flex">
                <StyledFormControlLabel
                  label={title}
                  value={value}
                  checked={checked}
                  control={<Radio onChange={action} />}
                />
                {tooltipText ? <TooltipComponent content={tooltipText} /> : ''}
              </Box>
              <Typography color="grey.400" fontSize="12px">
                {description}
              </Typography>
              {chipData ? (
                <Chip
                  sx={{
                    fontSize: '10px',
                    color: checked
                      ? theme.palette.info.main
                      : theme.palette.grey[300],
                    borderColor: checked
                      ? theme.palette.info.main
                      : theme.palette.grey[300]
                  }}
                  size="small"
                  color="info"
                  variant="outlined"
                  label={chipData}
                />
              ) : (
                ''
              )}
            </Box>
          );
        }
      )}
    </RadioGroup>
  );
}

export { DetailedRadioButton };
