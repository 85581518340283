import {
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  RadioButtonInput,
  TooltipComponent,
  DropdownInputComponent,
  ChipComponent,
  ClassificationTable
} from 'components';
import { theme } from 'styles/theme';
import { HelpOutlineRounded } from '@mui/icons-material';
import {
  CREATE_NRS,
  UPDATE_NRS,
  CreateNrsVars,
  UpdateNrsVars
} from 'services/graphql/mutations/nutritional-risk/nrs';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Nrs } from 'models/nrs';
import { useHistory } from 'react-router-dom';

interface IFormData {
  imcLessThan20: string;
  weightLoss: string;
  eatingLess: string;
  seriouslyIll: string;
  nutritionalStatusDeterioration: string;
  diseaseLevel: string;
}

const defaultValues: IFormData = {
  imcLessThan20: 'no',
  weightLoss: 'no',
  eatingLess: 'no',
  seriouslyIll: 'no',
  nutritionalStatusDeterioration: '0',
  diseaseLevel: '0'
};

const NRSForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    weightLossTime: string;
    imc: number;
    loadedValues: Nrs | undefined;
    lastNrs: Nrs | undefined;
  }
> = ({ weightLossTime, imc, loadedValues, lastNrs }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues
  });

  const history = useHistory();

  const [createNrsMutation] = useMutation<null, CreateNrsVars>(CREATE_NRS);
  const [updateNrsMutation] = useMutation<null, UpdateNrsVars>(UPDATE_NRS);

  const [result, setResult] = useState(0);
  const watchImcLessThan20 = watch('imcLessThan20');
  const watchWeightLoss = watch('weightLoss');
  const watchEatingLess = watch('eatingLess');
  const watchSeriouslyIll = watch('seriouslyIll');

  const watchNutritionalStatus = watch('nutritionalStatusDeterioration');
  const watchDiseaseLevel = watch('diseaseLevel');

  useEffect(() => {
    if (weightLossTime !== 'noWeightLoss' && weightLossTime !== 'sixMonths')
      setValue('weightLoss', 'yes');
    if (imc < 20.5) setValue('imcLessThan20', 'yes');
    if (imc >= 20.5) setValue('imcLessThan20', 'no');

    setValues(loadedValues);
  }, []);

  useEffect(() => {
    setValues(lastNrs);
  }, [lastNrs]);

  useEffect(() => {
    setResult(Number(watchNutritionalStatus) + Number(watchDiseaseLevel));
  }, [watchNutritionalStatus, watchDiseaseLevel]);

  const NRSquestions = [
    {
      name: 'imcLessThan20',
      title: `O IMC é menor que 20,5kg/m²?:`,
      radioButtons: [
        {
          checked: watchImcLessThan20 === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchImcLessThan20 === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    },
    {
      name: 'weightLoss',
      title: `O paciente perdeu peso nos 3 últimos meses?:`,
      radioButtons: [
        {
          checked: watchWeightLoss === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchWeightLoss === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    },
    {
      name: 'eatingLess',
      title: `O paciente teve sua ingestão dietética reduzida na última semana?:`,
      radioButtons: [
        {
          checked: watchEatingLess === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchEatingLess === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    },
    {
      name: 'seriouslyIll',
      title: `O paciente é gravemente doente?:`,
      radioButtons: [
        {
          checked: watchSeriouslyIll === 'yes',
          label: 'Sim',
          value: 'yes'
        },
        {
          checked: watchSeriouslyIll === 'no',
          label: 'Não',
          value: 'no'
        }
      ]
    }
  ];

  const dropdownValues = [
    {
      label: '0: Ausente',
      value: '0'
    },
    {
      label: '1: Leve',
      value: '1'
    },
    {
      label: '2: Moderado',
      value: '2'
    },
    {
      label: '3: Grave',
      value: '3'
    }
  ];

  function setValues(data: Nrs | undefined) {
    if (data) {
      setValue('imcLessThan20', data.imcLessThan20 === false ? 'no' : 'yes');
      setValue('weightLoss', data?.weightLoss === false ? 'no' : 'yes');
      setValue('eatingLess', data?.eatingLess === false ? 'no' : 'yes');
      setValue('seriouslyIll', data?.seriouslyIll === false ? 'no' : 'yes');
      setValue(
        'nutritionalStatusDeterioration',
        data?.nutritionalStatusDeterioration.toString()
      );
      setValue('diseaseLevel', data?.diseaseLevel.toString());
    }
  }

  const appointmentId = Number(localStorage.getItem('appointmentId'));

  function updateNrs(form: IFormData) {
    if (!appointmentId) return;
    if (loadedValues)
      updateNrsMutation({
        variables: {
          id: Number(loadedValues.id),
          nrs: {
            appointmentId,
            imcLessThan20: form.imcLessThan20 === 'yes' ? true : false,
            weightLoss: form.weightLoss === 'yes' ? true : false,
            eatingLess: form.eatingLess === 'yes' ? true : false,
            seriouslyIll: form.seriouslyIll === 'yes' ? true : false,
            nutritionalStatusDeterioration: Number(
              form.nutritionalStatusDeterioration
            ),
            diseaseLevel: Number(form.diseaseLevel)
          }
        },
        onCompleted: () => {
          toast.info(
            'Triagem do risco nutricional NRS atualizada com sucesso.'
          );
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  function createNrs(form: IFormData) {
    if (!appointmentId) return;
    if (!loadedValues)
      createNrsMutation({
        variables: {
          nrs: {
            appointmentId,
            imcLessThan20: form.imcLessThan20 === 'yes' ? true : false,
            weightLoss: form.weightLoss === 'yes' ? true : false,
            eatingLess: form.eatingLess === 'yes' ? true : false,
            seriouslyIll: form.seriouslyIll === 'yes' ? true : false,
            nutritionalStatusDeterioration: Number(
              form.nutritionalStatusDeterioration
            ),
            diseaseLevel: Number(form.diseaseLevel)
          }
        },
        onCompleted: () => {
          toast.success('Triagem do risco nutricional NRS criada com sucesso.');
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  function onSubmit(form: IFormData) {
    try {
      createNrs(form);
      updateNrs(form);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="130vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Triagem de Risco Nutricional (NRS 2002)
            </Typography>
            <TooltipComponent content=" Utilizada em pacientes internados" />
          </Box>
          <Divider />
          <Box>
            <Typography
              fontWeight="700"
              color={theme.palette.info.main}
              fontSize="14px"
            >
              Etapa 1. Triagem inicial
            </Typography>
            <Typography color="grey.400" fontSize="14px">
              Se obtiver alguma resposta “sim”, passar para a 2a etapa. Repetir
              a cada 7 dias, caso não obtenha nenhuma resposta positiva.
            </Typography>
          </Box>
          {NRSquestions.map(({ name, title, radioButtons }) => {
            return (
              <Box key={name} marginBottom="15px">
                <RadioButtonInput
                  control={control}
                  name={name}
                  disabled={false}
                  title={title}
                  radioButtons={radioButtons}
                />
              </Box>
            );
          })}
          <Typography
            fontWeight="700"
            color={theme.palette.info.main}
            fontSize="14px"
          >
            Etapa 2. Triagem final
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <DropdownInputComponent
              sx={{
                width: '48%'
              }}
              select={dropdownValues.map(({ label, value }) => {
                return { label, value };
              })}
              control={control}
              name="nutritionalStatusDeterioration"
              label={
                <Box position="absolute" marginTop="-10px">
                  Deterioração do estado nutricional:
                  <Tooltip
                    title="0: Ausente - Estado nutricional normal. 
                            1: Leve - Perda de peso >5% em 3 meses OU
                            aceitação da via oral entre 50 e 75% da
                            estimativa de requerimento há 1 semana.
                            2: Moderado - Perda de peso >5% em 2 meses OU IMC
                            = 18,5 a 20,5 + piora das condições gerais
                            OU aceitação da via oral entre 25% e
                            50% da estimativa de requerimento há 1
                            semana.
                            3: Grave - Perda de peso >5% em 1 mês (>15%
                            em 3 meses) OU IMC <18,5 + piora
                            das condições gerais OU aceitação da
                            via oral entre 0 e 25% da estimativa de
                            requerimento há 1 semana."
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <HelpOutlineRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
            <DropdownInputComponent
              sx={{
                width: '48%'
              }}
              select={dropdownValues.map(({ label, value }) => {
                return { label, value };
              })}
              control={control}
              name="diseaseLevel"
              label={
                <Box position="absolute" marginTop="-10px">
                  Gravidade da doença (grau de estresse:)
                  <Tooltip
                    title="0: - Requerimento nutricional normal.
                            1: Leve - Fratura de quadril, pacientes crônicos
                            (especialmente com complicações agudas):
                            cirrose, doença pulmonar obstrutiva crônica,
                            Hemodiálise, diabetes e oncologia.
                            2: Moderado - Cirurgia abdominal de grande porte, acidente
                            vascular cerebral, pneumonia grave, leucemia.
                            3: Grave - Traumatismo craniano, transplante de medula
                            óssea, pacientes críticos (APACHE>10)."
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <HelpOutlineRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          </Box>
          <ChipComponent
            color={{ colors: 'light-blue' }}
            label={`SOMA TOTAL ${result} PONTOS`}
            sx={{
              margin: '10px 0'
            }}
          />
          <Box
            minHeight="25vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              width="100%"
              fontWeight="500"
              borderBottom="1px solid"
              borderColor="grey.200"
              fontSize="18px"
              color="grey.900"
            >
              Classificação
            </Typography>
            <ClassificationTable
              rows={[
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Igual ou menor a 3',
                  secondCellContent: 'Risco nutricional',
                  selected: result <= 3 ? true : false
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Maior que 3',
                  secondCellContent: 'Reavaliar semanalmente',
                  selected: result > 3 ? true : false
                }
              ]}
            />
          </Box>
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(NRSForm);
