import { format } from 'date-fns';

export interface IFormInput {
  appointmentOrder: number;
  appointmentDate: string;
  appointmentHour: string;
  medicalRecord: number;
  name: string;
  gender: 'MALE' | 'FEMALE';
  birthdate: string;
  age: number;
  modality: 'INSURANCE' | 'PRIVATE' | 'SUS';
  observations?: string;
}

export const defaultValues: IFormInput = {
  age: 0,
  appointmentDate: format(new Date(), 'yyyy-MM-dd'),
  appointmentHour: format(new Date(), 'HH:mm'),
  appointmentOrder: 1,
  birthdate: format(new Date(), 'yyyy-MM-dd'),
  name: '',
  medicalRecord: 1,
  modality: 'PRIVATE',
  observations: '',
  gender: 'MALE'
};
