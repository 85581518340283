import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Divider, Typography } from '@mui/material';
import {
  TooltipComponent,
  RadioButtonInput,
  ClassificationTable,
  ChipComponent,
  InputWithNumberMask
} from 'components';
import { useMutation } from '@apollo/client';
import {
  CREATE_MUST,
  UPDATE_MUST,
  CreateMustVars,
  UpdateMustVars
} from 'services/graphql/mutations/nutritional-risk/must';
import { toast } from 'react-toastify';
import { Must } from 'models/must';
import { useHistory } from 'react-router-dom';

interface IFormData {
  imc: number;
  imcPontuation: string;
  weightLoss: string;
  cannotFeed: string;
}

type IFormDataSubmit = Omit<IFormData, 'imc'>;

const defaultValues: IFormDataSubmit = {
  imcPontuation: '0',
  weightLoss: '0',
  cannotFeed: '0'
};

const MUSTForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    imc: number;
    weightLossPercentage: number;
    loadedValues: Must | undefined;
    lastMust: Must | undefined;
  }
> = ({ imc, loadedValues, weightLossPercentage, lastMust }, ref) => {
  const { handleSubmit, control, watch, setValue, getValues } =
    useForm<IFormData>({
      defaultValues
    });
  const history = useHistory();

  const [createMustMutation] = useMutation<CreateMustVars>(CREATE_MUST);
  const [updateMustMutation] = useMutation<UpdateMustVars>(UPDATE_MUST);

  const watchWeightLoss = watch('weightLoss');
  const watchCannotFeed = watch('cannotFeed');
  const watchIMC = watch('imc');
  const [result, setResult] = useState(0);

  useEffect(() => {
    setValue('imc', imc);
    if (weightLossPercentage < 5) setValue('weightLoss', '0');
    if (weightLossPercentage >= 5 && weightLossPercentage <= 10)
      setValue('weightLoss', '1');
    if (weightLossPercentage >= 10) setValue('weightLoss', '2');
    setFormValues(loadedValues);
  }, []);

  useEffect(() => {
    setValue('imc', imc);
    if (weightLossPercentage < 5) setValue('weightLoss', '0');
    if (weightLossPercentage >= 5 && weightLossPercentage <= 10)
      setValue('weightLoss', '1');
    if (weightLossPercentage >= 10) setValue('weightLoss', '2');
    setFormValues(lastMust);
  }, [lastMust]);

  useEffect(() => {
    setIMCPuntuationValue();
  }, [watchIMC]);

  useEffect(() => {
    setFinalResultValue();
  }, [watchWeightLoss, watchCannotFeed, watchIMC]);

  function setIMCPuntuationValue() {
    if (watchIMC > 20) setValue('imcPontuation', '0');
    if (watchIMC >= 18.5 && watchIMC <= 20) setValue('imcPontuation', '1');
    if (watchIMC < 18.5) setValue('imcPontuation', '2');
  }

  function setFinalResultValue() {
    setResult(
      Number(watchWeightLoss) +
        Number(watchCannotFeed) +
        Number(getValues('imcPontuation'))
    );
  }

  function setFormValues(data: Must | undefined) {
    if (data) {
      setValue('weightLoss', data.weightLoss.toString());
      setValue('imcPontuation', data.imcPontuation.toString());
      setValue('cannotFeed', data.cannotFeed.toString());
    }
  }

  const appointmentId = Number(localStorage.getItem('appointmentId'));

  function updateMUST(data: IFormData) {
    if (loadedValues)
      updateMustMutation({
        variables: {
          id: loadedValues.id,
          must: {
            appointmentId,
            imcPontuation: Number(data.imcPontuation),
            weightLoss: Number(data.weightLoss),
            cannotFeed: Number(data.cannotFeed)
          }
        },
        onCompleted: () => {
          toast.info(
            'Triagem do risco nutricional MUST atualizada com sucesso.'
          );
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  function createMUST(data: IFormData) {
    if (!loadedValues)
      createMustMutation({
        variables: {
          must: {
            appointmentId,
            imcPontuation: Number(data.imcPontuation),
            weightLoss: Number(data.weightLoss),
            cannotFeed: Number(data.cannotFeed)
          }
        },
        onCompleted: () => {
          toast.success(
            'Triagem do risco nutricional MUST criada com sucesso.'
          );
          history.push('/home/nutritional-evaluation');
        }
      });
  }

  function onSubmit(form: IFormData) {
    try {
      createMUST(form);
      updateMUST(form);
    } catch {
      toast.error('Houve um erro.');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="38vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Ferramenta Universal de Triagem para Desnutrição (MUST)
            </Typography>
            <TooltipComponent content="Utilizada em pacientes adultos" />
          </Box>
          <Divider />
          <Box display="flex" margin="15px 0">
            <InputWithNumberMask
              label="IMC kg/m²"
              decimalScale={2}
              placeholder="Ex: 00,00"
              name="imc"
              control={control}
            />

            <RadioButtonInput
              control={control}
              name="weightLoss"
              title="Perda de peso involuntária
                nos últimos 3 a 6 meses:"
              disabled={false}
              radioButtons={[
                { checked: watchWeightLoss === '0', label: '< 5%', value: '0' },
                {
                  checked: watchWeightLoss === '1',
                  label: '5% a 10%',
                  value: '1'
                },
                {
                  checked: watchWeightLoss === '2',
                  label: '0 > 1%',
                  value: '2'
                }
              ]}
            />
          </Box>
          <RadioButtonInput
            control={control}
            name="cannotFeed"
            title="Se o indivíduo está gravemente
                      doente e reduziu drasticamente
                      a ingestão nutricional ou se
                      prevê não conseguir alimentar-se durante menos de 5 dias:"
            disabled={false}
            radioButtons={[
              { checked: watchCannotFeed === '0', label: 'Não', value: '0' },
              { checked: watchCannotFeed === '2', label: 'Sim', value: '2' }
            ]}
          />
        </Box>
        <ChipComponent
          sx={{
            width: '100%',
            margin: '10px 0'
          }}
          color={{ colors: 'light-blue' }}
          label={`SOMA TOTAL ${result} PONTOS`}
        />
        <Box
          minHeight="31vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            width="100%"
            fontWeight="500"
            borderBottom="1px solid"
            borderColor="grey.200"
            fontSize="18px"
            color="grey.900"
          >
            Classificação
          </Typography>
          <ClassificationTable
            rows={[
              {
                color: { colors: 'light-red' },
                firstCellContent: 'Maior ou igual a 2',
                secondCellContent: 'Alto risco',
                selected: result >= 2 ? true : false
              },
              {
                color: { colors: 'light-yellow' },
                firstCellContent: '1',
                secondCellContent: 'Médio risco',
                selected: result === 1 ? true : false
              },
              {
                color: { colors: 'light-green' },
                firstCellContent: '0',
                secondCellContent: 'Baixo risco',
                selected: result === 0 ? true : false
              }
            ]}
          />
        </Box>
        <Button sx={{ display: 'none' }} type="submit" ref={ref}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default forwardRef(MUSTForm);
