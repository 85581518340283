import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  MenuItem,
  Menu,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';
import { theme } from 'styles/theme';
import { ButtonComponent } from '../Button';
import logo from 'assets/newLogo.png';
import { ArrowDropDown } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { ChangePassModal } from 'components/ChangePassModal';

function Header() {
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [changePass, setChangePass] = useState(false);

  const token = localStorage.getItem('user_token');

  useEffect(() => {
    if (!token) return;
    const decoded_token: {
      name: string;
      role: string;
      privacyTermSignDate: Date;
    } = jwt_decode(token);
    setUserName(decoded_token.name);
    //setPrivacyTerm(decoded_token.privacyTermSignDate?.toString());
    setUserRole(
      decoded_token.role === 'NUTRITIONIST' ? 'Nutricionista' : 'Institucional'
    );
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function logout() {
    localStorage.removeItem('user_token');
    history.push('/');
  }

  return (
    <>
      <AppBar
        sx={{ background: theme.palette.background.paper }}
        color="primary"
        position="static"
      >
        <Toolbar>
          <Box
            padding="0 35px"
            display="flex"
            flex="1"
            justifyContent="space-between"
          >
            <img height="50px" src={logo} alt="Nestlé Health Science" />
            {userRole === 'Nutricionista' ? (
              <ButtonComponent
                onClick={() => {
                  history.push('/home/appointment-and-pacient-data');
                }}
                title="+ Adicionar consulta"
                isTransparent={false}
              />
            ) : (
              ''
            )}
          </Box>
          <Box
            sx={{
              borderLeft: '1px solid',
              borderColor: 'divider'
            }}
            width="20%"
            height="65px"
            display="flex"
            justifyContent="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="end"
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: theme.palette.grey[900]
                }}
              >
                {userName}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: theme.palette.info.main
                }}
              >
                {userRole}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box sx={{ flexGrow: 0, padding: '5px' }}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    sx={{ background: theme.palette.divider }}
                    alt="Nutricionista"
                  />
                </IconButton>
                <Tooltip title="Configurações">
                  <IconButton onClick={handleOpenUserMenu}>
                    <ArrowDropDown
                      sx={{
                        color: theme.palette.divider
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={() => history.push('/dashboard/institutional')}
                  >
                    <Typography textAlign="center">
                      Dashboard Institucional
                    </Typography>
                  </MenuItem>
                  {userRole === 'Nutricionista' && (
                    <MenuItem
                      onClick={() => history.push('/dashboard/nutritionist')}
                    >
                      <Typography textAlign="center">Home</Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setChangePass(true)}>
                    <Typography textAlign="center">Alterar Senha</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ChangePassModal show={changePass} onClose={() => setChangePass(false)} />
    </>
  );
}

export { Header };
