import { Result } from '..';
import { parseWeeklyMonthlyAndDailyText } from 'utils/constants/weeklyMonthlyAndDailyFrequency';
import { Symptoms } from 'pages/Symptons/interfaces';

export function generateSymptomsResults(symptoms: Symptoms | undefined) {
  const symptomsResultArray: Result[] = [];
  if (symptoms?.constipation) {
    symptomsResultArray.push({
      contentTitle: 'Obstipação',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.constipation)
    });
  }
  if (symptoms?.nausea) {
    symptomsResultArray.push({
      contentTitle: 'Náusea',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.nausea)
    });
  }
  if (symptoms?.anosmia) {
    symptomsResultArray.push({
      contentTitle: 'Anosmia',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.anosmia)
    });
  }
  if (symptoms?.quicklySatisfied) {
    symptomsResultArray.push({
      contentTitle: 'Se sente rapidamente satisfeito',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.quicklySatisfied)
    });
  }
  if (symptoms?.dryMouth) {
    symptomsResultArray.push({
      contentTitle: 'Boca seca - Xerostomia',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.dryMouth)
    });
  }
  if (symptoms?.dysphagia) {
    symptomsResultArray.push({
      contentTitle: 'Problemas para engolir - Disfagia',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.dysphagia)
    });
  }
  if (symptoms?.noAppetite) {
    symptomsResultArray.push({
      contentTitle: 'Sem apetite, apenas sem vontade para comer',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.noAppetite)
    });
  }
  if (symptoms?.ache) {
    symptomsResultArray.push({
      contentTitle: 'Dor',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.ache)
    });
  }
  if (symptoms?.vomit) {
    symptomsResultArray.push({
      contentTitle: 'Vômito',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.vomit)
    });
  }
  if (symptoms?.smellBothers) {
    symptomsResultArray.push({
      contentTitle: 'Os cheiros incomodam',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.smellBothers)
    });
  }
  if (symptoms?.inappetence) {
    symptomsResultArray.push({
      contentTitle: 'Inapetência',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.inappetence)
    });
  }
  if (symptoms?.mucositis) {
    symptomsResultArray.push({
      contentTitle: 'Feridas na boca - Mucosite',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.mucositis)
    });
  }
  if (symptoms?.tiredness) {
    symptomsResultArray.push({
      contentTitle: 'Cansaço',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.tiredness)
    });
  }
  if (symptoms?.dysgeusia) {
    symptomsResultArray.push({
      contentTitle:
        'Os alimentos não têm gosto ou têm gosto estranho - Disgeusia',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.dysgeusia)
    });
  }
  if (symptoms?.diarrhea) {
    symptomsResultArray.push({
      contentTitle: 'Diarreia',
      content: parseWeeklyMonthlyAndDailyText(symptoms?.diarrhea)
    });
  }
  return symptomsResultArray;
}
