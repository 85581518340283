import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  TooltipComponent,
  ChipComponent,
  InputWithNumberMask,
  ClassificationTable
} from 'components';
import { theme } from 'styles/theme';
import {
  CREATE_ANTHROPOMETRIC,
  UPDATE_ANTHROPOMETRIC,
  AnthropometricMutationVars
} from 'services/graphql/mutations/anthropometricEvaluation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { AnthropometricData } from 'models/anthropometric';

interface IFormData {
  armCircumference: number;
}

export function calculateManArmCircumferencePercentil(
  sex: string,
  age: number
) {
  if (sex === 'MALE') {
    if (age >= 2 && age <= 2.9) return 16;
    if (age >= 3 && age <= 3.9) return 16.6;
    if (age >= 4 && age <= 4.9) return 16.9;
    if (age >= 5 && age <= 5.9) return 17.3;
    if (age >= 6 && age <= 6.9) return 17.9;
    if (age >= 7 && age <= 7.9) return 18.7;
    if (age >= 8 && age <= 8.9) return 19.5;
    if (age >= 9 && age <= 9.9) return 20.4;
    if (age >= 10 && age <= 10.9) return 21.2;
    if (age >= 11 && age <= 11.9) return 22.3;
    if (age >= 12 && age <= 12.9) return 23.6;
    if (age >= 13 && age <= 13.9) return 25;
    if (age >= 14 && age <= 14.9) return 26.2;
    if (age >= 15 && age <= 15.9) return 27.2;
    if (age >= 16 && age <= 16.9) return 28;
    if (age >= 17 && age <= 17.9) return 28.9;
    if (age >= 18 && age <= 18.9) return 30;
    if (age >= 19 && age <= 19.9) return 30.5;
    if (age >= 20 && age <= 29.9) return 31.8;
    if (age >= 30 && age <= 39.9) return 32.3;
    if (age >= 40 && age <= 49.9) return 33;
    if (age >= 50 && age <= 59.9) return 32.6;
    if (age >= 60 && age <= 69.9) return 32;
    if (age >= 70 && age <= 79.9) return 30.6;
    if (age >= 80 && age <= 90.9) return 28.9;
  }
  return 0;
}

export function calculateWomanArmCircumferencePercentil(
  sex: string,
  age: number
) {
  if (sex === 'FEMALE') {
    if (age >= 2 && age <= 2.9) return 15.9;
    if (age >= 3 && age <= 3.9) return 16.5;
    if (age >= 4 && age <= 4.9) return 16.7;
    if (age >= 5 && age <= 5.9) return 17.4;
    if (age >= 6 && age <= 6.9) return 18.1;
    if (age >= 7 && age <= 7.9) return 18.7;
    if (age >= 8 && age <= 8.9) return 19.5;
    if (age >= 9 && age <= 9.9) return 20.4;
    if (age >= 10 && age <= 10.9) return 21.8;
    if (age >= 11 && age <= 11.9) return 23.2;
    if (age >= 12 && age <= 12.9) return 24.4;
    if (age >= 13 && age <= 13.9) return 25.2;
    if (age >= 14 && age <= 14.9) return 25.8;
    if (age >= 15 && age <= 15.9) return 26.2;
    if (age >= 16 && age <= 16.9) return 26.6;
    if (age >= 17 && age <= 17.9) return 26.6;
    if (age >= 18 && age <= 18.9) return 26.7;
    if (age >= 19 && age <= 19.9) return 26.8;
    if (age >= 20 && age <= 29.9) return 28.1;
    if (age >= 30 && age <= 39.9) return 30.3;
    if (age >= 40 && age <= 49.9) return 31.4;
    if (age >= 50 && age <= 59.9) return 31.9;
    if (age >= 60 && age <= 69.9) return 31.4;
    if (age >= 70 && age <= 79.9) return 29.9;
    if (age >= 80 && age <= 90.9) return 27.8;
  }
  return 0;
}

const CBForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    sex: string;
    age: number;
    loadedCb: AnthropometricData | undefined;
    lastCb: AnthropometricData | undefined;
  }
> = ({ sex, age, loadedCb, lastCb }, ref) => {
  const { handleSubmit, control, watch, setValue } = useForm();
  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const watchArmCircumference = watch('armCircumference');
  const [result, setResult] = useState(0);
  const [armCircumferencePercentil, setArmCircumferencePercentil] = useState(0);
  const [createAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(CREATE_ANTHROPOMETRIC);
  const [updateAnthropometricMutation] = useMutation<
    null,
    AnthropometricMutationVars
  >(UPDATE_ANTHROPOMETRIC);

  useEffect(() => {
    setArmCircumferencePercentil(
      sex === 'MALE'
        ? calculateManArmCircumferencePercentil(sex, age)
        : calculateWomanArmCircumferencePercentil(sex, age)
    );
  }, []);

  function calculateArmCircumferenceAdequacy(armCircumference: number) {
    const result = (armCircumference / armCircumferencePercentil) * 100;
    setResult(result);
  }

  useEffect(() => {
    calculateArmCircumferenceAdequacy(watchArmCircumference);
  }, [watchArmCircumference]);

  function calculateResultText() {
    if (result < 70) return 'Desnutrição grave';
    if (result >= 70 && result < 80) return 'Desnutrição moderada';
    if (result >= 80 && result < 90) return 'Desnutrição leve';
    if (result >= 90 && result <= 110) return 'Eutrofia';
    if (result > 110 && result < 120) return 'Sobrepeso';
    if (result >= 120) return 'Obesidade';
    if (!result) return 'Aguardando circunferência';
  }

  function setLoadedValue(data: AnthropometricData | undefined) {
    if (!data) return;
    setValue('armCircumference', data.cb);
  }

  useEffect(() => {
    setLoadedValue(loadedCb);
  }, [loadedCb]);

  useEffect(() => {
    setLoadedValue(lastCb);
  }, [lastCb]);

  async function createAnthropometric({ armCircumference }: IFormData) {
    if (loadedCb) return;
    await createAnthropometricMutation({
      variables: {
        antropometricData: {
          appointmentId,
          cb: armCircumference
        }
      },
      onCompleted: () => {
        toast.success('Dados antropométricos adicionados.');
      }
    });
  }

  async function updateAnthropometric({ armCircumference }: IFormData) {
    if (!loadedCb) return;
    await updateAnthropometricMutation({
      variables: {
        antropometricData: {
          appointmentId,
          cb: armCircumference
        }
      },
      onCompleted: () => {
        toast.info('Dados antropométricos adicionados.');
      }
    });
  }

  function onSubmit(data: IFormData) {
    try {
      createAnthropometric(data);
      updateAnthropometric(data);
    } catch {
      toast.error('Houve um erro.');
    }
  }

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          minHeight="55vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              marginTop="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="500"
                fontSize="18px"
                color={theme.palette.grey[900]}
              >
                Circunferência do braço
              </Typography>
              <TooltipComponent content="Avalia a perda ou aquisição da massa magra" />
            </Box>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputWithNumberMask
              name="armCircumference"
              control={control}
              decimalScale={1}
              label={
                <Box>
                  <Typography>
                    Circunferência <i>(cm)</i>:
                  </Typography>
                </Box>
              }
              placeholder="Ex: 31cm"
              suffix="cm"
            />
            <ChipComponent
              color={{ colors: 'light-blue' }}
              label={`RESULTADO: ${calculateResultText()}`}
              size="small"
            />
          </Box>
          <Box>
            <ClassificationTable
              size="small"
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Menor que 70%',
                  secondCellContent: 'Desnutrição grave',
                  selected: result < 70
                },
                {
                  color: { colors: 'light-orange' },
                  firstCellContent: 'Entre 70% e 80%',
                  secondCellContent: 'Desnutrição moderada',
                  selected: result >= 70 && result < 80
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 80% e 90%',
                  secondCellContent: 'Desnutrição leve',
                  selected: result >= 80 && result < 90
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: 'Entre 90 e 110%',
                  secondCellContent: 'Eutrofia',
                  selected: result >= 90 && result < 110
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'Entre 110% e 120%',
                  secondCellContent: 'Sobrepeso',
                  selected: result >= 110 && result < 120
                },
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'Maior que 120%',
                  secondCellContent: 'Obesidade',
                  selected: result >= 120
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(CBForm);
