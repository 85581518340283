import React from 'react';
import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';

interface ContentsType {
  contentTitle?: string;
  content?: string;
}

interface InfoBoxProps {
  title: string;
  contents?: ContentsType[];
  children?: React.ReactNode;
  backgroundHighlight?: boolean;
}

export function InfoBox({
  title,
  contents,
  backgroundHighlight = false,
  children
}: InfoBoxProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="80px"
      border="1px solid"
      padding="0 15px"
      sx={{
        background: backgroundHighlight ? '' : theme.palette.grey[100]
      }}
      borderColor={theme.palette.divider}
    >
      <Typography
        sx={{ cursor: 'default' }}
        fontSize="14px"
        fontWeight="700"
        color="grey.900"
      >
        {title ? `${title}:` : ''}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {contents
          ? contents?.map((content, index) => {
              return (
                <Typography
                  sx={{ cursor: 'default' }}
                  margin="5px 0"
                  marginRight="10px"
                  key={index}
                  fontSize="14px"
                  color="grey.300"
                >
                  {content.contentTitle} <strong>{content.content}</strong>
                </Typography>
              );
            })
          : null}
        {children ? children : null}
      </Box>
    </Box>
  );
}
