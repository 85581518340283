import React from 'react';
import { Box, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from '../components';
import { ChipComponent } from 'components/Chip';
import { calculateSkeletalMuscleMass } from 'pages/BodyCompositionEvaluation/forms/dxa';

interface DxaProps {
  appointment: Appointment;
}

export function Dxa({ appointment }: DxaProps) {
  const Dxa = appointment.bodyCompositionDxa;
  if (!Dxa) {
    return <></>;
  }
  const skeletalMuscleMass = calculateSkeletalMuscleMass(
    Dxa.appendicularMuscleMass,
    appointment.NutritionalData.height
  );
  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Typography
        sx={{ padding: '0 20px' }}
        fontSize="16px"
        fontWeight="700"
        color="grey.900"
      >
        Densitometria por Dupla Emissão de Raio X (DXA)
      </Typography>
      <Box
        display="flex"
        padding="0 20px"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Box margin="15px 0" marginRight="15px" width="48%">
          <Label title="Quantidade de massa muscular apendicular em Kg:" />
          <ResultText title={Dxa.appendicularMuscleMass + 'kg'} />
        </Box>
        <ChipComponent
          label={`Índice de massa muscular apendicular esquelético (IMEA): 7,0 kg/m2: ${skeletalMuscleMass}KG/M²`}
          color={{ colors: 'light-blue' }}
        />
      </Box>
    </Box>
  );
}
