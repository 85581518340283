import { Box, FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

interface IMainContentContainerProps {
  headerTitle: string;
  children: React.ReactNode;
  useDataFromLastAppointmentSwitch: {
    hasLastAppointment: boolean;
    action?: () => void;
  };
}

function MainContentContainer({
  children,
  headerTitle,
  useDataFromLastAppointmentSwitch
}: IMainContentContainerProps) {
  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        borderRadius: '4px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
        marginBottom: '60px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '49px',
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 5px 0 20px',
          fontSize: '18px',
          color: theme.palette.grey[900],
          fontWeight: '500'
        }}
      >
        {headerTitle}
        {useDataFromLastAppointmentSwitch.hasLastAppointment ? (
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '14px'
              }
            }}
            onChange={useDataFromLastAppointmentSwitch.action}
            value="useDataFromLastAppoitment"
            control={<Switch color="info" />}
            label="Clique aqui para utilizar os dados da última consulta."
            labelPlacement="end"
          />
        ) : (
          ''
        )}
      </Box>
      {children}
    </Box>
  );
}

export { MainContentContainer };
