import { Result } from '..';
import { FullAppointment } from 'models/fullAppointment';
import { calculateSkeletalMuscleMass } from 'pages/BodyCompositionEvaluation/forms/dxa';
import { calculateTCMuscleMass } from 'pages/BodyCompositionEvaluation/forms/tc';

export function generateBodyCompositionTCResults(
  loadedAppointment: FullAppointment | undefined
) {
  if (loadedAppointment?.bodyCompositionTc) {
    const value = calculateTCMuscleMass(
      loadedAppointment.bodyCompositionTc.skeletalMuscleMass,
      loadedAppointment.NutritionalData.height
    );
    return {
      contentTitle: 'TC',
      content: `Índice de massa muscular esquelética (IME): ${value}²/m²
`
    };
  }
  return {
    contentTitle: 'TC',
    content: 'Não há dados para exibir'
  };
}

export function generateBodyCompositionDXAResults(
  loadedAppointment: FullAppointment | undefined
): Result {
  if (loadedAppointment?.bodyCompositionDxa) {
    const value = calculateSkeletalMuscleMass(
      loadedAppointment.bodyCompositionDxa.appendicularMuscleMass,
      loadedAppointment.NutritionalData.height
    );
    return {
      contentTitle: 'DXA',
      content: `Índice de massa muscular apendicular esquelético (IMEA): ${value} kg/m²`
    };
  }
  return {
    contentTitle: 'DXA',
    content: 'Não há dados para exibir'
  };
}
