import { gql } from '@apollo/client';

const CREATE_SYMPTOMS = gql`
  mutation createSymptoms($symptoms: SymptomsInput!) {
    createSymptoms(symptoms: $symptoms) {
      id
      appointmentId
    }
  }
`;

export { CREATE_SYMPTOMS };
