import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  ClassificationTable,
  DropdownInputComponent,
  Input,
  InputWithNumberMask,
  RadioButtonInput,
  TooltipComponent
} from 'components';
import {
  CREATE_NE_AGS,
  UPDATE_NE_AGS,
  AGSMutationsVars
} from 'services/graphql/mutations/nutritional-evaluation/ags';
import { theme } from 'styles/theme';
import { verifyWeightLossPercentage } from 'utils/functions';
import { AGS } from 'models/ags';
import { useHistory } from 'react-router-dom';

interface ASGForm {
  lost6Mont: number;
  wheighthangeLastWeeks: string;
  changeIngestion: string;
  changeIngDuration: number;
  changeIngType: string;
  noGastroSymptoms: boolean;
  nausea: boolean;
  vomit: boolean;
  diarrhea: boolean;
  anorexia: boolean;
  functionalCapacity: string;
  functionalCapDuration: number;
  functionalCapType: string;
  primaryDiagnosis: string;
  metabolicDemand: string;
  fatLoss: string;
  muscleLoss: string;
  ankleEdema: string;
  sacralEdema: string;
  ascites: string;
  diseaseChangeLastWeeks: string;
  subjectiveEvaluation: string;
}

type SymptomsKey =
  | 'noGastroSymptoms'
  | 'nausea'
  | 'vomit'
  | 'diarrhea'
  | 'anorexia';

interface SymptomsType {
  name: SymptomsKey;
  title: string;
}

const AGSForm: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    weight: number;
    habitualWeight: number;
    loadedData: AGS | undefined;
    lastAGS: AGS | undefined;
  }
> = ({ weight, habitualWeight, loadedData, lastAGS }, ref) => {
  const { handleSubmit, control, watch, setValue, getValues } =
    useForm<ASGForm>({
      defaultValues: {
        lost6Mont: 0,
        wheighthangeLastWeeks: 'increase',
        changeIngestion: 'noAlteration',
        changeIngType: 'solid',
        noGastroSymptoms: false,
        nausea: false,
        vomit: false,
        diarrhea: false,
        anorexia: false,
        functionalCapacity: 'noDisfunction',
        functionalCapType: 'suboptimalWork',
        metabolicDemand: 'noStress',
        fatLoss: '0',
        muscleLoss: '0',
        ankleEdema: '0',
        sacralEdema: '0',
        ascites: '0',
        diseaseChangeLastWeeks: 'increase',
        subjectiveEvaluation: 'healthly',
        primaryDiagnosis: ''
      }
    });
  const history = useHistory();
  const [createASGMutation] = useMutation<null, AGSMutationsVars>(
    CREATE_NE_AGS
  );
  const [updateASGMutation] = useMutation<null, AGSMutationsVars>(
    UPDATE_NE_AGS
  );

  const appointmentId = Number(localStorage.getItem('appointmentId'));
  const watchWeightChange = watch('wheighthangeLastWeeks');
  const watchChangeIngestion = watch('changeIngestion');
  const watchChangeIngType = watch('changeIngType');
  const watchFunctionaCapacity = watch('functionalCapacity');
  const watchFunctionaCapacityType = watch('functionalCapType');
  const watchMetabolicDemand = watch('metabolicDemand');
  const watchDiseaseChangeLastWeeks = watch('diseaseChangeLastWeeks');
  const watchSubjectiveEvaluation = watch('subjectiveEvaluation');

  const [weightLoss, setWeightLoss] = useState<number>();

  useEffect(() => {
    if (weight) {
      setValue('lost6Mont', habitualWeight - weight);
      setWeightLoss(verifyWeightLossPercentage(weight, habitualWeight));
    }
  }, []);

  const symptoms: SymptomsType[] = [
    {
      name: 'noGastroSymptoms',
      title: 'Nenhum'
    },
    {
      name: 'nausea',
      title: 'Naúsea'
    },
    {
      name: 'vomit',
      title: 'Vômitos'
    },
    {
      name: 'diarrhea',
      title: 'Diarreia'
    },
    {
      name: 'anorexia',
      title: 'Anorexia'
    }
  ];

  function generateSymptoms(symptoms: SymptomsType[]) {
    return symptoms.map(({ name, title }) => {
      return (
        <FormControlLabel
          key={name}
          label={title}
          sx={{
            color: theme.palette.grey[900]
          }}
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={getValues(name) ? true : false}
              render={({ field }) => (
                <Checkbox
                  color="info"
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          }
        />
      );
    });
  }

  const dropdownValues = [
    {
      label: '0 = normal',
      value: '0'
    },
    {
      label: '+ 1 = Leve',
      value: '1'
    },
    {
      label: '+ 2 = Moderado',
      value: '2'
    },
    {
      label: '+ 3 = Grave',
      value: '3'
    }
  ];

  function loadData(data: AGS | undefined) {
    if (!data) return;
    setValue('lost6Mont', data.lost6Mont);
    setValue('wheighthangeLastWeeks', data.wheighthangeLastWeeks);
    setValue('changeIngestion', data.changeIngestion);
    setValue('changeIngDuration', data.changeIngDuration);
    setValue('changeIngType', data.changeIngType);
    setValue('noGastroSymptoms', data.noGastroSymptoms);
    setValue('nausea', data.nausea);
    setValue('vomit', data.vomit);
    setValue('diarrhea', data.diarrhea);
    setValue('anorexia', data.anorexia);
    setValue('functionalCapacity', data.functionalCapacity);
    setValue('functionalCapDuration', data.functionalCapDuration);
    setValue('functionalCapType', data.functionalCapType);
    setValue('primaryDiagnosis', data.primaryDiagnosis);
    setValue('metabolicDemand', data.metabolicDemand);
    setValue('fatLoss', data.fatLoss);
    setValue('muscleLoss', data.muscleLoss);
    setValue('ankleEdema', data.ankleEdema);
    setValue('sacralEdema', data.sacralEdema);
    setValue('ascites', data.ascites);
    setValue('diseaseChangeLastWeeks', data.diseaseChangeLastWeeks);
    setValue('subjectiveEvaluation', data.subjectiveEvaluation);
  }

  useEffect(() => {
    loadData(loadedData);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadData(lastAGS);
  }, [lastAGS]);

  async function updateASGMutationFunction(data: ASGForm) {
    if (loadedData)
      await updateASGMutation({
        variables: {
          nutritionalEvaluationAgs: {
            appointmentId,
            ...data,
            changeIngDuration: Number(data.changeIngDuration),
            functionalCapDuration: Number(data.functionalCapDuration)
          }
        },
        onCompleted: () => {
          toast.info('Avaliação nutricional AGS atualizada com sucesso.');
          history.push('/home/anthropometric-evaluation');
        }
      });
  }

  async function createASGMutationFunction(data: ASGForm) {
    if (loadedData) return;
    await createASGMutation({
      variables: {
        nutritionalEvaluationAgs: {
          appointmentId,
          ...data,
          changeIngDuration: Number(data.changeIngDuration),
          functionalCapDuration: Number(data.functionalCapDuration)
        }
      },
      onCompleted: () => {
        toast.success('Avaliação nutricional AGS criada com sucesso.');
        history.push('/home/anthropometric-evaluation');
      }
    });
  }

  function onSubmit(data: ASGForm) {
    try {
      createASGMutationFunction(data);
      updateASGMutationFunction(data);
    } catch {
      toast.error('Houve um erro');
    }
  }
  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="235vh"
        >
          <Box display="flex" alignItems="center" marginTop="15px">
            <Typography fontWeight="500" fontSize="18px" color="grey.900">
              Avaliação subjetiva global do estado nutricional (AGS)
            </Typography>
            <TooltipComponent content="Inclui histórico médico (peso, ingestão alimentar, sintomas gastrointestinais, capacidade funcional) e exame físico." />
          </Box>
          <Divider />
          <Box marginTop="15px" display="flex" flexDirection="column">
            <Typography
              fontSize="14px"
              color={theme.palette.grey[900]}
              fontWeight="700"
            >
              HISTÓRIA
            </Typography>
            <Typography
              marginTop="10px"
              fontSize="14px"
              color={theme.palette.info.main}
              fontWeight="700"
            >
              1. Alteração no peso:
            </Typography>
            <Box display="flex" width="100%" justifyContent="space-between">
              <InputWithNumberMask
                sx={{ width: '48%' }}
                disabled
                control={control}
                decimalScale={1}
                suffix="kg"
                name="lost6Mont"
                label={
                  <Typography>
                    Perda total nos últimos 6 meses <i>(kg):</i>
                  </Typography>
                }
                placeholder="Ex: 12kg"
              />
              <TextField
                disabled
                value={weightLoss + `%`}
                placeholder="Ex: 00%"
                label={
                  <Typography>
                    Perda <i>(%):</i>
                  </Typography>
                }
                variant="standard"
                sx={{ width: '48%' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
          </Box>
          <Box>
            <RadioButtonInput
              control={control}
              name="wheighthangeLastWeeks"
              disabled={false}
              title="Alteração nas últimas duas semanas:"
              radioButtons={[
                {
                  label: 'Aumento',
                  value: 'increase',
                  checked: watchWeightChange === 'increase'
                },
                {
                  label: 'Sem alteração',
                  value: 'noAlteration',
                  checked: watchWeightChange === 'noAlteration'
                },
                {
                  label: 'Diminuição',
                  value: 'decrease',
                  checked: watchWeightChange === 'decrease'
                }
              ]}
            />
          </Box>
          <Box
            margin="10px 0"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="30vh"
          >
            <RadioButtonInput
              control={control}
              name="changeIngestion"
              disabled={false}
              title={
                <Typography
                  fontSize="14px"
                  color={theme.palette.info.main}
                  fontWeight="700"
                >
                  2. Alteração na ingestão alimentar:
                </Typography>
              }
              radioButtons={[
                {
                  label: 'Sem alteração',
                  value: 'noAlteration',
                  checked: watchChangeIngestion === 'noAlteration'
                },
                {
                  label: 'Alterada',
                  value: 'changed',
                  checked: watchChangeIngestion === 'changed'
                }
              ]}
            />
            <InputWithNumberMask
              control={control}
              disabled={watchChangeIngestion === 'noAlteration'}
              name="changeIngDuration"
              placeholder="Digite aqui"
              decimalScale={0}
              label={
                <Typography>
                  Duração <i>(semanas):</i>
                </Typography>
              }
            />
            <RadioButtonInput
              control={control}
              name="changeIngType"
              disabled={watchChangeIngestion === 'noAlteration'}
              title="Tipo:"
              radioButtons={[
                {
                  label: 'Dieta sólida subótima',
                  value: 'solid',
                  checked: watchChangeIngType === 'solid'
                },
                {
                  label: 'Dieta líquida completa',
                  value: 'liquid',
                  checked: watchChangeIngType === 'liquid'
                },
                {
                  label: 'Líquidos hipocalóricos',
                  value: 'hypocaloricLiquids',
                  checked: watchChangeIngType === 'hypocaloricLiquids'
                },
                {
                  label: 'Inanição',
                  value: 'starvation ',
                  checked: watchChangeIngType === 'starvation '
                }
              ]}
            />
          </Box>
          <Box>
            <Typography
              fontSize="14px"
              color={theme.palette.info.main}
              fontWeight="700"
            >
              3. Sintomas gastrintestinais:{' '}
              <i>(que persistam por {'>'} 2 semanas)</i>
            </Typography>
            <FormGroup
              row
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              {generateSymptoms(symptoms)}
            </FormGroup>
          </Box>
          <Box>
            <RadioButtonInput
              control={control}
              name="functionalCapacity"
              disabled={false}
              title={
                <Typography
                  fontSize="14px"
                  color={theme.palette.info.main}
                  fontWeight="700"
                >
                  4. Capacidade funcional:
                </Typography>
              }
              radioButtons={[
                {
                  label: 'Sem disfunção (capacidade completa)',
                  value: 'noDisfunction',
                  checked: watchFunctionaCapacity === 'noDisfunction'
                },
                {
                  label: 'Disfunção',
                  value: 'disfunction',
                  checked: watchFunctionaCapacity === 'disfunction'
                }
              ]}
            />
            <InputWithNumberMask
              disabled={watchFunctionaCapacity === 'noDisfunction'}
              sx={{ margin: '10px 0' }}
              control={control}
              name="functionalCapDuration"
              decimalScale={0}
              placeholder="Digite aqui"
              label={
                <Typography>
                  Duração <i>(semanas):</i>
                </Typography>
              }
            />
            <Box marginTop="5px" marginBottom="10px">
              <RadioButtonInput
                control={control}
                name="functionalCapType"
                disabled={watchFunctionaCapacity === 'noDisfunction'}
                title="Tipo:"
                radioButtons={[
                  {
                    label: 'Trabalho subótimo',
                    value: 'suboptimalWork',
                    checked: watchFunctionaCapacityType === 'suboptimalWork'
                  },
                  {
                    label: 'Ambulatório',
                    value: 'ambulatory',
                    checked: watchFunctionaCapacityType === 'ambulatory'
                  },
                  {
                    label: 'Acamado',
                    value: 'bedridden',
                    checked: watchFunctionaCapacityType === 'bedridden'
                  }
                ]}
              />
            </Box>
          </Box>
          <Box margin="10px 0">
            <Typography
              fontSize="14px"
              color={theme.palette.info.main}
              fontWeight="700"
            >
              5. Doença e sua relação com necessidades nutricionais:
            </Typography>
            <Input
              sx={{ width: '100%', marginTop: '5px', marginBottom: '12px' }}
              control={control}
              label={
                <Typography>
                  Diagnóstico primário <i>(especificar)</i>
                </Typography>
              }
              name="primaryDiagnosis"
              placeholder="Digite aqui"
            />
            <RadioButtonInput
              control={control}
              name="metabolicDemand"
              disabled={false}
              title={
                <Typography fontSize="14px">
                  Demanda metabólica <i>(estresse):</i>
                </Typography>
              }
              radioButtons={[
                {
                  label: 'Sem estresse',
                  value: 'noStress',
                  checked: watchMetabolicDemand === 'noStress'
                },
                {
                  label: 'Baixo estresse',
                  value: 'low',
                  checked: watchMetabolicDemand === 'low'
                },
                {
                  label: 'Estresse moderado',
                  value: 'moderate',
                  checked: watchMetabolicDemand === 'moderate'
                },
                {
                  label: 'Estresse elevado',
                  value: 'high',
                  checked: watchMetabolicDemand === 'high'
                }
              ]}
            />
          </Box>
          <Box>
            <Typography
              fontSize="14px"
              color={theme.palette.grey[900]}
              fontWeight="700"
            >
              EXAME FÍSICO:
            </Typography>
            <Typography
              fontSize="14px"
              color={theme.palette.grey[300]}
              fontWeight="400"
            >
              (para cada categoria, especificar: 0 = normal, 1+ = leve, 2+ =
              moderada, 3+ = grave).
            </Typography>
            <Box marginTop="20px" display="flex" justifyContent="space-between">
              <DropdownInputComponent
                sx={{
                  width: '48%'
                }}
                control={control}
                name="fatLoss"
                select={dropdownValues.map(({ label, value }) => {
                  return { label, value };
                })}
                label={
                  <Box marginTop="-5px" position="absolute">
                    <Typography fontSize="14px">
                      Perda de gordura subctânea <i>(tríceps, torax)</i>
                    </Typography>
                  </Box>
                }
              />
              <DropdownInputComponent
                sx={{
                  width: '48%'
                }}
                control={control}
                name="muscleLoss"
                select={dropdownValues.map(({ label, value }) => {
                  return { label, value };
                })}
                label={
                  <Box marginTop="-5px" position="absolute">
                    <Typography fontSize="14px">
                      Perda muscular <i>(quadríceps, deltoide)</i>
                    </Typography>
                  </Box>
                }
              />
            </Box>

            <Box marginTop="20px" display="flex" justifyContent="space-between">
              <DropdownInputComponent
                sx={{
                  width: '30%'
                }}
                control={control}
                name="ankleEdema"
                select={dropdownValues.map(({ label, value }) => {
                  return { label, value };
                })}
                label={
                  <Box marginTop="-5px" position="absolute">
                    <Typography fontSize="14px">Edema tornozelo</Typography>
                  </Box>
                }
              />
              <DropdownInputComponent
                sx={{
                  width: '30%'
                }}
                control={control}
                name="sacralEdema"
                select={dropdownValues.map(({ label, value }) => {
                  return { label, value };
                })}
                label={
                  <Box marginTop="-5px" position="absolute">
                    <Typography fontSize="14px">Edema sacral</Typography>
                  </Box>
                }
              />
              <DropdownInputComponent
                sx={{
                  width: '30%'
                }}
                control={control}
                name="ascites"
                select={dropdownValues.map(({ label, value }) => {
                  return { label, value };
                })}
                label={
                  <Box marginTop="-5px" position="absolute">
                    <Typography fontSize="14px">Ascite</Typography>
                  </Box>
                }
              />
            </Box>
            <Box sx={{ margin: '10px 0' }}>
              <RadioButtonInput
                control={control}
                name="diseaseChangeLastWeeks"
                disabled={false}
                title="Alteração nas últimas duas semanas:"
                radioButtons={[
                  {
                    label: 'Aumento',
                    value: 'increase',
                    checked: watchDiseaseChangeLastWeeks === 'increase'
                  },
                  {
                    label: 'Sem alteração',
                    value: 'noAlteration',
                    checked: watchDiseaseChangeLastWeeks === 'noAlteration'
                  },
                  {
                    label: 'Diminuição',
                    value: 'decrease',
                    checked: watchDiseaseChangeLastWeeks === 'decrease'
                  }
                ]}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              fontSize="14px"
              color={theme.palette.grey[900]}
              fontWeight="700"
            >
              AVALIAÇÃO SUBJETIVA GLOBAL:
            </Typography>
            <Typography
              fontSize="14px"
              color={theme.palette.grey[300]}
              fontWeight="700"
            >
              Com base nas características da história e do exame físico, os
              profissionais devem identificar uma classificação ASG que indica o
              estado nutricional do paciente, com base na ponderação subjetiva e
              não em um esquema numérico explícito. É recomendado colocar maior
              peso dos julgamentos nas variáveis: perda de peso, má ingestão
              alimentar, perda de tecido subcutâneo e perda de massa muscular.
            </Typography>

            <RadioButtonInput
              control={control}
              name="subjectiveEvaluation"
              disabled={false}
              title="Alteração nas últimas duas semanas:"
              radioButtons={[
                {
                  label: 'Bem nutrido',
                  value: 'healthly',
                  checked: watchSubjectiveEvaluation === 'healthly'
                },
                {
                  label: 'Moderadamente (ou suspeita de ser) desnutrido',
                  value: 'malnourish',
                  checked: watchSubjectiveEvaluation === 'malnourish'
                },
                {
                  label: 'Gravemente desnutrido',
                  value: 'severeMalnourish',
                  checked: watchSubjectiveEvaluation === 'severeMalnourish'
                }
              ]}
            />
          </Box>
          <Box
            minHeight="31vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              width="100%"
              fontWeight="500"
              borderBottom="1px solid"
              borderColor="grey.200"
              fontSize="18px"
              color="grey.900"
            >
              Classificação
            </Typography>
            <ClassificationTable
              rows={[
                {
                  color: { colors: 'light-red' },
                  firstCellContent: 'C',
                  secondCellContent: 'Gravemente desnutrido',
                  selected: watchSubjectiveEvaluation === 'severeMalnourish'
                },
                {
                  color: { colors: 'light-yellow' },
                  firstCellContent: 'B',
                  secondCellContent:
                    'Moderadamente (ou suspeita de ser) desnutrido',
                  selected: watchSubjectiveEvaluation === 'malnourish'
                },
                {
                  color: { colors: 'light-green' },
                  firstCellContent: 'A',
                  secondCellContent: 'Bem nutrido',
                  selected: watchSubjectiveEvaluation === 'healthly'
                }
              ]}
            />
          </Box>
          <Button sx={{ display: 'none' }} type="submit" ref={ref}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default forwardRef(AGSForm);
