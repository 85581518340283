import { Appointment } from 'models/appointment';

export const prepareAntropometric = (appointments: Appointment[]) => {
  return appointments.map((a, i) => ({
    name: i < 10 ? `C0${i + 1}` : `C${i + 1}`,
    CB: a.antropometric?.cb || 0,
    DTC: a.antropometric?.dct || 0,
    CP: a.antropometric?.cp || 0
  }));
};
