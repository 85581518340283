import { gql } from '@apollo/client';

export const CREATE_MAN = gql`
  mutation createMan($man: ManInput!) {
    createMan(man: $man) {
      id
      appointmentId
    }
  }
`;
