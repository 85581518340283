import { Statistic } from 'models/dashboard';

export const prepareNutriSup = (nutrisups: Statistic[][]) => {
  const ret: { data: any[]; keys: string[] } = { data: [], keys: [] };

  let i = 1;
  for (const ns of nutrisups) {
    const data: any = {};
    data.id = i < 10 ? `C0${i}` : `C${i}`;

    if (ns) {
      for (const n of ns) {
        data[n.name] = n.quantity;
        if (ret.keys.indexOf(n.name) === -1) ret.keys.push(n.name);
      }
    }

    ret.data.push(data);
    i++;
  }

  return ret;
};
