import { gql } from '@apollo/client';

export const UPDATE_BIA = gql`
  mutation updateBia($bia: BiaInput!) {
    updateBia(bia: $bia) {
      appointmentId
      id
    }
  }
`;
