import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Appointment } from 'models/appointment';
import { Label, ResultText } from './components';
import { AnthropometricData as AnthropometricInterface } from 'models/anthropometric';
import {
  calculateManArmCircumferencePercentil,
  calculateWomanArmCircumferencePercentil
} from 'pages/AnthropometricEvaluation/forms/cb';
import { calculatePatientAge } from 'utils/functions/calculateLoadedPatientAge';
import {
  calculateManDCTPercentil,
  calculateWomanDCTPercentil
} from 'pages/AnthropometricEvaluation/forms/dct';
import {
  calculateCMBTextResult,
  calculateCMBValue,
  calculateManCMBPercentil,
  calculateWomanCMBPercentil
} from 'pages/AnthropometricEvaluation/forms/cmb';
import {
  calculateAMBValue,
  calculateManAMBPercentil,
  calculateWomanAMBPercentil
} from 'pages/AnthropometricEvaluation/forms/amb';
import { ExpandMore } from '../fullAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { editAppointment } from './editAppointment';
import { Edit } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

interface AnthropometricProps {
  appointment: Appointment;
  gender: string;
  birthdate: Date;
}

type AnthropometricKeys = 'cp' | 'cb' | 'dct';

interface AnthropometricObjectKey {
  key: AnthropometricKeys;
}

const allAnthropometricFields: AnthropometricObjectKey[] = [
  { key: 'cp' },
  { key: 'cb' },
  { key: 'dct' }
];

type MappedType<T> = {
  [P in keyof T]: T[P];
};

type newAnthropometricType = MappedType<AnthropometricInterface>;

export function Anthropometric({
  appointment,
  gender,
  birthdate
}: AnthropometricProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const Anthropometric: newAnthropometricType = appointment.antropometric;
  const age = calculatePatientAge(birthdate);
  if (!Anthropometric) {
    return (
      <>
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |9. Avaliação antropomêtrica
        </Typography>
        <Typography fontSize="18px" fontWeight="700" color="grey.900">
          Não há dados
        </Typography>
      </>
    );
  }

  function parseAnthropometricQuestion(value: string) {
    switch (value) {
      case 'cp':
        return 'Circunferência da panturrilha:';
      case 'cb':
        return 'Circunferência do braço:';
      case 'dct':
        return 'Dobra cutânea tricipital:';
      default:
        return 'Não há dados';
    }
  }

  function generateAnthropometricEvaluationCPResults(cp: number) {
    if (gender === 'MALE') {
      return {
        value: cp + 'cm',
        result: cp !== 34 ? 'Anormal' : 'Normal'
      };
    }
    if (gender === 'FEMALE') {
      return {
        value: cp + 'cm',
        result: cp !== 33 ? 'Anormal' : 'Normal'
      };
    }
  }

  function CBorDCTResults(result: number) {
    if (result < 70) return 'Desnutrição grave';
    if (result >= 70 && result < 80) return 'Desnutrição moderada';
    if (result >= 80 && result < 90) return 'Desnutrição leve';
    if (result >= 90 && result <= 110) return 'Eutrofia';
    if (result > 110 && result < 120) return 'Sobrepeso';
    if (result >= 120) return 'Obesidade';
  }

  function generateAnthropometricEvaluationCBResults(cb: number) {
    const CBPercentil =
      gender === 'MALE'
        ? calculateManArmCircumferencePercentil(gender, age)
        : calculateWomanArmCircumferencePercentil(gender, age);
    const result = (cb / CBPercentil) * 100;
    return {
      value: cb + 'cm',
      result: CBorDCTResults(result)
    };
  }

  function generateAnthropometricEvaluationDCTResults(dct: number) {
    const DCTPercentil =
      gender === 'MALE'
        ? calculateManDCTPercentil(gender, age)
        : calculateWomanDCTPercentil(gender, age);
    const result = (dct / DCTPercentil) * 100;
    return {
      value: dct + 'cm',
      result: CBorDCTResults(result)
    };
  }
  function generateAnthropometricEvaluationCMBResults(dct: number, cb: number) {
    const CMBPercentil =
      gender === 'MALE'
        ? calculateManCMBPercentil(gender, age)
        : calculateWomanCMBPercentil(gender, age);
    const result = (calculateCMBValue(cb || 0, dct || 0) / CMBPercentil) * 100;
    return {
      value: result,
      result: calculateCMBTextResult(result)
    };
  }
  function generateAnthropometricEvaluationAMBResults(dct: number, cb: number) {
    const AMBPercentil =
      gender === 'MALE'
        ? calculateManAMBPercentil(gender, age)
        : calculateWomanAMBPercentil(gender, age);
    const result =
      (calculateAMBValue(gender, cb || 0, dct || 0) / AMBPercentil) * 100;
    return {
      value: result,
      result: calculateCMBTextResult(result)
    };
  }

  function parseAnthropometricAnswers(key: string, value: number | undefined) {
    if (key === 'cp') {
      return {
        value: generateAnthropometricEvaluationCPResults(value || 0)?.value,
        result: generateAnthropometricEvaluationCPResults(value || 0)?.result
      };
    }
    if (key === 'cb') {
      return {
        value: generateAnthropometricEvaluationCBResults(value || 0).value,
        result: generateAnthropometricEvaluationCBResults(value || 0).result
      };
    }
    if (key === 'dct') {
      return {
        value: generateAnthropometricEvaluationDCTResults(value || 0).value,
        result: generateAnthropometricEvaluationDCTResults(value || 0).result
      };
    }
  }

  const history = useHistory();
  const appointmentId = appointment.id.toString();
  const patientId = appointment.patientId.toString();

  return (
    <Box display="flex" flexDirection="column" marginTop="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="700" color="info.main">
          |9. Avaliação antropomêtrica
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              editAppointment(appointmentId, patientId, () =>
                history.push('/home/anthropometric-evaluation')
              )
            }
          >
            <Edit />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          padding="0 20px"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {allAnthropometricFields.map(({ key }, index) => {
            return Anthropometric[key] ? (
              <Box key={index} margin="15px 0" marginRight="15px" width="48%">
                <Label title={parseAnthropometricQuestion(key)} />
                <ResultText
                  title={`${
                    parseAnthropometricAnswers(key, Anthropometric[key])?.value
                  }: ${
                    parseAnthropometricAnswers(key, Anthropometric[key])?.result
                  }`}
                />
              </Box>
            ) : (
              ''
            );
          })}
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title="Circunferência muscular do braço" />
            <ResultText
              title={`${calculateCMBValue(
                Anthropometric.cb || 0,
                Anthropometric.dct || 0
              )}cm: ${
                generateAnthropometricEvaluationCMBResults(
                  Anthropometric.dct || 0,
                  Anthropometric.cb || 0
                ).result
              }`}
            />
          </Box>
          <Box margin="15px 0" marginRight="15px" width="48%">
            <Label title="Área muscular do braço" />
            <ResultText
              title={`${calculateAMBValue(
                gender,
                Anthropometric.cb || 0,
                Anthropometric.dct || 0
              )}cm: ${
                generateAnthropometricEvaluationAMBResults(
                  Anthropometric.dct || 0,
                  Anthropometric.cb || 0
                ).result
              }`}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
