import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { RemovableChipComponent } from '../RemovableChipComponent';

export interface AutocompleteOptionType {
  label: string;
  value: string;
}

interface IMuiAutoCompleteProps {
  options: AutocompleteOptionType[];
  label: string | React.ReactNode;
  placeholder: string;
  chipsHeader: string;
  errorFromForm: any;
  loadedValues: AutocompleteOptionType[] | undefined;
  actionOnChange?: (data: AutocompleteOptionType[]) => void;
}

function AutoCompleteComponent({
  options,
  label,
  placeholder,
  chipsHeader,
  errorFromForm,
  actionOnChange,
  loadedValues
}: IMuiAutoCompleteProps) {
  const [value, setValue] = useState<AutocompleteOptionType[]>([]);
  function deleteChip(chipToDelete: string) {
    setValue((value) => value.filter((chip) => chip.label !== chipToDelete));
    if (actionOnChange)
      actionOnChange(value.filter((chip) => chip.label !== chipToDelete));
  }

  function addChip(newData: AutocompleteOptionType[]) {
    setValue(newData);
  }

  function setLoadedValues() {
    if (!loadedValues) return;
    setValue(loadedValues);
  }

  useEffect(() => {
    setLoadedValues();
  }, [loadedValues]);

  return (
    <>
      <Autocomplete
        renderTags={() => null}
        sx={{ width: '100%' }}
        multiple
        value={value}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            error={!!errorFromForm}
            helperText={errorFromForm ? errorFromForm.message : null}
            label={label}
            placeholder={placeholder}
            {...params}
            variant="standard"
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        onChange={(_, data) => {
          addChip(data);
          actionOnChange ? actionOnChange(data) : '';
        }}
      />
      <Box minHeight="fit-content">
        {value[0] ? (
          <Typography fontSize="14px" color="grey.900">
            {chipsHeader}
          </Typography>
        ) : (
          ''
        )}
        {value.map(({ label }, index) => {
          return (
            <RemovableChipComponent
              key={index}
              chipTitle={label}
              deleteChip={() => deleteChip(label)}
            />
          );
        })}
      </Box>
    </>
  );
}
export { AutoCompleteComponent };
